import { FC } from 'react';
import { mergeCss } from 'src/web/common/styles/createCss';
import LogoIconImg from 'src/web/resources/images/logo_icon.svg';
import LogoTextImg from 'src/web/resources/images/logo_text.svg';
import * as styles from './style';

export type LogoVesrion = 'minimal' | 'default';

interface FleezyLogoProps {
  className?: string;
  version?: LogoVesrion;
}

const FleezyLogo: FC<FleezyLogoProps> = ({ className, version = 'default' }) => {
  return (
    <div>
      <img
        src={LogoIconImg}
        alt="Fleezy"
        className={mergeCss(styles.LogoStyles, styles.iconStyles, className)}
      />
      <img
        src={LogoTextImg}
        alt="Fleezy"
        className={mergeCss(styles.LogoStyles, styles.TextStyles(version), className)}
      />
    </div>
  );
};

export default FleezyLogo;
