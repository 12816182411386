import { useIntl } from 'react-intl';

const useTranslate = () => {
  const intl = useIntl();

  const translate = (id: string, variables?: Record<string, string>): string => {
    return intl.formatMessage({ id }, variables);
  };

  // custom method because Lokalise + React-intl plural support seems difficul to setup
  const translateWithPlural = (
    singularId: string,
    pluralId: string,
    count: number,
    variables?: Record<string, string>,
  ): string => {
    if (count > 1) {
      return intl.formatMessage({ id: pluralId }, variables);
    }
    return intl.formatMessage({ id: singularId }, variables);
  };

  const formatCountry = (code: string): string => {
    return (
      intl.formatDisplayName(code, { type: 'region', style: 'long', fallback: 'code' }) ?? code
    );
  };

  const formatLanguage = (code: string): string => {
    return (
      intl.formatDisplayName(code, { type: 'language', style: 'long', fallback: 'code' }) ?? code
    );
  };

  const formatCurrency = (code: string): string => {
    return (
      intl.formatDisplayName(code, { type: 'currency', style: 'long', fallback: 'code' }) ?? code
    );
  };

  const formatNumberWithCurrency = (value: number, code: string): string => {
    return (
      intl.formatNumber(value, { style: 'currency', currency: code, currencyDisplay: 'symbol' }) ??
      value
    );
  };

  const formatNumberWithUnit = (value: number, code: string): string => {
    return intl.formatNumber(value, { style: 'unit', unit: code, unitDisplay: 'short' }) ?? value;
  };

  const formatNumberWithPercent = (value: number): string => {
    return (
      intl.formatNumber(value / 100, {
        style: 'percent',
        signDisplay: 'never',
        maximumFractionDigits: 2,
      }) ?? value
    );
  };

  return {
    translate,
    translateWithPlural,
    formatCountry,
    formatLanguage,
    formatCurrency,
    formatNumberWithCurrency,
    formatNumberWithUnit,
    formatNumberWithPercent,
  };
};

export default useTranslate;
