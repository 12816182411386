import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { type PropsWithChildren, type FC, useRef } from 'react';

type ButtonSubmitProps = {
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
};

const CLICK_AGAIN_DELAY = 1000;

const ButtonSubmit: FC<PropsWithChildren<ButtonSubmitProps>> = ({
  children,
  className,
  isLoading,
  fullWidth,
  disabled,
  onClick,
}) => {
  const lastSubmit = useRef<number>();

  const handleClick = () => {
    const now = Date.now();
    const canSubmit =
      !isLoading && (!lastSubmit.current || now - lastSubmit.current > CLICK_AGAIN_DELAY);

    if (!!onClick && canSubmit) {
      lastSubmit.current = now;
      onClick();
    }
  };

  return (
    <MuiLoadingButton
      color={'primary'}
      type={'submit'}
      className={className}
      variant={'contained'}
      size={'large'}
      onClick={() => handleClick()}
      loading={isLoading}
      loadingPosition="center"
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {children}
    </MuiLoadingButton>
  );
};

export default ButtonSubmit;
