import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface TranslateProps {
  id: string;
  variables?: Record<string, string>;
}

const Translate: FC<TranslateProps> = ({ id, variables }) => (
  <FormattedMessage id={id} values={variables} />
);

export default Translate;
