import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type CacheStorageClient from 'core/services/cache/cacheStorage.port';
import {
  CacheStorageDeleteState,
  type CacheStorage,
  type CacheStorageGetState,
  type CacheStorageSetState,
} from 'core/services/cache/cacheStorage.port';

class ZustandCacheAdapter implements CacheStorageClient {
  private cacheStorage;

  private initialState = {
    cache: {},
  };

  constructor() {
    this.cacheStorage = createStore<CacheStorage>()(immer(devtools(() => this.initialState)));
  }

  public reset(): void {
    // info: https://github.com/immerjs/immer/issues/296
    this.cacheStorage.setState(() => {
      return this.initialState;
    });
  }

  public getState: CacheStorageGetState = (handler) => {
    return handler(this.cacheStorage.getState());
  };

  public setState: CacheStorageSetState = (handler) => {
    this.cacheStorage.setState((a) => handler(a));
  };

  public deleteState: CacheStorageDeleteState = (key) => {
    this.cacheStorage.setState((state) => {
      if (key in state.cache) {
        delete state.cache[key];
      }
    });
  };
}

export default ZustandCacheAdapter;
