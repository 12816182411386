import { FC, PropsWithChildren } from 'react';

interface TabContentProps {
  isVisible: boolean;
}

const TabContent: FC<PropsWithChildren<TabContentProps>> = ({ isVisible, children }) => {
  return <div hidden={!isVisible}>{children}</div>;
};

export default TabContent;
