import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { MenuItemProps } from './MenuItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

type MenuItemExpendableProps = Omit<MenuItemProps, 'endIcon' | 'onClick' | 'isSelected'> & {
  iconOpen?: ReactNode;
  iconClose?: ReactNode;
  open?: boolean;
};

const DEFAULT_ICON_OPEN = <ExpandMore />;
const DEFAULT_ICON_CLOSE = <ExpandLess />;

const MenuItemExpendable: FC<PropsWithChildren<MenuItemExpendableProps>> = ({
  children,
  label,
  startIcon,
  iconOpen,
  iconClose,
  size,
  open,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const iconOpenComponent = iconOpen ?? DEFAULT_ICON_OPEN;
  const iconCloseComponent = iconClose ?? DEFAULT_ICON_CLOSE;

  return (
    <>
      <ListItemButton
        className={className}
        onClick={() => setIsOpen((state) => !state)}
        selected={open}
        dense={size === 'small'}
      >
        {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
        <ListItemText primary={label} inset={!startIcon} />
        <ListItemIcon>{isOpen ? iconCloseComponent : iconOpenComponent}</ListItemIcon>
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default MenuItemExpendable;
