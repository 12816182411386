import { useCallback, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import useGetAssignmentCertificate from './useGetAssignmentCertificate';
import { EntitiesEnum } from 'src/core/common/constants';

interface AssetAssignmentPostFormProps {
  assetIri: string;
  afterSubmit: (status: boolean) => void;
  generateAssignCertificate: boolean;
  personIri: string;
  entity: EntitiesEnum;
}

const useAssetAssignmentPostForm = ({
  assetIri,
  afterSubmit,
  generateAssignCertificate,
  personIri,
  entity,
}: AssetAssignmentPostFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { assignmentUseCase, cacheService } = useCore();
  const { handleGetAssignmentCertificate } = useGetAssignmentCertificate(assetIri);

  const assignmentHandler = useMemo(
    () => assignmentUseCase.assignAssetToPerson(assetIri),
    [assetIri],
  );

  const { properties, required, validate, submit, getInitialValues } = assignmentHandler;

  const [filteredPersons, SetFilteredPersons] = useState<
    Awaited<ReturnType<typeof assignmentHandler.handleSearch>>
  >([]);

  const form = useCreateForm({
    properties,
    initialValues: getInitialValues(),
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        cacheService.cacheInvalidate(`/api/private/${entity}`);
        if (afterSubmit) {
          afterSubmit(true);
          //condition to generate pdf certificate assignment
        }
      }
      if (generateAssignCertificate) {
        handleGetAssignmentCertificate(data.startAt ? data.startAt?.toString() : '', personIri);
      }
      setIsLoading(false);
    },
  });

  const onSearchPersons = useCallback(
    (value?: string, currentOnly = false) => {
      if (value && value.length >= 3) {
        assignmentHandler.handleSearch(value, currentOnly).then((persons) => {
          SetFilteredPersons(persons);
        });
      }
    },
    [assignmentHandler],
  );
  const handleReset = () => {
    form.handleReset();
  };
  return {
    form,
    onSearchPersons,
    persons: filteredPersons,
    handleReset,
    isLoading,
  };
};

export default useAssetAssignmentPostForm;
