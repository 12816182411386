import { useState } from 'react';
import { UiFormValues } from './types';
import { FormValidationErrors } from 'core/services/form/form.port';
import { validateValues } from './helpers';

interface UseValidationProps<K extends string> {
  properties: Record<K, any>;
  initialValues?: Partial<UiFormValues<K>>;
  required: K[];
  validate: (data: UiFormValues<K>) => FormValidationErrors<string>;
}

function useValidation<Keys extends string>(props: UseValidationProps<Keys>) {
  const [errors, setErrors] = useState<Partial<FormValidationErrors<Keys>>>({});
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  // on validate data
  const handleValidate = (formValues: UiFormValues<Keys>, key?: Keys) => {
    const currentErrors: FormValidationErrors<Keys> = validateValues({
      formValues,
      key,
      properties: props.properties,
      initialValues: props.initialValues,
      required: props.required,
      validate: props.validate,
    });

    const hasCurrentErrors =
      Object.keys(currentErrors).filter((errkey) => (props.required as string[]).includes(errkey))
        .length > 0;

    setHasErrors(hasCurrentErrors);

    if (key) {
      setErrors((current) => {
        let copy = { ...current };

        if (key in currentErrors) {
          copy = { ...copy, [key]: currentErrors[key] };
        } else if (key in copy) {
          delete copy[key];
        }

        return copy;
      });
    } else {
      setErrors(currentErrors);
    }

    return currentErrors;
  };

  return {
    errors,
    hasErrors,
    handleValidate,
  };
}

export default useValidation;
