import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import { AssetsTypesMap, EntitiesEnum } from 'core/common/constants';
import {
  ROUTE_CARD,
  ROUTE_COMPUTER,
  ROUTE_LICENCE,
  ROUTE_PERSON,
  ROUTE_SERVICE,
  ROUTE_TELEPHONE,
  ROUTE_TELEPHONE_LINE,
  ROUTE_USERS,
  ROUTE_VEHICULE,
} from 'src/web/common/router/constantes';

interface NotificationPreviewItem {
  id: string;
  topic: string;
  dateToDo?: string;
  subject: string;
  entityId: string;
  entityType: string;
  entityLink?: string;
  comment?: string;
  date: string;
}

const useHeaderNotification = () => {
  const { timeService /*notificationPreviewUseCase*/ } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NotificationPreviewItem[]>([]);
  const count = useMemo(() => data.length, [data]);

  const buildEntityLink = (entity: string, entityId: string) => {
    switch (entity) {
      case AssetsTypesMap[EntitiesEnum.person]:
        return `${ROUTE_PERSON}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.vehicle]:
        return `${ROUTE_VEHICULE}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.computer]:
        return `${ROUTE_COMPUTER}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.licence]:
        return `${ROUTE_LICENCE}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.service]:
        return `${ROUTE_SERVICE}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.telephone]:
        return `${ROUTE_TELEPHONE}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.telephoneLine]:
        return `${ROUTE_TELEPHONE_LINE}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.card]:
        return `${ROUTE_CARD}/${entityId}`;
      case AssetsTypesMap[EntitiesEnum.user]:
        return `${ROUTE_USERS}/${entityId}`;
    }
  };

  // const handleLoadNotifications = () => {
  //   setIsLoading(true);

  //   notificationPreviewUseCase.getNotificationPreview().then((itemData) => {
  //     if (itemData) {
  //       const list = itemData.map((item) => ({
  //         id: item.id,
  //         topic: item.topic,
  //         dateToDo: item.dateEvent ? timeService.format(item.dateEvent, 'short') : undefined,
  //         subject: item.content,
  //         entityId: item.entityId,
  //         entityType: item.entityType,
  //         comment: item.comment,
  //         entityLink: buildEntityLink(item.entityType, item.entityId),
  //         date: timeService.format(item.dateReceived ?? timeService.now(), 'short'),
  //       }));

  //       setData(list);
  //     }
  //     setIsLoading(false);
  //   });
  // };

  const handleMarkAsRead = (notificationId: string) => {
    setIsLoading(true);
    // notificationPreviewUseCase.markNotificationAsRead(notificationId).then((success) => {
    //   setIsLoading(false);

    //   if (success) {
    //     handleLoadNotifications();
    //   }
    // });
  };

  useEffect(() => {
    // handleLoadNotifications();
  }, []);

  return {
    isLoading,
    count,
    data,
    handleMarkAsRead,
  };
};

export default useHeaderNotification;
