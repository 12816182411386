import { useEffect, useState } from 'react';
import { useCore } from 'src/web/common/core';

const usePersonAssignment = (
  iri: string,
  isPast: boolean,
  isFutur: boolean,
  isCurrent: boolean,
  isAll: boolean,
  showMore: boolean,
) => {
  const { assignmentUseCase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState<
    Awaited<ReturnType<typeof assignmentUseCase.getPersonAssigments>>
  >([]);
  const itemPerPage = showMore ? undefined : 6;
  const getAssignments = async () => {
    try {
      setIsLoading(true);
      const data = await assignmentUseCase.getPersonAssigments(
        iri,
        isPast,
        isFutur,
        isCurrent,
        isAll,
        itemPerPage,
      );
      setAssignments(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (showMore) {
      getAssignments();
    }
  }, [showMore]);

  return {
    isLoading,
    data: assignments,
    loadData: () => getAssignments(),
    showMore,
  };
};

export default usePersonAssignment;
