import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import FormService from 'core/services/form/form.service';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { isUserGranted } from 'core/common/utils/grant';
import TimeService from 'core/services/time/time.service';
import VehicleEntity from 'core/entities/vehicle.entity';
import { isObject } from 'core/common/utils/predicatesType';

class SetVehicleUseCase implements Usecase {
  private currentOne: Record<string, unknown> = {};

  constructor(
    private store: AppStore,
    private formService: FormService,
    private timeService: TimeService,
    private vehicleEntity: VehicleEntity,
  ) {}

  getRoles() {
    return EntitiesRolesMap[EntitiesEnum.vehicle];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);
    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * DELETE MILEAGE
   */
  public async deleteMileageVehicle(id: string) {
    return this.vehicleEntity
      .deleteMileage({
        pathVar: { id },
      })
      .then(() => true)
      .catch(() => false);
  }

  /**
   * UPDATE MILEAGE
   */
  public putMileageVehicle(id: string) {
    const { properties, required, validate } = this.formService.createForm(
      this.vehicleEntity.getPathMileageOne(),
      'put',
    );

    this.currentOne = {};

    const getInitialValues = async () => {
      let initialValues = {
        recordedAt: this.timeService.toBackend(this.timeService.now()),
      };

      const currentAssetData = await this.vehicleEntity
        .getMileageOne({
          pathVar: { id },
        })
        .then((response) => response.data);

      if (isObject(currentAssetData)) {
        this.currentOne = this.formService.formatData(currentAssetData);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in this.currentOne) {
            initialValues = {
              ...initialValues,
              [propkey]: this.currentOne[propkey],
            };
          }
        });
      }

      return initialValues;
    };

    const handleSubmit = (data: object) => {
      return this.vehicleEntity
        .putMileage({
          body: this.formService.unformatData({ ...this.currentOne, ...data }),
          pathVar: { id },
        })
        .then((response) => response.data);
    };

    return {
      properties,
      initialValues: getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }

  /**
   * ADD MILEAGE
   */
  public postNewMileageVehicle(assetIri: string, value: number) {
    const { properties, required, validate } = this.formService.createForm(
      this.vehicleEntity.getPathMileageAll(),
      'post',
    );

    const getInitialValues = async () => {
      const currentUser = this.store.getState((state) => state.user.current);
      let initialValues = {
        vehicle: assetIri,
        createdBy: currentUser?.email,
        updatedBy: currentUser?.email,
        recordedAt: this.timeService.toBackend(this.timeService.now()),
        value: value,
      };

      const currentAssetData = await this.vehicleEntity
        .getMileageAll()
        .then((response) => response.data);

      if (typeof currentAssetData === 'object') {
        const flatCurrentData = this.formService.formatData(currentAssetData);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in flatCurrentData) {
            initialValues = {
              ...initialValues,
              [propkey]: flatCurrentData[propkey],
            };
          }
        });
      }

      return initialValues;
    };

    const handleSubmit = (data: object) => {
      return this.vehicleEntity.postMileage({
        body: this.formService.unformatData(data),
      });
    };

    return {
      properties,
      initialValues: getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }

  /**
   * GET MILEAGES
   */
  public async getVehicleMileage(id: string) {
    return this.vehicleEntity
      .getMileageAll({
        query: {
          'vehicle.id': id,
          'order[value]': 'desc',
        },
      })
      .then((response) => response?.data);
  }
}

export default SetVehicleUseCase;
