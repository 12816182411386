import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProps {
  value?: number;
}

const Loading: FC<LoadingProps> = ({ value }) => {
  return <CircularProgress value={value} />;
};

export default Loading;
