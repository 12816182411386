import { FC, useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import CardAssignation from './partials/CardAssignation';
import TitleAssetSection from './partials/TitleAssetSection';
import ContentLayout from 'src/web/layouts/ContentLayout/ContentLayout';
import { useGetAssetDetailData } from './hooks/useGetAssetDetailData';
import LicenceInformations from './partials/AssetsInformations/LicenceInformations';
import Card from 'src/web/components/Card';
import { ROUTE_LICENCE, ROUTE_UPDATE_TAG } from 'src/web/common/router/constantes';
import { CardMenuProps } from 'src/web/components/Card/CardMenu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Displayer from 'src/web/design_system/Displayer';
import Skeleton from 'src/web/design_system/Skeleton';
import DocumentsCard from 'src/web/components/DocumentsCard';
import DeleteEntityModal from 'src/web/components/DeleteEntityModal';
import useDeleteAsset from './hooks/useDeleteAsset';
import useTranslate from 'src/web/common/translate/useTranslate';
// import NotificationsConditionsCard from 'src/web/components/NotificationsConditionsCard';
import { useTheme } from 'src/web/design_system/Theme';
import Icon from 'src/web/components/Icon';
import Text from 'src/web/design_system/Text';
import Button from 'src/web/components/Button';

const ENTITY = EntitiesEnum.licence;

const DetailsLicence: FC = () => {
  const { data, isLoading } = useGetAssetDetailData(ENTITY, ROUTE_LICENCE);
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { handleDelete } = useDeleteAsset(ENTITY);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { theme } = useTheme();

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const cardMenuItems: CardMenuProps['menuItems'] = [
    {
      label: (
        <Displayer justifyContent="space-between" alignItems="center" layout="row" innerSpacing={1}>
          <Icon.Edit size="small" />
          <Text>{translate('licences.edit.button')}</Text>
        </Displayer>
      ),
      onClick: () => {
        navigate(`${ROUTE_UPDATE_TAG}?${queryString}`);
      },
    },
    {
      label: (
        <Displayer alignItems="center" justifyContent="space-between" layout="row" innerSpacing={1}>
          <Icon.Delete size="small" color="error" />
          <Text color={theme.palettes.error?.[500]}>{translate('licences.delete.button')}</Text>
        </Displayer>
      ),
      onClick: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  return (
    <>
      <Button.BackNavigate
        to={`${ROUTE_LICENCE}?${queryString}`}
        label={translate('global.navigateBack.inventory.label')}
      />
      <ContentLayout
        primaryContent={
          <Skeleton shape="round" isLoading={isLoading} width="100%" height="70vh">
            <Card.Container floatingActionContent={<Card.Menu menuItems={cardMenuItems} />}>
              <Card.Content>
                <TitleAssetSection
                  isLoading={isLoading}
                  headerTextMain={`${translate(
                    `licences.termAndConditionsType.option.${data?.termAndConditionsType}.label`,
                  )} | ${data?.asset?.identification}`}
                  headerTextSecond={data?.asset?.manufacturer}
                  conditionState={data?.assetCondition}
                  statusState={data?.asset?.status}
                />
                <LicenceInformations dataAsset={data} />
              </Card.Content>
            </Card.Container>
          </Skeleton>
        }
        secondaryContent={
          <>
            <Skeleton shape="round" isLoading={isLoading} width="100%" height="50vh">
              <Displayer layout={'column'} innerSpacing={4}>
                <CardAssignation
                  assetId={data?.asset?.id ?? ''}
                  assetIri={data?.asset?.['@id'] ?? ''}
                  entity={ENTITY}
                  assetStatus={data?.asset?.status ?? ''}
                />
                {/* <NotificationsConditionsCard assetId={data?.asset?.id ?? ''} entityType={ENTITY} /> */}
                <DocumentsCard
                  type={data?.['@type'] ?? ''}
                  entity={ENTITY}
                  id={data?.asset?.id ?? ''}
                />
              </Displayer>
            </Skeleton>
          </>
        }
      />
      <DeleteEntityModal
        title={translate('licences.delete.title')}
        text={translate('licences.delete.confirm.title')}
        open={isDeleteModalOpen}
        redirect={ROUTE_LICENCE}
        onSubmit={() => handleDelete()}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default DetailsLicence;
