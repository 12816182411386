import { FC, useMemo } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import InputSelect, { type InputSelectProps } from './InputSelect';
import useFormComponent from './useFormComponent';

type FormSelectExcludedProps = keyof Pick<
  InputSelectProps,
  'value' | 'isError' | 'onChange' | 'options' | 'message'
>;

interface FormSelectProps extends Omit<InputSelectProps, FormSelectExcludedProps> {
  item: FormItem;
}

const FormSelect: FC<FormSelectProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props);
    return <></>;
  }

  const { formatFormMessage } = useFormComponent();

  const enumList = item.enum ?? [];

  // format value to provide allowed type
  const value = item.formValue && item.formValue !== null ? String(item.formValue) : undefined;

  // format options to provide allowed type
  // and also put the reset value on the top of the list when the item is nullable
  const options = useMemo(() => {
    let opts = enumList.map((enumItem) => (enumItem ? enumItem.toString() : undefined));

    if ('nullable' in item && item.nullable === true) {
      opts = [undefined, ...opts.filter((opt) => !!opt)];
    }

    return opts;
  }, [item]);

  return (
    <InputSelect
      value={value}
      options={options}
      isRequired={item.required}
      isError={!!item.errors}
      onChange={(val) => item.handleChange(val)}
      message={formatFormMessage(item)}
      {...props}
    />
  );
};

export default FormSelect;
