import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import Grid from 'src/web/design_system/Grid';
import Icon from 'src/web/components/Icon';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import { isNumber } from 'src/core/common/utils/predicatesType';

interface FincancingFormPartialProps {
  form: UseSetAssetFormReturn;
}

const FinancingFormPartial: FC<FincancingFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  const isFinanced =
    !['untraced', ''].includes(form.items.financingMethod.formValue as string) &&
    form.items.financingMethod.formValue !== undefined;

  const isOwned = ['buying', 'buyingNew', 'buyingUsed'].includes(
    form.items.financingMethod.formValue as string,
  );

  const isRentedOrOther = isFinanced && !isOwned;

  const isLoa = ['LOA'].includes(form.items.financingMethod.formValue as string);

  //condition that allow to show amortization period in form
  const isAmortizationPeriod =
    isNumber(form?.items?.asset_contractualCommitment_purchasePrice?.formValue) &&
    form?.items?.asset_contractualCommitment_purchasePrice?.formValue >= 500;

  //condition that allow to show en of warranty in form
  const isEnOfWarranty = !(
    form.items.asset_assetType.formValue === 'licence' ||
    form.items.asset_assetType.formValue === 'service' ||
    form.items.asset_assetType.formValue === 'card' ||
    form.items.asset_assetType.formValue === 'telephoneLine'
  );

  const isVehicle = form.items.asset_assetType.formValue === 'vehicle';

  return (
    <Card.Basic title={translate('assets.asset.contractualCommitment.title')}>
      <Displayer innerSpacing={2}>
        <Form.SelectAdapted
          item={form.items.financingMethod}
          label={translate('assets.financingMethod.label')}
          renderOptionItem={(value) => ({
            label: translate(`assets.financingMethod.option.${value}.label`),
          })}
        />
        <Form.TextAdapted
          item={form.items.asset_contractualCommitment_supplier}
          label={translate('contractualCommitment.supplier.label')}
        />

        {/* IF IS FINANCED */}
        {isFinanced && (
          <>
            <Form.TextAdapted
              item={form.items.asset_contractualCommitment_reference}
              label={translate('contractualCommitment.reference.label')}
            />

            {/* IF IS OWNED */}
            {isOwned && (
              <>
                <Form.DateAdapted
                  item={form.items.asset_contractualCommitment_purchaseDate}
                  label={translate('contractualCommitment.purchaseDate.label')}
                />

                <Form.NumberAdapted
                  item={form.items.asset_contractualCommitment_purchasePrice}
                  label={translate('contractualCommitment.purchasePrice.label')}
                  type="number"
                  endExtra={<Icon.Money />}
                />
                {isAmortizationPeriod ? (
                  <Form.NumberAdapted
                    item={form.items.asset_contractualCommitment_amortizationPeriod}
                    label={translate('contractualCommitment.amortizationPeriod.label')}
                    type="integer"
                    endExtra={<Text>an</Text>}
                  />
                ) : (
                  ''
                )}
                {isEnOfWarranty && (
                  <Form.DateAdapted
                    item={form.items.asset_contractualCommitment_endOfWarranty}
                    label={translate('contractualCommitment.endOfWarranty.label')}
                  />
                )}
              </>
            )}

            {/* IF IS RENTED OR OTHER */}
            {isRentedOrOther && (
              <>
                <Form.DateAdapted
                  item={form.items.asset_contractualCommitment_startDate}
                  label={translate('contractualCommitment.startDate.label')}
                />

                <Grid.Container>
                  <Grid.Item>
                    <Form.NumberAdapted
                      item={form.items.asset_contractualCommitment_counter}
                      label={translate('contractualCommitment.counter.label')}
                      type="integer"
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Form.SelectAdapted
                      item={form.items.asset_contractualCommitment_counterUnit}
                      label={translate('contractualCommitment.counterUnit.label')}
                      renderOptionItem={(value) => ({
                        label: translate(`contractualCommitment.counterUnit.option.${value}.label`),
                      })}
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <Form.NumberAdapted
                      item={form.items.asset_contractualCommitment_priceProrated}
                      label={translate('contractualCommitment.priceProrated.label')}
                      type="number"
                      endExtra={<Icon.Money />}
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Form.SelectAdapted
                      item={form.items.asset_contractualCommitment_frequency}
                      label={translate('contractualCommitment.frequency.label')}
                      renderOptionItem={(value) => ({
                        label: translate(`contractualCommitment.frequency.option.${value}.label`),
                      })}
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <Form.NumberAdapted
                      item={form.items.limit}
                      label={translate(
                        isVehicle
                          ? 'contractualCommitment.vehiclesLimit.label'
                          : 'contractualCommitment.limit.label',
                      )}
                      type="integer"
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Form.SelectAdapted
                      item={form.items.limitUnit}
                      label={translate('contractualCommitment.limitUnit.label')}
                      renderOptionItem={(value) => ({
                        label: translate(`contractualCommitment.limitUnit.option.${value}.label`),
                      })}
                    />
                  </Grid.Item>
                </Grid.Container>
                {isLoa ? (
                  <Form.NumberAdapted
                    item={form.items.asset_contractualCommitment_endPayment}
                    label={translate('contractualCommitment.endPayment.label')}
                    type="number"
                    endExtra={<Icon.Money />}
                  />
                ) : (
                  <></>
                )}

                {'asset_contractualCommitment_endOfWarranty' in form.items && isEnOfWarranty && (
                  <Form.DateAdapted
                    item={form.items.asset_contractualCommitment_endOfWarranty}
                    label={translate('contractualCommitment.endOfWarranty.label')}
                  />
                )}
              </>
            )}
          </>
        )}
      </Displayer>
    </Card.Basic>
  );
};

export default FinancingFormPartial;
