import { useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';

interface DeleteDocumentsReturn {
  loading: boolean;
  handleDelete: (id: string, entity: EntitiesEnum) => void;
}

const useDeleteDocuments = (reload: () => void): DeleteDocumentsReturn => {
  const [loading, setLoading] = useState(false);
  const { documentUsecase } = useCore();

  const handleDelete = async (id: string, entity: EntitiesEnum) => {
    setLoading(true);
    const response = await documentUsecase.deleteDocuments(id, entity);
    if (response) {
      reload();
    }
    setLoading(false);
  };

  return { loading, handleDelete };
};

export default useDeleteDocuments;
