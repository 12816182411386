import CardBasic from './CardBasic';
import CardDetails from './CardDetails';
import CardFooter from 'src/web/design_system/NewCard/cardFooter';
import CardContainer from './CardContainer';
import CardContent from './cardContent';
import CardToolbar from './cardToolbar';
import CardMenu from './CardMenu';

export default {
  Container: CardContainer,
  Content: CardContent,
  Toolbar: CardToolbar,
  Footer: CardFooter,
  Menu: CardMenu,

  // to remove or move
  Basic: CardBasic,
  Details: CardDetails,
};
