import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import Grid from 'src/web/design_system/Grid';

interface ServiceInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const ServiceInfoFormPartial: FC<ServiceInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.TextAdapted item={form.items.name} label={translate('services.name.label')} />

        <Form.TextAdapted item={form.items.provider} label={translate('services.provider.label')} />

        <Form.TextAdapted
          item={form.items.asset_manufacturerPerson}
          label={translate('services.asset.manufacturerPerson.label')}
        />
        <Grid.Container>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.asset_manufacturerTelephone}
              label={translate('services.asset.manufacturerTelephone.label')}
              type="tel"
            />
          </Grid.Item>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.asset_manufacturerEmail}
              label={translate('services.asset.manufacturerEmail.label')}
              type="email"
            />
          </Grid.Item>
        </Grid.Container>
      </Displayer>
    </Card.Basic>
  );
};

export default ServiceInfoFormPartial;
