import { FC, ReactNode } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';

interface SwitchFieldProps {
  label?: ReactNode;
  value: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: boolean) => void;
}

const SwitchField: FC<SwitchFieldProps> = ({ value, label, isDisabled, isRequired, onChange }) => {
  const SwitchComponent = () => (
    <Switch
      disabled={isDisabled}
      required={isRequired}
      checked={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.checked);
        }
      }}
    />
  );

  if (label) {
    return (
      <FormControlLabel
        label={label}
        control={<SwitchComponent />}
        disabled={isDisabled}
        required={isRequired}
      />
    );
  }

  return <SwitchComponent />;
};

export default SwitchField;
