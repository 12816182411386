import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { isUserGranted } from 'core/common/utils/grant';
import { transformCurlyPath } from 'src/core/common/utils/transformCurlyPath';
import VehicleEntity from 'core/entities/vehicle.entity';
import ComputerEntity from 'core/entities/computer.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ServiceEntity from 'core/entities/service.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import CardEntity from 'core/entities/card.entity';
import PersonEntity from 'core/entities/person.entity';
import HttpService from 'core/services/api/http.service';
import CustomAssetEntity from 'core/entities/customAsset.entity';
import { toCamelCase } from 'core/common/utils/format';

class DocumentsUsecase implements Usecase {
  constructor(
    private store: AppStore,
    private http: HttpService,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private licenceEntity: LicenceEntity,
    private serviceEntity: ServiceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private personEntity: PersonEntity,
    private customAssetEntity: CustomAssetEntity,
  ) {}

  getRoles() {
    return EntitiesRolesMap[EntitiesEnum.vehicle]; // WTF is bind to vehicule?
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);
    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * POST DOCUMENT
   */
  public async postDocuments(file: File, entity: EntitiesEnum, type: string, id: string) {
    const fileFormData = new FormData();
    fileFormData.append(entity === EntitiesEnum.person ? 'personId' : 'assetId', id);
    fileFormData.append('entityTag', toCamelCase(type));
    fileFormData.append('file', file);

    // @todo remove the any type when the backend
    // will provide a right typing for the request body
    const options: any = {
      contentType: 'multipart/form-data',
      body: fileFormData,
    };

    switch (entity) {
      case EntitiesEnum.vehicle:
        return this.vehicleEntity.postMediaObject(options);
      case EntitiesEnum.computer:
        return this.computerEntity.postMediaObject(options);
      case EntitiesEnum.licence:
        return this.licenceEntity.postMediaObject(options);
      case EntitiesEnum.service:
        return this.serviceEntity.postMediaObject(options);
      case EntitiesEnum.telephone:
        return this.telephoneEntity.postMediaObject(options);
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity.postMediaObject(options);
      case EntitiesEnum.card:
        return this.cardEntity.postMediaObject(options);
      case EntitiesEnum.person:
        return this.personEntity.postMediaObject(options);
      case EntitiesEnum.customAsset:
        return this.customAssetEntity.postMediaObject(options);
      default:
        return undefined;
    }
  }

  /**
   * GET DOCUMENT BY ENTITY ID
   */
  public async getDocumentsByEntityId(id: string, entity: EntitiesEnum) {
    switch (entity) {
      case EntitiesEnum.vehicle:
        return this.vehicleEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.computer:
        return this.computerEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.licence:
        return this.licenceEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.service:
        return this.serviceEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.telephone:
        return this.telephoneEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.card:
        return this.cardEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.customAsset:
        return this.customAssetEntity
          .getMediaObjectAll({
            query: {
              'assets.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      case EntitiesEnum.person:
        return this.personEntity
          .getMediaObjectAll({
            query: {
              'persons.id': id,
              'order[createdAt]': 'desc',
            },
          })
          .then((response) => response?.data);
      default:
        return undefined;
    }
  }

  /**
   * DELETE DOCUMENT
   */
  public async deleteDocuments(documentId: string, entity: EntitiesEnum) {
    const pathVar = { id: documentId };

    switch (entity) {
      case EntitiesEnum.vehicle:
        return this.vehicleEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.computer:
        return this.computerEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.licence:
        return this.licenceEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.service:
        return this.serviceEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.telephone:
        return this.telephoneEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.card:
        return this.cardEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.customAsset:
        return this.customAssetEntity.deleteMediaObject({ pathVar });
      case EntitiesEnum.person:
        return this.personEntity.deleteMediaObject({ pathVar });
      default:
        return undefined;
    }
  }

  /**
   * GET DOCUMENT URL
   */
  public async getDocumentDownloadBlob(documentId: string, entity: EntitiesEnum) {
    const url = (() => {
      switch (entity) {
        case EntitiesEnum.vehicle:
          return transformCurlyPath(this.vehicleEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.computer:
          return transformCurlyPath(this.computerEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.card:
          return transformCurlyPath(this.cardEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.licence:
          return transformCurlyPath(this.licenceEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.person:
          return transformCurlyPath(this.personEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.service:
          return transformCurlyPath(this.serviceEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.telephone:
          return transformCurlyPath(this.telephoneEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.telephoneLine:
          return transformCurlyPath(this.telephoneLineEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        case EntitiesEnum.customAsset:
          return transformCurlyPath(this.customAssetEntity.getPathMediaObjectDownload(), {
            id: documentId,
          });
        default:
          return undefined;
      }
    })();

    if (url) {
      const response = await this.http.request<Blob>({
        url,
        method: 'GET',
        responseType: 'blob',
        contentType: 'application/ld+json',
      });

      if (response.data) {
        return response.data;
      }
    }

    return undefined;
  }
}

export default DocumentsUsecase;
