import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { AppCoreInit } from 'core/index';
import { type AppState } from 'core/services/store/store.service';

/**
 * Instance of the Fleezy Core
 */
const core = AppCoreInit();

/**
 * UI app context to allow to provide the Core instance to every pages of the app
 */
const CoreContext = createContext(core);

/**
 * Core context provider
 */
export const CoreProvider: FC<PropsWithChildren> = ({ children }) => (
  <CoreContext.Provider value={core}>{children}</CoreContext.Provider>
);

/**
 * Core context consumer
 */
export const CoreConsumer = CoreContext.Consumer;

/**
 * Core context hook
 */
export const useCore = () => {
  const { store, ...services } = useContext(CoreContext);

  /**
   * The core is agnostic and so the store too.
   * But the data needs to be bound to the React lifecycle to generete rerender when it change.
   * @todo think about it and try to find a better architecture
   */
  function useReactGetState<T>(handler: (state: AppState) => T): T {
    const [value, setValue] = useState<T>(store.getState(handler));

    useEffect(() => {
      store.subscribe((state) => {
        setValue(handler(state));
      });
    }, []);

    return value;
  }

  const ns = {
    ...store,
    getState: useReactGetState,
  };

  return {
    ...services,
    store: ns,
  };
};
