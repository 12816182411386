import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface CardInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const CardInfoFormPartial: FC<CardInfoFormPartialProps> = ({ form }) => {
  const currentType = String(form.items.purpose.formValue);
  const isPurposeSelected = !!currentType;
  const showExpirationDateField = ['oilCard', 'tollBadge', 'creditCard'].includes(currentType);
  const showServiceField = ['oilCard', 'tollBadge'].includes(currentType);
  const showSpaceField = ['parkBadge'].includes(currentType);
  const showNameField = ['badge'].includes(currentType);
  const showDeviceField = ['deviceBadge'].includes(currentType);
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.SelectAdapted
          item={form.items.purpose}
          label={translate('cards.purpose.label')}
          renderOptionItem={(value) => ({
            label: translate(`cards.purpose.option.${value}.label`),
          })}
        />

        {isPurposeSelected && (
          <>
            <Form.TextAdapted
              item={form.items.asset_identification}
              label={translate('assets.asset.identification.label')}
            />

            {showExpirationDateField && (
              <Form.DateAdapted
                item={form.items.expirationDate}
                label={translate('cards.expirationDate.label')}
              />
            )}

            <Form.TextAdapted
              item={form.items.provider}
              label={translate('cards.provider.label')}
            />

            {showServiceField && (
              <Form.TextAdapted
                item={form.items.services}
                label={translate('cards.services.label')}
              />
            )}

            {showSpaceField && (
              <Form.TextAdapted item={form.items.space} label={translate('cards.space.label')} />
            )}

            {showNameField && (
              <Form.TextAdapted item={form.items.name} label={translate('cards.name.label')} />
            )}

            {showDeviceField && (
              <Form.TextAdapted item={form.items.device} label={translate('cards.device.label')} />
            )}
          </>
        )}
      </Displayer>
    </Card.Basic>
  );
};

export default CardInfoFormPartial;
