import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { RolesEnum } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import PersonEntity from 'core/entities/person.entity';

class PersonUseCase implements Usecase {
  constructor(private store: AppStore, private personEntity: PersonEntity) {}

  getRoles() {
    return [RolesEnum.ROLE_ADMIN_PERSON];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * GET PERSON
   */
  public async getPerson(personId: string) {
    const response = await this.personEntity.getOne({
      pathVar: {
        id: personId,
      },
    });

    return response?.data;
  }

  /**
   * GET SERVICES FROM PERSONS
   */
  public async getServices(search: string) {
    const response = await this.personEntity.getAll({
      query: { 'autocomplete[jobTitle]': search },
    });
    return response?.data;
  }

  /**
   * DELETE PERSON
   */
  public async deletePerson(personId: string) {
    return this.personEntity
      .delete({
        pathVar: {
          id: personId,
        },
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }
}

export default PersonUseCase;
