import { FC } from 'react';
import GuiInputRadio, { type RadioFieldProps } from 'src/web/design_system/Input/Radio';

export interface InputRadioProps extends RadioFieldProps {
  className?: string;
}

const InputRadio: FC<InputRadioProps> = ({ className, ...props }) => {
  const { fullWidth, ...restProps } = props;

  return (
    <div className={className}>
      <GuiInputRadio {...restProps} fullWidth={fullWidth ?? true} />
    </div>
  );
};

export default InputRadio;
