import { FC, ReactNode } from 'react';
import Card from 'src/web/components/Card';
import Toolbar from 'src/web/design_system/Toolbar';

interface InventoryCardToolbarProps {
  primaryContent?: ReactNode;
  secondaryContent?: ReactNode;
}

const InventoryCardToolbar: FC<InventoryCardToolbarProps> = ({
  primaryContent,
  secondaryContent,
}) => {
  return (
    <Card.Content disablePaddingY>
      <Toolbar noGutters primaryContent={primaryContent} secondaryContent={secondaryContent} />
    </Card.Content>
  );
};

export default InventoryCardToolbar;
