export default {
  "/api/import/callback": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "rows": {
              "readOnly": true,
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "errors": {
              "readOnly": true,
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          }
        }
      },
      "parameters": []
    }
  },
  "/api/login_check": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "username": {
              "type": "string",
              "nullable": false
            },
            "password": {
              "type": "string",
              "nullable": false
            }
          },
          "required": [
            "username",
            "password"
          ]
        }
      },
      "parameters": []
    }
  },
  "/api/private/api/user-password-retrieve": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "username": {
              "type": "string"
            }
          },
          "example": {
            "username": "email@gac-technology.com"
          }
        }
      },
      "parameters": []
    }
  },
  "/api/private/asset-assignments": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "isAssigned",
          "in": "query",
          "description": "If provided, it returns only current assets assignment by asset [FALSE].",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "boolean"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[startAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.category]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "person.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "person.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.model",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.model[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.identification",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "asset": {
              "type": "string",
              "format": "iri-reference",
              "nullable": false
            },
            "person": {
              "type": "string",
              "format": "iri-reference",
              "nullable": false
            },
            "startAt": {
              "type": "string",
              "format": "date-time",
              "nullable": false
            },
            "endAt": {
              "type": "string",
              "format": "date-time",
              "nullable": true
            }
          },
          "required": [
            "asset",
            "person",
            "startAt"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/asset-assignments/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AssetAssignment identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "startAt"
          ],
          "properties": {
            "startAt": {
              "type": "string",
              "format": "date-time"
            },
            "endAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AssetAssignment identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AssetAssignment identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/assets/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Asset identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/attachment-sites": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "name"
          ],
          "properties": {
            "name": {
              "type": "string"
            },
            "location": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    },
                    "status": {
                      "type": "number"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/attachment-sites/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AttachmentSite identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "name"
          ],
          "properties": {
            "name": {
              "type": "string"
            },
            "location": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    },
                    "status": {
                      "type": "number"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AttachmentSite identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AttachmentSite identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "AttachmentSite identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/card-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "default": "card",
              "example": "card",
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          },
          "required": [
            "file"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/card-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CardMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CardMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/cards": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "purpose",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "purpose[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "provider",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "provider[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[purpose]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[purpose]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[purpose]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[purpose]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "identification",
                "status",
                "assetType"
              ]
            },
            "purpose": {
              "enum": [
                "oilCard",
                "creditCard",
                "restaurantCard",
                "tollBadge",
                "securityBadge",
                "computerBadge",
                "parkBadge",
                "deviceBadge",
                "badge",
                "key",
                "lock"
              ],
              "type": "string"
            },
            "provider": {
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": [
                "string",
                "null"
              ]
            },
            "expirationDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "services": {
              "type": [
                "string",
                "null"
              ]
            },
            "area": {
              "type": [
                "string",
                "null"
              ]
            },
            "space": {
              "type": [
                "string",
                "null"
              ]
            },
            "device": {
              "type": [
                "string",
                "null"
              ]
            },
            "openingMechanism": {
              "enum": [
                null,
                "keyLock",
                "digiCode",
                "other"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "name": {
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "locked",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "purpose",
            "financingMethod",
            "assetCondition"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/cards/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Card identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "identification",
                "status",
                "assetType"
              ]
            },
            "purpose": {
              "enum": [
                "oilCard",
                "creditCard",
                "restaurantCard",
                "tollBadge",
                "securityBadge",
                "computerBadge",
                "parkBadge",
                "deviceBadge",
                "badge",
                "key",
                "lock"
              ],
              "type": "string"
            },
            "provider": {
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": [
                "string",
                "null"
              ]
            },
            "expirationDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "services": {
              "type": [
                "string",
                "null"
              ]
            },
            "area": {
              "type": [
                "string",
                "null"
              ]
            },
            "space": {
              "type": [
                "string",
                "null"
              ]
            },
            "device": {
              "type": [
                "string",
                "null"
              ]
            },
            "openingMechanism": {
              "enum": [
                null,
                "keyLock",
                "digiCode",
                "other"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "name": {
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "locked",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "purpose",
            "financingMethod",
            "assetCondition"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Card identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Card identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Card identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/computer-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "externalDocs": {
            "url": "https://schema.org/MediaObject"
          },
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "default": "computer",
              "example": "computer",
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          },
          "required": [
            "file"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/computer-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ComputerMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ComputerMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/computers": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "formFactor",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "formFactor[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "os",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "os[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[os]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[os]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[os]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[os]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "formFactor": {
              "enum": [
                "desktop",
                "server",
                "laptop",
                "tablet",
                "screen",
                "printer",
                "nas",
                "router",
                "switch",
                "inverter"
              ],
              "default": "laptop",
              "example": "laptop",
              "type": "string"
            },
            "os": {
              "enum": [
                null,
                "windows",
                "macos",
                "linux"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "macAddress": {
              "pattern": "^(([0-9A-F]{2}[:-]){5}([0-9A-F]{2}))$",
              "default": "00:00:00:00:00:00",
              "example": "00:00:00:00:00:00",
              "type": [
                "string",
                "null"
              ]
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buyingNew",
                "buyingUsed",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "formFactor",
            "assetCondition",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/computers/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Computer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "formFactor": {
              "enum": [
                "desktop",
                "server",
                "laptop",
                "tablet",
                "screen",
                "printer",
                "nas",
                "router",
                "switch",
                "inverter"
              ],
              "default": "laptop",
              "example": "laptop",
              "type": "string"
            },
            "os": {
              "enum": [
                null,
                "windows",
                "macos",
                "linux"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "macAddress": {
              "pattern": "^(([0-9A-F]{2}[:-]){5}([0-9A-F]{2}))$",
              "default": "00:00:00:00:00:00",
              "example": "00:00:00:00:00:00",
              "type": [
                "string",
                "null"
              ]
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buyingNew",
                "buyingUsed",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "formFactor",
            "assetCondition",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Computer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Computer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Computer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/contractual-commitments": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[reference]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[priceProrated]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[frequency]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[startDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[reference]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/contractual-commitments/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "ContractualCommitment identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/current-user": {
    "get": {
      "body": {},
      "parameters": []
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "email": {
              "type": "string"
            },
            "optIn": {
              "type": "boolean"
            },
            "preferredLanguage": {
              "type": "string",
              "nullable": true
            },
            "dashboardConfig": {
              "type": "array",
              "nullable": true,
              "items": {
                "Type": "string"
              }
            }
          },
          "example": {
            "email": "marco@test.com",
            "optIn": true,
            "preferredLanguage": "fr-FR",
            "dashboardConfig": []
          }
        }
      },
      "parameters": []
    }
  },
  "/api/private/custom-asset-categories": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "Category of custom assets: Personalised list of category defined by the client but set by Fleezy",
          "deprecated": false
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-asset-categories/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetCategory identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "Category of custom assets: Personalised list of category defined by the client but set by Fleezy",
          "deprecated": false
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetCategory identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetCategory identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetCategory identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-asset-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "externalDocs": {
            "url": "https://schema.org/MediaObject"
          },
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "default": "customAsset",
              "example": "customAsset",
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          },
          "required": [
            "file"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-asset-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAssetMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-assets": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "category.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "category.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[category.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[category.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[category.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "endPayment": {
                      "default": 1,
                      "example": 1,
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "assetCondition": {
                  "default": "normal",
                  "example": "normal",
                  "type": "string"
                },
                "person": {
                  "readOnly": true,
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "category": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType",
                "assetCondition"
              ]
            },
            "id": {
              "readOnly": true,
              "type": [
                "string",
                "null"
              ],
              "format": "uuid"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "locked",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "name": {
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "minLength": 1,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "category": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            }
          },
          "required": [
            "assetCondition",
            "financingMethod",
            "category"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-assets/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAsset identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "endPayment": {
                      "default": 1,
                      "example": 1,
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "assetCondition": {
                  "default": "normal",
                  "example": "normal",
                  "type": "string"
                },
                "person": {
                  "readOnly": true,
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "category": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType",
                "assetCondition"
              ]
            },
            "id": {
              "readOnly": true,
              "type": [
                "string",
                "null"
              ],
              "format": "uuid"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "locked",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "name": {
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "minLength": 1,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "category": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            }
          },
          "required": [
            "assetCondition",
            "financingMethod",
            "category"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAsset identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAsset identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomAsset identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-form-items": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[customForm.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[owner]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[customForm.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "task",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "task[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "owner",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "owner[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "customForm.topic",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "customForm.topic[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "customForm.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "customForm.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "order[deadline]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[owner]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[customForm.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[customForm.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "customForm",
            "task",
            "owner",
            "status"
          ],
          "properties": {
            "customForm": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "task": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "comment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "owner": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": "string"
            },
            "ownerComment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "deadline": {
              "type": "string",
              "format": "date-time"
            },
            "status": {
              "enum": [
                "disabled",
                "pending",
                "processing",
                "success",
                "error"
              ],
              "default": "pending",
              "example": "pending",
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-form-items/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "customForm",
            "task",
            "owner",
            "status"
          ],
          "properties": {
            "customForm": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "task": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "comment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "owner": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": "string"
            },
            "ownerComment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "deadline": {
              "type": "string",
              "format": "date-time"
            },
            "status": {
              "enum": [
                "disabled",
                "pending",
                "processing",
                "success",
                "error"
              ],
              "default": "pending",
              "example": "pending",
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-forms": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "topic",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "topic[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "name",
            "status",
            "topic",
            "entityType"
          ],
          "properties": {
            "name": {
              "type": "string"
            },
            "status": {
              "enum": [
                "active",
                "disabled"
              ],
              "type": "string"
            },
            "topic": {
              "enum": [
                "peopleOnBoarding",
                "peopleOffBoarding"
              ],
              "default": "peopleOnBoarding",
              "example": "peopleOnBoarding",
              "type": "string"
            },
            "entityType": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "default": "person",
              "example": "person",
              "type": "string"
            },
            "description": {
              "type": [
                "string",
                "null"
              ]
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/custom-forms/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomForm identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "name",
            "status",
            "topic",
            "entityType"
          ],
          "properties": {
            "name": {
              "type": "string"
            },
            "status": {
              "enum": [
                "active",
                "disabled"
              ],
              "type": "string"
            },
            "topic": {
              "enum": [
                "peopleOnBoarding",
                "peopleOffBoarding"
              ],
              "default": "peopleOnBoarding",
              "example": "peopleOnBoarding",
              "type": "string"
            },
            "entityType": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "default": "person",
              "example": "person",
              "type": "string"
            },
            "description": {
              "type": [
                "string",
                "null"
              ]
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomForm identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomForm identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/distributors": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/distributors/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "Distributor identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/enums/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "Enum identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/export-medias": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[id]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[rootEntityName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[startAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[endAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[mimeType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[userName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "rootEntityName",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "rootEntityName[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "mimeType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "mimeType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "rootEntityName",
            "mimeType"
          ],
          "properties": {
            "rootEntityName": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "type": "string"
            },
            "mimeType": {
              "enum": [
                "text/csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ],
              "type": "string"
            },
            "extraParams": {
              "type": "array",
              "items": {
                "type": "string"
              }
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/export-medias-download/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "legalEntityId",
          "in": "query",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/export-medias/template": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityType",
          "in": "query",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "mimeType",
          "in": "query",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "legalEntityId",
          "in": "query",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[id]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[rootEntityName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[startAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[endAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[mimeType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[userName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "startAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "endAt[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "rootEntityName",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "rootEntityName[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "mimeType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/export-medias/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "legalEntityId",
          "in": "query",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "rootEntityName",
            "mimeType",
            "status"
          ],
          "properties": {
            "rootEntityName": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "type": "string"
            },
            "mimeType": {
              "enum": [
                "text/csv",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ],
              "type": "string"
            },
            "extraParams": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "status": {
              "enum": [
                "disabled",
                "pending",
                "processing",
                "success",
                "error"
              ],
              "default": "pending",
              "example": "pending",
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ExportMedia identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ExportMedia identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/import-asset-assignments": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "Different roles are required required. desc",
          "deprecated": false,
          "required": [
            "asset",
            "person",
            "startAt"
          ],
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "required": [
                "identification",
                "assetType"
              ],
              "properties": {
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                }
              }
            },
            "person": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "required": [
                    "firstname",
                    "lastname",
                    "identification"
                  ],
                  "properties": {
                    "firstname": {
                      "minLength": 1,
                      "maxLength": 32,
                      "type": "string"
                    },
                    "lastname": {
                      "minLength": 1,
                      "maxLength": 32,
                      "type": "string"
                    },
                    "identification": {
                      "maxLength": 36,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": "string"
                    },
                    "birthdate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "gender": {
                      "enum": [
                        "F",
                        "M",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "startAt": {
              "type": "string",
              "format": "date-time"
            },
            "endAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/import/{entity}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "entity",
          "in": "path",
          "description": "CSVBoxSheet identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/keep-alive": {
    "get": {
      "body": {},
      "parameters": []
    }
  },
  "/api/private/legal-entities": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[locationCity]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[locationPostcode]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "identification",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[personJobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/legal-entities/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "name",
            "identification",
            "field",
            "status",
            "locationStreetName",
            "locationCountry",
            "locationPostcode",
            "locationCity",
            "personEmail",
            "personFirstname",
            "personLastname",
            "personJobTitle",
            "subscription",
            "settings"
          ],
          "properties": {
            "name": {
              "minLength": 3,
              "maxLength": 32,
              "type": "string"
            },
            "identification": {
              "minLength": 3,
              "maxLength": 32,
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": "string"
            },
            "field": {
              "minLength": 1,
              "maxLength": 2,
              "pattern": "^([A-Z])$",
              "type": "string"
            },
            "status": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "telephone": {
              "minLength": 0,
              "maxLength": 16,
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "locationStreetName": {
              "minLength": 0,
              "maxLength": 320,
              "pattern": "^(.*[\\w\\s\\d\\-À-ÿñæœ]*.*)$",
              "type": "string"
            },
            "locationStreetNameBis": {
              "pattern": "^(.*[\\w\\s\\d\\-À-ÿñæœ]*.*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "locationCountry": {
              "enum": [
                "AF",
                "AX",
                "AL",
                "DZ",
                "AS",
                "AD",
                "AO",
                "AI",
                "AQ",
                "AG",
                "AR",
                "AM",
                "AW",
                "AU",
                "AT",
                "AZ",
                "BS",
                "BH",
                "BD",
                "BB",
                "BY",
                "BE",
                "BZ",
                "BJ",
                "BM",
                "BT",
                "BO",
                "BQ",
                "BA",
                "BW",
                "BV",
                "BR",
                "IO",
                "BN",
                "BG",
                "BF",
                "BI",
                "KH",
                "CM",
                "CA",
                "CV",
                "KY",
                "CF",
                "TD",
                "CL",
                "CN",
                "CX",
                "CC",
                "CO",
                "KM",
                "CG",
                "CD",
                "CK",
                "CR",
                "CI",
                "HR",
                "CU",
                "CW",
                "CY",
                "CZ",
                "DK",
                "DJ",
                "DM",
                "DO",
                "EC",
                "EG",
                "SV",
                "GQ",
                "ER",
                "EE",
                "ET",
                "FK",
                "FO",
                "FJ",
                "FI",
                "FR",
                "GF",
                "PF",
                "TF",
                "GA",
                "GM",
                "GE",
                "DE",
                "GH",
                "GI",
                "GR",
                "GL",
                "GD",
                "GP",
                "GU",
                "GT",
                "GG",
                "GN",
                "GW",
                "GY",
                "HT",
                "HM",
                "VA",
                "HN",
                "HK",
                "HU",
                "IS",
                "IN",
                "ID",
                "IR",
                "IQ",
                "IE",
                "IM",
                "IL",
                "IT",
                "JM",
                "JP",
                "JE",
                "JO",
                "KZ",
                "KE",
                "KI",
                "KP",
                "KR",
                "KW",
                "KG",
                "LA",
                "LV",
                "LB",
                "LS",
                "LR",
                "LY",
                "LI",
                "LT",
                "LU",
                "MO",
                "MK",
                "MG",
                "MW",
                "MY",
                "MV",
                "ML",
                "MT",
                "MH",
                "MQ",
                "MR",
                "MU",
                "YT",
                "MX",
                "FM",
                "MD",
                "MC",
                "MN",
                "ME",
                "MS",
                "MA",
                "MZ",
                "MM",
                "NA",
                "NR",
                "NP",
                "NL",
                "NC",
                "NZ",
                "NI",
                "NE",
                "NG",
                "NU",
                "NF",
                "MP",
                "NO",
                "OM",
                "PK",
                "PW",
                "PS",
                "PA",
                "PG",
                "PY",
                "PE",
                "PH",
                "PN",
                "PL",
                "PT",
                "PR",
                "QA",
                "RE",
                "RO",
                "RU",
                "RW",
                "BL",
                "SH",
                "KN",
                "LC",
                "MF",
                "PM",
                "VC",
                "WS",
                "SM",
                "ST",
                "SA",
                "SN",
                "RS",
                "SC",
                "SL",
                "SG",
                "SX",
                "SK",
                "SI",
                "SB",
                "SO",
                "ZA",
                "GS",
                "SS",
                "ES",
                "LK",
                "SD",
                "SR",
                "SJ",
                "SZ",
                "SE",
                "CH",
                "SY",
                "TW",
                "TJ",
                "TZ",
                "TH",
                "TL",
                "TG",
                "TK",
                "TO",
                "TT",
                "TN",
                "TR",
                "TM",
                "TC",
                "TV",
                "UG",
                "UA",
                "AE",
                "GB",
                "US",
                "UM",
                "UY",
                "UZ",
                "VU",
                "VE",
                "VN",
                "VG",
                "VI",
                "WF",
                "EH",
                "YE",
                "ZM",
                "ZW",
                null
              ],
              "default": "FR",
              "example": "FR",
              "type": "string"
            },
            "locationPostcode": {
              "minLength": 0,
              "maxLength": 10,
              "type": "string"
            },
            "locationCity": {
              "minLength": 0,
              "maxLength": 64,
              "type": "string"
            },
            "personTelephone": {
              "minLength": 0,
              "maxLength": 20,
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "personEmail": {
              "minLength": 0,
              "maxLength": 320,
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": "string"
            },
            "personFirstname": {
              "minLength": 0,
              "maxLength": 32,
              "type": "string"
            },
            "personLastname": {
              "minLength": 0,
              "maxLength": 32,
              "type": "string"
            },
            "personJobTitle": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "default": "researchAndDevelopment",
              "example": "researchAndDevelopment",
              "type": "string"
            },
            "subscription": {
              "enum": [
                "fleezy-freemium",
                "fleezy-monthly",
                "fleezy-yearly"
              ],
              "default": "fleezy-freemium",
              "example": "fleezy-freemium",
              "type": "string"
            },
            "comment": {
              "type": [
                "string",
                "null"
              ]
            },
            "appModules": {
              "type": "array",
              "items": {
                "type": "object",
                "description": "",
                "deprecated": false,
                "properties": {
                  "name": {
                    "type": "string"
                  },
                  "isActive": {
                    "default": true,
                    "example": true,
                    "type": "boolean"
                  }
                }
              }
            },
            "settings": {
              "default": {
                "currency": "EUR",
                "customAssetTitle": "titre"
              },
              "example": {
                "currency": "EUR",
                "customAssetTitle": "titre"
              },
              "type": "object",
              "nullable": false,
              "properties": {
                "currency": {
                  "type": "string"
                },
                "customAssetTitle": {
                  "type": "string"
                }
              }
            }
          }
        }
      },
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/legal-entities/{id}/notification-config": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "notificationConfig"
          ],
          "properties": {
            "notificationConfig": {
              "default": {
                "card": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "computer": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "service": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "licence": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "telephone": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "telephoneLine": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "vehicle": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "technicalCheck",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "technicalService",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ]
              },
              "example": {
                "card": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "computer": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "service": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "licence": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "telephone": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "telephoneLine": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "contractRenewal",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ],
                "vehicle": [
                  {
                    "label": "endOfContract",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "technicalCheck",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "endOfWarranty",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "amortizationPeriod",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "technicalService",
                    "limit": 30,
                    "limitUnit": "days",
                    "status": true
                  },
                  {
                    "label": "byPreDefinedDatetime",
                    "limit": 0,
                    "limitUnit": "days",
                    "status": true
                  }
                ]
              },
              "type": "object",
              "properties": {
                "card": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "computer": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "service": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "licence": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "telephone": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "telephoneLine": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "vehicle": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "properties": {
                      "label": {
                        "type": "string"
                      },
                      "limit": {
                        "type": "number"
                      },
                      "limitUnit": {
                        "type": "string"
                      },
                      "status": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/legal-entities/{id}/reset-created-at": {
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "createdAt"
          ],
          "properties": {
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/legal-entities/{id}/settings": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "LegalEntity identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/legal-entity-app-modules": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "isActive",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "boolean"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "legalEntity.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "legalEntity.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "id": {
              "readOnly": true,
              "type": "number"
            },
            "legalEntity": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "name": {
              "type": "string"
            },
            "isActive": {
              "default": true,
              "example": true,
              "type": "boolean"
            },
            "stripeSubscriptionId": {
              "type": [
                "string",
                "null"
              ]
            }
          }
        }
      },
      "parameters": []
    }
  },
  "/api/private/legal-entity-app-modules/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "AppModule identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "id": {
              "readOnly": true,
              "type": "number"
            },
            "legalEntity": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "name": {
              "type": "string"
            },
            "isActive": {
              "default": true,
              "example": true,
              "type": "boolean"
            },
            "stripeSubscriptionId": {
              "type": [
                "string",
                "null"
              ]
            }
          }
        }
      },
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "AppModule identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/licence-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "externalDocs": {
            "url": "https://schema.org/MediaObject"
          },
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "default": "licence",
              "example": "licence",
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          },
          "required": [
            "file"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/licence-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "LicenceMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "LicenceMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/licences": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "termAndConditionsType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "termAndConditionsType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[termAndConditionsType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[termAndConditionsType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[termAndConditionsType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[termAndConditionsType]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[invoicingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "termAndConditionsType": {
              "enum": [
                "licence",
                "saas",
                "other"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "version": {
              "maxLength": 32,
              "pattern": "^([a-zA-Z\\d\\s\\.]+)$",
              "type": [
                "string",
                "null"
              ]
            },
            "url": {
              "maxLength": 128,
              "pattern": "^(https?://[-a-zA-Z0-9@:%_\\+~#=]{1,256}\\.[-a-zA-Z0-9@:%_\\+~#=]{1,256}\\.[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "username": {
              "maxLength": 256,
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": [
                "string",
                "null"
              ]
            },
            "usageLimit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "invoicingMethod": {
              "enum": [
                "user",
                "flat",
                "volume",
                "functionality",
                "other"
              ],
              "default": "flat",
              "example": "flat",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                "days",
                "weeks",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "assetCondition",
            "termAndConditionsType",
            "invoicingMethod",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/licences/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Licence identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "designation": {
                  "enum": [
                    "legalEntity",
                    "team",
                    "person",
                    "site"
                  ],
                  "default": "site",
                  "example": "site",
                  "type": "string"
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "termAndConditionsType": {
              "enum": [
                "licence",
                "saas",
                "other"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "version": {
              "maxLength": 32,
              "pattern": "^([a-zA-Z\\d\\s\\.]+)$",
              "type": [
                "string",
                "null"
              ]
            },
            "url": {
              "maxLength": 128,
              "pattern": "^(https?://[-a-zA-Z0-9@:%_\\+~#=]{1,256}\\.[-a-zA-Z0-9@:%_\\+~#=]{1,256}\\.[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "username": {
              "maxLength": 256,
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": [
                "string",
                "null"
              ]
            },
            "usageLimit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "invoicingMethod": {
              "enum": [
                "user",
                "flat",
                "volume",
                "functionality",
                "other"
              ],
              "default": "flat",
              "example": "flat",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                "days",
                "weeks",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "assetCondition",
            "termAndConditionsType",
            "invoicingMethod",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Licence identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Licence identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/locations/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "Location identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "externalDocs": {
            "url": "https://schema.org/MediaObject"
          },
          "required": [
            "file"
          ],
          "properties": {
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "MediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "MediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/pdf/render/{id}": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "personId": {
              "type": "string"
            },
            "assignmentDate": {
              "type": "string",
              "format": "datetime"
            },
            "assetAssignmentIds": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "locale": {
              "type": "string"
            }
          },
          "example": {
            "personId": "7eae81d6-a9bf-11ec-ab8e-0242ac140003",
            "assignmentDate": "2022-01-14 23:59:59",
            "assetAssignmentIds": [
              "03082c38-a9c0-11ec-ab8e-0242ac140003",
              "03e6cc9f-a9c0-11ec-ab8e-0242ac140003",
              "054e860d-a9c0-11ec-ab8e-0242ac140003",
              "058653db-a9c0-11ec-ab8e-0242ac140003",
              "069dd9f7-a9c0-11ec-ab8e-0242ac140003",
              "07245d73-a9c0-11ec-ab8e-0242ac140003",
              "09f8bfd5-a9c0-11ec-ab8e-0242ac140003",
              "0cdfab50-a9c0-11ec-ab8e-0242ac140003",
              "0d345881-a9c0-11ec-ab8e-0242ac140003",
              "0da5f1a5-a9c0-11ec-ab8e-0242ac140003",
              "0e179380-a9c0-11ec-ab8e-0242ac140003"
            ],
            "locale": "en-US"
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string",
            "enum": [
              "assetAssignments",
              "assetReturns"
            ]
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "returnId",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "boolean",
            "default": false
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/person-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "externalDocs": {
            "url": "https://schema.org/MediaObject"
          },
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "entityTag": {
              "default": "person",
              "example": "person",
              "type": "string"
            },
            "fileName": {
              "type": "string"
            },
            "fileExtension": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileMime": {
              "type": "string"
            },
            "fileSize": {
              "type": "number"
            },
            "userId": {
              "type": "string"
            },
            "userName": {
              "type": "string"
            },
            "file": {
              "type": "string",
              "format": "binary"
            },
            "filePath": {
              "type": [
                "string",
                "null"
              ]
            },
            "fileContent": {
              "type": "string"
            },
            "createdAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "updatedAt": {
              "default": "CURRENT_TIMESTAMP",
              "example": "CURRENT_TIMESTAMP",
              "type": "string",
              "format": "date-time"
            },
            "assets": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "persons": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            }
          },
          "required": [
            "file"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/person-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "PersonMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "PersonMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/persons": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[firstname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[lastname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[email]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[telephone]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[comment]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[firstname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[lastname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[telephone]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[email]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "jobTitle",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "jobTitle[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "autocomplete[jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "firstname",
            "lastname",
            "status",
            "jobTitle",
            "identification",
            "entryAt",
            "legalEntityId"
          ],
          "properties": {
            "firstname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "lastname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "status": {
              "enum": [
                "employee",
                "formerEmployee",
                "onBoardingEmployee"
              ],
              "default": "onBoardingEmployee",
              "example": "onBoardingEmployee",
              "type": "string"
            },
            "jobTitle": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "identification": {
              "maxLength": 36,
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": "string"
            },
            "telephone": {
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": [
                "string",
                "null"
              ]
            },
            "privateEmail": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": [
                "string",
                "null"
              ]
            },
            "entryAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "exitAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "birthdate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "gender": {
              "enum": [
                "F",
                "M",
                null
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "drivingLicenceRef": {
              "pattern": "^(\\w{0,20})$",
              "type": [
                "string",
                "null"
              ]
            },
            "locationPro": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "locationPrivate": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "comment": {
              "maxLength": 512,
              "type": [
                "string",
                "null"
              ]
            },
            "legalEntityId": {
              "type": "string"
            },
            "drivingLicenceDeliveryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "drivingLicenceExpiryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "attachmentSite": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/persons/existing": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "firstname",
            "lastname",
            "status",
            "jobTitle",
            "identification",
            "entryAt",
            "legalEntityId"
          ],
          "properties": {
            "firstname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "lastname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "status": {
              "enum": [
                "employee",
                "formerEmployee",
                "onBoardingEmployee"
              ],
              "default": "onBoardingEmployee",
              "example": "onBoardingEmployee",
              "type": "string"
            },
            "jobTitle": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "identification": {
              "maxLength": 36,
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": "string"
            },
            "telephone": {
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": [
                "string",
                "null"
              ]
            },
            "privateEmail": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": [
                "string",
                "null"
              ]
            },
            "entryAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "exitAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "birthdate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "gender": {
              "enum": [
                "F",
                "M",
                null
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "drivingLicenceRef": {
              "pattern": "^(\\w{0,20})$",
              "type": [
                "string",
                "null"
              ]
            },
            "locationPro": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "locationPrivate": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "comment": {
              "maxLength": 512,
              "type": [
                "string",
                "null"
              ]
            },
            "legalEntityId": {
              "type": "string"
            },
            "drivingLicenceDeliveryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "drivingLicenceExpiryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "attachmentSite": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/persons/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Person identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "firstname",
            "lastname",
            "status",
            "jobTitle",
            "identification",
            "entryAt",
            "legalEntityId"
          ],
          "properties": {
            "firstname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "lastname": {
              "minLength": 1,
              "maxLength": 32,
              "type": "string"
            },
            "status": {
              "enum": [
                "employee",
                "formerEmployee",
                "onBoardingEmployee"
              ],
              "default": "onBoardingEmployee",
              "example": "onBoardingEmployee",
              "type": "string"
            },
            "jobTitle": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "identification": {
              "maxLength": 36,
              "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
              "type": "string"
            },
            "telephone": {
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "email": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": [
                "string",
                "null"
              ]
            },
            "privateEmail": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": [
                "string",
                "null"
              ]
            },
            "entryAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "exitAt": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "birthdate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "gender": {
              "enum": [
                "F",
                "M",
                null
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "drivingLicenceRef": {
              "pattern": "^(\\w{0,20})$",
              "type": [
                "string",
                "null"
              ]
            },
            "locationPro": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "locationPrivate": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "streetName": {
                      "minLength": 5,
                      "maxLength": 128,
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "streetNameExtra": {
                      "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
                      "minLength": 0,
                      "maxLength": 128,
                      "type": "string",
                      "nullable": true,
                      "example": "26 rue de la gare"
                    },
                    "country": {
                      "pattern": "^([a-zA-Z]{2})$",
                      "minLength": 0,
                      "maxLength": 2,
                      "enum": [
                        "AF",
                        "AX",
                        "AL",
                        "DZ",
                        "AS",
                        "AD",
                        "AO",
                        "AI",
                        "AQ",
                        "AG",
                        "AR",
                        "AM",
                        "AW",
                        "AU",
                        "AT",
                        "AZ",
                        "BS",
                        "BH",
                        "BD",
                        "BB",
                        "BY",
                        "BE",
                        "BZ",
                        "BJ",
                        "BM",
                        "BT",
                        "BO",
                        "BQ",
                        "BA",
                        "BW",
                        "BV",
                        "BR",
                        "IO",
                        "BN",
                        "BG",
                        "BF",
                        "BI",
                        "KH",
                        "CM",
                        "CA",
                        "CV",
                        "KY",
                        "CF",
                        "TD",
                        "CL",
                        "CN",
                        "CX",
                        "CC",
                        "CO",
                        "KM",
                        "CG",
                        "CD",
                        "CK",
                        "CR",
                        "CI",
                        "HR",
                        "CU",
                        "CW",
                        "CY",
                        "CZ",
                        "DK",
                        "DJ",
                        "DM",
                        "DO",
                        "EC",
                        "EG",
                        "SV",
                        "GQ",
                        "ER",
                        "EE",
                        "ET",
                        "FK",
                        "FO",
                        "FJ",
                        "FI",
                        "FR",
                        "GF",
                        "PF",
                        "TF",
                        "GA",
                        "GM",
                        "GE",
                        "DE",
                        "GH",
                        "GI",
                        "GR",
                        "GL",
                        "GD",
                        "GP",
                        "GU",
                        "GT",
                        "GG",
                        "GN",
                        "GW",
                        "GY",
                        "HT",
                        "HM",
                        "VA",
                        "HN",
                        "HK",
                        "HU",
                        "IS",
                        "IN",
                        "ID",
                        "IR",
                        "IQ",
                        "IE",
                        "IM",
                        "IL",
                        "IT",
                        "JM",
                        "JP",
                        "JE",
                        "JO",
                        "KZ",
                        "KE",
                        "KI",
                        "KP",
                        "KR",
                        "KW",
                        "KG",
                        "LA",
                        "LV",
                        "LB",
                        "LS",
                        "LR",
                        "LY",
                        "LI",
                        "LT",
                        "LU",
                        "MO",
                        "MK",
                        "MG",
                        "MW",
                        "MY",
                        "MV",
                        "ML",
                        "MT",
                        "MH",
                        "MQ",
                        "MR",
                        "MU",
                        "YT",
                        "MX",
                        "FM",
                        "MD",
                        "MC",
                        "MN",
                        "ME",
                        "MS",
                        "MA",
                        "MZ",
                        "MM",
                        "NA",
                        "NR",
                        "NP",
                        "NL",
                        "NC",
                        "NZ",
                        "NI",
                        "NE",
                        "NG",
                        "NU",
                        "NF",
                        "MP",
                        "NO",
                        "OM",
                        "PK",
                        "PW",
                        "PS",
                        "PA",
                        "PG",
                        "PY",
                        "PE",
                        "PH",
                        "PN",
                        "PL",
                        "PT",
                        "PR",
                        "QA",
                        "RE",
                        "RO",
                        "RU",
                        "RW",
                        "BL",
                        "SH",
                        "KN",
                        "LC",
                        "MF",
                        "PM",
                        "VC",
                        "WS",
                        "SM",
                        "ST",
                        "SA",
                        "SN",
                        "RS",
                        "SC",
                        "SL",
                        "SG",
                        "SX",
                        "SK",
                        "SI",
                        "SB",
                        "SO",
                        "ZA",
                        "GS",
                        "SS",
                        "ES",
                        "LK",
                        "SD",
                        "SR",
                        "SJ",
                        "SZ",
                        "SE",
                        "CH",
                        "SY",
                        "TW",
                        "TJ",
                        "TZ",
                        "TH",
                        "TL",
                        "TG",
                        "TK",
                        "TO",
                        "TT",
                        "TN",
                        "TR",
                        "TM",
                        "TC",
                        "TV",
                        "UG",
                        "UA",
                        "AE",
                        "GB",
                        "US",
                        "UM",
                        "UY",
                        "UZ",
                        "VU",
                        "VE",
                        "VN",
                        "VG",
                        "VI",
                        "WF",
                        "EH",
                        "YE",
                        "ZM",
                        "ZW",
                        null
                      ],
                      "type": "string",
                      "nullable": true,
                      "example": "FR"
                    },
                    "city": {
                      "minLength": 2,
                      "maxLength": 128,
                      "type": "string",
                      "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
                      "nullable": true,
                      "example": "Lyon"
                    },
                    "postcode": {
                      "minLength": 4,
                      "maxLength": 10,
                      "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
                      "type": "string",
                      "nullable": true,
                      "example": "69009"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "comment": {
              "maxLength": 512,
              "type": [
                "string",
                "null"
              ]
            },
            "legalEntityId": {
              "type": "string"
            },
            "drivingLicenceDeliveryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "drivingLicenceExpiryDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "attachmentSite": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Person identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Person identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Person identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/prospects": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "SignUp et Prospect",
          "deprecated": false,
          "required": [
            "personEmail",
            "firstname",
            "lastname",
            "legalEntityName"
          ],
          "properties": {
            "personEmail": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            },
            "firstname": {
              "minLength": 2,
              "maxLength": 32,
              "type": "string"
            },
            "lastname": {
              "minLength": 2,
              "maxLength": 32,
              "type": "string"
            },
            "legalEntityName": {
              "minLength": 2,
              "maxLength": 32,
              "type": "string"
            },
            "signed": {
              "type": "boolean"
            },
            "preferredLanguage": {
              "default": "fr-FR",
              "example": "fr-FR",
              "type": [
                "string",
                "null"
              ]
            },
            "status": {
              "type": "number"
            },
            "streetName": {
              "minLength": 5,
              "maxLength": 128,
              "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
              "type": "string",
              "nullable": true,
              "example": "26 rue de la gare"
            },
            "streetNameExtra": {
              "pattern": "[\\w\\s\\d\\-À-ÿñæœ]*",
              "minLength": 0,
              "maxLength": 128,
              "type": "string",
              "nullable": true,
              "example": "26 rue de la gare"
            },
            "country": {
              "pattern": "^([a-zA-Z]{2})$",
              "minLength": 0,
              "maxLength": 2,
              "enum": [
                "AF",
                "AX",
                "AL",
                "DZ",
                "AS",
                "AD",
                "AO",
                "AI",
                "AQ",
                "AG",
                "AR",
                "AM",
                "AW",
                "AU",
                "AT",
                "AZ",
                "BS",
                "BH",
                "BD",
                "BB",
                "BY",
                "BE",
                "BZ",
                "BJ",
                "BM",
                "BT",
                "BO",
                "BQ",
                "BA",
                "BW",
                "BV",
                "BR",
                "IO",
                "BN",
                "BG",
                "BF",
                "BI",
                "KH",
                "CM",
                "CA",
                "CV",
                "KY",
                "CF",
                "TD",
                "CL",
                "CN",
                "CX",
                "CC",
                "CO",
                "KM",
                "CG",
                "CD",
                "CK",
                "CR",
                "CI",
                "HR",
                "CU",
                "CW",
                "CY",
                "CZ",
                "DK",
                "DJ",
                "DM",
                "DO",
                "EC",
                "EG",
                "SV",
                "GQ",
                "ER",
                "EE",
                "ET",
                "FK",
                "FO",
                "FJ",
                "FI",
                "FR",
                "GF",
                "PF",
                "TF",
                "GA",
                "GM",
                "GE",
                "DE",
                "GH",
                "GI",
                "GR",
                "GL",
                "GD",
                "GP",
                "GU",
                "GT",
                "GG",
                "GN",
                "GW",
                "GY",
                "HT",
                "HM",
                "VA",
                "HN",
                "HK",
                "HU",
                "IS",
                "IN",
                "ID",
                "IR",
                "IQ",
                "IE",
                "IM",
                "IL",
                "IT",
                "JM",
                "JP",
                "JE",
                "JO",
                "KZ",
                "KE",
                "KI",
                "KP",
                "KR",
                "KW",
                "KG",
                "LA",
                "LV",
                "LB",
                "LS",
                "LR",
                "LY",
                "LI",
                "LT",
                "LU",
                "MO",
                "MK",
                "MG",
                "MW",
                "MY",
                "MV",
                "ML",
                "MT",
                "MH",
                "MQ",
                "MR",
                "MU",
                "YT",
                "MX",
                "FM",
                "MD",
                "MC",
                "MN",
                "ME",
                "MS",
                "MA",
                "MZ",
                "MM",
                "NA",
                "NR",
                "NP",
                "NL",
                "NC",
                "NZ",
                "NI",
                "NE",
                "NG",
                "NU",
                "NF",
                "MP",
                "NO",
                "OM",
                "PK",
                "PW",
                "PS",
                "PA",
                "PG",
                "PY",
                "PE",
                "PH",
                "PN",
                "PL",
                "PT",
                "PR",
                "QA",
                "RE",
                "RO",
                "RU",
                "RW",
                "BL",
                "SH",
                "KN",
                "LC",
                "MF",
                "PM",
                "VC",
                "WS",
                "SM",
                "ST",
                "SA",
                "SN",
                "RS",
                "SC",
                "SL",
                "SG",
                "SX",
                "SK",
                "SI",
                "SB",
                "SO",
                "ZA",
                "GS",
                "SS",
                "ES",
                "LK",
                "SD",
                "SR",
                "SJ",
                "SZ",
                "SE",
                "CH",
                "SY",
                "TW",
                "TJ",
                "TZ",
                "TH",
                "TL",
                "TG",
                "TK",
                "TO",
                "TT",
                "TN",
                "TR",
                "TM",
                "TC",
                "TV",
                "UG",
                "UA",
                "AE",
                "GB",
                "US",
                "UM",
                "UY",
                "UZ",
                "VU",
                "VE",
                "VN",
                "VG",
                "VI",
                "WF",
                "EH",
                "YE",
                "ZM",
                "ZW",
                null
              ],
              "type": "string",
              "nullable": true,
              "example": "FR"
            },
            "city": {
              "minLength": 2,
              "maxLength": 128,
              "type": "string",
              "pattern": "^[\\a-zA-ZÀ-ſ\\s]*$",
              "nullable": true,
              "example": "Lyon"
            },
            "postcode": {
              "minLength": 4,
              "maxLength": 10,
              "pattern": "^(\\d{5}|\\d{4}-\\d{3})$",
              "type": "string",
              "nullable": true,
              "example": "69009"
            }
          }
        }
      },
      "parameters": []
    }
  },
  "/api/private/prospects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "Prospect identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/service-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {},
      "parameters": []
    }
  },
  "/api/private/service-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ServiceMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "ServiceMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/services": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "provider",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "provider[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.manufacturerPerson]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[provider]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.designation]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturerPerson]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "designation": {
                  "enum": [
                    "legalEntity",
                    "team",
                    "person",
                    "site"
                  ],
                  "default": "site",
                  "example": "site",
                  "type": "string"
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "country": {
                  "enum": [
                    "AF",
                    "AX",
                    "AL",
                    "DZ",
                    "AS",
                    "AD",
                    "AO",
                    "AI",
                    "AQ",
                    "AG",
                    "AR",
                    "AM",
                    "AW",
                    "AU",
                    "AT",
                    "AZ",
                    "BS",
                    "BH",
                    "BD",
                    "BB",
                    "BY",
                    "BE",
                    "BZ",
                    "BJ",
                    "BM",
                    "BT",
                    "BO",
                    "BQ",
                    "BA",
                    "BW",
                    "BV",
                    "BR",
                    "IO",
                    "BN",
                    "BG",
                    "BF",
                    "BI",
                    "KH",
                    "CM",
                    "CA",
                    "CV",
                    "KY",
                    "CF",
                    "TD",
                    "CL",
                    "CN",
                    "CX",
                    "CC",
                    "CO",
                    "KM",
                    "CG",
                    "CD",
                    "CK",
                    "CR",
                    "CI",
                    "HR",
                    "CU",
                    "CW",
                    "CY",
                    "CZ",
                    "DK",
                    "DJ",
                    "DM",
                    "DO",
                    "EC",
                    "EG",
                    "SV",
                    "GQ",
                    "ER",
                    "EE",
                    "ET",
                    "FK",
                    "FO",
                    "FJ",
                    "FI",
                    "FR",
                    "GF",
                    "PF",
                    "TF",
                    "GA",
                    "GM",
                    "GE",
                    "DE",
                    "GH",
                    "GI",
                    "GR",
                    "GL",
                    "GD",
                    "GP",
                    "GU",
                    "GT",
                    "GG",
                    "GN",
                    "GW",
                    "GY",
                    "HT",
                    "HM",
                    "VA",
                    "HN",
                    "HK",
                    "HU",
                    "IS",
                    "IN",
                    "ID",
                    "IR",
                    "IQ",
                    "IE",
                    "IM",
                    "IL",
                    "IT",
                    "JM",
                    "JP",
                    "JE",
                    "JO",
                    "KZ",
                    "KE",
                    "KI",
                    "KP",
                    "KR",
                    "KW",
                    "KG",
                    "LA",
                    "LV",
                    "LB",
                    "LS",
                    "LR",
                    "LY",
                    "LI",
                    "LT",
                    "LU",
                    "MO",
                    "MK",
                    "MG",
                    "MW",
                    "MY",
                    "MV",
                    "ML",
                    "MT",
                    "MH",
                    "MQ",
                    "MR",
                    "MU",
                    "YT",
                    "MX",
                    "FM",
                    "MD",
                    "MC",
                    "MN",
                    "ME",
                    "MS",
                    "MA",
                    "MZ",
                    "MM",
                    "NA",
                    "NR",
                    "NP",
                    "NL",
                    "NC",
                    "NZ",
                    "NI",
                    "NE",
                    "NG",
                    "NU",
                    "NF",
                    "MP",
                    "NO",
                    "OM",
                    "PK",
                    "PW",
                    "PS",
                    "PA",
                    "PG",
                    "PY",
                    "PE",
                    "PH",
                    "PN",
                    "PL",
                    "PT",
                    "PR",
                    "QA",
                    "RE",
                    "RO",
                    "RU",
                    "RW",
                    "BL",
                    "SH",
                    "KN",
                    "LC",
                    "MF",
                    "PM",
                    "VC",
                    "WS",
                    "SM",
                    "ST",
                    "SA",
                    "SN",
                    "RS",
                    "SC",
                    "SL",
                    "SG",
                    "SX",
                    "SK",
                    "SI",
                    "SB",
                    "SO",
                    "ZA",
                    "GS",
                    "SS",
                    "ES",
                    "LK",
                    "SD",
                    "SR",
                    "SJ",
                    "SZ",
                    "SE",
                    "CH",
                    "SY",
                    "TW",
                    "TJ",
                    "TZ",
                    "TH",
                    "TL",
                    "TG",
                    "TK",
                    "TO",
                    "TT",
                    "TN",
                    "TR",
                    "TM",
                    "TC",
                    "TV",
                    "UG",
                    "UA",
                    "AE",
                    "GB",
                    "US",
                    "UM",
                    "UY",
                    "UZ",
                    "VU",
                    "VE",
                    "VN",
                    "VG",
                    "VI",
                    "WF",
                    "EH",
                    "YE",
                    "ZM",
                    "ZW",
                    null
                  ],
                  "default": "FR",
                  "example": "FR",
                  "type": "string"
                },
                "manufacturerPerson": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "manufacturerEmail": {
                  "format": "email",
                  "externalDocs": {
                    "url": "https://schema.org/email"
                  },
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "manufacturerTelephone": {
                  "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "status",
                "assetType",
                "country"
              ]
            },
            "type": {
              "enum": [
                "other",
                "consulting",
                "auditing",
                "catering",
                "upkeepAndGardening",
                "socialAndCultural",
                "digitalService",
                "syndicate"
              ],
              "default": "other",
              "example": "other",
              "type": "string"
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "name": {
              "minLength": 1,
              "maxLength": 56,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "provider": {
              "minLength": 1,
              "maxLength": 56,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                "days",
                "weeks",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "assetCondition",
            "name",
            "provider",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/services/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Service identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "id": {
              "readOnly": true,
              "type": "string"
            },
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "designation": {
                  "enum": [
                    "legalEntity",
                    "team",
                    "person",
                    "site"
                  ],
                  "default": "site",
                  "example": "site",
                  "type": "string"
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "country": {
                  "enum": [
                    "AF",
                    "AX",
                    "AL",
                    "DZ",
                    "AS",
                    "AD",
                    "AO",
                    "AI",
                    "AQ",
                    "AG",
                    "AR",
                    "AM",
                    "AW",
                    "AU",
                    "AT",
                    "AZ",
                    "BS",
                    "BH",
                    "BD",
                    "BB",
                    "BY",
                    "BE",
                    "BZ",
                    "BJ",
                    "BM",
                    "BT",
                    "BO",
                    "BQ",
                    "BA",
                    "BW",
                    "BV",
                    "BR",
                    "IO",
                    "BN",
                    "BG",
                    "BF",
                    "BI",
                    "KH",
                    "CM",
                    "CA",
                    "CV",
                    "KY",
                    "CF",
                    "TD",
                    "CL",
                    "CN",
                    "CX",
                    "CC",
                    "CO",
                    "KM",
                    "CG",
                    "CD",
                    "CK",
                    "CR",
                    "CI",
                    "HR",
                    "CU",
                    "CW",
                    "CY",
                    "CZ",
                    "DK",
                    "DJ",
                    "DM",
                    "DO",
                    "EC",
                    "EG",
                    "SV",
                    "GQ",
                    "ER",
                    "EE",
                    "ET",
                    "FK",
                    "FO",
                    "FJ",
                    "FI",
                    "FR",
                    "GF",
                    "PF",
                    "TF",
                    "GA",
                    "GM",
                    "GE",
                    "DE",
                    "GH",
                    "GI",
                    "GR",
                    "GL",
                    "GD",
                    "GP",
                    "GU",
                    "GT",
                    "GG",
                    "GN",
                    "GW",
                    "GY",
                    "HT",
                    "HM",
                    "VA",
                    "HN",
                    "HK",
                    "HU",
                    "IS",
                    "IN",
                    "ID",
                    "IR",
                    "IQ",
                    "IE",
                    "IM",
                    "IL",
                    "IT",
                    "JM",
                    "JP",
                    "JE",
                    "JO",
                    "KZ",
                    "KE",
                    "KI",
                    "KP",
                    "KR",
                    "KW",
                    "KG",
                    "LA",
                    "LV",
                    "LB",
                    "LS",
                    "LR",
                    "LY",
                    "LI",
                    "LT",
                    "LU",
                    "MO",
                    "MK",
                    "MG",
                    "MW",
                    "MY",
                    "MV",
                    "ML",
                    "MT",
                    "MH",
                    "MQ",
                    "MR",
                    "MU",
                    "YT",
                    "MX",
                    "FM",
                    "MD",
                    "MC",
                    "MN",
                    "ME",
                    "MS",
                    "MA",
                    "MZ",
                    "MM",
                    "NA",
                    "NR",
                    "NP",
                    "NL",
                    "NC",
                    "NZ",
                    "NI",
                    "NE",
                    "NG",
                    "NU",
                    "NF",
                    "MP",
                    "NO",
                    "OM",
                    "PK",
                    "PW",
                    "PS",
                    "PA",
                    "PG",
                    "PY",
                    "PE",
                    "PH",
                    "PN",
                    "PL",
                    "PT",
                    "PR",
                    "QA",
                    "RE",
                    "RO",
                    "RU",
                    "RW",
                    "BL",
                    "SH",
                    "KN",
                    "LC",
                    "MF",
                    "PM",
                    "VC",
                    "WS",
                    "SM",
                    "ST",
                    "SA",
                    "SN",
                    "RS",
                    "SC",
                    "SL",
                    "SG",
                    "SX",
                    "SK",
                    "SI",
                    "SB",
                    "SO",
                    "ZA",
                    "GS",
                    "SS",
                    "ES",
                    "LK",
                    "SD",
                    "SR",
                    "SJ",
                    "SZ",
                    "SE",
                    "CH",
                    "SY",
                    "TW",
                    "TJ",
                    "TZ",
                    "TH",
                    "TL",
                    "TG",
                    "TK",
                    "TO",
                    "TT",
                    "TN",
                    "TR",
                    "TM",
                    "TC",
                    "TV",
                    "UG",
                    "UA",
                    "AE",
                    "GB",
                    "US",
                    "UM",
                    "UY",
                    "UZ",
                    "VU",
                    "VE",
                    "VN",
                    "VG",
                    "VI",
                    "WF",
                    "EH",
                    "YE",
                    "ZM",
                    "ZW",
                    null
                  ],
                  "default": "FR",
                  "example": "FR",
                  "type": "string"
                },
                "manufacturerPerson": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "manufacturerEmail": {
                  "format": "email",
                  "externalDocs": {
                    "url": "https://schema.org/email"
                  },
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "manufacturerTelephone": {
                  "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "status",
                "assetType",
                "country"
              ]
            },
            "type": {
              "enum": [
                "other",
                "consulting",
                "auditing",
                "catering",
                "upkeepAndGardening",
                "socialAndCultural",
                "digitalService",
                "syndicate"
              ],
              "default": "other",
              "example": "other",
              "type": "string"
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "name": {
              "minLength": 1,
              "maxLength": 56,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "provider": {
              "minLength": 1,
              "maxLength": 56,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": "string"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                "days",
                "weeks",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "assetCondition",
            "name",
            "provider",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Service identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Service identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/sibling-users": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[username]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[username]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[roles]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[lastConnectionDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "username",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "autocomplete[department]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "hidden",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "boolean"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/sivin/vehicle/{licencePlate}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "licencePlate",
          "in": "path",
          "description": "Sivin identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "topic",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "topic[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "order[listOrder]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks-groups": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityId",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityId[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "entityType",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityType[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "topic",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "topic[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "entityId": {
              "type": "string"
            },
            "entityType": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "default": "person",
              "example": "person",
              "type": "string"
            },
            "commonName": {
              "type": "string"
            },
            "jobTitle": {
              "minLength": 1,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "topic": {
              "enum": [
                "peopleOnBoarding",
                "peopleOffBoarding"
              ],
              "default": "peopleOnBoarding",
              "example": "peopleOnBoarding",
              "type": "string"
            }
          },
          "required": [
            "entityType",
            "commonName",
            "jobTitle",
            "topic"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks-groups/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "entityId": {
              "type": "string"
            },
            "entityType": {
              "enum": [
                "asset",
                "vehicle",
                "person",
                "licence",
                "telephone",
                "application",
                "legalEntity",
                "telephoneLine",
                "computer",
                "card",
                "service",
                "customAsset"
              ],
              "default": "person",
              "example": "person",
              "type": "string"
            },
            "commonName": {
              "type": "string"
            },
            "jobTitle": {
              "minLength": 1,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "topic": {
              "enum": [
                "peopleOnBoarding",
                "peopleOffBoarding"
              ],
              "default": "peopleOnBoarding",
              "example": "peopleOnBoarding",
              "type": "string"
            }
          },
          "required": [
            "entityType",
            "commonName",
            "jobTitle",
            "topic"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswer identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks-items": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[customFormAnswer.commonName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[customFormAnswer.jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[owner]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[customFormAnswer.commonName]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[customFormAnswer.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "task",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "task[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "owner",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "owner[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.jobTitle",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.jobTitle[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.commonName",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.commonName[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.topic",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "customFormAnswer.topic[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "order[deadline]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[owner]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[customFormAnswer.jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[customFormAnswer.jobTitle]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[customFormAnswer.topic]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "customFormAnswer",
            "task",
            "owner",
            "deadline",
            "status"
          ],
          "properties": {
            "customFormAnswer": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "task": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "comment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "owner": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": "string"
            },
            "ownerComment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "deadline": {
              "type": "string",
              "format": "date-time"
            },
            "status": {
              "enum": [
                "disabled",
                "pending",
                "processing",
                "success",
                "error"
              ],
              "default": "pending",
              "example": "pending",
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks-items/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswerItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "customFormAnswer",
            "task",
            "owner",
            "deadline",
            "status"
          ],
          "properties": {
            "customFormAnswer": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "task": {
              "type": "string",
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "comment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "owner": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "type": "string"
            },
            "ownerComment": {
              "minLength": 0,
              "maxLength": 255,
              "type": [
                "string",
                "null"
              ]
            },
            "deadline": {
              "type": "string",
              "format": "date-time"
            },
            "status": {
              "enum": [
                "disabled",
                "pending",
                "processing",
                "success",
                "error"
              ],
              "default": "pending",
              "example": "pending",
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswerItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormAnswerItem identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/tasks/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "CustomFormTask identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-line-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-line-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneLineMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneLineMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-lines": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "formFactor",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "formFactor[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[lineNumber]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[lineNumber]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "identification",
                "status",
                "assetType"
              ]
            },
            "country": {
              "pattern": "^([a-zA-Z]{2})$",
              "default": "FR",
              "example": "FR",
              "type": "string"
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "lineNumber": {
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "minLength": 0,
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "puk": {
              "pattern": "^([0-9]{4,8})$",
              "type": [
                "string",
                "null"
              ]
            },
            "sim": {
              "minLength": 0,
              "maxLength": 256,
              "pattern": "^([0-9]{12,20})$",
              "type": [
                "string",
                "null"
              ]
            },
            "formFactor": {
              "enum": [
                "fix",
                "mobile"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "country",
            "assetCondition",
            "lineNumber",
            "formFactor",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-lines/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneLine identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "identification",
                "status",
                "assetType"
              ]
            },
            "assetCondition": {
              "enum": [
                "locked",
                "normal"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "lineNumber": {
              "pattern": "^(.*(^(?:(?:\\+|00)\\d{1,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$).*)$",
              "minLength": 0,
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "puk": {
              "pattern": "^([0-9]{4,8})$",
              "type": [
                "string",
                "null"
              ]
            },
            "sim": {
              "minLength": 0,
              "maxLength": 256,
              "pattern": "^([0-9]{12,20})$",
              "type": [
                "string",
                "null"
              ]
            },
            "formFactor": {
              "enum": [
                "fix",
                "mobile"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buying",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "assetCondition",
            "lineNumber",
            "formFactor",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneLine identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneLine identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephone-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "TelephoneMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephones": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "formFactor",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "formFactor[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[formFactor]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "formFactor": {
              "enum": [
                "fix",
                "mobile"
              ],
              "default": "mobile",
              "example": "mobile",
              "type": "string"
            },
            "os": {
              "enum": [
                "apple",
                "android",
                "sibian",
                "rim",
                null
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "country": {
              "pattern": "^([a-zA-Z]{2})$",
              "default": "FR",
              "example": "FR",
              "type": "string"
            },
            "imei": {
              "minLength": 0,
              "maxLength": 17,
              "pattern": "^(\\d{0,17})$",
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buyingNew",
                "buyingUsed",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "formFactor",
            "assetCondition",
            "country",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/telephones/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Telephone identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "autoRenew": {
                      "type": [
                        "boolean",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "id": {
                  "readOnly": true,
                  "type": "string"
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "formFactor": {
              "enum": [
                "fix",
                "mobile"
              ],
              "default": "mobile",
              "example": "mobile",
              "type": "string"
            },
            "os": {
              "enum": [
                "apple",
                "android",
                "sibian",
                "rim",
                null
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "imei": {
              "minLength": 0,
              "maxLength": 17,
              "pattern": "^(\\d{0,17})$",
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buyingNew",
                "buyingUsed",
                "subscription",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "days",
                "weeks",
                "months",
                "years",
                "km"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "formFactor",
            "assetCondition",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Telephone identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Telephone identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "patch": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Telephone identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/users": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[username]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[username]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[roles]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[lastConnectionDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "username",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "autocomplete[department]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "hidden",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "boolean"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "legalEntity": {
              "anyOf": [
                {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "type": "string"
                    }
                  }
                },
                {
                  "type": "null"
                }
              ]
            },
            "email": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            },
            "roles": {
              "allOf": [
                {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                {
                  "type": "string"
                }
              ]
            },
            "plainPassword": {
              "type": [
                "string",
                "null"
              ]
            },
            "accessRights": {
              "type": "array",
              "items": {
                "type": "object",
                "description": "",
                "deprecated": false,
                "properties": {
                  "id": {
                    "type": "string"
                  }
                }
              }
            },
            "firstname": {
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "lastname": {
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "department": {
              "minLength": 1,
              "maxLength": 64,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "hidden": {
              "type": "boolean",
              "example": false
            }
          },
          "required": [
            "email"
          ]
        }
      },
      "parameters": []
    }
  },
  "/api/private/users/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "User identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "email"
          ],
          "properties": {
            "email": {
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            },
            "roles": {
              "allOf": [
                {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                {
                  "type": "string"
                }
              ]
            },
            "status": {
              "enum": [
                "normal",
                "toBeNotified",
                "newPasswordPending",
                "blocked"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "optIn": {
              "type": "boolean"
            },
            "accessRights": {
              "type": "array",
              "items": {
                "type": "string",
                "format": "iri-reference",
                "example": "https://example.com/"
              }
            },
            "preferredLanguage": {
              "enum": [
                "fr",
                "fr-FR",
                "en-US"
              ],
              "default": "fr-FR",
              "example": "fr-FR",
              "type": "string"
            },
            "note": {
              "type": [
                "string",
                "null"
              ]
            },
            "firstname": {
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "lastname": {
              "maxLength": 32,
              "type": [
                "string",
                "null"
              ]
            },
            "department": {
              "minLength": 1,
              "maxLength": 64,
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "hidden": {
              "type": "boolean",
              "example": false
            }
          }
        }
      },
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "User identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "id",
          "in": "path",
          "description": "User identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicle-media-objects": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "entityTag[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "persons.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "persons.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "assets.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "assets.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicle-media-objects/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "VehicleMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "VehicleMediaObject identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicle-mileages": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "vehicle.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "vehicle.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[recordedAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[value]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[updatedBy]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "recordedAt",
            "vehicle",
            "value"
          ],
          "properties": {
            "recordedAt": {
              "type": "string",
              "format": "date-time"
            },
            "vehicle": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "value": {
              "type": "number"
            },
            "createdBy": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            },
            "updatedBy": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicle-mileages/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "VehicleMileage identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "required": [
            "recordedAt",
            "vehicle",
            "value"
          ],
          "properties": {
            "recordedAt": {
              "type": "string",
              "format": "date-time"
            },
            "vehicle": {
              "type": [
                "string",
                "null"
              ],
              "format": "iri-reference",
              "example": "https://example.com/"
            },
            "value": {
              "type": "number"
            },
            "updatedBy": {
              "pattern": "^(([a-zA-ZÀ-ÿ0-9\\.\\-\\+\\_]+@+[a-zA-Z\\-\\_]+(\\.)+[a-zA-Z0-9]{2,3}))$",
              "format": "email",
              "externalDocs": {
                "url": "https://schema.org/email"
              },
              "type": "string"
            }
          }
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "VehicleMileage identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "VehicleMileage identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicles": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "page",
          "in": "query",
          "description": "The collection page number",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 1
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "itemsPerPage",
          "in": "query",
          "description": "The number of items per page",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "number",
            "default": 30,
            "minimum": 0
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.startDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_before]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.endDate[strictly_after]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.id[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.status",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.status[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.manufacturer[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.supplier[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.contractualCommitment.financingMethod[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "category",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "category[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.assetCondition[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.position",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.position[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "country",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "country[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "asset.attachmentSite.name[]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "style": "form",
          "explode": true,
          "allowReserved": false
        },
        {
          "name": "search[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.assetAssignments.person.firstname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "search[asset.assetAssignments.person.lastname]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[category]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "autocomplete[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[category]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "filterBy[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "translatable[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.startAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "unreserved[asset.assetAssignments.endAt]",
          "in": "query",
          "description": "If provided and compliant to RFC3339, it returns only matching results .",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string"
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[co2Emission]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.supplier]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[category]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.manufacturer]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.model]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.identification]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.status]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.financingMethod]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.contractualCommitment.endDate]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[createdAt]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.assetCondition]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.position]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "order[asset.attachmentSite.name]",
          "in": "query",
          "description": "",
          "required": false,
          "deprecated": false,
          "allowEmptyValue": true,
          "schema": {
            "type": "string",
            "enum": [
              "asc",
              "desc"
            ]
          },
          "style": "form",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "endPayment": {
                      "default": 1,
                      "example": 1,
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "purchaseDate": {
                  "externalDocs": {
                    "url": "https://schema.org/DateTime"
                  },
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "date-time"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "intoCirculationDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "initialMileage": {
              "type": [
                "integer",
                "null"
              ]
            },
            "lastTechnicalCheckDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "lastTechnicalServiceDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "co2Emission": {
              "type": [
                "integer",
                "null"
              ]
            },
            "fuelType": {
              "maxLength": 100,
              "pattern": "^(.*(^(|[a-zA-Z\\d\\+\\s\\.]{1,100}$)).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "category": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "maxLength": 12,
              "type": [
                "string",
                "null"
              ]
            },
            "horsePower": {
              "type": [
                "integer",
                "null"
              ]
            },
            "weightLimit": {
              "minimum": 0,
              "maximum": 100000,
              "type": [
                "number",
                "null"
              ]
            },
            "country": {
              "pattern": "^([a-zA-Z]{2})$",
              "default": "fr",
              "example": "fr",
              "type": [
                "string",
                "null"
              ]
            },
            "financingMethod": {
              "enum": [
                "buying",
                "rent",
                "LOA",
                "other",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "km",
                "days",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            }
          },
          "required": [
            "intoCirculationDate",
            "assetCondition",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/vehicles/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Vehicle identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "put": {
      "body": {
        "schema": {
          "type": "object",
          "description": "",
          "deprecated": false,
          "properties": {
            "asset": {
              "type": "object",
              "description": "",
              "deprecated": false,
              "properties": {
                "contractualCommitment": {
                  "type": "object",
                  "description": "",
                  "deprecated": false,
                  "properties": {
                    "id": {
                      "readOnly": true,
                      "type": "string"
                    },
                    "name": {
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "minLength": 0,
                      "maxLength": 255,
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "reference": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "purchaseDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "purchasePrice": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "amortizationPeriod": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "supplier": {
                      "minLength": 0,
                      "maxLength": 32,
                      "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "startDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "endDate": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "counter": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "counterUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "limit": {
                      "type": [
                        "integer",
                        "null"
                      ]
                    },
                    "limitUnit": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "years",
                        "km"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "priceProrated": {
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "frequency": {
                      "enum": [
                        null,
                        "days",
                        "weeks",
                        "months",
                        "quarters",
                        "semesters",
                        "years"
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    },
                    "endPayment": {
                      "default": 1,
                      "example": 1,
                      "type": [
                        "number",
                        "null"
                      ]
                    },
                    "endOfWarranty": {
                      "type": [
                        "string",
                        "null"
                      ],
                      "format": "date-time"
                    },
                    "payingMethod": {
                      "enum": [
                        "credit-debit-card",
                        "wire-transfer",
                        "sepadd",
                        "cheque",
                        "cash",
                        "other",
                        null
                      ],
                      "type": [
                        "string",
                        "null"
                      ]
                    }
                  }
                },
                "manufacturer": {
                  "pattern": "^([À-ÿa-zA-Z\\d\\+&\\s\\.\\-]*)$",
                  "minLength": 0,
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "model": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "identification": {
                  "minLength": 0,
                  "maxLength": 64,
                  "pattern": "^([\\w/\\-\\s\\:\\+]+)$",
                  "type": "string"
                },
                "name": {
                  "minLength": 0,
                  "maxLength": 32,
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "comment": {
                  "minLength": 0,
                  "maxLength": 4096,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "status": {
                  "enum": [
                    "active",
                    "disabled",
                    "pending"
                  ],
                  "default": "active",
                  "example": "active",
                  "type": "string"
                },
                "purchaseDate": {
                  "externalDocs": {
                    "url": "https://schema.org/DateTime"
                  },
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "date-time"
                },
                "assetType": {
                  "enum": [
                    "vehicle",
                    "telephone",
                    "telephoneLine",
                    "licence",
                    "computer",
                    "card",
                    "service",
                    "customAsset"
                  ],
                  "default": "vehicle",
                  "example": "vehicle",
                  "type": "string"
                },
                "payload": {
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "position": {
                  "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
                  "maxLength": 32,
                  "type": [
                    "string",
                    "null"
                  ]
                },
                "attachmentSite": {
                  "type": [
                    "string",
                    "null"
                  ],
                  "format": "iri-reference",
                  "example": "https://example.com/"
                }
              },
              "required": [
                "manufacturer",
                "identification",
                "status",
                "assetType"
              ]
            },
            "intoCirculationDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "assetCondition": {
              "enum": [
                "broken",
                "stolen",
                "maintenance",
                "normal",
                "lost"
              ],
              "default": "normal",
              "example": "normal",
              "type": "string"
            },
            "initialMileage": {
              "type": [
                "integer",
                "null"
              ]
            },
            "lastTechnicalCheckDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "lastTechnicalServiceDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "co2Emission": {
              "type": [
                "integer",
                "null"
              ]
            },
            "fuelType": {
              "maxLength": 100,
              "pattern": "^(.*(^(|[a-zA-Z\\d\\+\\s\\.]{1,100}$)).*)$",
              "type": [
                "string",
                "null"
              ]
            },
            "category": {
              "pattern": "^([À-ÿñæœ_a-zA-Z\\d\\+&\\s\\.\\-\\(\\)/\\'\\\\\"]*)$",
              "maxLength": 12,
              "type": [
                "string",
                "null"
              ]
            },
            "horsePower": {
              "type": [
                "integer",
                "null"
              ]
            },
            "weightLimit": {
              "minimum": 0,
              "maximum": 100000,
              "type": [
                "number",
                "null"
              ]
            },
            "lastMileage": {
              "pattern": "^(\\d{1,10})$",
              "type": [
                "integer",
                "null"
              ]
            },
            "lastMileageDate": {
              "type": [
                "string",
                "null"
              ],
              "format": "date-time"
            },
            "financingMethod": {
              "enum": [
                "buying",
                "rent",
                "LOA",
                "other",
                "untraced"
              ],
              "type": "string"
            },
            "limit": {
              "type": [
                "integer",
                "null"
              ]
            },
            "limitUnit": {
              "enum": [
                null,
                "km",
                "days",
                "months",
                "years"
              ],
              "type": [
                "string",
                "null"
              ]
            },
            "id": {
              "readOnly": true,
              "type": "string"
            }
          },
          "required": [
            "intoCirculationDate",
            "assetCondition",
            "financingMethod"
          ]
        }
      },
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Vehicle identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    },
    "delete": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "Vehicle identifier",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/private/{assetType}-media-objects-download/{id}": {
    "get": {
      "body": {},
      "parameters": [
        {
          "name": "x-legal-entity",
          "in": "header",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string"
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        },
        {
          "name": "id",
          "in": "path",
          "description": "",
          "required": true,
          "deprecated": false,
          "allowEmptyValue": false,
          "schema": {
            "type": "string",
            "enum": [
              "assetAssignments",
              "assetReturns"
            ]
          },
          "style": "simple",
          "explode": false,
          "allowReserved": false
        }
      ]
    }
  },
  "/api/user-password-reset": {
    "post": {
      "body": {
        "schema": {
          "type": "object",
          "properties": {
            "token": {
              "type": "string"
            },
            "password": {
              "type": "string"
            }
          },
          "example": {
            "token": "0123456789012345678901234567890123456789012345678901234567891234",
            "password": "admin"
          }
        }
      },
      "parameters": []
    }
  }
}