import { FC, ReactNode } from 'react';
import Grid from 'src/web/design_system/Grid';

interface ContentLayoutProps {
  primaryContent: ReactNode;
  secondaryContent: ReactNode;
}

const ContentLayout: FC<ContentLayoutProps> = ({ primaryContent, secondaryContent }) => {
  return (
    <Grid.Container hSpacing={'large'}>
      <Grid.Item size={{ xs: 12, sm: 12, md: 12, lg: 5 }}>{primaryContent}</Grid.Item>
      <Grid.Item size={'flex'}>{secondaryContent}</Grid.Item>
    </Grid.Container>
  );
};

export default ContentLayout;
