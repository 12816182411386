import { FC, useState } from 'react';
import useUserDetail from './hooks/useUserDetail';
import Card from 'src/web/components/Card';
import EntityDetailHeader from 'src/web/components/EntityDetailHeader';
import { ROUTE_UPDATE_TAG, ROUTE_USERS } from 'src/web/common/router/constantes';
import { CardMenuProps } from 'src/web/components/Card/CardMenu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RoleEmblemGroup from 'src/web/components/RoleEmblemGroup';
import Text from 'src/web/design_system/Text';
import { useTheme } from 'src/web/design_system/Theme';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import useDeleteUser from './hooks/useDeleteUser';
import DeleteEntityModal from 'src/web/components/DeleteEntityModal';
import useDisplayRoles from 'src/web/common/roles/useDisplayRoles';
import useTranslate from 'src/web/common/translate/useTranslate';
import Icon from 'src/web/components/Icon';
import Button from 'src/web/components/Button';

const UserDetailPage: FC = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { data, isLoading } = useUserDetail();
  const { handleDelete } = useDeleteUser();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const { theme } = useTheme();
  const cardMenuItems: CardMenuProps['menuItems'] = [
    {
      label: (
        <Displayer justifyContent="space-between" alignItems="center" layout="row" innerSpacing={1}>
          <Icon.Edit size="small" />
          <Text>{translate('users.edit.button')}</Text>
        </Displayer>
      ),
      onClick: () => {
        navigate(`${ROUTE_UPDATE_TAG}?${queryString}`);
      },
    },
    {
      label: (
        <Displayer alignItems="center" justifyContent="space-between" layout="row" innerSpacing={1}>
          <Icon.Delete size="small" color="error" />
          <Text color={theme.palettes.error?.[500]}>{translate('users.delete.button')}</Text>
        </Displayer>
      ),
      onClick: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  const roles = useDisplayRoles(data?.roles ?? []);

  return (
    <>
      <Button.BackNavigate
        to={`${ROUTE_USERS}?${queryString}`}
        label={translate('global.navigateBack.inventory.label')}
      />
      <Card.Container floatingActionContent={<Card.Menu menuItems={cardMenuItems} />}>
        <Card.Content>
          {/* HEADER */}
          <EntityDetailHeader
            isLoading={isLoading}
            title={data?.username ?? ''}
            subtitle={[data?.lastname, data?.firstname].filter((v) => !!v).join(' ')}
            states={[
              {
                label: data?.status ? translate(`users.status.option.${data.status}.label`) : '',
                value: data?.status ?? '',
              },
            ]}
          />

          <Displayer innerSpacing={1}>
            <Skeleton isLoading={isLoading}>
              <Text color={theme.colors.primaryTextColor} fontWeight={900} variant="sectionTitle">
                {`Roles (${roles.length})`}
              </Text>
            </Skeleton>
            <Skeleton isLoading={isLoading} width="100%" height="120px">
              <RoleEmblemGroup
                canSelect={false}
                roles={roles.map(({ key, label, icon: RoleIcon }) => ({
                  role: key,
                  label: label,
                  icon: <RoleIcon size="large" />,
                }))}
              />
            </Skeleton>
          </Displayer>
        </Card.Content>
      </Card.Container>

      <DeleteEntityModal
        title={translate('users.delete.title')}
        text={translate('users.delete.confirm.title')}
        open={isDeleteModalOpen}
        redirect={ROUTE_USERS}
        onSubmit={() => handleDelete()}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default UserDetailPage;
