import { FormEvent, useMemo } from 'react';
import { FormValidationErrors } from 'core/services/form/form.port';
import { ExhaustiveValue } from 'core/swagger';
import { useFormManager } from './formik.adapter';
import { FormItem, UiFormValues, UseFormReturn } from './types';
import useValidation from './useValidation';
import { formatUIFormNullValues, formatUIFormValues, unformatUIFormValues } from './helpers';

interface UseFormProps<K extends string> {
  properties: Record<K, any>;
  initialValues?: Partial<UiFormValues<K>>;
  required: K[];
  validate: (data: UiFormValues<K>) => FormValidationErrors<string>;
  submit?: (data: UiFormValues<K>) => void;
  onFormChange?: (data: UiFormValues<K>) => void;
}

/**
 * Tool to create form
 * @description
 * The props imperativeUpdateOnSubmit allows to bind data coming from another source than a UI event (onChange, onBlur) like by example hidden inputs
 */
function useCreateForm<Keys extends string>(props: UseFormProps<Keys>): UseFormReturn<Keys> {
  const { properties, initialValues, required, validate, submit } = props;

  const { errors, hasErrors, handleValidate } = useValidation({
    properties,
    required,
    validate,
    initialValues,
  });

  const defaultInitialValues: UiFormValues<Keys> = useMemo(
    () => formatUIFormValues(properties),
    [],
  );

  // bind to the UI form manager
  const formManager = useFormManager<UiFormValues<Keys>>({
    initialValues: { ...defaultInitialValues, ...(initialValues ?? {}) },
    validateOnChange: true,
    onSubmit: (values) => {
      const currentErrors = handleValidate(values);

      if (Object.keys(currentErrors).length === 0) {
        const formattedValues = formatUIFormNullValues(properties, values, required);
        if (submit) {
          submit(unformatUIFormValues<Keys>(initialValues, formattedValues, required, 'submit'));
        }
      } else {
        /**
         * TODO: Inform the user that there is error into the form
         * keep this log the time to check everything is alright
         */
        if (process.env.NODE_ENV === 'development') {
          console.log('ISSUE: CAN SUBMIT BUT ERRORS', currentErrors, values);
        }
      }
    },
  });

  // on form submit
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    formManager.handleSubmit(e);
  };

  const handleChange = () => {
    formManager.setValues(formManager.values);
  };

  const handleValidation = () => {
    handleValidate(formManager.values);
  };

  // transform schema properties to UI items
  const processedItems = useMemo<Record<Keys, FormItem>>(() => {
    const keysMap = Object.keys(properties).map((_key) => {
      const key = _key as Keys;
      const item = properties[key];

      const formObj: FormItem = {
        ...item,
        formValue: formManager.values[key],
        name: key,
        required: required.includes(key),
        errors: errors[key],
        handleChange: (value: unknown) => {
          const nextFormValues = {
            ...formManager.values,
            [key]: value,
          };
          if (props.onFormChange) {
            props.onFormChange(nextFormValues);
          }
          handleValidate(nextFormValues, key);
          formManager.setFieldValue(key, value);
        },
      };

      return [key, formObj];
    });

    return Object.fromEntries(keysMap);
  }, [properties, errors, formManager]);

  const handleImperativeChangeField = (key: string, value: ExhaustiveValue) => {
    formManager.setFieldValue(key, value);
  };

  const handleResetForm = () => {
    formManager.reset(null);
  };

  return {
    values: formManager.values,
    items: processedItems,
    hasErrors,
    isDirty: formManager.isDirty,
    handleSubmit,
    handleChange,
    handleValidation,
    imperativeChangeField: handleImperativeChangeField,
    handleReset: handleResetForm,
  };
}

export default useCreateForm;
