import { PropsWithChildren, FormEvent, forwardRef, useImperativeHandle, useRef } from 'react';

export interface FormContainerRef {
  submit: () => void;
}

export type FormContainerEvent = FormEvent<HTMLFormElement>;

interface FormContainerProps {
  className?: string;
  onSubmit?: (event: FormContainerEvent) => void;
}

const FormContainer = forwardRef<FormContainerRef, PropsWithChildren<FormContainerProps>>(
  (props, ref) => {
    const { children, className, onSubmit } = props;
    const formRef = useRef<HTMLFormElement>(null);

    // Create a custom ref handler that expose only a custom 'submit' method
    // This method will trigger the real form element ref
    // This weird code prevent the page to reload when we trigger the submit from a ref
    // (because i guess the ref trigger the native method but not the React one or something like that...)
    useImperativeHandle(
      ref,
      () => ({
        submit: () => {
          if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          }
        },
      }),
      [],
    );

    const handleSubmit = (e: FormContainerEvent) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    };

    return (
      <form ref={formRef} className={className} onSubmit={handleSubmit}>
        {children}
      </form>
    );
  },
);

export default FormContainer;
