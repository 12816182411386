import { FC, useEffect, useState } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import { useCore } from 'src/web/common/core';
import { EntitiesEnum } from 'core/common/constants';
import Skeleton from 'src/web/design_system/Skeleton';
import { isString } from 'core/common/utils/predicatesType';

interface DetailFormPartialProps {
  form: UseSetAssetFormReturn;
  entityType: EntitiesEnum;
}

const DetailFormPartial: FC<DetailFormPartialProps> = ({ form, entityType }) => {
  const { translate } = useTranslate();
  const { sitesUseCase } = useCore();

  const [isLoadingSites, setIsLoadingSites] = useState(false);
  const [sites, setSites] = useState<Awaited<ReturnType<typeof sitesUseCase.getAllSites>>>([]);

  const loadSites = async () => {
    if (entityType !== EntitiesEnum.licence) {
      setIsLoadingSites(true);
      const response = await sitesUseCase.getAllSites();
      setSites(response);
      setIsLoadingSites(false);
    }
  };

  useEffect(() => {
    loadSites();
  }, []);

  return (
    <Card.Basic title={translate('assets.asset.details.title')}>
      <Displayer innerSpacing={2}>
        <Form.RadioAdapted
          item={form.items.asset_status}
          label={translate('assets.asset.status.label')}
          renderOptionLabel={(value) => translate(`assets.asset.status.option.${value}.label`)}
        />
        <Form.SelectAdapted
          item={form.items.assetCondition}
          label={translate('assets.assetCondition.label')}
          renderOptionItem={(value) => ({
            label: translate(`assets.assetCondition.option.${value}.label`),
          })}
        />
        {'asset_name' in form.items && (
          <Form.TextAdapted
            item={form.items.asset_name}
            label={translate('assets.asset.name.label')}
          />
        )}

        {entityType !== EntitiesEnum.licence ? (
          <Skeleton isLoading={isLoadingSites} width="100%" height="55px">
            <Form.Select
              labelWhenNoOptions={translate('assets.options.site.noOptions')}
              renderOptionItem={(value) => ({
                label: sites.find((opt) => opt['@id'] === value)?.name,
              })}
              renderValue={(value) => sites.find((opt) => opt['@id'] === value)?.name}
              options={[undefined, ...sites.map((site) => site['@id'])]}
              value={
                isString(form.items.asset_attachmentSite.formValue)
                  ? form.items.asset_attachmentSite.formValue
                  : undefined
              }
              onChange={(value) => {
                form.items.asset_attachmentSite?.handleChange(value);
              }}
              label={translate('assets.asset.site.label')}
            />
          </Skeleton>
        ) : (
          <></>
        )}
        <Form.TextAreaAdapted
          label={translate('assets.asset.comment.label')}
          item={form.items.asset_comment}
        />
      </Displayer>
    </Card.Basic>
  );
};

export default DetailFormPartial;
