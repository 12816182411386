import { FC } from 'react';
import { createCss, mergeCss } from 'src/web/common/styles/createCss';
import GuiSelect from 'src/web/design_system/Input/Select';
import LibPagination from 'src/web/design_system/Pagination';

interface PaginationProps {
  className?: string;
  count: number;
  current: number;
  itemsPerPage: number;
  itemsPerPageItems: number[];
  onChange: (value: number) => void;
  onChangeItemPerPage: (value: number) => void;
}

const paginationStyle = createCss(`
  display: flex;
  justify-content: center;
  align-items: center;
`);

const selectStyle = createCss(`
  margin-left: 20px;
`);

const Pagination: FC<PaginationProps> = ({
  className,
  count,
  current,
  onChange,
  itemsPerPage,
  itemsPerPageItems,
  onChangeItemPerPage,
}) => {
  return (
    <div className={mergeCss(paginationStyle, className)}>
      {count > 1 && (
        <LibPagination
          className={selectStyle}
          count={count}
          current={current}
          onChange={(value) => onChange(value)}
        />
      )}
      {itemsPerPageItems.length > 1 && (
        <GuiSelect
          isFullWidth={false}
          size="small"
          value={itemsPerPage.toString()}
          options={itemsPerPageItems.map((v) => String(v))}
          onChange={(value) => {
            const singleValue = Array.isArray(value) ? value[0] : value;
            onChangeItemPerPage(parseInt(singleValue ?? ''));
          }}
        />
      )}
    </div>
  );
};

export default Pagination;
