import { Paper, Typography, Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { themeColors } from 'src/web/common/theme/constant';

interface EmblemProps {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  selectedColor?: string;
  defaultColor?: string;
  disableColor?: string;
}

const Emblem: FC<EmblemProps> = ({ icon, label }) => {
  return (
    <Paper
      elevation={5}
      variant={'elevation'}
      sx={{
        p: 2,
        gap: '1em',
        display: 'flex',
        width: 'auto',
        alignItems: 'center',
        backgroundColor: 'inherit',
        cursor: 'default',
      }}
      title={label}
    >
      <Box sx={{ opacity: 0.3 }}>{icon}</Box>
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          color: themeColors.blackColor,
          width: 'auto',
          maxHeight: '3em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Typography>
    </Paper>
  );
};

export default Emblem;
