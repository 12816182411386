import { FC, PropsWithChildren } from 'react';
import GuiCard from 'src/web/design_system/NewCard';
import { CardContentProps as GuiCardContentProps } from 'src/web/design_system/NewCard/cardContent';

type CardContentProps = Omit<GuiCardContentProps, 'padding'>;

const CardContent: FC<PropsWithChildren<CardContentProps>> = (props) => {
  return <GuiCard.Content {...props} />;
};

export default CardContent;
