interface ParseIriResponse {
  pathParts: string[];
  query: {
    name: string;
    value: string;
  }[];
}

export function parseIri(iri: string): ParseIriResponse {
  const result: ParseIriResponse = {
    pathParts: [],
    query: [],
  };

  const parts = iri.split('?');
  result.pathParts = parts[0].split('/').filter((p) => p.length > 0);

  if (parts.length > 0) {
    if (parts.length > 1) {
      const queryString = parts[1];
      const paramPairs = queryString.split('&');

      for (const paramPair of paramPairs) {
        const [key, value] = paramPair.split('=');
        result.query.push({
          name: key,
          value: decodeURIComponent(value),
        });
      }
    }
  }

  return result;
}

export function getIdFromIri(iri: string): string | undefined {
  const { pathParts } = parseIri(iri);
  return pathParts.slice().pop();
}
