import { FC, PropsWithChildren, ReactNode } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '../Grid';

interface AccordionProps {
  title: ReactNode;
  extraContent?: ReactNode;
  expended: boolean;
  disabled?: boolean;
  onChange: (state: boolean) => void;
}

const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
  title,
  extraContent,
  children,
  expended = false,
  disabled = false,
  onChange,
}) => {
  const handleChange = (state: boolean) => {
    if (onChange) {
      onChange(state);
    }
  };

  return (
    <MuiAccordion
      disabled={disabled}
      expanded={expended}
      onChange={(_, state) => handleChange(state)}
    >
      <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Grid.Container>
          <Grid.Item size="flex">{title}</Grid.Item>
          {extraContent && <Grid.Item size="auto">{extraContent}</Grid.Item>}
        </Grid.Container>
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
