import { HTMLInputTypeAttribute, ReactNode, useCallback, useMemo } from 'react';
import { InputAdornment, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { isNumber, isString } from 'core/common/utils/predicatesType';

// Global input types (named mode) the component will accept
type OptimizedInputMode = 'text' | 'number';

// Define the value type based on the input mode
type OptimizedInputValue<M extends OptimizedInputMode> = M extends 'number' ? number : string;

// Select allowed props from MUI TextField props
// Must not to includes our custom props
type AllowedMuiTextFieldProps = Pick<
  TextFieldProps,
  'type' | 'label' | 'disabled' | 'fullWidth' | 'error'
>;

// Change handler
type OptimizedInputChangeHandler<M extends OptimizedInputMode> = (
  value: OptimizedInputValue<M> | undefined,
) => void;

// Props override
interface MuiInputCustomProps<M extends OptimizedInputMode> {
  mode: M;
  value?: OptimizedInputValue<M>;
  onChange?: OptimizedInputChangeHandler<M>;
  onBlur?: OptimizedInputChangeHandler<M>;
  startExtra?: ReactNode;
  endExtra?: ReactNode;
  message?: ReactNode;
  multiline?: M extends 'text' ? boolean : never;
  isRequired?: boolean;
  size?: 'small' | 'medium';
  min?: number;
  max?: number;
  minRows?: M extends 'text' ? number : never;
  maxRows?: M extends 'text' ? number : never;
  pattern?: string | RegExp;
}

// Merged type from custom type and component native type
export type OptimizedInputProps<T extends OptimizedInputMode> = AllowedMuiTextFieldProps &
  MuiInputCustomProps<T>;

/**
 * A component that override the messy and unsafe MUI TextField
 * Used to produce text and number fields
 */
function OptimizedInput<M extends OptimizedInputMode>(props: OptimizedInputProps<M>): JSX.Element {
  const {
    value,
    type,
    message,
    mode,
    startExtra,
    endExtra,
    size,
    isRequired,
    multiline,
    min,
    max,
    pattern,
    minRows,
    maxRows,
    onChange,
    onBlur,
    ...restProps
  } = props;

  // prevent the component to change from uncontrolled to controlled
  const computedValue = useMemo((): OptimizedInputValue<M> => {
    if (mode === 'number' && isNumber(value)) {
      return value;
    }

    if (mode === 'text' && isString(value)) {
      return value;
    }

    return '' as OptimizedInputValue<M>;
  }, [value, mode]);

  // Apply a custom type of a default type relative to the mode
  const computedType = useMemo((): HTMLInputTypeAttribute | undefined => {
    if (!type) {
      if (mode === 'text') {
        return 'text';
      }
      if (mode === 'number') {
        return 'number';
      }
    }
    return type;
  }, [type, mode]);

  // Handle input change on different triggers
  // the important part is what is returned when the field is empty.
  // With a number field we can't return 0 because we want the field to display nothing. So we must return undefined.
  const handleUpdate = useCallback(
    (nextValue?: string, callback?: OptimizedInputChangeHandler<M>) => {
      if (callback) {
        if (mode === 'number') {
          const safeValue =
            nextValue && !isNaN(parseFloat(nextValue)) ? parseFloat(nextValue) : undefined;
          return callback(safeValue as OptimizedInputValue<M>);
        }

        if (mode === 'text') {
          const safeValue = isString(nextValue) ? nextValue : '';
          return callback(safeValue as OptimizedInputValue<M>);
        }
      }
    },
    [mode],
  );

  // Override MUI TextField behavior with type number until an official and sage InputNumber appears in the library
  // prevent the MuiTextField to capture invalid keyboard value
  const handleKeyDown = useCallback(
    (keyValue: string, cancelCallback: () => void) => {
      if (mode === 'number') {
        const forbiddenCharactersRegex = /^[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|<>/?]*$/;
        const isNotControlKey = keyValue.length === 1;

        if (isNotControlKey && !!String(keyValue).match(forbiddenCharactersRegex)) {
          cancelCallback();
        }
      }
    },
    [mode],
  );

  // Display an icon before the field value
  const computedStartExtra = useMemo(() => {
    if (startExtra) {
      return <InputAdornment position="start">{startExtra}</InputAdornment>;
    }
  }, [startExtra]);

  // Display an icon after the field value
  const computedEndExtra = useMemo(() => {
    if (endExtra) {
      return <InputAdornment position="end">{endExtra}</InputAdornment>;
    }
  }, [endExtra]);

  //
  /// I comment the code below to avoid shrinking the label when we type period ('.') because MUI just accept comma (',') in the number type
  // TODO: fix the component by deleting the number type and just use the text type and customize it to accept any decimal type
  //
  // Prevent the MUI shrink/notch system to fail in some cases...
  // const shouldShrinkLabel = useMemo(() => {
  //   return (
  //     (isString(computedValue) && computedValue.length > 0) ||
  //     isNumber(computedValue) ||
  //     !!computedStartExtra
  //   );
  // }, [computedValue, startExtra]);

  return (
    <>
      <MuiTextField
        {...restProps}
        type={computedType}
        value={computedValue}
        label={props.label}
        multiline={multiline}
        size={size}
        helperText={message}
        required={isRequired}
        minRows={minRows}
        maxRows={maxRows}
        onChange={(event) => handleUpdate(event.target.value, onChange)}
        onBlur={(event) => handleUpdate(event.target.value, onBlur)}
        onKeyDown={(event) => handleKeyDown(event.key, () => event.preventDefault())}
        InputProps={{
          startAdornment: computedStartExtra,
          endAdornment: computedEndExtra,
        }}
        inputProps={{
          min: min ?? 0,
          max,
          step: 0.01,
          pattern,
        }}
      />
    </>
  );
}

export default OptimizedInput;
