import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

interface UseCreateSiteProps {
  afterSubmit: (status: boolean) => void;
}

const useCreateSite = ({ afterSubmit }: UseCreateSiteProps) => {
  const { sitesUseCase } = useCore();
  const { properties, initialValues, required, validate, submit } =
    sitesUseCase.getCreateSiteForm();

  const form = useCreateForm({
    properties,
    initialValues,
    required,
    validate,
    submit: async (data) => {
      const response = await submit(data);

      afterSubmit(response);
    },
  });

  const handleReset = () => {
    form.handleReset();
  };

  return {
    form,
    handleReset,
  };
};

export default useCreateSite;
