import { FC, PropsWithChildren } from 'react';
import { Card as MuiCard } from '@mui/material';

interface CardContainerProps {
  className?: string;
  mode?: 'default' | 'raised';
}

const CardContainer: FC<PropsWithChildren<CardContainerProps>> = ({
  children,
  className,
  mode = 'default',
}) => {
  return (
    <MuiCard className={className} raised={mode === 'raised'}>
      {children}
    </MuiCard>
  );
};

export default CardContainer;
