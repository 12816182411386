import { FC } from 'react';
import Translate from 'src/web/common/translate/translate.component';
import Alert from 'src/web/design_system/Alert';

const NotificationPreviewEmpty: FC = () => {
  return (
    <Alert mode="info">
      <Translate id="notifications.notifications.empty.label" />
    </Alert>
  );
};

export default NotificationPreviewEmpty;
