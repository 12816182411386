import InputText from './InputText';
import InputTextAdapted from './InputTextAdapted';
import InputNumber from './InputNumber';
import InputNumberAdapted from './InputNumberAdapted';
import InputSelect from './InputSelect';
import InputSelectAdapted from './InputSelectAdapted';
import InputRadio from './InputRadio';
import InputRadioAdapted from './InputRadioAdapted';
import InputDate from './InputDate';
import InputDateAdapted from './InputDateAdapted';
import InputPassword from './InputPassword';
import InputPasswordAdapted from './InputPasswordAdapted';
import InputTextArea from './InputTextArea';
import InputTextAreaAdapted from './InputTextAreaAdapted';

export default {
  Password: InputPassword,
  PasswordAdapted: InputPasswordAdapted,
  Text: InputText,
  TextAdapted: InputTextAdapted,
  Number: InputNumber,
  NumberAdapted: InputNumberAdapted,
  Select: InputSelect,
  SelectAdapted: InputSelectAdapted,
  Radio: InputRadio,
  RadioAdapted: InputRadioAdapted,
  Date: InputDate,
  DateAdapted: InputDateAdapted,
  TextArea: InputTextArea,
  TextAreaAdapted: InputTextAreaAdapted,
};
