import { Modal as MuiModal } from '@mui/material';
import { FC, ReactNode } from 'react';
import { css, cx } from '@emotion/css';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  edgeLimit?: number;
  align?:
    | 'top-left'
    | 'top-middle'
    | 'top-right'
    | 'middle-left'
    | 'middle-right'
    | 'middle-middle'
    | 'bottom-left'
    | 'bottom-middle'
    | 'bottom-right';
};

const baseModalStyle = (padding: number) => css`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: ${padding}px;
  outline: none;
`;

const modalAlignStyles = {
  'top-left': css`
    margin: 0 auto auto 0;
  `,
  'top-middle': css`
    margin: 0 auto auto;
  `,
  'top-right': css`
    margin: 0 0 auto auto;
  `,
  'middle-left': css`
    margin: auto auto auto 0;
  `,
  'middle-right': css`
    margin: auto 0 auto auto;
  `,
  'middle-middle': css`
    margin: auto;
  `,
  'bottom-left': css`
    margin: auto auto 0 0;
  `,
  'bottom-middle': css`
    margin: auto auto 0 auto;
  `,
  'bottom-right': css`
    margin: auto 0 0 auto;
  `,
};

const Modal: FC<ModalProps> = ({
  children,
  open,
  onClose,
  className,
  align = 'middle-middle',
  edgeLimit = 16,
}) => {
  const alignStyle = modalAlignStyles[align];

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      sx={{
        overflow: 'auto',
      }}
    >
      <div className={baseModalStyle(edgeLimit)}>
        <div className={cx(alignStyle, className)}>{children}</div>
      </div>
    </MuiModal>
  );
};

export default Modal;
