import React, { FC, ChangeEvent } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, useTheme } from '@mui/material';
import { css } from '@emotion/css';

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: React.ReactNode;
}

const formControlStyle = css`
  width: fit-content;
`;

const Checkbox: FC<CheckboxProps> = ({ checked, onChange, label }) => {
  const theme = useTheme();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      className={formControlStyle}
      control={
        <MuiCheckbox
          checked={checked}
          onChange={handleChange}
          sx={{
            '&.Mui-checked': {
              color: theme.palette.secondary.main,
            },
          }}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
