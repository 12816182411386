import HttpService, { RequestOptions } from 'core/services/api/http.service';
import { exactString } from 'core/common/utils/manageType';

class PdfRenderEntity {
  constructor(private http: HttpService) {}

  getPath() {
    return exactString('/api/private/pdf/render/{id}');
  }

  getPathDownload() {
    return exactString('/api/private/media-objects/download/{id}');
  }

  post = async (options: RequestOptions<ReturnType<typeof this.getPath>, 'post'>) => {
    return this.http.requestFromSwagger(this.getPath(), 'post', options);
  };
}

export default PdfRenderEntity;
