import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCore } from 'src/web/common/core';

const useDeletePerson = () => {
  const { id } = useParams();
  const { personUsercase } = useCore();

  const handleDelete = useCallback(async () => {
    if (id) {
      return personUsercase.deletePerson(id);
    }
    return false;
  }, [id]);

  return {
    handleDelete,
  };
};

export default useDeletePerson;
