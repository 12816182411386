import { Divider as DividerMui, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface DividerProps {
  color?: string;
  dashed?: boolean;
  vSpacing?: number;
  hSpacing?: number;
  align?: 'center' | 'right' | 'left';
}

const Divider: FC<PropsWithChildren<DividerProps>> = ({
  color,
  dashed,
  vSpacing,
  hSpacing,
  children,
  align = 'center',
}) => {
  const theme = useTheme();
  const x = theme.spacing(vSpacing ?? 0);
  const y = theme.spacing(hSpacing ?? 0);
  const colorDivider = color ?? theme.palette.grey[50];

  return (
    <DividerMui
      textAlign={align}
      color={colorDivider}
      sx={{
        marginX: x,
        marginY: y,
        borderStyle: dashed ? 'dashed' : 'solid',
      }}
    >
      {children}
    </DividerMui>
  );
};
export default Divider;
