import { FC, ReactNode } from 'react';
import Grid from 'src/web/design_system/Grid';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';

interface PageHeaderBasicProps {
  title?: string;
  subtitle?: ReactNode;
  extraContent?: React.ReactNode;
  className?: string;
}

const PageHeaderBasic: FC<PageHeaderBasicProps> = ({
  title,
  subtitle,
  extraContent,
  className,
}) => {
  if (!title && !extraContent) return <></>;

  return (
    <Displayer bottomSpacing={3}>
      <Grid.Container
        className={className}
        data-testid="pageheaderbasic"
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid.Item size="flex">
          {title && <Text variant="pageTitle">{title}</Text>}
          {subtitle && <>{subtitle}</>}
        </Grid.Item>
        {extraContent && <Grid.Item size="auto">{extraContent}</Grid.Item>}
      </Grid.Container>
    </Displayer>
  );
};

export default PageHeaderBasic;
