import { FC } from 'react';
import { useCore } from 'src/web/common/core';
import TextPair from 'src/web/components/TextPair';
import { useGetAssetDetailData } from '../../hooks/useGetAssetDetailData';
import useTranslate from 'src/web/common/translate/useTranslate';

interface DynamicFinancingFieldsProps {
  dataAsset?: ReturnType<typeof useGetAssetDetailData>['data'];
}

const DynamicFinancingFields: FC<DynamicFinancingFieldsProps> = ({ dataAsset }) => {
  const assetCommitment = dataAsset?.asset?.contractualCommitment;
  const { translate } = useTranslate();
  const { timeService, legalEntityUsecase } = useCore();
  const currency = legalEntityUsecase.getCurrency();
  const financingMethod = dataAsset?.financingMethod;
  const isSubscription = financingMethod === 'subscription';
  const isRent = financingMethod === 'rent';
  const isLoa = financingMethod === 'LOA';
  const isOtherFinancingMethod = financingMethod === 'other';
  const isPaid =
    financingMethod === 'buyingNew' ||
    financingMethod === 'buyingUsed' ||
    financingMethod === 'buying';

  const isCardsType = dataAsset?.['@type'] === 'Card';
  const isServiceType = dataAsset?.['@type'] === 'Service';
  const isLicenceType = dataAsset?.['@type'] === 'Licence';
  const isTelephoneLineType = dataAsset?.['@type'] === 'TelephoneLine';

  return (
    <>
      <TextPair
        label={translate('assets.financingMethod.label')}
        value={
          financingMethod
            ? translate(`assets.financingMethod.option.${financingMethod}.label`)
            : undefined
        }
      />

      {isSubscription && (
        <>
          <TextPair
            label={translate('contractualCommitment.supplier.label')}
            value={assetCommitment?.supplier}
          />
          <TextPair
            label={translate('contractualCommitment.reference.label')}
            value={assetCommitment?.reference}
          />
          <TextPair
            label={translate('contractualCommitment.startDate.label')}
            value={
              assetCommitment?.startDate &&
              timeService.format(timeService.fromBackend(assetCommitment?.startDate), 'short')
            }
          />

          {assetCommitment?.startDate && assetCommitment?.endDate ? (
            <TextPair
              label={translate('contractualCommitment.endDate.label')}
              value={
                assetCommitment?.endDate &&
                timeService.format(timeService.fromBackend(assetCommitment?.endDate), 'short')
              }
            />
          ) : (
            <></>
          )}
          <TextPair
            label={translate('contractualCommitment.cost.label')}
            value={
              assetCommitment?.priceProrated && assetCommitment?.frequency
                ? `${assetCommitment?.priceProrated} ${currency} / ${translate(
                    `contractualCommitment.frequency.option.${assetCommitment?.frequency}.label`,
                  )}`
                : undefined
            }
          />
          <TextPair
            label={translate('contractualCommitment.limit.label')}
            value={
              dataAsset?.asset?.contractualCommitment?.limit
                ? `${dataAsset?.asset?.contractualCommitment?.limit}  ${translate(
                    `contractualCommitment.limitUnit.option.${dataAsset?.asset?.contractualCommitment?.limitUnit}.label`,
                  )}`
                : undefined
            }
          />
          {isTelephoneLineType || isCardsType || isLicenceType || isServiceType ? (
            <></>
          ) : (
            <TextPair
              label={translate('contractualCommitment.endOfWarranty.label')}
              value={
                assetCommitment?.endOfWarranty &&
                timeService.format(timeService.fromBackend(assetCommitment?.endOfWarranty), 'short')
              }
            />
          )}
        </>
      )}

      {(isRent || isLoa || isOtherFinancingMethod) && (
        <>
          <TextPair
            label={translate('contractualCommitment.supplier.label')}
            value={assetCommitment?.supplier}
          />
          <TextPair
            label={translate('contractualCommitment.reference.label')}
            value={assetCommitment?.reference}
          />
          <TextPair
            label={translate('contractualCommitment.startDate.label')}
            value={
              assetCommitment?.startDate &&
              timeService.format(timeService.fromBackend(assetCommitment?.startDate), 'short')
            }
          />
          <TextPair
            label={translate('contractualCommitment.endDate.label')}
            value={
              assetCommitment?.endDate &&
              timeService.format(timeService.fromBackend(assetCommitment?.endDate), 'short')
            }
          />
          <TextPair
            label={translate('contractualCommitment.cost.label')}
            value={
              assetCommitment?.priceProrated && assetCommitment?.frequency
                ? `${assetCommitment?.priceProrated} ${currency} / ${translate(
                    `contractualCommitment.frequency.option.${assetCommitment?.frequency}.label`,
                  )}`
                : undefined
            }
          />
          {dataAsset?.['@type'] === 'Vehicle' ? (
            <TextPair
              label={translate('contractualCommitment.vehiclesLimit.label')}
              value={
                assetCommitment?.limit && assetCommitment.limitUnit
                  ? `${assetCommitment.limit} ${translate(
                      `contractualCommitment.limitUnit.option.${assetCommitment.limitUnit}.label`,
                    )}`
                  : undefined
              }
            />
          ) : (
            <></>
          )}
          {isLoa && (
            <>
              <TextPair
                label={translate('contractualCommitment.endPayment.label')}
                value={
                  assetCommitment && 'endPayment' in assetCommitment
                    ? `${assetCommitment.endPayment} ${currency}`
                    : undefined
                }
              />
            </>
          )}
          {isTelephoneLineType || isCardsType || isLicenceType || isServiceType ? (
            <></>
          ) : (
            <TextPair
              label={translate('contractualCommitment.endOfWarranty.label')}
              value={
                assetCommitment?.endOfWarranty &&
                timeService.format(timeService.fromBackend(assetCommitment?.endOfWarranty), 'short')
              }
            />
          )}
        </>
      )}

      {isPaid && (
        <>
          <TextPair
            label={translate('contractualCommitment.supplier.label')}
            value={assetCommitment?.supplier}
          />
          <TextPair
            label={translate('contractualCommitment.reference.label')}
            value={assetCommitment?.reference}
          />
          <TextPair
            label={translate('contractualCommitment.purchaseDate.label')}
            value={
              assetCommitment?.purchaseDate &&
              timeService.format(timeService.fromBackend(assetCommitment?.purchaseDate), 'short')
            }
          />
          <TextPair
            label={translate('contractualCommitment.purchasePrice.label')}
            value={
              assetCommitment?.purchasePrice
                ? `${assetCommitment?.purchasePrice} ${currency}`
                : undefined
            }
          />
          {isTelephoneLineType || isCardsType || isLicenceType || isServiceType ? (
            <></>
          ) : (
            <TextPair
              label={translate('contractualCommitment.endOfWarranty.label')}
              value={
                assetCommitment?.endOfWarranty &&
                timeService.format(timeService.fromBackend(assetCommitment?.endOfWarranty), 'short')
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default DynamicFinancingFields;
