import { FC, PropsWithChildren, useMemo } from 'react';
import { createCss, mergeCss } from 'src/web/common/styles/createCss';
import { Theme, useTheme } from 'src/web/common/theme';
import GuiCard from 'src/web/design_system/NewCard';
import { CardContainerProps as GuiCardContainerProps } from 'src/web/design_system/NewCard/cardContainer';

interface CardContainerProps extends Omit<GuiCardContainerProps, 'shadow'> {
  mode?: 'default' | 'highlight';
  border?: 'shadow' | 'line' | 'none';
}

const cardModeStyle = (theme: Theme) =>
  createCss(`
  background: linear-gradient(to bottom right, #ffffff20 0%, #ffffff60 100%), ${theme.palettes.primary?.[200]};
  color: ${theme.colors.black};
`);

const CardContainer: FC<PropsWithChildren<CardContainerProps>> = ({
  children,
  mode,
  border = 'shadow',
  className,
  ...guiProps
}) => {
  const { theme } = useTheme();

  const cardModeProps = useMemo(() => {
    switch (mode) {
      case 'highlight':
        return cardModeStyle(theme);
      default:
        return '';
    }
  }, [mode]);

  const shadowMode = useMemo(() => {
    switch (border) {
      case 'line':
        return 'outline';
      case 'shadow':
        return true;
      case 'none':
      default:
        return false;
    }
  }, [border]);

  return (
    <GuiCard.Container
      {...guiProps}
      shadow={shadowMode}
      className={mergeCss(cardModeProps, className)}
    >
      {children}
    </GuiCard.Container>
  );
};

export default CardContainer;
