import { Navigate, Outlet, createHashRouter } from 'react-router-dom';
import LoginPage from 'src/web/pages/auth/login';
import DashboardPage from 'src/web/pages/dashboard';
import BaseLayout from 'src/web/layouts/BaseLayout';
import { RolesEnum } from 'core/common/constants';
import Signup from 'src/web/pages/auth/signup';
import * as ROUTES from './constantes';
import DevTest from 'src/web/pages/devTest';
import DashboardLayout from 'src/web/layouts/DashboardLayout';
import InventoryVehiclePage from 'src/web/pages/assets/inventory/inventoryVehicle';
import InventoryComputerPage from 'src/web/pages/assets/inventory/inventoryComputer';
import InventoryServicePage from 'src/web/pages/assets/inventory/inventoryService';
import InventoryLicencePage from 'src/web/pages/assets/inventory/inventoryLicence';
import InventoryTelephonePage from 'src/web/pages/assets/inventory/inventoryTelephone';
import InventoryTelephoneLinePage from 'src/web/pages/assets/inventory/inventoryTelephoneLine';
import InventoryCardPage from 'src/web/pages/assets/inventory/inventoryCard';
import EditVehiclePage from 'src/web/pages/assets/set/editVehicle';
import AddVehiclePage from 'src/web/pages/assets/set/addVehicle';
import EditComputerPage from 'src/web/pages/assets/set/editComputer';
import AddComputerPage from 'src/web/pages/assets/set/addComputer';
import EditServicePage from 'src/web/pages/assets/set/editService';
import AddServicePage from 'src/web/pages/assets/set/addService';
import EditLicencePage from 'src/web/pages/assets/set/editLicence';
import AddLicencePage from 'src/web/pages/assets/set/addLicence';
import EditTelephonePage from 'src/web/pages/assets/set/editTelephone';
import AddTelephonePage from 'src/web/pages/assets/set/addTelephone';
import EditTelephoneLinePage from 'src/web/pages/assets/set/editTelephoneLine';
import AddTelephoneLinePage from 'src/web/pages/assets/set/addTelephoneLine';
import EditCardPage from 'src/web/pages/assets/set/editCard';
import AddCardPage from 'src/web/pages/assets/set/addCard';
import InventoryPersonPage from 'src/web/pages/person/inventory';
import TaskInventoryPage from 'src/web/pages/tasks/inventory';
import AddPersonPage from 'src/web/pages/person/set/addPerson';
import EditPersonPage from 'src/web/pages/person/set/editPerson';
import DetailsCard from 'src/web/pages/assets/detail/detailsCard';
import DetailsVehicle from 'src/web/pages/assets/detail/detailsVehicle';
import DetailsComputer from 'src/web/pages/assets/detail/detailsComputer';
import DetailsService from 'src/web/pages/assets/detail/detailsService';
import DetailsLicence from 'src/web/pages/assets/detail/detailsLicence';
import DetailsTelephone from 'src/web/pages/assets/detail/detailsTelephone';
import DetailsTelephoneLine from 'src/web/pages/assets/detail/detailsTelephoneLine';
import MyProfilPage from 'src/web/pages/myProfil';
import Grant from './grant.component';
import PersonDetailPage from 'src/web/pages/person/detail';
import LegalEntityPage from 'src/web/pages/legalEntity';
import UserInventoryPage from 'src/web/pages/user/inventory';
import AddUserPage from 'src/web/pages/user/set/addUser';
import EditUserPage from 'src/web/pages/user/set/editUser';
import UserDetailPage from 'src/web/pages/user/detail';
import OffboardingPage from 'src/web/pages/person/boarding/offboarding';
import OnboardingPage from 'src/web/pages/person/boarding/onboarding';
import DefinePassword from 'src/web/pages/auth/definePassword';
import LostPassword from 'src/web/pages/auth/lostPassword';
import NotificationsConfigPage from 'src/web/pages/notifications-config';
import NotFound from 'src/web/components/NotFound';
import RootErrorBoundary from 'src/web/components/RootErrorBoundary/RootErrorBoundary';
import InventoryCustomAssetPage from 'src/web/pages/assets/inventory/inventoryCustomAsset';
import DetailsCustomAssets from 'src/web/pages/assets/detail/detailsCustomAssets';
import EditCustomAssetPage from 'src/web/pages/assets/set/editCustomAsset';
import AddCustomAssetPage from 'src/web/pages/assets/set/addCustomAsset';

export default createHashRouter([
  {
    path: '/',
    element: (
      <RootErrorBoundary>
        <BaseLayout content={<Outlet />} />
      </RootErrorBoundary>
    ),
    children: [
      {
        path: '/',
        element: (
          <Grant includes={[RolesEnum.ROLE_USER]} fallback="/login">
            <DashboardLayout content={<Outlet />} />
          </Grant>
        ),
        children: [
          // TEMPORARY FILE TO DEV ONLY
          {
            path: 'dev',
            element: <DevTest />,
          },
          // DEFAULT APP PAGE
          {
            index: true,
            element: <Navigate to={ROUTES.ROUTE_DASHBOARD} replace />,
          },
          {
            path: ROUTES.ROUTE_DASHBOARD,
            element: <DashboardPage />,
          },
          // PERSON
          {
            path: ROUTES.ROUTE_PERSON,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_PERSON]} fallback="/">
                    <InventoryPersonPage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <PersonDetailPage />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditPersonPage />,
                  },
                  {
                    path: ROUTES.ROUTE_ONBOARDING_TAG,
                    children: [
                      {
                        path: ROUTES.ROUTE_CREATE_TAG,
                        element: <OnboardingPage />,
                      },
                      {
                        path: ROUTES.ROUTE_UPDATE_TAG,
                        element: <OnboardingPage isEdition />,
                      },
                    ],
                  },
                  {
                    path: ROUTES.ROUTE_OFFBOARDING_TAG,
                    children: [
                      {
                        path: ROUTES.ROUTE_CREATE_TAG,
                        element: <OffboardingPage />,
                      },
                      {
                        path: ROUTES.ROUTE_UPDATE_TAG,
                        element: <OffboardingPage isEdition />,
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddPersonPage />,
              },
            ],
          },
          // VEHICULE
          {
            path: ROUTES.ROUTE_VEHICULE,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_VEHICLE]} fallback="/">
                    <InventoryVehiclePage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsVehicle />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditVehiclePage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddVehiclePage />,
              },
            ],
          },
          // COMPUTER
          {
            path: ROUTES.ROUTE_COMPUTER,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_COMPUTER]} fallback="/">
                    <InventoryComputerPage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsComputer />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditComputerPage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddComputerPage />,
              },
            ],
          },
          // SERVICE
          {
            path: ROUTES.ROUTE_SERVICE,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_SERVICE]} fallback="/">
                    <InventoryServicePage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsService />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditServicePage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddServicePage />,
              },
            ],
          },
          // LICENCE
          {
            path: ROUTES.ROUTE_LICENCE,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_LICENCE]} fallback="/">
                    <InventoryLicencePage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsLicence />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditLicencePage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddLicencePage />,
              },
            ],
          },
          // TELEPHONE
          {
            path: ROUTES.ROUTE_TELEPHONE,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_TELEPHONE]} fallback="/">
                    <InventoryTelephonePage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsTelephone />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditTelephonePage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddTelephonePage />,
              },
            ],
          },
          // TELEPHONE LINE
          {
            path: ROUTES.ROUTE_TELEPHONE_LINE,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_TELEPHONE_LINE]} fallback="/">
                    <InventoryTelephoneLinePage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsTelephoneLine />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditTelephoneLinePage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddTelephoneLinePage />,
              },
            ],
          },
          // CARD
          {
            path: ROUTES.ROUTE_CARD,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_CARD]} fallback="/">
                    <InventoryCardPage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsCard />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditCardPage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddCardPage />,
              },
            ],
          },
          // CUSTOM ASSETS
          {
            path: ROUTES.ROUTE_CUSTOM_ASSET,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_CUSTOM_ASSET]} fallback="/">
                    <InventoryCustomAssetPage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <DetailsCustomAssets />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditCustomAssetPage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddCustomAssetPage />,
              },
            ],
          },
          // USER
          {
            path: ROUTES.ROUTE_USERS,
            children: [
              {
                index: true,
                element: (
                  <Grant includes={[RolesEnum.ROLE_ADMIN_USER]} fallback="/">
                    <UserInventoryPage />
                  </Grant>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <UserDetailPage />,
                  },
                  {
                    path: ROUTES.ROUTE_UPDATE_TAG,
                    element: <EditUserPage />,
                  },
                ],
              },
              {
                path: ROUTES.ROUTE_CREATE_TAG,
                element: <AddUserPage />,
              },
            ],
          },

          {
            path: ROUTES.ROUTE_TASKS,
            element: <TaskInventoryPage />,
          },

          {
            path: ROUTES.ROUTE_CONTRACTS,
            element: <DashboardPage />,
          },
          {
            path: ROUTES.ROUTE_TASKS_MODELES,
            element: <DashboardPage />,
          },
          {
            path: ROUTES.ROUTE_ACCOUNT_LEGALENTITY,
            element: (
              <Grant includes={[RolesEnum.ROLE_MY_LEGAL_ENTITY]} fallback="/">
                <LegalEntityPage />
              </Grant>
            ),
          },
          {
            path: ROUTES.ROUTE_ACCOUNT_MYPROFILE,
            element: <MyProfilPage />,
          },
          {
            path: ROUTES.ROUTE_NOTIFICATIONS,
            element: <DashboardPage />,
          },
          {
            path: ROUTES.ROUTE_NOTIFICATIONS_SETTINGS,
            element: (
              <Grant includes={[RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION]} fallback="/">
                <NotificationsConfigPage />
              </Grant>
            ),
          },
        ],
      },
      {
        path: ROUTES.ROUTE_LOGIN,
        element: (
          <Grant excludes={[RolesEnum.ROLE_USER]} fallback="/">
            <LoginPage />
          </Grant>
        ),
      },
      {
        path: ROUTES.ROUTE_FORGOT_PASSWORD,
        element: (
          <Grant excludes={[RolesEnum.ROLE_USER]} fallback="/">
            <LostPassword />
          </Grant>
        ),
      },
      {
        path: ROUTES.ROUTE_SIGNUP,
        element: (
          <Grant excludes={[RolesEnum.ROLE_USER]} fallback="/">
            <Signup />
          </Grant>
        ),
      },
      {
        path: `${ROUTES.ROUTE_PASSWORD_DEFINE}/:token`,
        element: (
          <Grant excludes={[RolesEnum.ROLE_USER]} fallback="/">
            <DefinePassword />
          </Grant>
        ),
      },
      {
        path: ROUTES.ROUTE_PASSWORD_DEFINE,
        element: (
          <Grant excludes={[RolesEnum.ROLE_USER]} fallback="/">
            <DefinePassword />
          </Grant>
        ),
      },
      // 404 STATUS PAGE
      {
        path: ROUTES.ROUTE_ALL,
        element: (
          <Grant includes={[RolesEnum.ROLE_USER]} fallback="/">
            <DashboardLayout content={<NotFound backUrl="/" />} />
          </Grant>
        ),
      },
    ],
  },
]);
