import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';

const useDeleteAsset = (entity: EntitiesEnum) => {
  const { id } = useParams();
  const { deleteAssetUseCase } = useCore();

  const handleDelete = useCallback(() => {
    if (id) {
      return deleteAssetUseCase.deleteAsset(entity, id);
    }

    return new Promise<false>((resolve) => resolve(false));
  }, [id]);

  return {
    handleDelete,
  };
};

export default useDeleteAsset;
