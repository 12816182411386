import React from 'react';
import Timeline from 'src/web/design_system/Timeline';
import Text from 'src/web/design_system/Text';
import Icon from 'src/web/components/Icon';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import { useCore } from 'src/web/common/core';
import { useTheme } from 'src/web/design_system/Theme';
import Link from 'src/web/components/Link';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';
import { RolesEnum } from 'src/core/common/constants';
import useGrantRole from 'src/web/common/grant/useGrantRole';

interface TimeLineDetailAssetProps {
  isLoading?: boolean;
  data?: {
    id?: string;
    firstname?: string;
    lastname?: string;
    startAt?: string;
    endAt?: string;
  }[];
}

const TimeLineDetailAsset: React.FC<TimeLineDetailAssetProps> = ({ data, isLoading }) => {
  const { timeService } = useCore();
  const { theme } = useTheme();
  const isRoleAdminPerson = useGrantRole(RolesEnum.ROLE_ADMIN_PERSON);
  const contentFilled = (
    firstname: string,
    lastname: string,
    id: string,
    startAt: string,
    endAt?: string | null,
  ) => (
    <Displayer innerSpacing={1}>
      <Link to={isRoleAdminPerson ? `${ROUTE_PERSON}/${id}` : ''}>
        <Text fontWeight={900}>
          {lastname} {firstname}
        </Text>
      </Link>
      <div>
        <Text variant="textSmall" color={theme.colors.secondaryTextColor}>
          {timeService.format(timeService.fromBackend(startAt), 'short')} <Icon.To size="small" />{' '}
          {endAt ? timeService.format(timeService.fromBackend(endAt), 'short') : '...'}
        </Text>
      </div>
    </Displayer>
  );

  const contentEmpty = (
    <Text variant="textSmall" fontWeight={900}>
      Aucune attribution sur cet élément
    </Text>
  );

  const contentLoaded = (
    <>
      <Skeleton isLoading={isLoading} width="100%" height="30px" />
      <br />
      <Skeleton isLoading={isLoading} width="70%" height="20px" />
    </>
  );
  const timelineDatas =
    data !== undefined
      ? data.map((item) => ({
          rightContent: contentFilled(
            item.firstname ?? '',
            item.lastname ?? '',
            item.id ?? '',
            item.startAt ?? '',
            item.endAt,
          ),
        }))
      : [{ rightContent: contentEmpty }];

  return (
    <>
      {isLoading ? (
        <Timeline
          datas={[
            {
              rightContent: contentLoaded,
            },
          ]}
        />
      ) : (
        <Timeline datas={timelineDatas} />
      )}
    </>
  );
};

export default TimeLineDetailAsset;
