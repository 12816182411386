import { FC, ReactNode, useMemo, useState } from 'react';
import Tabs from '.';
import { TabItem } from './tabHeader';
import { Box } from '@mui/material';

interface TabGlobalItem extends TabItem<string> {
  content: ReactNode;
}

interface TabGlobalProps {
  tabs: TabGlobalItem[];
  initialActiveId?: string;
  spaceBetween?: string;
}

const TabGlobal: FC<TabGlobalProps> = ({ tabs, initialActiveId, spaceBetween }) => {
  const [activeId, setActiveId] = useState(initialActiveId ?? tabs[0].id);
  const tabsData = useMemo(() => {
    return tabs.map((tab) => {
      const tabProps = { ...tab };
      delete tabProps.content;
      return tabProps;
    });
  }, [tabs]);

  return (
    <>
      <Box sx={{ marginBottom: spaceBetween ?? 3 }}>
        <Tabs.Header selectedId={activeId} tabs={tabsData} onChange={(id) => setActiveId(id)} />
      </Box>
      {tabs.map((tab) => (
        <Tabs.Content isVisible={activeId === tab.id} key={tab.id}>
          {tab.content}
        </Tabs.Content>
      ))}
    </>
  );
};

export default TabGlobal;
