import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { isUserGranted } from 'core/common/utils/grant';
import TaskItemEntity from 'core/entities/taskItem.entity';

class TasksItemsUseCase implements Usecase {
  constructor(private store: AppStore, private taskItemEntity: TaskItemEntity) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * UPDATE TASK STATUS BY TOGGLING
   */
  public async switchStatus(taskItemId: string, currentStatus: string) {
    if (!['success', 'pending'].includes(currentStatus)) {
      return false;
    }

    return this.taskItemEntity
      .put({
        pathVar: {
          id: taskItemId,
        },
        body: {
          status: currentStatus === 'pending' ? 'success' : 'pending',
        },
      })
      .then((response) => !!response.data);
  }
}

export default TasksItemsUseCase;
