import { FC } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import Card from 'src/web/components/Card';
import Inventory, { type InventoryProps, type BaseRow } from 'src/web/design_system/Inventory';

type AllowedInventoryProps = Pick<
  InventoryProps<BaseRow>,
  'isLoading' | 'rows' | 'columns' | 'size' | 'onClickRow' | 'onSort'
>;

type InventoryCardInventoryProps = AllowedInventoryProps;

const InventoryCardInventory: FC<InventoryCardInventoryProps> = (props) => {
  const { translate } = useTranslate();
  const inventorySize = props.size === 'auto' ? 'auto' : 'compact';

  return (
    <Card.Content disablePaddingX disablePaddingY>
      <Inventory
        {...props}
        noBorder
        size={inventorySize}
        localeText={{
          noRowsLabel: translate('global.inventory.empty.label'),
          noResultsOverlayLabel: translate('global.inventory.empty.label'),
          columnHeaderSortIconLabel: translate('global.inventory.sort.label'),
          columnMenuSortAsc: translate('global.inventory.sort.ascendent.label'),
          columnMenuSortDesc: translate('global.inventory.sort.descendent.label'),
        }}
      />
    </Card.Content>
  );
};

export default InventoryCardInventory;
