import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { useNavigate } from 'react-router-dom';
import { UseSetAssetFormProps } from './commonType';
import { SivinAppData } from 'core/usecases/sivin.usecase';
import { EntitiesEnum } from 'core/common/constants';
import useToaster from 'src/web/design_system/Toaster/useToaster';
import useTranslate from 'src/web/common/translate/useTranslate';

export type UseAddAssetFormProps = UseSetAssetFormProps;

interface ApiResponse {
  status?: number;
  data: any;
}

const useAddAssetForm = ({ asset, baseRoute }: UseAddAssetFormProps) => {
  const { translate } = useTranslate();
  const { setAssetUsercase, timeService } = useCore();
  const { properties, required, initialValues, validate, submit } = useMemo(
    () => setAssetUsercase.getAddForm(asset),
    [asset],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [assetIdentifiactionError, setAssetIdentifiactionError] = useState(false);

  const navigate = useNavigate();
  const toaster = useToaster();

  // FIXME: strange, the response throw custom-assets instead of customAsset
  const createErrorToast = (response: ApiResponse) => {
    toaster.createToast({
      message: `${translate(
        `${asset}.${response.data.violations[0].propertyPath}.label`,
      )} : ${translate(`assets.${response.data.violations[0].message}.label`)}`,
      variant: 'error',
      duration: 3000,
    });
  };

  const form = useCreateForm({
    properties,
    initialValues,
    required,
    validate,
    submit: async (data): Promise<void> => {
      setIsLoading(true);
      try {
        const response = (await submit(data)) as ApiResponse;

        if (response.status === 201) {
          // create asset if success and navigate to asset
          const id = (response.data as { id?: string }).id;
          navigate(`${baseRoute}/${id}`);
        } else if (response.status === 422) {
          //create toast error if error server
          createErrorToast(response);
          setAssetIdentifiactionError(true);
        } else {
          // TODO: Display a general error toaster here
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  // FIXME: As the form refreshing after an onSubmit, this is useless.
  // FIXME: Refactor the loading logic to not refresh the form if the response contain errors
  // to identify the wrong field.
  useEffect(() => {
    if (assetIdentifiactionError && form.items.asset_identification) {
      form.items.asset_identification.errors = {
        keyword: 'required',
        reasons: {
          value: 'The field contains invalid data',
        },
      };
    }
  }, [assetIdentifiactionError]);

  // Sivin autofill, only for vehicle
  const handleUpdateFormFromSivin = (sivinData: SivinAppData) => {
    if (asset === EntitiesEnum.vehicle) {
      if (sivinData.intoCirculationDate && 'intoCirculationDate' in form.items) {
        form.imperativeChangeField(
          'intoCirculationDate',
          timeService.toBackend(sivinData.intoCirculationDate),
        );
      }
      if (sivinData.manufacturer && 'asset_manufacturer' in form.items) {
        form.imperativeChangeField('asset_manufacturer', sivinData.manufacturer);
      }
      if (sivinData.model && 'asset_model' in form.items) {
        form.imperativeChangeField('asset_model', sivinData.model);
      }
      if (sivinData.weightLimit && 'weightLimit' in form.items) {
        form.imperativeChangeField('weightLimit', sivinData.weightLimit);
      }
      if (sivinData.category && 'category' in form.items) {
        form.imperativeChangeField('category', sivinData.category);
      }
      if (sivinData.fuelType && 'fuelType' in form.items) {
        form.imperativeChangeField('fuelType', sivinData.fuelType);
      }
      if (sivinData.horsePower && 'horsePower' in form.items) {
        form.imperativeChangeField('horsePower', sivinData.horsePower);
      }
      if (sivinData.co2Emission && 'co2Emission' in form.items) {
        form.imperativeChangeField('co2Emission', sivinData.co2Emission);
      }
    }
  };

  return {
    form,
    isLoading,
    requestSivinData: handleUpdateFormFromSivin,
  };
};

export default useAddAssetForm;
