import { useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

interface UseEditSiteProps {
  siteId: string;
  afterSubmit: (status: boolean) => void;
}

const useEditSite = ({ siteId, afterSubmit }: UseEditSiteProps) => {
  const { sitesUseCase } = useCore();
  const { properties, initialValues, required, validate, submit } =
    sitesUseCase.getEditSiteForm(siteId);
  const [isLoading, setIsLoading] = useState(true);

  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);

      if (afterSubmit) {
        afterSubmit(response);
      }
      setIsLoading(false);
    },
  });

  const handleGetInitialValues = async () => {
    setIsLoading(true);
    Promise.resolve(initialValues).then((values) => {
      for (const key in values) {
        form.imperativeChangeField(key, values[key]);
      }

      setIsLoading(false);
    });
  };

  return {
    form,
    isLoading,
    init: handleGetInitialValues,
  };
};

export default useEditSite;
