import { styled } from '@mui/material/styles';
import { useTheme } from 'src/web/common/theme';
import MenuItem from '../../design_system/Menu/MenuItem';

export const selectedButtonStyle = (color: string) => ({
  backgroundColor: `${color}10`,
  color: `${color}`,
  '&:hover': {
    backgroundColor: `${color}20`,
  },
});

const MenuItemSide = styled(MenuItem)(({ isSelected }) => {
  const { theme } = useTheme();
  return {
    '&.MuiListItemButton-root': {
      borderRadius: theme.shape.defaultRadius,
      marginBottom: '2px',
      ...(!isSelected ? {} : selectedButtonStyle(theme.colors.brand)),
    },
  };
});

export default MenuItemSide;
