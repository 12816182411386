import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

interface AlertProps {
  icon?: ReactNode;
  extra?: ReactNode;
  title?: string;
  mode?: 'error' | 'warning' | 'info' | 'success';
  variant?: 'outlined' | 'filled' | 'light';
}

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  children,
  title,
  mode = 'info',
  icon = false,
  extra,
  variant = 'light',
}) => {
  const muiVariant = useMemo(() => {
    switch (variant) {
      case 'outlined':
        return 'outlined';
      case 'light':
        return 'standard';
      case 'filled':
      default:
        return 'filled';
    }
  }, [variant]);

  return (
    <MuiAlert severity={mode} icon={icon} action={extra} variant={muiVariant}>
      {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
      {children}
    </MuiAlert>
  );
};

export default Alert;
