import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface TelephoneInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const TelephoneInfoFormPartial: FC<TelephoneInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.RadioAdapted
          item={form.items.formFactor}
          label={translate('telephones.formFactor.label')}
          renderOptionLabel={(value) => translate(`telephones.formFactor.option.${value}.label`)}
        />

        <Form.TextAdapted
          item={form.items.asset_manufacturer}
          label={translate('assets.manufacturer.label')}
        />

        <Form.TextAdapted
          item={form.items.asset_model}
          label={translate('assets.asset.model.label')}
        />

        <Form.TextAdapted
          item={form.items.asset_identification}
          label={translate('telephone.identification.label')}
        />

        <Form.TextAdapted item={form.items.imei} label={translate('telephones.imei.label')} />
        <Form.SelectAdapted
          item={form.items.os}
          label={translate('telephones.os.label')}
          renderOptionItem={(value) => ({
            label: translate(`telephones.os.option.${value}.label`),
          })}
        />
      </Displayer>
    </Card.Basic>
  );
};

export default TelephoneInfoFormPartial;
