import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface ComputerInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const ComputerInfoFormPartial: FC<ComputerInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.SelectAdapted
          item={form.items.formFactor}
          label={translate('computers.formFactor.label')}
          renderOptionItem={(value) => ({
            label: translate(`computers.formFactor.option.${value}.label`),
          })}
        />

        <Form.TextAdapted
          item={form.items.asset_manufacturer}
          label={translate('assets.manufacturer.label')}
        />

        <Form.TextAdapted
          item={form.items.asset_model}
          label={translate('assets.asset.model.label')}
        />

        <Form.TextAdapted
          item={form.items.asset_identification}
          label={translate('computers.asset.identification.label')}
        />

        <Form.SelectAdapted
          item={form.items.os}
          label={translate('computers.os.label')}
          renderOptionItem={(value) => ({
            label: translate(`computers.os.option.${value}.label`),
          })}
        />
      </Displayer>
    </Card.Basic>
  );
};

export default ComputerInfoFormPartial;
