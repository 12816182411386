import { type FC } from 'react';
import PageContainerPartial from './partials/pageContainerPartial';
import useEditPersonForm from './hooks/useEditPersonForm';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import Grid from 'src/web/design_system/Grid';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import { isString } from 'core/common/utils/predicatesType';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import useFormComponent from 'src/web/components/FormInput/useFormComponent';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';
import { useParams, useSearchParams } from 'react-router-dom';

const EditPersonPage: FC = () => {
  const { timeService } = useCore();
  const { form, isLoading, sites, optionsServices, handleSearch } = useEditPersonForm();
  const { translate } = useTranslate();
  const { formatFormMessage } = useFormComponent();

  const handleJobTitleChange = (value?: string | string[]) => {
    if (Array.isArray(value)) {
      form.items.jobTitle.handleChange(value[0]);
    } else {
      form.items.jobTitle.handleChange(value);
    }
  };

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { id } = useParams();

  return (
    <PageContainerPartial
      isLoading={isLoading}
      pageTitle={translate('persons.edit.title')}
      submitLabel={translate('persons.edit.submit')}
      canSubmit={form.isDirty && !form.hasErrors}
      onSubmit={(e) => form.handleSubmit(e)}
      backNavigate={`${ROUTE_PERSON}/${id}?${queryString}`}
    >
      <Card.Basic title={translate('persons.informations.title')}>
        <Displayer innerSpacing={2}>
          <Form.RadioAdapted
            item={form.items.gender}
            label={translate('persons.gender.label')}
            renderOptionLabel={(value) => {
              if (value === null) {
                return translate('global.notSpecified.label');
              }
              return translate(`persons.gender.option.${value}.label`);
            }}
          />

          <Grid.Container>
            <Grid.Item>
              <Form.TextAdapted
                item={form.items.lastname}
                label={translate('persons.lastname.label')}
              />
            </Grid.Item>
            <Grid.Item>
              <Form.TextAdapted
                item={form.items.firstname}
                label={translate('persons.firstname.label')}
              />
            </Grid.Item>
          </Grid.Container>

          <Grid.Container>
            <Grid.Item size={6}>
              <Form.DateAdapted
                item={form.items.birthdate}
                label={translate('persons.birthdate.label')}
                maxDate={timeService.now()}
              />
            </Grid.Item>
          </Grid.Container>
        </Displayer>
      </Card.Basic>

      <Card.Basic title={translate('persons.humanResources.title')}>
        <Displayer innerSpacing={2}>
          <Form.RadioAdapted
            item={form.items.status}
            label={translate('persons.status.label')}
            renderOptionLabel={(value) => {
              if (value === null) {
                return translate('global.notSpecified.label');
              }
              return translate(`persons.status.option.${value}.label`);
            }}
          />
          <Grid.Container>
            <Grid.Item>
              <Form.DateAdapted
                item={form.items.entryAt}
                label={translate('persons.entryAt.label')}
              />
            </Grid.Item>
            <Grid.Item>
              <Form.DateAdapted
                item={form.items.exitAt}
                label={translate('persons.exitAt.label')}
                minDate={
                  form.items.entryAt
                    ? timeService.manipulate(timeService.fromBackend(String(form.items.entryAt)), {
                        day: 1,
                      })
                    : undefined
                }
              />
            </Grid.Item>
          </Grid.Container>
          <Form.TextAdapted
            item={form.items.identification}
            label={translate('persons.identification.label')}
          />
          <Autocomplete
            label={translate('persons.jobTitle.label')}
            noOptionsText={translate('persons.jobTitle.autocomplete.noOptions')}
            isRequired
            options={
              optionsServices
                ? optionsServices?.map((options) => ({
                    label: options?.jobTitle ?? '',
                    value: options?.jobTitle ?? '',
                  }))
                : []
            }
            onSearch={(value) => {
              form.items.jobTitle.handleChange(value);
              handleSearch(value);
            }}
            selectValue={(item) => item.value}
            onChange={(value) => {
              handleJobTitleChange(value);
            }}
            value={
              isString(form?.items?.jobTitle?.formValue)
                ? {
                    label: form?.items?.jobTitle?.formValue,
                    value: form?.items?.jobTitle?.formValue,
                  }
                : undefined
            }
            isError={!!form.items.jobTitle.errors}
            errorMessage={formatFormMessage(form.items.jobTitle)}
          />
          <Form.TextAdapted
            item={form.items.email}
            label={translate('persons.email.label')}
            type="email"
          />
          <Form.Select
            renderOptionItem={(value) => ({
              label: sites.find((opt) => opt['@id'] === value)?.name,
            })}
            renderValue={(value) => sites.find((opt) => opt['@id'] === value)?.name}
            options={[undefined, ...sites.map((site) => site['@id'])]}
            value={
              isString(form.items.attachmentSite.formValue)
                ? form.items.attachmentSite.formValue
                : undefined
            }
            onChange={(value) => {
              form.items.attachmentSite.handleChange(value);
            }}
            label={translate('persons.site.label')}
          />
          <Form.TextAreaAdapted
            label={translate('assets.asset.comment.label')}
            item={form.items.comment}
          />
        </Displayer>
      </Card.Basic>

      <Card.Basic title={translate('persons.drivingLicence.title')}>
        <Displayer innerSpacing={2}>
          <Form.TextAdapted
            item={form.items.drivingLicenceRef}
            label={translate('persons.drivingLicenceRef.label')}
          />
          <Grid.Container>
            <Grid.Item>
              <Form.DateAdapted
                item={form.items.drivingLicenceDeliveryDate}
                label={translate('persons.drivingLicenceDeliveryDate.label')}
              />
            </Grid.Item>
            <Grid.Item>
              <Form.DateAdapted
                item={form.items.drivingLicenceExpiryDate}
                label={translate('persons.drivingLicenceExpiryDate.label')}
                minDate={
                  form.items.entryAt
                    ? timeService.manipulate(
                        timeService.fromBackend(String(form.items.drivingLicenceDeliveryDate)),
                        { day: 1 },
                      )
                    : undefined
                }
              />
            </Grid.Item>
          </Grid.Container>
        </Displayer>
      </Card.Basic>
    </PageContainerPartial>
  );
};

export default EditPersonPage;
