import Button from 'src/web/components/Button';
import Text from 'src/web/design_system/Text';
import Grid from 'src/web/design_system/Grid';
import { timeLineContainerStyle } from './style';
import TimeLineDetailAsset from './TimelineDetailAsset';
import Skeleton from 'src/web/design_system/Skeleton';
import { useEffect, useMemo, useState } from 'react';
import { useGetAssignementDatas } from '../hooks/useGetAssignementDatas';
import Displayer from 'src/web/design_system/Displayer';
import Card from 'src/web/components/Card';
import { EntitiesEnum } from 'core/common/constants';
import Icon from 'src/web/components/Icon';
import AssetAssignmentEditModal from 'src/web/components/AssetAssignmentEditModal';
import Avatar from 'src/web/design_system/Avatar';
import AssetAssignmentPostModal from 'src/web/components/AssetAssignmentPostForm';
import useTranslate from 'src/web/common/translate/useTranslate';
import { getIdFromIri } from 'core/common/utils/id';
import { useTheme } from 'src/web/design_system/Theme';
import Tooltip from 'src/web/design_system/Tooltip';

interface CardAssignationProps {
  assetId: string;
  assetIri: string;
  entity: EntitiesEnum;
  assetStatus: string;
}

const CardAssignation: React.FC<CardAssignationProps> = ({
  assetId,
  assetIri,
  entity,
  assetStatus,
}) => {
  const { translate } = useTranslate();
  const { theme } = useTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { dataCurrentAssignment, dataAssignement, isLoading, reload } =
    useGetAssignementDatas(assetId);

  const lastAssignment = useMemo(
    () => ({
      iri: dataAssignement?.[0]?.['@id'],
      startAt: dataAssignement?.[0]?.startAt,
      endAt: dataAssignement?.[0]?.endAt,
    }),
    [dataAssignement],
  );
  const isAssetDisabled = assetStatus === 'disabled';

  const [isRestituteAsset, setIsResituteAsset] = useState(false);

  useEffect(() => {
    if (dataAssignement && dataAssignement.length > 0) {
      setIsResituteAsset(!lastAssignment.endAt);
    } else {
      setIsResituteAsset(false);
    }
  }, [lastAssignment.endAt, dataAssignement]);

  const handleSubmit = () => {
    reload();
  };

  return (
    <>
      <Card.Container>
        <Card.Toolbar
          secondaryContent={
            isLoading ? (
              <Skeleton isLoading={isLoading} width="120px" height="40px" shape="round" />
            ) : isRestituteAsset ? (
              <Button.Custom
                onClick={() => setOpenModal(true)}
                fullWidth={false}
                startIcon={<Icon.Remove size="small" />}
              >
                {translate('assets.assignment.resistute')}
              </Button.Custom>
            ) : (
              <Tooltip
                title={isAssetDisabled ? translate('assets.assignment.disabledStatus.tooltip') : ''}
              >
                <Button.Custom
                  startIcon={<Icon.Add size="small" />}
                  onClick={() => setOpenModal(true)}
                  fullWidth={false}
                  disabled={isAssetDisabled}
                >
                  {translate('assets.assignment.attribute')}
                </Button.Custom>
              </Tooltip>
            )
          }
        >
          <Text variant="sectionTitle">{translate('assets.assignment.title')}</Text>
        </Card.Toolbar>
        {dataAssignement && dataAssignement.length > 0 ? (
          <Card.Content disablePaddingY>
            <Grid.Container>
              <Grid.Item size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <Displayer
                  justifyContent={'center'}
                  alignItems={'center'}
                  innerSpacing={2}
                  fullHeight
                >
                  {dataCurrentAssignment ? (
                    <Displayer
                      layout="column"
                      innerSpacing={2}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Skeleton shape="circle" isLoading={isLoading}>
                        <Avatar size={80} />
                      </Skeleton>
                      <Displayer layout={'column'} alignItems={'center'}>
                        <Skeleton width={'150px'} height="50px" isLoading={isLoading}>
                          <Text variant={'textSmall'}>
                            {translate('assets.assignment.attributeTo')} :
                          </Text>

                          <Text variant={'textMedium'} align="center">
                            {dataCurrentAssignment
                              ? `${dataCurrentAssignment[0]?.person?.lastname} ${dataCurrentAssignment[0]?.person?.firstname}`
                              : ''}
                          </Text>
                        </Skeleton>
                      </Displayer>
                    </Displayer>
                  ) : (
                    <Text align="center">{translate('assets.assignment.noData')}</Text>
                  )}
                </Displayer>
              </Grid.Item>
              <Grid.Item size={'flex'}>
                <Text variant="textAction" color={theme.colors.secondaryTextColor}>
                  <Skeleton isLoading={isLoading} shape="text">
                    {translate('assets.assignment.history')}
                  </Skeleton>
                </Text>
                <div className={timeLineContainerStyle}>
                  <TimeLineDetailAsset
                    data={dataAssignement?.map((item) => ({
                      id: item.person && item.person['@id'] ? getIdFromIri(item.person['@id']) : '',
                      firstname: item?.person?.firstname,
                      lastname: item?.person?.lastname,
                      startAt: item.startAt,
                      endAt: item.endAt ?? undefined,
                    }))}
                    isLoading={isLoading}
                  />
                </div>
              </Grid.Item>
            </Grid.Container>
          </Card.Content>
        ) : (
          <Card.Content>
            <Text align="center">{translate('assets.assignment.noData')}</Text>
          </Card.Content>
        )}
      </Card.Container>

      {isRestituteAsset ? (
        <AssetAssignmentEditModal
          startAt={lastAssignment.startAt ?? ''}
          entity={entity}
          assetIri={lastAssignment.iri ?? ''}
          onEdit={handleSubmit}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title={translate(`${entity}.assignment.edit.title`)}
          personIri={dataAssignement ? getIdFromIri(dataAssignement[0].person?.['@id'] ?? '') : ''}
        />
      ) : (
        <></>
      )}

      {!isRestituteAsset ? (
        <AssetAssignmentPostModal
          endDateLastAssignement={lastAssignment.endAt ?? undefined}
          title={translate(`${entity}.assignment.post.title`)}
          entity={entity}
          assetIri={assetIri}
          onSubmit={handleSubmit}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default CardAssignation;
