import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { RequestOptions } from 'core/usecases/inventory.usecase';
import { useCore } from 'src/web/common/core';

export interface FiltersData {
  id: string;
  key: string;
  value: string | string[];
  options: string[];
  onChange: (value: string[]) => void;
}

export interface SortersData {
  id: string;
  key: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
}

export type InventoryFilter = Record<string, FiltersData>;
export type InventorySorter = Record<string, SortersData>;

function useCreateInventory<E extends EntitiesEnum>(entity: E) {
  const { inventoryUsecase } = useCore();
  const canLoadData = inventoryUsecase.isGranted(entity);
  const inventoryHandler = inventoryUsecase.getInventoryRequestByName(entity);
  const [currentResponse, setCurrentResponse] =
    useState<Awaited<ReturnType<typeof inventoryHandler>>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});
  const [selectedSorters, setSelectedSorters] = useState<Record<string, string>>({});
  const [currentSearch, setCurrentSearch] = useState<string | undefined>(undefined);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filtersWasCleared, setFiltersWasCleared] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const [options, setOptions] = useState<RequestOptions>({});

  const handleOptions = (initialOptions: RequestOptions) => {
    setOptions(initialOptions);
  };

  // format filters
  const filters = useMemo((): InventoryFilter => {
    if (!currentResponse) return {};

    return Object.fromEntries(
      Object.keys(currentResponse.filters).map((key) => {
        const formatedFilter: FiltersData = {
          id: nanoid(),
          key: key,
          value: selectedFilters[key],
          options: currentResponse.filters[key],
          onChange: (value) => {
            setSelectedFilters((current) => {
              const copy = { ...current };

              delete copy[key];

              if (value.length > 0) {
                copy[key] = value;
              }

              return copy;
            });
          },
        };
        return [key, formatedFilter];
      }),
    );
  }, [currentResponse, selectedFilters]);

  // format sorters
  const sorters = useMemo((): InventorySorter => {
    if (!currentResponse) return {};

    return Object.fromEntries(
      Object.keys(currentResponse.sorters).map((key) => {
        const formatedSorter: SortersData = {
          id: nanoid(),
          key: key,
          value: '',
          options: currentResponse.sorters[key],
          onChange: (value) => {
            setSelectedSorters({ [key]: value });
          },
        };
        return [key, formatedSorter];
      }),
    );
  }, [currentResponse]);

  // run the reload
  const handleClearFilter = useCallback(
    (name: string) => {
      if (filters && name in filters) {
        filters[name].onChange([]);
        setFiltersWasCleared(true);
      }
    },
    [filters],
  );

  // handle load data
  const handleLoadData = useCallback(
    (customOptions?: RequestOptions) => {
      setIsInit(true);
      setIsLoading(true);

      const currentOptions = customOptions ?? options;

      setOptions(currentOptions);

      const requestOptions: RequestOptions = {
        ...currentOptions,
        filter: selectedFilters,
        sorter: selectedSorters,
        page: currentPage?.toString(),
        itemsPerPage: itemPerPage?.toString(),
        search: currentSearch,
      };

      if (inventoryHandler) {
        return inventoryHandler(requestOptions)
          .then((response) => {
            if (response) {
              setCurrentResponse(response);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [options, currentPage, selectedFilters, itemPerPage, selectedSorters, currentSearch],
  );

  // Load data filtered by search
  const handleSearch = useCallback((value?: string): void => {
    setCurrentSearch(value && value.length > 0 ? value : undefined);
  }, []);

  // handle Item display per page
  const handleItemPerPage = (nextItemPerPage: number) => {
    const nextCurrentPage = Math.max(1, Math.ceil((itemPerPage * currentPage) / nextItemPerPage));

    setItemPerPage(nextItemPerPage);
    setCurrentPage(nextCurrentPage);
  };

  // handle data loading
  useEffect(() => {
    if (isInit) {
      handleLoadData();
    }
  }, [selectedSorters, currentPage, itemPerPage, currentSearch]);

  useEffect(() => {
    if (filtersWasCleared && isInit) {
      handleLoadData();
      setFiltersWasCleared(false);
    }
  }, [selectedFilters]);

  return {
    rows: currentResponse?.data,
    filters: filters,
    selectedFilters: selectedFilters,
    sorters: sorters,
    selectedSorters: selectedSorters,
    handleSearch,
    currentSearch,
    handleLoadData,
    handleClearFilter,
    isLoading,
    canLoadData,
    setItemPerPage: handleItemPerPage,
    setCurrentPage,
    totalItems: currentResponse?.count ?? 0,
    // Is it really necessary to provide these two values below?
    // It could be only useful to know the initial state
    // but maybe initial values could be optionally provided from the hook props
    itemPerPage,
    currentPage,
    handleInitialOptions: handleOptions,
  };
}

export default useCreateInventory;
