import { GuiPalette } from '.';

export const GUI_PALETTE_NEUTRAL: GuiPalette = {
  '50': '#f2f3f5',
  '100': '#dee2e6',
  '200': '#c8cfd5',
  '300': '#b2bbc4',
  '400': '#a2adb8',
  '500': '#919eab',
  '600': '#8996a4',
  '700': '#7e8c9a',
  '800': '#748291',
  '900': '#627080',
  A100: '#f3f9ff',
  A200: '#c1defe',
  A400: '#8dc2ff',
  A700: '#74b5ff',
  contrast: '#000000',
};

export const GUI_PALETTE_PRIMARY: GuiPalette = {
  '50': '#e4edf9',
  '100': '#bcd1f1',
  '200': '#90b2e8',
  '300': '#6393df',
  '400': '#417cd8',
  '500': '#2065d1',
  '600': '#1c5dcc',
  '700': '#1853c6',
  '800': '#1349c0',
  '900': '#0b37b5',
  A100: '#e1e8ff',
  A200: '#aec0ff',
  A400: '#7b98ff',
  A700: '#6284ff',
  contrast: '#ffffff',
};

export const GUI_PALETTE_SECONDARY: GuiPalette = {
  '50': '#e7edff',
  '100': '#c2d1ff',
  '200': '#99b3ff',
  '300': '#7094ff',
  '400': '#527dff',
  '500': '#3366ff',
  '600': '#2e5eff',
  '700': '#2753ff',
  '800': '#2049ff',
  '900': '#1438ff',
  A100: '#ffffff',
  A200: '#fbfbff',
  A400: '#c8ceff',
  A700: '#aeb8ff',
  contrast: '#ffffff',
};

export const GUI_PALETTE_INFO: GuiPalette = {
  '50': '#e3f2ff',
  '100': '#badeff',
  '200': '#8cc8ff',
  '300': '#5db1ff',
  '400': '#3ba1ff',
  '500': '#1890ff',
  '600': '#1588ff',
  '700': '#117dff',
  '800': '#0e73ff',
  '900': '#0861ff',
  A100: '#ffffff',
  A200: '#f4f7ff',
  A400: '#c1d4ff',
  A700: '#a7c3ff',
  contrast: '#ffffff',
};

export const GUI_PALETTE_SUCCESS: GuiPalette = {
  '50': '#eafae6',
  '100': '#ccf3c0',
  '200': '#aaeb96',
  '300': '#87e26b',
  '400': '#6edc4c',
  '500': '#54d62c',
  '600': '#4dd127',
  '700': '#22c55e',
  '800': '#3ac61b',
  '900': '#118d57',
  A100: '#edffeb',
  A200: '#c0ffb8',
  A400: '#93ff85',
  A700: '#7cff6b',
  contrast: '#000000',
};

export const GUI_PALETTE_WARNING: GuiPalette = {
  '50': '#fff8e1',
  '100': '#ffecb5',
  '200': '#ffe083',
  '300': '#ffd451',
  '400': '#ffca2c',
  '500': '#ffc107',
  '600': '#ffbb06',
  '700': '#ffab00',
  '800': '#ffab04',
  '900': ' #b76e00',
  A100: '#ffffff',
  A200: '#fffaf2',
  A400: '#ffe4bf',
  A700: '#ffd9a6',
  contrast: '#000000',
};

export const GUI_PALETTE_ERROR: GuiPalette = {
  '50': '#ffe9e8',
  '100': '#ffc8c6',
  '200': '#ffa4a1',
  '300': '#ff7f7b',
  '400': '#ff635e',
  '500': '#ff4842',
  '600': '#ff413c',
  '700': '#ff5630',
  '800': '#ff302b',
  '900': '#b71d18',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffcece',
  A700: '#ffb5b4',
  contrast: '#ffffff',
};
export const GUI_PALETTE_DANGER: GuiPalette = {
  '50': '#FFFFF0',
  '100': '#FEFCBF',
  '200': '#FAF089',
  '300': '#F6E05E',
  '400': '#ECC94B',
  '500': '#D69E2E',
  '600': '#B7791F',
  '700': '#975A16',
  '800': '#744210',
  '900': '#5F370E',
  A100: '#ffffff',
  A200: '#FDF8DD',
  A400: '#FFF1B5',
  A700: '#FFE06E',
  contrast: '#ffffff',
};
