import { useEffect, useState } from 'react';
import { useTheme } from 'src/web/design_system/Theme';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const useBreakpoint = (comparator: 'up' | 'down', breakpoint: Breakpoints): boolean => {
  const { theme } = useTheme();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const compare = (win: number, bp: number): boolean => {
    return comparator === 'down' ? win <= bp : win > bp;
  };

  if (breakpoint in theme.breakpoints) {
    return compare(windowSize, theme.breakpoints[breakpoint]);
  }

  return true;
};

export default useBreakpoint;
