import { Drawer as MuiDrawer } from '@mui/material';
import { type PropsWithChildren, type FC } from 'react';

interface DrawerProps {
  className?: string;
  open?: boolean;
  mode?: 'fixed' | 'toggle';
  position?: 'left' | 'right' | 'bottom' | 'top';
  onClose?: () => void;
  disableBackdrop?: boolean;
  drawerOpenWidth?: number;
  drawerCloseWidth?: number;
}

const DrawerDefault: FC<PropsWithChildren<DrawerProps>> = ({
  className,
  children,
  mode = 'toggle',
  position = 'left',
  open,
  onClose,
  disableBackdrop = false,
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <MuiDrawer
      className={className}
      open={open}
      variant={mode === 'toggle' ? 'temporary' : 'permanent'}
      anchor={position}
      onClose={handleClose}
      hideBackdrop={disableBackdrop}
    >
      {children}
    </MuiDrawer>
  );
};

export default DrawerDefault;
