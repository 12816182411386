import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { UseSetPersonFormProps } from './commonType';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';

export type UseAddAssetFormProps = UseSetPersonFormProps;
type PersonState = {
  id?: string;
  lastName?: string;
  firstName?: string;
};

const useAddPersonForm = () => {
  const { setPersonUsecase, sitesUseCase, personUsercase, timeService } = useCore();
  const navigate = useNavigate();

  const [sites, setSites] = useState<Awaited<ReturnType<typeof sitesUseCase.getAllSites>>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsServices, setOptionsServices] =
    useState<Awaited<ReturnType<typeof personUsercase.getServices>>>(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [person, setPerson] = useState<PersonState>({
    id: undefined,
    lastName: undefined,
    firstName: undefined,
  });

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const { properties, getInitialValues, required, validate, submit } = useMemo(
    () => setPersonUsecase.getAddForm(),
    [],
  );

  const loadServices = async () => {
    if (searchValue.length > 1) {
      const response = await personUsercase.getServices(searchValue);
      if (response) {
        setOptionsServices(response);
      }
    } else {
      setOptionsServices(undefined);
    }
  };

  useEffect(() => {
    loadServices();
  }, [searchValue]);

  const loadSites = async () => {
    setIsLoading(true);
    const response = await sitesUseCase.getAllSites();
    if (response) {
      setSites(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadSites();
  }, []);

  const form = useCreateForm({
    properties,
    initialValues: getInitialValues(),
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);

      const isEntryFutur =
        form.items.entryAt.formValue &&
        timeService.toBackend(timeService.yesterday()) < form.items.entryAt.formValue;

      const isFuturCollaborator = form.items.status.formValue === 'onBoardingEmployee';

      const canOpenModal = isFuturCollaborator && isEntryFutur;

      if (response) {
        if (canOpenModal) {
          setModalOpen(true);
        } else {
          navigate(`${ROUTE_PERSON}/${response.id}`);
        }
        setPerson({ id: response.id, lastName: response.lastname, firstName: response.firstname });
      }
      setIsLoading(false);
    },
  });

  return {
    form,
    sites,
    isLoading,
    optionsServices,
    handleSearch,
    setModalOpen,
    modalOpen,
    person,
  };
};

export default useAddPersonForm;
