import { FC, ReactNode, useMemo } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import InputRadio, { type InputRadioProps } from './InputRadio';
import useFormComponent from './useFormComponent';
import { ExhaustiveValue } from 'core/swagger';
import { isBoolean, isNumber, isString } from 'core/common/utils/predicatesType';

type FormRadioExcludedProps = keyof Pick<
  InputRadioProps,
  'value' | 'isError' | 'onChange' | 'options' | 'message'
>;

interface FormRadioProps extends Omit<InputRadioProps, FormRadioExcludedProps> {
  item: FormItem;
  renderOptionLabel?: (value: ExhaustiveValue) => ReactNode;
}

const FormRadio: FC<FormRadioProps> = ({ item, renderOptionLabel, ...props }) => {
  if (!item) {
    console.warn('can not render input', props);
    return <></>;
  }

  const { formatFormMessage } = useFormComponent();

  const enumList = item.enum ?? [];

  // format value to provide allowed type
  const value = item.formValue && item.formValue !== null ? String(item.formValue) : '';

  const sanitizeValue = (val: ExhaustiveValue) => {
    if (isString(val) || isNumber(val) || isBoolean(val)) {
      return val;
    }
    return '';
  };

  // format options to provide allowed type
  const options = useMemo(() => {
    return enumList.map((enumItem) => ({
      value: sanitizeValue(enumItem),
      label: renderOptionLabel ? renderOptionLabel(enumItem) : <>{enumItem}</>,
    }));
  }, [item]);

  return (
    <InputRadio
      value={value}
      options={options}
      isRequired={item.required}
      isError={!!item.errors}
      onChange={(val) => item.handleChange(val)}
      message={formatFormMessage(item)}
      {...props}
    />
  );
};

export default FormRadio;
