import { FC, useState } from 'react';
import Icon from 'src/web/components/Icon';
import Button from 'src/web/design_system/Button';
import { useCore } from 'src/web/common/core';
import { SivinAppData } from 'core/usecases/sivin.usecase';
import Translate from 'src/web/common/translate/translate.component';

interface ButtonAntaiProps {
  assetIdentification?: string;
  onDataReceived: (data: SivinAppData) => void;
}

const ButtonAntai: FC<ButtonAntaiProps> = ({ assetIdentification, onDataReceived }) => {
  const { sivinUseCase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const { requestData } = sivinUseCase.getSivinData();
  const isDisable = assetIdentification === undefined;

  const handleRunAntai = async () => {
    if (assetIdentification) {
      setIsLoading(true);

      const data = await requestData(assetIdentification);

      onDataReceived(data);
      setIsLoading(false);
    }
  };

  return (
    <Button.Default
      onClick={() => handleRunAntai()}
      isLoading={isLoading}
      disabled={isDisable}
      variant="outlined"
      endIcon={<Icon.Autofill />}
      color="inherit"
    >
      <Translate id="global.sivin.cta" />
    </Button.Default>
  );
};

export default ButtonAntai;
