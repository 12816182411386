import FocusLayout from 'src/web/layouts/FocusLayout';
import FleezyIcon from '../FleezyIcon';
import useTranslate from 'src/web/common/translate/useTranslate';
import Text from 'src/web/design_system/Text';

// interface ErrorCatchedProps {
//   errorMessage?: string;
// }

// TODO: { errorMessage }: ErrorCatchedProps
const RootErrorCatched = (): JSX.Element => {
  const { translate } = useTranslate();

  return (
    <FocusLayout>
      <FleezyIcon width={'2xl'} />
      <Text variant="pageTitle">{translate('global.errorcatched.title')}</Text>
      <p>{translate('global.errorcatched.description')}</p>
    </FocusLayout>
  );
};

export default RootErrorCatched;
