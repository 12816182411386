import * as ROUTES from 'src/web/common/router/constantes';
import Icon from 'src/web/components/Icon';
import { DEFAULT_CUSTOM_ASSET_NAME, RolesEnum } from 'src/core/common/constants';

export type MenuItemConfig = {
  labelKey: string;
  icon: JSX.Element;
  route: string;
  roles?: RolesEnum[];
  custom?: boolean;
};

export const dashboardMenuItemsConfig: MenuItemConfig[] = [
  {
    labelKey: 'global.nav.dashboard.button',
    icon: <Icon.Dashboard />,
    route: ROUTES.ROUTE_DASHBOARD,
  },
  {
    labelKey: 'global.nav.persons.button',
    icon: <Icon.Person />,
    route: ROUTES.ROUTE_PERSON,
    roles: [RolesEnum.ROLE_ADMIN_PERSON],
  },
  // Add any additional menu items here...
];
export const assetMenuItemsConfig: MenuItemConfig[] = [
  {
    labelKey: 'global.nav.vehicles.button',
    icon: <Icon.Vehicle />,
    route: ROUTES.ROUTE_VEHICULE,
    roles: [RolesEnum.ROLE_ADMIN_VEHICLE],
  },
  {
    labelKey: 'global.nav.computer.button',
    icon: <Icon.Computer />,
    route: ROUTES.ROUTE_COMPUTER,
    roles: [RolesEnum.ROLE_ADMIN_COMPUTER],
  },
  {
    labelKey: 'global.nav.licence.button',
    icon: <Icon.Licence />,
    route: ROUTES.ROUTE_LICENCE,
    roles: [RolesEnum.ROLE_ADMIN_LICENCE],
  },
  {
    labelKey: 'global.nav.service.button',
    icon: <Icon.Service />,
    route: ROUTES.ROUTE_SERVICE,
    roles: [RolesEnum.ROLE_ADMIN_SERVICE],
  },
  {
    labelKey: 'global.nav.telephone.button',
    icon: <Icon.Telephone />,
    route: ROUTES.ROUTE_TELEPHONE,
    roles: [RolesEnum.ROLE_ADMIN_TELEPHONE],
  },
  {
    labelKey: 'global.nav.telephoneLine.button',
    icon: <Icon.TelephoneLine />,
    route: ROUTES.ROUTE_TELEPHONE_LINE,
    roles: [RolesEnum.ROLE_ADMIN_TELEPHONE_LINE],
  },
  {
    labelKey: 'global.nav.card.button',
    icon: <Icon.Card />,
    route: ROUTES.ROUTE_CARD,
    roles: [RolesEnum.ROLE_ADMIN_CARD],
  },
  {
    labelKey: DEFAULT_CUSTOM_ASSET_NAME,
    icon: <Icon.Autofill />,
    route: ROUTES.ROUTE_CUSTOM_ASSET,
    roles: [RolesEnum.ROLE_ADMIN_CUSTOM_ASSET],
    custom: true,
  },
  // Add any additional menu items here...
];
