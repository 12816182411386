import { Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';

export interface TabItem<Id extends string | number> {
  id: Id;
  label: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
}

interface TabHeaderProps<Id extends string | number> {
  selectedId: Id;
  onChange: (id: Id) => void;
  tabs: TabItem<Id>[];
  orientation?: 'vertical' | 'horizontal';
}

function TabHeader<Id extends string | number>({
  selectedId,
  tabs,
  orientation = 'horizontal',
  onChange,
}: TabHeaderProps<Id>): JSX.Element {
  return (
    <Tabs value={selectedId} onChange={(_e, v) => onChange(v)} orientation={orientation}>
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          value={tab.id}
          label={tab.label}
          disabled={tab.disabled}
          icon={tab.icon ? <>{tab.icon}</> : undefined}
          iconPosition={tab.iconPosition}
        />
      ))}
    </Tabs>
  );
}

export default TabHeader;
