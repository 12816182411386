import { FC, useRef } from 'react';
import Form from 'src/web/components/FormInput';
import Icon from 'src/web/components/Icon';
import Button from 'src/web/design_system/Button';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import usePutMileageVehicle from 'src/web/pages/assets/detail/hooks/vehicle/usePutMileageVehicle';
import { inputFormEditMileage } from './style';
import Skeleton from 'src/web/design_system/Skeleton';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface FormEditMileageProps {
  id: string;
  onSubmit: () => void;
}

const FormEditMileage: FC<FormEditMileageProps> = ({ id, onSubmit }) => {
  const { form, isLoading } = usePutMileageVehicle(id, onSubmit);
  const formRef = useRef<FormContainerRef>(null);
  const { translate } = useTranslate();
  const handleEdit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
      <Skeleton isLoading={isLoading} width="100%" height="40px">
        <Displayer innerSpacing={2} layout="row">
          <Form.NumberAdapted
            className={inputFormEditMileage}
            item={form.items.value}
            label={translate('assets.mileage.post.modal.value.label')}
            size="small"
            type="integer"
          />
          <Button.Default
            variant="contained"
            size="medium"
            onClick={() => handleEdit()}
            disabled={form.hasErrors}
            isLoading={isLoading}
          >
            <Icon.Check />
          </Button.Default>
        </Displayer>
      </Skeleton>
    </FormContainer>
  );
};
export default FormEditMileage;
