import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';
import { type SwaggerRequestResponse } from 'core/swagger';
import { LocalesEnum } from 'src/core/common/constants';

export type User = SwaggerRequestResponse<'/api/private/users/{id}', 'get'>;
export type Guest = {
  preferredLanguage: LocalesEnum;
};

class UserEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathOne() {
    return exactString('/api/private/users/{id}');
  }

  getPathAll() {
    return exactString('/api/private/users');
  }

  getPathCurrent() {
    return exactString('/api/private/current-user');
  }

  getPathSibling() {
    return exactString('/api/private/sibling-users');
  }

  getPathPasswordRetrieve() {
    return exactString('/api/private/api/user-password-retrieve');
  }

  getPathMediaObjectDownload() {
    return exactString('/api/private/user-media-objects-download/{id}');
  }

  getOne = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathOne(), 'get', options),
      {
        path: this.getPathOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getCurrent = async () => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathCurrent(), 'get'),
      {
        path: this.getPathCurrent(),
        method: 'get',
      },
    );
  };

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathAll(), 'get', options),
      {
        path: this.getPathAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  getSibling = async (options: RequestOptions<ReturnType<typeof this.getPathSibling>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathSibling(), 'get', options),
      {
        path: this.getPathSibling(),
        method: 'get',
        payload: options,
      },
    );
  };

  post = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'post'>) => {
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathSibling());
    return this.http.requestFromSwagger(this.getPathAll(), 'post', options);
  };

  passwordRetrieve = async (
    options: RequestOptions<ReturnType<typeof this.getPathPasswordRetrieve>, 'post'>,
  ) => {
    return this.http.requestFromSwagger(this.getPathPasswordRetrieve(), 'post', options);
  };

  put = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathSibling());
    return this.http.requestFromSwagger(this.getPathOne(), 'put', options);
  };

  putCurrent = async (options: RequestOptions<ReturnType<typeof this.getPathCurrent>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathCurrent());
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathSibling());
    return this.http.requestFromSwagger(this.getPathCurrent(), 'put', options);
  };

  delete = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'delete'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathSibling());
    return this.http.requestFromSwagger(this.getPathOne(), 'delete', options);
  };
}

export default UserEntity;
