import { useState, useEffect, useCallback } from 'react';
import { useCore } from 'src/web/common/core';

function useGetAssignementDatas(assetId?: string) {
  const { assetDetailsUseCase } = useCore();
  const [dataRes, setDataRes] =
    useState<Awaited<ReturnType<typeof assetDetailsUseCase.getAssignments>>>();
  const [dataCurrentRes, setDataCurrentRes] =
    useState<Awaited<ReturnType<typeof assetDetailsUseCase.getCurrentAssignments>>>();
  const [isLoadingRes, setIsLoadingRes] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    setIsLoadingRes(true);

    const dataAssignment = await assetDetailsUseCase.getAssignments(assetId ?? '');
    setDataRes(dataAssignment);

    const dataCurrentAssignment = await assetDetailsUseCase.getCurrentAssignments(assetId ?? '');
    setDataCurrentRes(dataCurrentAssignment);

    setIsLoadingRes(false);
  }, [assetId, assetDetailsUseCase]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    dataCurrentAssignment: dataCurrentRes,
    dataAssignement: dataRes,
    isLoading: isLoadingRes,
    reload: loadData,
  };
}

export { useGetAssignementDatas };
