import { ElementType, FC, ReactNode } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export interface MenuItemProps {
  label?: string | ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: () => void;
  component?: ElementType;
  to?: string;
  isSelected?: boolean;
  inset?: boolean;
  size?: 'small' | 'default';
  className?: string;
}

const MenuItem: FC<MenuItemProps> = ({
  label,
  onClick,
  startIcon,
  endIcon,
  isSelected,
  inset = false,
  size = 'default',
  className,
  component,
  to,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const linkProps =
    component && to && !onClick
      ? {
          component: component,
          to: to,
        }
      : undefined;

  return (
    <ListItemButton
      className={className}
      onClick={() => handleClick()}
      selected={isSelected}
      dense={size === 'small'}
      {...linkProps}
    >
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      <ListItemText primary={label} inset={inset && !startIcon} />
      {endIcon && <ListItemIcon>{endIcon}</ListItemIcon>}
    </ListItemButton>
  );
};

export default MenuItem;
