import { FC } from 'react';
import OptimizedInput, { OptimizedInputProps } from 'src/web/design_system/Input/Input';

export interface InputTextProps extends Omit<OptimizedInputProps<'text'>, 'mode'> {
  className?: string;
}

const InputText: FC<InputTextProps> = ({ className, ...props }) => {
  const { fullWidth, ...restProps } = props;

  return (
    <div className={className}>
      <OptimizedInput mode="text" {...restProps} fullWidth={fullWidth ?? true} />
    </div>
  );
};

export default InputText;
