import { createCss } from 'src/web/common/styles/createCss';
import { Theme } from 'src/web/common/theme';

export const smallStyle = createCss(`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  word-break: keep-all;
  max-width: 200px;
`);

export const iconStyles = (theme: Theme, isSelected: boolean) =>
  createCss(`
  color: ${isSelected && theme.colors.brand}
`);

export const MenuIconStyle = (open: boolean) =>
  createCss(`
  & .MuiListItemIcon-root {
    min-width: 0;
    margin-right: ${open && '16px'};
  }
`);
