import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TaskTopics } from 'core/common/constants';
import { useCore } from 'src/web/common/core';
import {
  ROUTE_CREATE_TAG,
  ROUTE_OFFBOARDING_TAG,
  ROUTE_ONBOARDING_TAG,
  ROUTE_PERSON,
  ROUTE_UPDATE_TAG,
} from 'src/web/common/router/constantes';
import useTranslate from 'src/web/common/translate/useTranslate';

interface BoardingLink {
  label: string;
  to: string;
}

const usePersonDetail = () => {
  const { translate } = useTranslate();
  const { boardingUseCase } = useCore();
  const { id } = useParams();
  const navigate = useNavigate();
  const { personUsercase } = useCore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [person, setPerson] = useState<Awaited<ReturnType<typeof personUsercase.getPerson>>>();
  const [boardingLink, setBoardingLink] = useState<BoardingLink>();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  // get person
  const getPerson = async (personId: string) => {
    setIsLoading(true);

    personUsercase.getPerson(personId).then((data) => {
      if (data) {
        setPerson(data);
      } else {
        navigate(ROUTE_PERSON);
      }
      setIsLoading(false);
    });
  };

  // load or redirect
  useEffect(() => {
    if (id) {
      getPerson(id);
    } else {
      navigate(`${ROUTE_PERSON}?${queryString}`);
    }
  }, [id]);

  const getBoardingLinkLabel = (exist: boolean, type: TaskTopics): string => {
    if (type === TaskTopics.peopleOnBoarding) {
      if (exist) {
        return translate('onBoarding.edit.title');
      }
      return translate('onBoarding.add.title');
    }
    if (type === TaskTopics.peopleOffBoarding) {
      if (exist) {
        return translate('offBoarding.edit.title');
      }
      return translate('offBoarding.details.select');
    }
    return '';
  };

  const getBoardingLinkTo = (exist: boolean, type: TaskTopics): string => {
    if (type === TaskTopics.peopleOnBoarding) {
      if (exist) {
        return `${ROUTE_ONBOARDING_TAG}/${ROUTE_UPDATE_TAG}`;
      }
      return `${ROUTE_ONBOARDING_TAG}/${ROUTE_CREATE_TAG}`;
    }
    if (type === TaskTopics.peopleOffBoarding) {
      if (exist) {
        return `${ROUTE_OFFBOARDING_TAG}/${ROUTE_UPDATE_TAG}`;
      }
      return `${ROUTE_OFFBOARDING_TAG}/${ROUTE_CREATE_TAG}`;
    }
    return '';
  };

  // get boarding
  useEffect(() => {
    if (person) {
      boardingUseCase
        .getPersonBoarding({
          id: person.id,
          entryAt: person.entryAt,
          exitAt: person.exitAt,
        })
        .then((boarding) => {
          if (boarding.type) {
            setBoardingLink({
              label: getBoardingLinkLabel(boarding.exist, boarding.type),
              to: getBoardingLinkTo(boarding.exist, boarding.type),
            });
          }
        });
    }
  }, [person]);

  return {
    isLoading,
    data: person,
    boardingLink,
  };
};

export default usePersonDetail;
