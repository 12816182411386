import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';

class CustomAssetEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathOne() {
    return exactString('/api/private/custom-assets/{id}');
  }

  getPathAll() {
    return exactString('/api/private/custom-assets');
  }

  getPathMediaObjectOne() {
    return exactString('/api/private/custom-asset-media-objects/{id}');
  }

  getPathMediaObjectAll() {
    return exactString('/api/private/custom-asset-media-objects');
  }

  getPathMediaObjectDownload() {
    return exactString('/api/private/custom-asset-media-objects-download/{id}');
  }

  getOne = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathOne(), 'get', options),
      {
        path: this.getPathOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathAll(), 'get', options),
      {
        path: this.getPathAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  post = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'post'>) => {
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathAll(), 'post', options);
  };

  put = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'put', options);
  };

  delete = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'delete'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'delete', options);
  };

  getMediaObjectOne = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectOne>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMediaObjectOne(), 'get', options),
      {
        path: this.getPathMediaObjectOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getMediaObjectAll = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectAll>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMediaObjectAll(), 'get', options),
      {
        path: this.getPathMediaObjectAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  postMediaObject = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectAll>, 'post'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathMediaObjectAll());
    return this.http.requestFromSwagger(this.getPathMediaObjectAll(), 'post', options);
  };

  deleteMediaObject = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectOne>, 'delete'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathMediaObjectOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMediaObjectAll());
    return this.http.requestFromSwagger(this.getPathMediaObjectOne(), 'delete', options);
  };
}

export default CustomAssetEntity;
