import { IconButton, InputAdornment } from '@mui/material';
import { FC, useState } from 'react';
import Icon from 'src/web/components/Icon';
import useTranslate from 'src/web/common/translate/useTranslate';
import OptimizedInput, { OptimizedInputProps } from 'src/web/design_system/Input/Input';

export interface InputPasswordProps
  extends Omit<OptimizedInputProps<'text'>, 'mode' | 'type' | 'endExtra'> {
  className?: string;
}

const InputPassword: FC<InputPasswordProps> = ({ className, ...props }) => {
  const { translate } = useTranslate();
  const { fullWidth, ...restProps } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className={className}>
      <OptimizedInput
        mode="text"
        {...restProps}
        type={showPassword ? 'text' : 'password'}
        endExtra={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
              title={
                showPassword
                  ? translate('global.passwordInput.hide.tooltip')
                  : translate('global.passwordInput.show.tooltip')
              }
            >
              {showPassword ? <Icon.VisibleOff /> : <Icon.VisibleOn />}
            </IconButton>
          </InputAdornment>
        }
        fullWidth={fullWidth ?? true}
      />
    </div>
  );
};

export default InputPassword;
