import { FC } from 'react';
import RolesPartial from './partials/rolesPartial';
// import NotificationPartial from './partials/notificationPartial';
import LanguagePartial from './partials/languagePartial';
import PasswordPartial from './partials/passwordPartial';
import CookiesPartial from './partials/cookiesPartial';
import { useCore } from 'src/web/common/core';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import Tabs from 'src/web/design_system/Tabs';
import Text from 'src/web/design_system/Text';
import useTranslate from 'src/web/common/translate/useTranslate';

const MyProfilPage: FC = () => {
  const { store } = useCore();
  const userName = store.getState(
    (state) => `${state.user.current?.lastname ?? ''} ${state.user.current?.firstname ?? ''}`,
  );
  const userLogin = store.getState((state) => state.user.current?.username);
  const { translate } = useTranslate();

  return (
    <>
      <PageHeaderBasic title={userName} subtitle={<Text>{userLogin}</Text>} />
      <Tabs.Global
        initialActiveId="password"
        tabs={[
          {
            id: 'password',
            label: translate('currentUser.password.title'),
            content: <PasswordPartial />,
          },
          {
            id: 'roles',
            label: translate('currentUser.roles.title'),
            content: <RolesPartial />,
          },
          {
            id: 'notifications',
            label: translate('currentUser.notifications.title'),
            content: <></>,
          },
          {
            id: 'language',
            label: translate('currentUser.language.title'),
            content: <LanguagePartial />,
          },

          {
            id: 'cookies',
            label: translate('currentUser.cookies.title'),
            content: <CookiesPartial />,
          },
        ]}
      />
    </>
  );
};

export default MyProfilPage;
