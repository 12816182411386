import { FC, PropsWithChildren, ReactNode } from 'react';
import List from '@mui/material/List';
import { ListSubheader } from '@mui/material';

interface MenuContainerProps {
  title?: ReactNode;
  className?: string;
}

const MenuContainer: FC<PropsWithChildren<MenuContainerProps>> = ({
  children,
  title,
  className,
}) => {
  return (
    <List
      className={className}
      subheader={
        !!title && (
          <ListSubheader
            color="inherit"
            disableSticky
            sx={{
              background: 'inherit',
            }}
          >
            {title}
          </ListSubheader>
        )
      }
    >
      {children}
    </List>
  );
};

export default MenuContainer;
