import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import TimeService from 'core/services/time/time.service';
import VehicleEntity from 'core/entities/vehicle.entity';
import ComputerEntity from 'core/entities/computer.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ServiceEntity from 'core/entities/service.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import CardEntity from 'core/entities/card.entity';
import AssetAssignmentEntity from 'core/entities/assetAssignment.entity';
import CustomAssetEntity from 'core/entities/customAsset.entity';
import { SwaggerRequestResponse } from 'core/swagger';

export type AllowedAssetDetailsEntities =
  | EntitiesEnum.card
  | EntitiesEnum.computer
  | EntitiesEnum.vehicle
  | EntitiesEnum.licence
  | EntitiesEnum.service
  | EntitiesEnum.telephone
  | EntitiesEnum.telephoneLine
  | EntitiesEnum.customAsset;

/**
 * @todo: to remove. The difficulty is that interface is used by too many components that inherit the data from there parents
 */
export type DynamicAssetDetailsReturn<N extends AllowedAssetDetailsEntities> =
  | {
      [EntitiesEnum.vehicle]: {
        getOne: SwaggerRequestResponse<'/api/private/vehicles/{id}', 'get'>;
      };
      [EntitiesEnum.card]: {
        getOne: SwaggerRequestResponse<'/api/private/cards/{id}', 'get'>;
      };
      [EntitiesEnum.licence]: {
        getOne: SwaggerRequestResponse<'/api/private/licences/{id}', 'get'>;
      };
      [EntitiesEnum.computer]: {
        getOne: SwaggerRequestResponse<'/api/private/computers/{id}', 'get'>;
      };
      [EntitiesEnum.service]: {
        getOne: SwaggerRequestResponse<'/api/private/services/{id}', 'get'>;
      };
      [EntitiesEnum.telephone]: {
        getOne: SwaggerRequestResponse<'/api/private/telephones/{id}', 'get'>;
      };
      [EntitiesEnum.telephoneLine]: {
        getOne: SwaggerRequestResponse<'/api/private/telephone-lines/{id}', 'get'>;
      };
      [EntitiesEnum.person]: {
        getOne: SwaggerRequestResponse<'/api/private/persons/{id}', 'get'>;
      };
      [EntitiesEnum.user]: {
        getOne: SwaggerRequestResponse<'/api/private/users/{id}', 'get'>;
      };
      [EntitiesEnum.task]: {
        getOne: SwaggerRequestResponse<'/api/private/tasks-items/{id}', 'get'>;
      };
      [EntitiesEnum.customAsset]: {
        getOne: SwaggerRequestResponse<'/api/private/custom-assets/{id}', 'get'>;
      };
    }[N]['getOne']
  | undefined;

class AssetDetailsUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private timeService: TimeService,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private licenceEntity: LicenceEntity,
    private serviceEntity: ServiceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private customAssetEntity: CustomAssetEntity,
    private assetAssignmentEntity: AssetAssignmentEntity,
  ) {}

  getRoles<T extends AllowedAssetDetailsEntities>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends AllowedAssetDetailsEntities>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);
    return isUserGranted(currentUser, this.getRoles(params));
  }

  /**
   * GET ONE ENTITY
   */
  public async getOneAsset<E extends AllowedAssetDetailsEntities>(
    entity: E,
    id: string,
  ): Promise<DynamicAssetDetailsReturn<E>> {
    const pathVar = { id };

    switch (entity) {
      case EntitiesEnum.card:
        return this.cardEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.vehicle:
        return this.vehicleEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.licence:
        return this.licenceEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.telephone:
        return this.telephoneEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.service:
        return this.serviceEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.computer:
        return this.computerEntity.getOne({ pathVar }).then((response) => response.data);
      case EntitiesEnum.customAsset:
        return this.customAssetEntity.getOne({ pathVar }).then((response) => response.data);
      default:
        return undefined;
    }
  }

  /**
   * GET ALL ASSIGNMENTS
   * @todo: to move
   */
  public async getAssignments(id: string) {
    return this.assetAssignmentEntity
      .getAll({
        query: {
          'asset.id': id,
          'order[startAt]': 'desc',
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param assetId
   * @returns
   */
  public async getCurrentAssignments(assetId: string) {
    return this.assetAssignmentEntity
      .getAll({
        query: {
          'asset.id': assetId,
          'order[startAt]': 'desc',
          'startAt[before]': this.timeService.toBackend(this.timeService.now()),
        },
      })
      .then((response) => {
        if (response?.data && response.data.length > 0) {
          const firstAssignment = response.data[0];
          const endAt = firstAssignment.endAt
            ? this.timeService.fromBackend(firstAssignment.endAt).getTime()
            : null;
          const now = this.timeService.now().getTime();

          if (endAt === null || endAt > now) {
            return response.data;
          }
        }
        return undefined;
      });
  }
}

export default AssetDetailsUseCase;
