import { FC, useRef, useState } from 'react';
import Modal from 'src/web/components/Modal';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import useSetPersonAssignment from 'src/web/pages/person/detail/hooks/useSetPersonAssignment';
import Displayer from 'src/web/design_system/Displayer';
import Button from 'src/web/components/Button';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Switch from 'src/web/design_system/Input/Switch';
import { attestationContainer } from './style';
import { useCore } from 'src/web/common/core';
import { EntitiesEnum } from 'src/core/common/constants';

interface AssetAssignmentEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  assetIri: string;
  onEdit: () => void;
  title?: string;
  startAt?: string;
  personIri?: string;
  entity: EntitiesEnum;
}

const AssetAssignmentEditModal: FC<AssetAssignmentEditModalProps> = ({
  assetIri,
  isOpen,
  onClose,
  onEdit,
  title,
  personIri,
  entity,
}) => {
  const formRef = useRef<FormContainerRef>(null);
  const { translate } = useTranslate();
  const { timeService } = useCore();
  const [generateDeliveryAssignementCertificate, setGenerateDeliveryAssignementCertificate] =
    useState(false);
  const [generateReturnAssignmentCertificate, setGenerateReturnAssignmentCertificate] =
    useState(false);

  const { form, isLoading } = useSetPersonAssignment({
    assetIri,
    afterSubmit: (status) => {
      if (status) {
        onEdit();
        onClose();
      }
    },
    personIri: personIri ?? '',
    generateDeliveryAssignementCertificate,
    generateReturnAssignmentCertificate,
    entity,
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const actionArea = (
    <Displayer layout="row" justifyContent="center" innerSpacing={3}>
      <Button.Custom onClick={() => onClose()} variant="outlined" fullWidth={false}>
        {translate('assets.assignment.edit.modal.cancel')}
      </Button.Custom>
      <Button.Custom
        disabled={!form.isDirty && form.hasErrors}
        fullWidth={false}
        onClick={() => handleSubmit()}
        isLoading={isLoading}
      >
        {translate('assets.assignment.edit.modal.submit')}
      </Button.Custom>
    </Displayer>
  );

  if (!isOpen) return <></>;

  return (
    <Modal
      title={title ?? translate('assets.assignment.edit.modal.title')}
      open={isOpen}
      onClose={() => onClose()}
      footer={actionArea}
    >
      <FormContainer ref={formRef} onSubmit={form.handleSubmit}>
        <Displayer innerSpacing={2}>
          <Form.DateAdapted
            label={translate('assets.assignment.edit.modal.startDate')}
            item={form.items.startAt}
          />
          <Form.DateAdapted
            label={translate('assets.assignment.edit.modal.endDate')}
            item={form.items.endAt}
            minDate={
              form.items.startAt.formValue
                ? timeService.manipulate(
                    timeService.fromBackend(form.items.startAt.formValue.toString()),
                    { day: 1 },
                  )
                : timeService.now()
            }
          />
          <div className={attestationContainer}>
            <Switch
              label={translate('persons.assign.generateDeliveryAssignmentCertificate.label')}
              value={generateDeliveryAssignementCertificate}
              onChange={(value) => setGenerateDeliveryAssignementCertificate(value)}
            />
            {form.items.endAt.formValue || form.items.endAt.formValue !== null ? (
              <Switch
                label={translate('persons.assign.generateReturnAssignmentCertificate.label')}
                value={generateReturnAssignmentCertificate}
                onChange={(value) => setGenerateReturnAssignmentCertificate(value)}
              />
            ) : (
              <></>
            )}
          </div>
        </Displayer>
      </FormContainer>
    </Modal>
  );
};

export default AssetAssignmentEditModal;
