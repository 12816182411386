import { FC } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import InputTextArea, { type InputTextAreaProps } from './InputTextArea';
import useFormComponent from './useFormComponent';

type FormTextAreaExcludedProps = keyof Pick<
  InputTextAreaProps,
  'value' | 'error' | 'message' | 'onChange' | 'isRequired' | 'pattern'
>;

interface FormTextAreaProps extends Omit<InputTextAreaProps, FormTextAreaExcludedProps> {
  item: FormItem;
}

const FormTextArea: FC<FormTextAreaProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props, item);
    return <></>;
  }
  const { ...restProps } = props;
  const { formatFormMessage } = useFormComponent();
  const value = item.formValue ? String(item.formValue) : '';

  return (
    <InputTextArea
      value={value}
      isRequired={item.required}
      error={!!item.errors}
      message={formatFormMessage(item)}
      onChange={(val) => item.handleChange(val)}
      {...restProps}
    />
  );
};

export default FormTextArea;
