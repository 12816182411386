import { FC, PropsWithChildren } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useCore } from '../core';
import { RolesEnum } from 'core/common/constants';

interface GrantProps {
  includes?: ReadonlyArray<RolesEnum>[number][];
  excludes?: ReadonlyArray<RolesEnum>[number][];
  fallback: string;
}

const Grant: FC<PropsWithChildren<GrantProps>> = ({
  children,
  fallback,
  includes = [],
  excludes = [],
}) => {
  const { store } = useCore();
  const userRoles = store.getState((state) => state.user?.current?.roles);
  const location = useLocation();

  // user has these roles
  const includesRoles =
    Array.isArray(userRoles) && includes.filter((role) => userRoles.includes(role)).length > 0;

  // user doesn't has these roles
  const excludesRoles =
    !Array.isArray(userRoles) || excludes.filter((role) => userRoles.includes(role)).length === 0;

  if ((includes.length > 0 && !includesRoles) || (excludes.length > 0 && !excludesRoles)) {
    return <Navigate to={fallback} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default Grant;
