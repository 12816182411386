import { FC, useEffect, useState } from 'react';
import { LocalesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';
import Card from 'src/web/components/Card';
import Skeleton from 'src/web/design_system/Skeleton';
import Text from 'src/web/design_system/Text';
import FormInput from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';

const LanguagePartial: FC = () => {
  const { currentUserUsecase, store } = useCore();
  const currentUserLanguage = store.getState((state) => state.user.current?.preferredLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useTranslate();
  const handleChangeLanguage = (value: LocalesEnum) => {
    setIsLoading(true);
    currentUserUsecase.updatePreferredLanguage(value);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [currentUserLanguage]);

  return (
    <Card.Basic title={translate('currentUser.language.title')}>
      <Text tag="p">{translate('currentUser.language.description')}</Text>
      <Skeleton isLoading={isLoading}>
        <FormInput.Radio
          value={currentUserLanguage}
          onChange={(value) => handleChangeLanguage(value as LocalesEnum)}
          options={[
            {
              label: translate('myProfile.language.select.label.fr'),
              value: LocalesEnum.fr,
            },
            {
              label: translate('myProfile.language.select.label.en'),
              value: LocalesEnum.en,
            },
          ]}
        />
      </Skeleton>
    </Card.Basic>
  );
};

export default LanguagePartial;
