import { useState } from 'react';
import useAddSignupForm from './useSignupForm';
import { themeColors } from 'src/web/common/theme/constant';
import Button from 'src/web/design_system/Button';
import Text from 'src/web/design_system/Text';
import FocusLayout from 'src/web/layouts/FocusLayout';
import ReCAPTCHA from 'react-google-recaptcha';
import Checkbox from 'src/web/design_system/Checkbox';
import * as styles from './styles';
import FleezyIcon from 'src/web/components/FleezyIcon';
import Form from 'src/web/components/FormInput';
import Icon from 'src/web/components/Icon';
import { ROUTE_LOGIN } from 'src/web/common/router/constantes';
import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import Link from 'src/web/components/Link';
import { useTheme } from 'src/web/design_system/Theme';
import Grid from 'src/web/design_system/Grid';
import useTranslate from 'src/web/common/translate/useTranslate';
import Alert from 'src/web/design_system/Alert';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import countries, { Country } from 'src/web/resources/countries/countries';
import LanguageSwitching from 'src/web/components/LanguageSwitching';
import { useCore } from 'src/web/common/core';
import { LocalesEnum } from 'src/core/common/constants';

export default function Signup() {
  const { form, isLoading, errorSubmit, succesSubmit } = useAddSignupForm();
  const [step, setStep] = useState<number>(1);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(true);
  const [captchaValue, setCaptchaValue] = useState<string>('');
  const { theme } = useTheme();
  const { translate } = useTranslate();
  const { store } = useCore();
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);

  const countryName: keyof Country =
    store.getState((state) => state.user.guest?.preferredLanguage) === LocalesEnum.fr
      ? 'nameFr'
      : 'nameEn';
  const handleSearch = (searchText: string): void => {
    const searchResult = countries.filter((country) =>
      country[countryName].toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredCountries(searchResult);
  };

  const handleChange = (selectedValues?: string[]): void => {
    if (selectedValues) form.values.country = selectedValues[0];
  };

  const handleCaptcha = (value: string | null) => {
    setCaptchaValue(value ?? '');
  };

  const retrieveRecaptchaKey = (): string => {
    const url = new URL(window.location.origin);
    if (url.hostname === 'fleezy.localhost') {
      return '6LfRVrAUAAAAAEnPSvUTVWHKijFghOBxWhHT5pIx';
    } else {
      return '6LfTHOYpAAAAANcCXcFS1jQQC1VEF-eKBedSmIs0';
    }
  };

  return (
    <FocusLayout>
      <FleezyIcon width={'lg'} />

      <Card.Container className={styles.card}>
        <Card.Content>
          <Displayer bottomSpacing={3}>
            <Text
              variant="sectionTitle"
              color={themeColors.blackColor}
              align="left"
              fontWeight={900}
            >
              {translate('auth.signupPage.title')}
            </Text>
            <Text variant="textSmall" align="left" fontWeight={400} color={themeColors.blackColor}>
              {step === 1
                ? translate('auth.signupPage.stepOne.title')
                : translate('auth.signupPage.stepTwo.title')}
            </Text>
          </Displayer>
          <Displayer innerSpacing={2}>
            <form onSubmit={form.handleSubmit} onBlur={form.handleValidation}>
              {step === 1 && (
                <Displayer innerSpacing={2}>
                  <Form.TextAdapted
                    item={form.items.legalEntityName}
                    label={translate('auth.legalEntityName.label')}
                  />

                  <Form.TextAdapted
                    item={form.items.streetName}
                    label={translate('auth.streetName.label')}
                  />

                  <Grid.Container>
                    <Grid.Item size={4}>
                      <Form.TextAdapted
                        item={form.items.postcode}
                        label={translate('auth.postcode.label')}
                      />
                    </Grid.Item>
                    <Grid.Item size={8}>
                      <Form.TextAdapted
                        item={form.items.city}
                        label={translate('auth.city.label')}
                      />
                    </Grid.Item>
                  </Grid.Container>
                  <Autocomplete
                    label={translate('global.location.country')}
                    options={filteredCountries?.map((country) => ({
                      value: country.alpha2.toUpperCase(),
                      label: `${country[countryName]} (${country.alpha2.toUpperCase()})`,
                    }))}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    renderInput={(option) => option.label}
                    renderOption={(option) => option.label}
                  />
                  <Displayer justifyContent="flex-end" layout="row">
                    <Button.Default
                      className={styles.NextButton}
                      onClick={() => setStep(2)}
                      variant={'contained'}
                      isLoading={false}
                      endIcon={<Icon.Next />}
                    >
                      {translate('auth.signupPage.next.button')}
                    </Button.Default>
                  </Displayer>
                </Displayer>
              )}
              {step === 2 && (
                <Displayer innerSpacing={2}>
                  <Grid.Container>
                    <Grid.Item>
                      <Form.TextAdapted
                        item={form.items.firstname}
                        label={translate('auth.firstname.label')}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextAdapted
                        item={form.items.lastname}
                        label={translate('auth.lastname.label')}
                      />
                    </Grid.Item>
                  </Grid.Container>
                  <Form.TextAdapted
                    item={form.items.personEmail}
                    label={translate('auth.personEmail.label')}
                    type="email"
                  />

                  <Checkbox
                    checked={isTermsChecked}
                    onChange={setIsTermsChecked}
                    label={
                      <>
                        {translate('auth.signupPage.legal.text')}
                        <a
                          href="https://www.fleezy.io/cgu"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translate('auth.signupPage.legal.link')}
                        </a>
                      </>
                    }
                  />

                  <ReCAPTCHA sitekey={retrieveRecaptchaKey()} onChange={handleCaptcha} />
                  {errorSubmit && (
                    <Alert icon={<Icon.Close />} mode="error">
                      {translate('auth.signup.error.message')}
                    </Alert>
                  )}
                  {succesSubmit && (
                    <Alert icon={<Icon.Check />} mode="success">
                      {translate('auth.signup.success.message')}
                    </Alert>
                  )}
                  {succesSubmit ? (
                    <></>
                  ) : (
                    <Grid.Container>
                      <Grid.Item>
                        <Button.Default
                          className={styles.backButton}
                          onClick={() => setStep(1)}
                          variant={'contained'}
                          isLoading={false}
                          fullWidth
                          startIcon={<Icon.Back />}
                        >
                          {translate('auth.signupPage.back.button')}
                        </Button.Default>
                      </Grid.Item>
                      <Grid.Item>
                        <Button.Default
                          className={styles.ValidationButton(
                            !isTermsChecked || !captchaValue || isLoading,
                          )}
                          variant={'contained'}
                          type="submit"
                          isLoading={isLoading}
                          fullWidth
                          disabled={!isTermsChecked || !captchaValue}
                        >
                          {translate('auth.signupPage.submit')}
                        </Button.Default>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                </Displayer>
              )}
            </form>

            <Displayer justifyContent="center" topSpacing={2} layout={'row'} innerSpacing={1}>
              <div className={styles.step(step === 1)} />
              <div className={styles.step(step === 2)} />
            </Displayer>
          </Displayer>
        </Card.Content>
      </Card.Container>

      <Displayer alignContent="center">
        <Link to={ROUTE_LOGIN}>
          <Text fontWeight={600} color={theme.colors.black} variant="textSmall">
            {translate('auth.signupPage.login.cta')}
          </Text>
        </Link>
      </Displayer>
      <LanguageSwitching />
    </FocusLayout>
  );
}
