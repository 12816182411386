import { Usecase } from 'core/.framework/usecase.abstract';
import AppStore from 'core/services/store/store.service';
import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import FormService from 'core/services/form/form.service';
import LegalEntityEntity from 'core/entities/legalEntity.entity';

class LegalEntityUsecase implements Usecase {
  private defaultCurrency = 'EUR';

  private currencies = ['EUR', 'GBP', 'USD'];

  constructor(
    private store: AppStore,
    private formService: FormService,
    private legalEntityEntity: LegalEntityEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  /**
   * GET CURRENCY
   */
  public getCurrency(): string {
    /**
     * @todo missing typing on the backend, remove that fix as soon as possible
     */
    const currency = this.store.getState((state) => state.legalEntity.settings?.settings?.currency);

    return currency ?? this.defaultCurrency;
  }

  public getCurrencies() {
    return this.currencies;
  }

  /**
   * GET LEGAL ENTITY
   */
  public async getLegalEntity(id: string) {
    return this.legalEntityEntity
      .getOne({
        pathVar: {
          id,
        },
      })
      .then((response) => {
        if (response.data) {
          this.store.setState((state) => {
            state.legalEntity.current = response.data;
          });
        }

        return this.store.getState((state) => state.legalEntity.current);
      });
  }

  /**
   * GET LEGAL ENTITY SETTINGS
   */
  public async getLegalEntitySettings(id: string) {
    return this.legalEntityEntity
      .getSettings({
        pathVar: {
          id,
        },
      })
      .then((response) => {
        if (response.data) {
          this.store.setState((state) => {
            state.legalEntity.settings = response.data;
          });
        }

        return this.store.getState((state) => state.legalEntity.settings);
      });
  }

  /**
   * UPDATE LEGAL ENTITY CURRENCY ONLY ?
   * FIXME: 2471 DEPRECATED due to new Stripe implementation
   */
  public getUpdateLegalEntityForm = () => {
    const { properties, required, validate } = this.formService.createForm(
      this.legalEntityEntity.getPathOne(),
      'put',
    );

    const getInitialesValues = () => {
      let initialValues = {};
      const currentValues = this.store.getState((state) => state.legalEntity.current);

      if (currentValues) {
        const flatCurrentData = this.formService.formatData(currentValues);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in flatCurrentData) {
            initialValues = {
              ...initialValues,
              [propkey]: flatCurrentData[propkey],
            };
          }
        });
      }

      return initialValues;
    };

    const submit = async (data: object) => {
      const currentLegalEntity = this.store.getState((state) => state.legalEntity.current);

      if (!currentLegalEntity || !currentLegalEntity.id) {
        return new Promise((resolve) => resolve(false));
      }

      const flatCurrentLegalEntity = this.formService.formatData(currentLegalEntity);

      return this.legalEntityEntity
        .put({
          pathVar: {
            id: currentLegalEntity.id,
          },
          body: this.formService.unformatData({ ...flatCurrentLegalEntity, ...data }),
        })
        .then((response) => {
          if (response.data) {
            this.store.setState((state) => {
              state.legalEntity.current = response.data;
            });

            return true;
          }

          return false;
        });
    };

    return {
      properties,
      required,
      validate,
      initialValues: getInitialesValues(),
      submit,
    };
  };
}
export default LegalEntityUsecase;
