import { Pagination as MuiPagination } from '@mui/material';
import { FC } from 'react';

export interface PaginationProps {
  count: number;
  current: number;
  onChange: (value: number) => void;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ count, current, className, onChange }) => {
  return (
    <MuiPagination
      className={className}
      count={count}
      page={current}
      onChange={(_, value) => onChange(value)}
    />
  );
};

export default Pagination;
