import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UseSetPersonFormProps } from './commonType';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';

export type UseAddAssetFormProps = UseSetPersonFormProps;

const useEditAssetForm = () => {
  const { setPersonUsecase, sitesUseCase, personUsercase } = useCore();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [sites, setSites] = useState<Awaited<ReturnType<typeof sitesUseCase.getAllSites>>>([]);
  const [optionsServices, setOptionsServices] =
    useState<Awaited<ReturnType<typeof personUsercase.getServices>>>(undefined);
  const [searchValue, setSearchValue] = useState('');

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  // get asset id
  const personId = useMemo(() => {
    if (!id) {
      navigate('/');
      return '';
    }

    return id;
  }, [id]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const { properties, required, validate, submit, getInitialValues } = useMemo(
    () => setPersonUsecase.getEditForm(personId),
    [],
  );

  const loadServices = async () => {
    if (searchValue.length > 1) {
      const response = await personUsercase.getServices(searchValue);
      if (response) {
        setOptionsServices(response);
      }
    } else {
      setOptionsServices(undefined);
    }
  };

  const loadSites = async () => {
    setIsLoading(true);
    const response = await sitesUseCase.getAllSites();
    setSites(response);
    setIsLoading(false);
  };
  useEffect(() => {
    loadSites();
  }, []);

  useEffect(() => {
    loadServices();
  }, [searchValue]);

  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      if (!data.attachmentSite) {
        data = { ...data, attachmentSite: null };
      }
      const response = await submit(data);

      setIsLoading(false);

      if (response) {
        navigate(`${ROUTE_PERSON}/${personId}?${queryString}`);
      }
    },
  });

  // Update form values from current data
  useEffect(() => {
    getInitialValues().then((values) => {
      for (const key in values) {
        form.imperativeChangeField(key, values[key]);
      }
      setIsLoading(false);
    });
  }, []);

  return {
    form,
    sites,
    isLoading,
    optionsServices,
    handleSearch,
  };
};

export default useEditAssetForm;
