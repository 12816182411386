import { createCss } from 'src/web/common/styles/createCss';
import { themeColors } from 'src/web/common/theme/constant';

export const card = createCss(`
  width: 100%;
  max-width: 400px;
  min-width: 350px;
  z-index: 2;
`);

// todo: to remove: use the project theme instead to produce the expected result
export const ValidationButton = (disable: boolean) =>
  createCss(`
  background-color: ${
    disable ? themeColors.secondaryColor + 40 : themeColors.secondaryColor
  } !important;
  color: white !important;
  text-transform: none !important;
  box-shadow: none !important;
`);

// todo: to remove: use the project theme instead to produce the expected result
export const NextButton = createCss(`
  background-color: ${themeColors.secondaryColor} !important;
  color: white !important;
  text-transform: none !important;
  box-shadow: none !important;
  width: 50%;
`);

// todo: to remove: use the project theme instead to produce the expected result
export const backButton = createCss(`
  background-color: ${themeColors.greyColor} !important;
  color: grey !important;
  text-transform: none !important;
  box-shadow: none !important;
`);

export const step = (active: boolean) =>
  createCss(`
  background: ${active ? themeColors.secondaryColor : themeColors.greyColor};
  height: 10px;
  width: 25px;
  border-radius: 7px;
  transition: ${active ? '0.3s ease 0.2s' : '0.3s ease'};
`);
