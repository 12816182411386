import { useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

const useSetPassword = (token: string) => {
  const { authUsecase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [succesSubmit, setSuccesSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const { properties, required, validate, submit } = authUsecase.passwordReset();

  const form = useCreateForm({
    properties,
    initialValues: {
      token: token,
    },
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        setSuccesSubmit(true);
      } else {
        setErrorSubmit(true);
      }
      setIsLoading(false);
    },
  });

  return {
    form,
    succesSubmit,
    errorSubmit,
    isLoading,
  };
};
export default useSetPassword;
