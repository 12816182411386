import { type PropsWithChildren, type FC, ReactNode } from 'react';
import Button from 'src/web/design_system/Button';
import { useNavigate } from 'react-router-dom';

type ButtonBaseProps = {
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  isLoading?: boolean;
  onClick?: () => void;
  to?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
};

const ButtonBase: FC<PropsWithChildren<ButtonBaseProps>> = ({
  children,
  color = 'primary',
  className,
  isLoading,
  size = 'medium',
  variant = 'contained',
  fullWidth = true,
  type,
  startIcon,
  endIcon,
  disabled,
  to,
  onClick,
}) => {
  const navigate = useNavigate();
  const isLink = to !== undefined;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (isLink) {
      navigate(to);
    }
  };

  return (
    <Button.Default
      color={color}
      type={isLink ? 'button' : type}
      className={className}
      variant={variant}
      size={size}
      onClick={handleClick}
      isLoading={isLoading}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </Button.Default>
  );
};

export default ButtonBase;
