import FormValidationClient, { FormValidationHandler } from './form.port';
import {
  SwaggerPathsKeys,
  SwaggerPathsMethods,
  SwaggerJsonSchemaProperty,
  schemas,
  JsonSchema,
} from 'core/swagger';
import { type CoreAdapters } from 'core/adapters';
import { FORM_FLATTEN_SEPARATOR } from 'core/common/constants';

export type FormProperties = Record<string, SwaggerJsonSchemaProperty>;

export type FormRequired = string[];

export type FormValidate = FormValidationHandler;

export type FormGetValues = <D>(data: D) => any;

export type FormDefinition = {
  properties: FormProperties;
  required: FormRequired;
  validate: FormValidate;
  getValues: FormGetValues;
};

class FormService {
  formValidationClient: FormValidationClient;

  constructor(private adapters: CoreAdapters) {
    this.formValidationClient = this.adapters.formValidationClient;
  }

  /**
   * Create form
   */
  createForm = <P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    path: P,
    method: M,
  ): FormDefinition => {
    const schema = schemas[path][method];

    if (!schema || !schema.body || !schema.body.schema || !schema.body.schema.properties) {
      return {
        properties: {},
        required: [],
        validate: () => ({}),
        getValues: () => null,
      };
    }

    // Note: Don't use fixedBodySchema (which overload the schema adding a fake required field)
    // Instead, use the real required field generated by the backend.
    // const fixedBodySchema = this.fixRequired(schema.body.schema);

    const bodySchema = schema.body.schema;
    const { properties, required } = this.flattenSchema(bodySchema);

    const validate: FormValidationHandler = (data) => {
      return this.formValidationClient.process(bodySchema)(this.unformatData(data));
    };

    const getValues: FormGetValues = (data) => this.unformatData(data);

    return { properties, required, validate, getValues };
  };

  /**
   * Create form item
   */
  createFormItem(values: SwaggerJsonSchemaProperty): SwaggerJsonSchemaProperty {
    return values;
  }

  /**
   * Format from nested API data to flat form data
   */
  formatData = <D extends object>(data: D, prefix = '') => {
    let result: Record<string, unknown> = {};

    for (const key in data) {
      const item = data[key];
      const prefixedKey = prefix.concat(key.toString());

      if (item && typeof item === 'object' && !Array.isArray(item)) {
        const unnested = this.formatData(item as object, `${prefixedKey}${FORM_FLATTEN_SEPARATOR}`);

        result = {
          ...result,
          ...unnested,
        };
      } else {
        result[prefixedKey] = item;
      }
    }

    return result;
  };

  /**
   * Unformat from flat form data to nested API data
   */
  unformatData = <D>(data: D) => {
    // todo: find a way to avoid the 'any' type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const result: Record<string, any> = {};

    function reduceKeys(source: D, key: keyof D, keys: string[]) {
      return keys.reduce((previous, current, index) => {
        return (
          previous[current] ||
          (previous[current] = isNaN(Number(keys[index + 1]))
            ? keys.length - 1 == index
              ? source[key]
              : {}
            : [])
        );
      }, result);
    }

    if (data && typeof data === 'object' && !Array.isArray(data)) {
      for (const key in data) {
        const keys = key.split(FORM_FLATTEN_SEPARATOR);

        reduceKeys(data, key, keys);
      }
    }

    return result;
  };

  /**
   * Flattens a given JSON schema by concatenating nested property names with a separator and
   * collecting required fields. This function recursively processes nested schemas, including
   * handling the `anyOf` keyword to ensure all possible schema variations are considered.
   *
   * @function
   * @param {JsonSchema} schema - The JSON schema to be flattened. This schema can contain nested
   * objects, arrays, and the `anyOf` keyword.
   * @param {string} [prefix=''] - A prefix to be added to each property name. This is used for
   * recursive calls to maintain the correct property path.
   * @returns {Object} An object containing two properties:
   *  - `required`: An array of strings representing the full paths of required fields.
   *  - `properties`: A flat object with full paths as keys and corresponding schema properties as values.
   *
   * @example
   * const schema = {
   *   type: "object",
   *   required: ["name"],
   *   properties: {
   *     name: { type: "string" },
   *     location: {
   *       anyOf: [
   *         {
   *           type: "object",
   *           properties: {
   *             streetName: { type: "string" },
   *             city: { type: "string" }
   *           }
   *         },
   *         { type: "null" }
   *       ]
   *     }
   *   }
   * };
   *
   * const result = flattenSchema(schema);
   * // result = {
   * //   required: ["name"],
   * //   properties: {
   * //     "name": { type: "string" },
   * //     "location_streetName": { type: "string" },
   * //     "location_city": { type: "string" }
   * //   }
   * // }
   *
   * @description
   * This function processes JSON schemas to produce a flat structure where nested properties
   * are represented by their full paths. This is particularly useful for form generation where
   * field names need to be unique and descriptive of their hierarchy.
   *
   * The function has been enhanced to handle the `anyOf` keyword, which allows a property to
   * conform to one of several schemas. When encountering `anyOf`, the function recursively
   * processes each schema in the `anyOf` array, merging their required fields and properties.
   *
   * The changes made to handle `anyOf` ensure that all possible variations of the schema are
   * considered. This can lead to a comprehensive set of properties and required fields, but it
   * may also result in more complex and potentially redundant structures.
   *
   * @param {JsonSchema} schema - The JSON schema to be flattened.
   * @param {string} [prefix=''] - A prefix to be added to each property name during the flattening process.
   * @returns {Object} An object containing two properties:
   *  - `required`: An array of strings representing the full paths of required fields.
   *  - `properties`: A flat object with full paths as keys and corresponding schema properties as values.
   *
   *
   */
  private flattenSchema = (schema: JsonSchema, prefix = '') => {
    let required: FormRequired = [];
    let properties: FormProperties = {};

    if (schema.required) {
      required = [
        ...required,
        ...schema.required.map((reqName) => prefix.concat(reqName.toString())),
      ];
    }

    if (schema.properties) {
      for (const key in schema.properties) {
        const item = schema.properties[key];
        const prefixedKey = prefix.concat(key);

        if ('anyOf' in item) {
          let combinedRequired: FormRequired = [];
          let combinedProperties: FormProperties = {};

          for (const subSchema of item.anyOf!) {
            const childSchema = this.flattenSchema(
              subSchema as JsonSchema,
              `${prefixedKey}${FORM_FLATTEN_SEPARATOR}`,
            );

            combinedRequired = [...combinedRequired, ...childSchema.required];
            combinedProperties = {
              ...combinedProperties,
              ...childSchema.properties,
            };
          }

          required = [...required, ...combinedRequired];
          properties = {
            ...properties,
            ...combinedProperties,
          };
        } else if (this.isJsonSchema(item)) {
          const childSchema = this.flattenSchema(
            item as JsonSchema,
            `${prefixedKey}${FORM_FLATTEN_SEPARATOR}`,
          );

          required = [...required, ...childSchema.required];
          properties = {
            ...properties,
            ...childSchema.properties,
          };
        } else {
          properties = {
            ...properties,
            [prefixedKey]: item,
          };
        }
      }
    }

    // Deduplicate required fields
    required = [...new Set(required)];

    return { required, properties };
  };

  /**
   * FIX BACKEND ISSUE
   * because the required property is not always or well used by the backend 
   * so we can only trust on field and deduce the required state 
   * Applied rule
      - this is a value
      - and does't own a nullable property or own it defined to false
      - is not readonly
   * 
   * Todo: try to move it into the preprocess file scripts/swagger.js
   * 
   * @returns A required[] array containing a collection of required field
   * 
   * @note to be deleted, this is deprecated and should not be used anymore.
   */
  private fixRequired = (schema: JsonSchema): JsonSchema => {
    // Create a shallow copy of the input schema
    let ref: JsonSchema = { ...schema };

    // Check if the schema is a valid JsonSchema object
    if (this.isJsonSchema(schema)) {
      // Ensure schema.properties is defined before proceeding
      if (schema.properties) {
        // Filter properties to determine which should be required
        const realRequired = Object.keys(schema.properties).filter((key) => {
          const propitem = schema.properties?.[key];

          if (!propitem) return false;

          const isParent = typeof propitem === 'object' && 'properties' in propitem;
          const hasNullableProp = 'nullable' in propitem;
          const isNullable = hasNullableProp && propitem.nullable === true;
          const isReadonly = 'readOnly' in propitem && propitem.readOnly === true;

          // A property is considered required if it's not a parent object,
          // not readonly, and either doesn't have a nullable property or is not nullable
          return !isParent && !isReadonly && (!hasNullableProp || !isNullable);
        });

        // Update the schema with the new required fields
        ref = {
          ...ref,
          required: realRequired,
        };

        // Recursively process nested schemas
        for (const key in schema.properties) {
          const item = schema.properties[key];
          if (this.isJsonSchema(item)) {
            const subFormItems = this.fixRequired(item);

            // Update the schema with the processed nested schema
            ref = {
              ...ref,
              properties: {
                ...ref.properties,
                [key]: subFormItems,
              },
            };
          }
        }
      }
    }

    return ref;
  };

  /**
   * Typescript guard to allow to use a specific type as a not specific type
   */
  private isJsonSchema = (obj: object): obj is JsonSchema => {
    return 'type' in obj && obj.type === 'object' && 'properties' in obj;
  };
}

export default FormService;
