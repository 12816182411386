import { FC, PropsWithChildren } from 'react';
import { createCss } from 'src/web/common/styles/createCss';
import Card from 'src/web/design_system/Card';

const contentCardStyle = createCss(`
padding:0px !important;
`);
const CardDetails: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card.Container>
      <Card.Content className={contentCardStyle}>{children}</Card.Content>
    </Card.Container>
  );
};
export default CardDetails;
