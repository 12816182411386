import { ComponentType, FC } from 'react';
import { type SvgIconProps } from '@mui/material';
import {
  Cloud,
  Computer,
  DirectionsCar,
  Delete,
  Remove,
  Handshake,
  Payment,
  Person,
  Smartphone,
  Add,
  Close,
  Edit,
  EditNote,
  Dashboard,
  SupervisorAccount,
  SettingsInputAntenna,
  CorporateFare,
  Notifications,
  Upload,
  Download,
  Logout,
  ArrowForward,
  ArrowBack,
  Description,
  ArrowDropDown,
  ArrowDropUp,
  Assignment,
  AssignmentInd,
  AssignmentReturned,
  AssignmentTurnedIn,
  AccountCircle,
  Euro,
  AutoAwesome,
  Login,
  FilterList,
  Check,
  SportsScore,
  Groups,
  ShapeLine,
  DynamicForm,
  Receipt,
  AllOut,
  CropSquare as Random,
  ErrorOutline,
  DriveFolderUpload,
  MoreVert,
  MoreHoriz,
  Settings,
  West,
  East,
  Undo,
  Place,
  AlternateEmail,
  Visibility,
  VisibilityOff,
  CheckBox,
  CheckBoxOutlineBlank,
  AdminPanelSettings,
  CheckCircle,
  CircleOutlined,
  ChevronLeft,
  ChevronRight,
  Language,
  OpenInNew,
  RadioButtonUnchecked,
  RadioButtonChecked,
  HelpOutline,
  Business,
} from '@mui/icons-material';

import { createCss } from 'src/web/common/styles/createCss';

// todo: declare more accurately what we accept as values by creating a custom props mapping
export interface IconProps extends Pick<SvgIconProps, 'color'> {
  size?: 'small' | 'medium' | 'large';
}

const iconAlignment = createCss(`
  vertical-align: middle;
`);

// High order React component to handle props
const withWrapper = (Element: ComponentType<SvgIconProps>) => {
  const IconComponent: FC<IconProps> = (props) => {
    const { size, ...restProps } = props;

    return <Element className={iconAlignment} {...restProps} fontSize={size} />;
  };
  return IconComponent;
};

export default {
  // UI
  Add: withWrapper(Add),
  Close: withWrapper(Close),
  Edit: withWrapper(Edit),
  EditVariant: withWrapper(EditNote),
  Import: withWrapper(Upload),
  Export: withWrapper(Download),
  Logout: withWrapper(Logout),
  Login: withWrapper(Login),
  Back: withWrapper(ArrowBack),
  Next: withWrapper(ArrowForward),
  DropUp: withWrapper(ArrowDropUp),
  DropDown: withWrapper(ArrowDropDown),
  File: withWrapper(Description),
  Money: withWrapper(Euro),
  Autofill: withWrapper(AutoAwesome),
  Filter: withWrapper(FilterList),
  Check: withWrapper(Check),
  CheckCricle: withWrapper(CheckCircle),
  CircleOutline: withWrapper(CircleOutlined),
  CheckBox: withWrapper(CheckBox),
  CheckBoxOutline: withWrapper(CheckBoxOutlineBlank),
  Delete: withWrapper(Delete),
  Remove: withWrapper(Remove),
  StartFlag: withWrapper(SportsScore),
  Random: withWrapper(Random),
  Done: withWrapper(Check),
  Undo: withWrapper(Undo),
  Fail: withWrapper(ErrorOutline),
  uploadFile: withWrapper(DriveFolderUpload),
  Menu: withWrapper(MoreVert),
  MenuVariant: withWrapper(MoreHoriz),
  From: withWrapper(West),
  To: withWrapper(East),
  Location: withWrapper(Place),
  Email: withWrapper(AlternateEmail),
  VisibleOn: withWrapper(Visibility),
  VisibleOff: withWrapper(VisibilityOff),
  Boarding: withWrapper(AssignmentInd),
  Business: withWrapper(Business),

  Language: withWrapper(Language),

  NewTab: withWrapper(OpenInNew),

  RadioUncheck: withWrapper(RadioButtonUnchecked),
  RadioCheck: withWrapper(RadioButtonChecked),
  Help: withWrapper(HelpOutline),
  // nav
  Account: withWrapper(AccountCircle),
  Dashboard: withWrapper(Dashboard),
  User: withWrapper(SupervisorAccount),
  LegalEntity: withWrapper(CorporateFare),
  Profile: withWrapper(Person),
  Notification: withWrapper(Notifications),
  Task: withWrapper(Assignment),
  Assign: withWrapper(AssignmentInd),
  AssignmentIn: withWrapper(AssignmentTurnedIn),
  AssignmentOut: withWrapper(AssignmentReturned),
  Settings: withWrapper(Settings),
  ChevronRight: withWrapper(ChevronRight),
  ChevronLeft: withWrapper(ChevronLeft),

  // assets
  Person: withWrapper(Person),
  Vehicle: withWrapper(DirectionsCar),
  Computer: withWrapper(Computer),
  Licence: withWrapper(Cloud),
  Service: withWrapper(Handshake),
  Telephone: withWrapper(Smartphone),
  TelephoneLine: withWrapper(SettingsInputAntenna),
  Card: withWrapper(Payment),

  // roles
  RoleUser: withWrapper(SupervisorAccount),
  RolePerson: withWrapper(Person),
  RoleAsset: withWrapper(AllOut),
  RoleVehicle: withWrapper(DirectionsCar),
  RoleComputer: withWrapper(Computer),
  RoleLicence: withWrapper(Cloud),
  RoleService: withWrapper(Handshake),
  RoleTelephone: withWrapper(Smartphone),
  RoleTelephoneLine: withWrapper(SettingsInputAntenna),
  RoleCard: withWrapper(Payment),
  RoleNotification: withWrapper(Notifications),
  RoleLegalEntityNotification: withWrapper(Settings),
  RoleDistributor: withWrapper(Groups),
  RoleLegalEntity: withWrapper(AdminPanelSettings),
  RoleBluePrint: withWrapper(ShapeLine),
  RoleContractualCommitment: withWrapper(Receipt),
  RoleCustomForm: withWrapper(DynamicForm),
};
