import ButtonDefault from './buttonDefault';
import ButtonDropdown from './buttonDropdown';
import ButtonIcon from './buttonIcon';
import ButtonSubmit from './buttonSubmit';

export default {
  Default: ButtonDefault,
  Submit: ButtonSubmit,
  Dropdown: ButtonDropdown,
  Icon: ButtonIcon,
};
