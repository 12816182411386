/**
 * This file is not generated!
 * This allow to write derived types and values
 * from generated swagger files
 */
import { PartialRecord } from 'core/common/types/helpers';
import * as swaggerTypes from './generated_swagger.types';
import swaggerSchemas from './generated_swagger.schemas';

export { swaggerTypes };

type AllowedContentType = 'application/ld+json' | 'application/json';
type JsonLDContentType = 'application/ld+json';
type FormDataContentType = 'multipart/form-data';

/**
 * According what we can see in the Swagger
 */
export type ExhaustiveValue = string | boolean | number | undefined | null | string[];

/**
 * @todo to fix but currently not enough time to do it well
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const schemas: Schemas = swaggerSchemas;

/**
 * Bind generated interfaces to custom generated schemas list
 */
export type Schemas = {
  [P in SwaggerPathsKeys]: {
    [M in SwaggerPathsMethods<P>]: {
      body: {
        schema: JsonSchema;
      };
      parameters: JsonSchemaParams<P, M>[];
    };
  };
};
export interface JsonSchemaParams<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>> {
  name: keyof SwaggerRequestQuery<P, M>;
  in: keyof SwaggerRequestParams<P, M>;
  description: string;
  required: boolean;
  deprecated: boolean;
  allowEmptyValue: boolean;
  schema: SwaggerJsonSchemaProperty;
  style: string;
  explode: boolean;
  allowReserved: boolean;
}

/**
 * @typedef {Object} JsonSchema
 * @property {string} type - The type of the schema (e.g., "object", "string").
 * @property {(string | number | symbol)[]} [required] - An array of required field names.
 * @property {Record<string | number | symbol, SwaggerJsonSchemaProperty | JsonSchema>} [properties] - An object representing the schema properties.
 * @property {(SwaggerJsonSchemaProperty | JsonSchema)[]} [anyOf] - An array of schemas for the `anyOf` keyword.
 *
 * @note The properties field is set as optional because not all JSON schemas will have properties. JSON Schema can describe various
 * types of data structures, including primitive types (e.g., strings, numbers), arrays, and objects. When a schema describes a
 * primitive type or an array, it does not need the properties field.
 *
 * TODO: Define or infer and rename because this is only body schema.
 */
export type JsonSchema = {
  type: string;
  required?: (string | number | symbol)[];
  properties?: Record<string | number | symbol, SwaggerJsonSchemaProperty | JsonSchema>;
  anyOf?: (SwaggerJsonSchemaProperty | JsonSchema)[];
};

/**
 * Schema property used in the Swagger Json
 * Partial because the object is not fully described but only useful or predictive values
 * This type is used both for 'paths' and 'components' section but this is not sure they really use the same shape of data...
 */
export type SwaggerJsonSchemaProperty<T = ExhaustiveValue> = {
  [key: `@${string}`]: unknown;
  type?: string;
  description?: string;
  format?: string;
  pattern?: string;
  readOnly?: boolean;
  nullable?: boolean;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  example?: T;
  default?: T;
  enum?: T[];
  items?: SwaggerJsonSchemaProperty;
};

/**
 * Swagger paths list
 */
export type SwaggerPathsKeys = keyof swaggerTypes.paths;

/**
 * Swagger methods of a path
 */
export type SwaggerPathsMethods<P extends SwaggerPathsKeys> = keyof swaggerTypes.paths[P];

/**
 * Swagger params of a path
 */
export type SwaggerRequestParams<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'parameters', infer Par> ? Par : never;

/**
 * Swagger request path variable
 * @example /api/subject/{id}
 */
// export type SwaggerRequestPathVar<
//   P extends SwaggerPathsKeys,
//   M extends SwaggerPathsMethods<P>,
// > = swaggerTypes.paths[P][M] extends PartialRecord<'parameters', infer Par>
//   ? Par extends PartialRecord<'path', infer Pv>
//     ? Pv
//     : never
//   : never;

// Todo: quick fix the time the backend solve the typing issue
export type SwaggerRequestPathVar<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'parameters', infer Par>
  ? Record<string, string>
  : never;

/**
 * Swagger request query params
 */
export type SwaggerRequestQuery<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'parameters', infer Par>
  ? Par extends PartialRecord<'query', infer Q>
    ? Q
    : never
  : never;

/**
 * Swagger request headers params
 */
export type SwaggerRequestHeaders<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'parameters', infer Par>
  ? Par extends PartialRecord<'header', infer H>
    ? H
    : never
  : never;

/**
 * Swagger request body params
 */
export type SwaggerRequestBody<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'requestBody', infer B>
  ? B extends PartialRecord<'content', infer C>
    ? C
    : never
  : never;

/**
 * Swagger request body params
 */
export type SwaggerRequestBodyJsonContent<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = SwaggerRequestBody<P, M> extends PartialRecord<AllowedContentType, infer JsonContent>
  ? JsonContent extends PartialRecord<string, infer C>
    ? {
        [Property in keyof JsonContent as string]: JsonContent[Property];
      }
    : JsonContent
  : never;

/**
 * Swagger request body params
 */
export type SwaggerRequestBodyMediaContent<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = SwaggerRequestBody<P, M> extends PartialRecord<FormDataContentType, infer Content>
  ? Content extends PartialRecord<string, infer C>
    ? Record<string, C>
    : Content
  : never;

/**
 * Type helper to filter operation parameters from Swagger
 */
export type SwaggerRequestOperations<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M];

/**
 * Type helper to get the request response data from Swagger
 * @description
 * Check if every key exists when exploring operation?.responses?.200?.content?.['application/ld+json'] to finally get 'hydra:member'
 * Todo: maybe an issue here. The number in "Rs extends Record<number, infer Ok>" can be some http code number. But we want to target either 200 and 201.
 * But define both returns "never" when the expected is one or the other. Typescript seems to want something too accurate... But if we set only 200 that works.
 */
export type SwaggerRequestResponse<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'responses', infer Rs>
  ? Rs extends Record<number, infer Ok>
    ? Ok extends PartialRecord<'content', infer C>
      ? C extends PartialRecord<JsonLDContentType, infer JLD>
        ? JLD extends PartialRecord<'hydra:member', infer Mb>
          ? Mb
          : JLD
        : never
      : never
    : never
  : never;

export type SwaggerRequestFullResponse<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'responses', infer Rs>
  ? Rs extends Record<200, infer Ok>
    ? Ok extends PartialRecord<'content', infer C>
      ? C extends PartialRecord<JsonLDContentType, infer JLD>
        ? JLD
        : undefined
      : undefined
    : undefined
  : undefined;

export type SwaggerComponentSchemas = swaggerTypes.components extends PartialRecord<
  'schemas',
  infer Schema
>
  ? Schema
  : unknown;

export type SwaggerComponentSchemasProperties<Mod extends keyof SwaggerComponentSchemas> =
  swaggerTypes.components extends PartialRecord<'schemas', infer Schema>
    ? Schema extends PartialRecord<Mod, infer P>
      ? P
      : unknown
    : unknown;

export type SwaggerComponentSchemasProperty<
  Mod extends keyof SwaggerComponentSchemas,
  Ps extends keyof SwaggerComponentSchemasProperties<Mod>,
> = swaggerTypes.components extends PartialRecord<'schemas', infer Schema>
  ? Schema extends PartialRecord<Mod, infer K>
    ? K extends PartialRecord<Ps, infer V>
      ? V
      : unknown
    : unknown
  : unknown;

export type SwaggerRequestResponseSearch<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'responses', infer Rs>
  ? Rs extends Record<200, infer Ok>
    ? Ok extends PartialRecord<'content', infer C>
      ? C extends PartialRecord<JsonLDContentType, infer JLD>
        ? JLD extends PartialRecord<'hydra:search', infer S>
          ? S extends PartialRecord<'hydra:mapping', infer Mp>
            ? Mp
            : never
          : never
        : never
      : never
    : never
  : never;

export type SwaggerRequestResponseSearchKeys<
  P extends SwaggerPathsKeys,
  M extends SwaggerPathsMethods<P>,
> = swaggerTypes.paths[P][M] extends PartialRecord<'responses', infer Rs>
  ? Rs extends Record<200, infer Ok>
    ? Ok extends PartialRecord<'content', infer C>
      ? C extends PartialRecord<JsonLDContentType, infer JLD>
        ? JLD extends PartialRecord<'hydra:search', infer S>
          ? S extends PartialRecord<'hydra:mapping', infer Mp>
            ? Mp extends { variable: string }[]
              ? Mp[number]['variable']
              : never
            : never
          : never
        : never
      : never
    : never
  : never;
