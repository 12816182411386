import { FC, PropsWithChildren } from 'react';
import Displayer from 'src/web/design_system/Displayer';
import GuiDivider from 'src/web/design_system/Divider';

interface DividerDatasProps {
  color?: string;
  dashed?: boolean;
  align?: 'center' | 'right' | 'left';
}

const DividerDatas: FC<PropsWithChildren<DividerDatasProps>> = ({
  color,
  dashed,
  children,
  align = 'center',
}) => {
  return (
    <Displayer bottomSpacing={2} topSpacing={2}>
      <GuiDivider align={align} color={color} dashed={dashed}>
        {children}
      </GuiDivider>
    </Displayer>
  );
};
export default DividerDatas;
