import { FC, PropsWithChildren, ReactNode } from 'react';
import Card from 'src/web/components/Card';

interface InventoryCardContainerProps {
  primaryContent?: ReactNode;
  secondaryContent?: ReactNode;
}

const InventoryCardContainer: FC<PropsWithChildren<InventoryCardContainerProps>> = ({
  children,
}) => {
  return <Card.Container>{children}</Card.Container>;
};

export default InventoryCardContainer;
