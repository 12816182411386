import { FC, PropsWithChildren } from 'react';
import GuiCard from 'src/web/design_system/NewCard';
import { CardToolbarProps as GuiCardToolbarProps } from 'src/web/design_system/NewCard/cardToolbar';

type CardToolbarProps = Omit<GuiCardToolbarProps, 'padding'>;

const CardToolbar: FC<PropsWithChildren<CardToolbarProps>> = (props) => {
  return <GuiCard.Toolbar {...props} />;
};

export default CardToolbar;
