import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCore } from 'src/web/common/core';

const useUserDetail = () => {
  const { userUseCase } = useCore();
  const { id } = useParams();
  const [userData, setUserData] = useState<Awaited<ReturnType<typeof userUseCase.getUser>>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      userUseCase.getUser(id).then((data) => {
        if (data) {
          setUserData(data);
        }

        setIsLoading(false);
      });
    }
  }, [id]);

  return {
    isLoading,
    data: userData,
  };
};

export default useUserDetail;
