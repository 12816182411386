import { useState, useCallback, useEffect } from 'react';
import { useCore } from 'src/web/common/core';

export const useGetMileageFromVehicleId = (id: string) => {
  const { setVehicleUseCase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] =
    useState<Awaited<ReturnType<typeof setVehicleUseCase.getVehicleMileage>>>(undefined);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const result = await setVehicleUseCase.getVehicleMileage(id);
    if (result) {
      setData(result);
    }
    setIsLoading(false);
  }, [id, setVehicleUseCase]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    data,
    isLoading,
    loadData,
  };
};
