import AssetAssignmentEntity from './assetAssignment.entity';
import AttachmentSiteEntity from './attachmentSite.entiry';
import CardEntity from './card.entity';
import ComputerEntity from './computer.entity';
import EnumEntity from './enum.entity';
import LegalEntityEntity from './legalEntity.entity';
import LicenceEntity from './licence.entity';
// import NotificationsConditionEntity from './notificationsCondition.entity';
// import NotificationsConfigEntity from './notificationsConfig.entity';
// import NotificationsNotificationEntity from './notificationsNotification.entity';
// import NotificationsSubscriptionEntity from './notificationsSubscription.entity';
import PdfRenderEntity from './pdfRender.entity';
import PersonEntity from './person.entity';
import ServiceEntity from './service.entity';
import TaskEntity from './task.entity';
import TaskGroupEntity from './taskGroup.entity';
import TaskItemEntity from './taskItem.entity';
import TelephoneEntity from './telephone.entity';
import TelephoneLineEntity from './telephoneLine.entity';
import UserEntity from './user.entity';
import VehicleEntity from './vehicle.entity';
import CustomAssetEntity from './customAsset.entity';
import CustomAssetCategoryEntity from './customAssetCategory.entity';

export default {
  personEntity: PersonEntity,
  vehicleEntity: VehicleEntity,
  computerEntity: ComputerEntity,
  licenceEntity: LicenceEntity,
  serviceEntity: ServiceEntity,
  telephoneEntity: TelephoneEntity,
  telephoneLineEntity: TelephoneLineEntity,
  cardEntity: CardEntity,
  customAssetEntity: CustomAssetEntity,
  customAssetCategoryEntity: CustomAssetCategoryEntity,
  userEntity: UserEntity,
  taskEntity: TaskEntity,
  taskItemEntity: TaskItemEntity,
  taskGroupEntity: TaskGroupEntity,
  // notificationsConditionEntity: NotificationsConditionEntity,
  // notificationsSubscriptionEntity: NotificationsSubscriptionEntity,
  // notificationsNotificationEntity: NotificationsNotificationEntity,
  // notificationsConfigEntity: NotificationsConfigEntity,
  legalEntityEntity: LegalEntityEntity,
  assetAssignmentEntity: AssetAssignmentEntity,
  pdfRenderEntity: PdfRenderEntity,
  attachmentSiteEntity: AttachmentSiteEntity,
  enumEntity: EnumEntity,
};
