import { FC, useMemo } from 'react';
import useBoarding from './hooks/useBoarding';
import Skeleton from 'src/web/design_system/Skeleton';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';
import Card from 'src/web/components/Card';
import FormInput from 'src/web/components/FormInput';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import Icon from 'src/web/components/Icon';
import Button from 'src/web/components/Button';
import { useCore } from 'src/web/common/core';
import Grid from 'src/web/design_system/Grid';
import { isString, isStringNotEmpty } from 'core/common/utils/predicatesType';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import { TaskTopics } from 'core/common/constants';
import Toolbar from 'src/web/design_system/Toolbar';
import useTranslate from 'src/web/common/translate/useTranslate';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';
import { useParams, useSearchParams } from 'react-router-dom';

interface OnboardingPageProps {
  isEdition?: boolean;
}

const OnboardingPage: FC<OnboardingPageProps> = ({ isEdition }) => {
  const { timeService } = useCore();
  const {
    isDirty,
    personData,
    isLoading,
    taskRows,
    taskTypes,
    handleAddTask,
    personLimitAction,
    handleSubmit,
    handleSearchRowOwner,
    handleDeleteRow,
    isSubmitLoading,
  } = useBoarding(TaskTopics.peopleOnBoarding);
  const { translate } = useTranslate();
  // Build displayed person name
  const personFullName = useMemo(() => {
    return [personData?.lastname, personData?.firstname].filter((d) => !!d).join(' ');
  }, [personData]);

  // Build displayed person entry date
  const personEntryDate = useMemo(() => {
    return personData?.entryAt
      ? timeService.format(timeService.fromBackend(personData.entryAt), 'short')
      : '';
  }, [personData]);

  // Check is the task is not the last one
  const canDeleteRow = useMemo(() => taskRows.length > 1, [taskRows]);

  // can submit the page
  const canSubmit = useMemo((): boolean => {
    const formSubmittable = taskRows.every((row) => {
      return Object.keys(row.items).every((key) => {
        return Object.keys(row.items[key].errors).length === 0;
      });
    });

    return isDirty && formSubmittable && !isLoading;
  }, [taskRows, isDirty, isLoading]);

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { id } = useParams();

  return (
    <>
      <PageHeaderBasic
        title={isEdition ? translate('onBoarding.edit.title') : translate('onBoarding.add.title')}
        subtitle={
          <Skeleton isLoading={isLoading}>
            <Text variant="textLarge">
              {translate('onBoarding.personEntryDate.title', {
                person: personFullName,
                date: personEntryDate,
              })}
            </Text>
          </Skeleton>
        }
      />
      <Button.BackNavigate
        to={`${ROUTE_PERSON}/${id}?${queryString}`}
        label={translate('global.navigateBack.details.label')}
      />

      <Card.Container>
        <Card.Toolbar
          secondaryContent={
            <ButtonIcon onClick={() => handleAddTask()} edge="end">
              <Icon.Add />
            </ButtonIcon>
          }
        >
          <Text variant="sectionTitle">{translate('onBoarding.task.title')}</Text>
        </Card.Toolbar>
        <Card.Content>
          <Skeleton isLoading={isLoading} width="100%" height="5rem">
            <Displayer innerSpacing={2}>
              {taskRows.map((taskRow) => (
                <Toolbar
                  noGutters
                  key={taskRow.id}
                  primaryContent={
                    <Grid.Container>
                      <Grid.Item size={3}>
                        <FormInput.Select
                          label={translate('boarding.task.label')}
                          value={isString(taskRow.items.task.value) ? taskRow.items.task.value : ''}
                          options={taskTypes.map((option) => option.value)}
                          renderOptionItem={(value) => ({
                            label: translate(
                              `tasks.task.option.${
                                taskTypes.find((el) => el.value === value)?.label
                              }.label`,
                            ),
                          })}
                          renderValue={(value) =>
                            translate(
                              `tasks.task.option.${
                                taskTypes.find((el) => el.value === value)?.label
                              }.label`,
                            )
                          }
                          onChange={(value) => {
                            if (isString(value)) {
                              taskRow.items.task.handleChange(value);
                            }
                          }}
                          isFullWidth
                          isRequired={taskRow.items.task.required}
                          isError={taskRow.isDirty && taskRow.items.task.hasErrors}
                        />
                      </Grid.Item>
                      <Grid.Item size={3}>
                        <Autocomplete
                          noOptionsText={translate('global.autocomplete.noOptions')}
                          value={
                            isStringNotEmpty(taskRow.items.owner.value)
                              ? {
                                  label: taskRow.items.owner.value,
                                  value: taskRow.items.owner.value,
                                }
                              : undefined
                          }
                          label={translate('boarding.owner.label')}
                          onChange={(value) => {
                            if (Array.isArray(value)) {
                              taskRow.items.owner.handleChange(value[0]);
                            } else {
                              taskRow.items.owner.handleChange(value);
                            }
                          }}
                          isRequired={taskRow.items.owner.required}
                          renderOption={(item) => item.label}
                          selectValue={(item) => item.value}
                          isFullWidth
                          onSearch={(value) => handleSearchRowOwner(taskRow.id, value)}
                          options={taskRow.owners}
                          isError={taskRow.isDirty && taskRow.items.owner.hasErrors}
                        />
                      </Grid.Item>
                      <Grid.Item size={3}>
                        <FormInput.Date
                          label={translate('boarding.limit.label')}
                          value={
                            isStringNotEmpty(taskRow.items.deadline.value)
                              ? timeService.fromBackend(taskRow.items.deadline.value)
                              : undefined
                          }
                          onChange={(value) => {
                            if (value) {
                              taskRow.items.deadline.handleChange(timeService.toBackend(value));
                            }
                          }}
                          isRequired={taskRow.items.deadline.required}
                          minDate={timeService.now()}
                          maxDate={personLimitAction}
                          error={taskRow.isDirty && taskRow.items.deadline.hasErrors}
                        />
                      </Grid.Item>
                      <Grid.Item size={3}>
                        <FormInput.Text
                          label={translate('boarding.comment.label')}
                          value={
                            isString(taskRow.items.comment.value) ? taskRow.items.comment.value : ''
                          }
                          onChange={(value) => taskRow.items.comment.handleChange(value)}
                          isRequired={taskRow.items.comment.required}
                          error={taskRow.isDirty && taskRow.items.comment.hasErrors}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  }
                  secondaryContent={
                    <ButtonIcon
                      title={translate('onBoarding.delete.tooltip')}
                      onClick={() => handleDeleteRow(taskRow.id)}
                      disabled={!canDeleteRow}
                      edge="end"
                    >
                      <Icon.Delete />
                    </ButtonIcon>
                  }
                />
              ))}
            </Displayer>
          </Skeleton>
        </Card.Content>
        <Card.Footer>
          <Button.Custom
            isLoading={isSubmitLoading}
            onClick={() => handleSubmit()}
            fullWidth={false}
            disabled={!canSubmit}
          >
            Enregistrer
          </Button.Custom>
        </Card.Footer>
      </Card.Container>
    </>
  );
};

export default OnboardingPage;
