import { FC, useState } from 'react';
import PopoverMenu, { PopoverMenuItem } from 'src/web/components/PopoverMenu';

interface CardMenuItem extends PopoverMenuItem {
  closeOnClick?: boolean;
}

export interface CardMenuProps {
  menuItems: CardMenuItem[];
}

const CardMenu: FC<CardMenuProps> = ({ menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const buildedMenuItems: PopoverMenuItem[] = menuItems.map((item) => ({
    label: item.label,
    onClick: () => {
      item.onClick();
      if (item.closeOnClick !== false) {
        setIsMenuOpen(false);
      }
    },
  }));

  return (
    <PopoverMenu
      isOpen={isMenuOpen}
      items={buildedMenuItems}
      onChange={(state) => setIsMenuOpen(state)}
    />
  );
};

export default CardMenu;
