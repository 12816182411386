import { FC, useState } from 'react';
import * as ROUTES from 'src/web/common/router/constantes';
import useLogout from 'src/web/common/auth/useLogout';
import Icon from 'src/web/components/Icon';
import PopoverMenu from 'src/web/design_system/PopoverMenu';
import Menu from 'src/web/design_system/Menu';
import {
  HEADER_ICON_COLOR,
  HEADER_ICON_SIZE,
  HEADER_MENU_ICON_SIZE,
  HEADER_MENU_ICON_COLOR,
} from './constants';
import { useNavigate } from 'react-router-dom';
import Translate from 'src/web/common/translate/translate.component';
import { nanoid } from 'nanoid';
import useTranslate from 'src/web/common/translate/useTranslate';
import { RolesEnum } from 'src/core/common/constants';
import GrantRoles from 'src/web/components/Grant';

const MENU_OPTIONS_1 = [
  {
    id: nanoid(),
    label: <Translate id="global.nav.myprofile.button" />,
    icon: <Icon.Profile size={HEADER_MENU_ICON_SIZE} color={HEADER_MENU_ICON_COLOR} />,
    path: ROUTES.ROUTE_ACCOUNT_MYPROFILE,
    role: RolesEnum.ROLE_USER,
  },
  {
    id: nanoid(),
    label: <Translate id="global.nav.myLegalEntity.button" />,
    icon: <Icon.RoleLegalEntity size={HEADER_MENU_ICON_SIZE} color={HEADER_MENU_ICON_COLOR} />,
    path: ROUTES.ROUTE_ACCOUNT_LEGALENTITY,
    role: RolesEnum.ROLE_MY_LEGAL_ENTITY,
  },
  {
    id: nanoid(),
    label: <Translate id="global.nav.users.button" />,
    icon: <Icon.User size={HEADER_MENU_ICON_SIZE} color={HEADER_MENU_ICON_COLOR} />,
    path: ROUTES.ROUTE_USERS,
    role: RolesEnum.ROLE_ADMIN_USER,
  },
];

const HeaderAccount: FC = () => {
  const [open, setOpen] = useState(false);
  const { handleLogout } = useLogout();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const handleClose = () => {
    setOpen(false);
  };

  const handleNav = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <PopoverMenu
      isOpen={open}
      onChange={(state) => setOpen(state)}
      horizontal="right"
      icon={<Icon.Close size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
      iconActive={<Icon.Account size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />}
      title={translate('global.nav.account.button')}
    >
      <Menu.Container>
        {MENU_OPTIONS_1.map((option) => (
          <GrantRoles includes={[option.role]} key={option.id}>
            <Menu.Item
              startIcon={option.icon}
              label={option.label}
              onClick={() => handleNav(option.path)}
              inset={false}
              size={'small'}
            />
          </GrantRoles>
        ))}

        <Menu.Divider />

        <Menu.Item
          label={<Translate id="global.nav.logout.button" />}
          startIcon={<Icon.Logout size={HEADER_MENU_ICON_SIZE} color={HEADER_MENU_ICON_COLOR} />}
          onClick={handleLogout}
          inset={false}
          size={'small'}
        />
      </Menu.Container>
    </PopoverMenu>
  );
};

export default HeaderAccount;
