import { cx, css } from '@emotion/css';
import { useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export interface CardContentProps {
  className?: string;
  padding?: number;
  compact?: boolean;
  disablePaddingX?: boolean;
  disablePaddingY?: boolean;
}

const DEFAULT_PADDING = 3;
const DEFAULT_PADDING_COMPACT = 1.5;

const cardContentStyle = (padding: string, noPaddingX: boolean, noPaddingY: boolean) => css`
  ${!noPaddingX &&
  `
    padding-left: ${padding};
    padding-right: ${padding};
  `}
  ${!noPaddingY &&
  `
    &:first-child {
      padding-top: ${padding};
    }
  `}
  ${!noPaddingY &&
  `
    &:last-child {
      padding-bottom: ${padding};
    }
  `}
`;

const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  children,
  className,
  padding,
  compact,
  disablePaddingX,
  disablePaddingY,
}) => {
  const theme = useTheme();
  const computedPadding = theme.spacing(
    padding !== undefined ? padding : compact ? DEFAULT_PADDING_COMPACT : DEFAULT_PADDING,
  );

  return (
    <div
      className={cx(
        cardContentStyle(computedPadding, !!disablePaddingX, !!disablePaddingY),
        className,
      )}
    >
      {children}
    </div>
  );
};

export default CardContent;
