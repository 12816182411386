import { Box, Toolbar as MuiToolbar } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface CardToolbarProps {
  className?: string;
  primaryContent?: ReactNode;
  secondaryContent?: ReactNode;
  compact?: boolean;
  noGutters?: boolean;
}

const Toolbar: FC<CardToolbarProps> = ({
  className,
  primaryContent,
  secondaryContent,
  compact,
  noGutters,
}) => {
  const variant = compact ? 'dense' : 'regular';

  return (
    <MuiToolbar variant={variant} className={className} disableGutters={noGutters}>
      {primaryContent}
      <Box sx={{ flexGrow: 1 }} />
      {secondaryContent}
    </MuiToolbar>
  );
};

export default Toolbar;
