import styled from '@emotion/styled';
import DrawerDefault from '../../../design_system/Drawer/drawerDefault';
import { useTheme } from 'src/web/common/theme';

const openedMixin = (drawerOpenWidth = 279) => ({
  width: drawerOpenWidth,
  '& ::-webkit-scrollbar': {
    width: '6px',
  },
  '& ::-webkit-scrollbar-track': {
    display: 'none',
  },
  '& ::-webkit-scrollbar-thumb': {
    display: 'none',
  },
});

const closedMixin = (drawerCloseWidth = 87) => ({
  width: drawerCloseWidth,
  '& ::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
});

const DrawerPartial = styled(DrawerDefault, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open, drawerOpenWidth, drawerCloseWidth }) => {
  const { theme } = useTheme();
  return {
    '& .MuiDrawer-paper': {
      transition: 'width 0.3s cubic-bezier(.86,.56,.24,.98)',
      overflow: 'visible',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      borderRight: `1px dashed  ${theme.colors.activeAction}25`,
      boxShadow: 'none',
      '&:hover': {
        '& ::-webkit-scrollbar-thumb': {
          display: 'block',
          background: theme.palettes.neutral?.[400],
          borderRadius: theme.shape.defaultRadius,
        },
      },
      ...(open && openedMixin(drawerOpenWidth)),
      ...(!open && closedMixin(drawerCloseWidth)),
    },
  };
});

export default DrawerPartial;
