import React, { FC, useRef } from 'react';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';
import Form from 'src/web/components/FormInput';
import Button from 'src/web/design_system/Button';
import Displayer from 'src/web/design_system/Displayer';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import { usePostMileageVehicle } from 'src/web/pages/assets/detail/hooks/vehicle/usePostMileageVehicle';

interface FormEditMileageProps {
  assetIri?: string;
  reload: () => void;
  minValue?: number;
  minDate?: Date | 'now';
  lastMileage: number;
}

const FormAddMileage: FC<FormEditMileageProps> = ({
  assetIri,
  reload,
  minValue,
  minDate,
  lastMileage,
}) => {
  const { translate } = useTranslate();
  const { form, isLoading } = usePostMileageVehicle({ assetIri, reload, lastMileage });
  const formRef = useRef<FormContainerRef>(null);
  const { timeService } = useCore();
  const handleCreate = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  return (
    <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
      <Displayer innerSpacing={2}>
        <Form.NumberAdapted
          label={translate('assets.mileage.post.modal.value.label')}
          item={form.items.value}
          min={minValue}
          type="integer"
        />
        <Form.DateAdapted
          item={form.items.recordedAt}
          label={translate('assets.mileage.post.modal.startDate.label')}
          maxDate={timeService.now()}
          minDate={minDate}
        />
        <Button.Default
          variant="contained"
          size="large"
          onClick={() => handleCreate()}
          disabled={!form.isDirty && form.hasErrors}
          isLoading={isLoading}
        >
          {translate('assets.mileage.post.modal.submit')}
        </Button.Default>
      </Displayer>
    </FormContainer>
  );
};
export default FormAddMileage;
