import { FC, PropsWithChildren, ReactNode } from 'react';
import { CardHeader as MuiCardHeader } from '@mui/material';

interface CardHeaderProps {
  className?: string;
  start?: ReactNode;
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  end?: ReactNode;
}

const CardHeader: FC<PropsWithChildren<CardHeaderProps>> = ({
  children,
  className,
  start,
  title,
  subtitle,
  end,
}) => {
  return (
    <MuiCardHeader
      className={className}
      avatar={start}
      action={end}
      title={children ?? title}
      subheader={subtitle}
    />
  );
};

export default CardHeader;
