import { FC, ReactNode } from 'react';
import { createCss } from 'src/web/common/styles/createCss';
import Emblem from 'src/web/design_system/Emblem';
import Alert from 'src/web/design_system/Alert';
import Translate from 'src/web/common/translate/translate.component';

interface RoleEmblemGroupProps {
  roles: {
    role: string;
    label: string;
    icon: ReactNode;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
  }[];
  spacing?: number;
  canSelect?: boolean;
}

const groupStyle = createCss(`
     display: flex;
     flex-direction:row;
     flex-wrap:wrap;
     gap:1em;
     width:100%;
   `);

const groupItemStyle = createCss(`
   width:calc(50% - 1em);
   max-width: 100%;
   box-sizing: border-box;

   @media screen and (max-width:480px){
    width:100%;
   }
 `);

const RoleEmblemGroup: FC<RoleEmblemGroupProps> = ({ canSelect = true, roles }) => {
  if (roles.length == 0) {
    return (
      <Alert mode="info">
        <Translate id="users.roles.empty.label" />
      </Alert>
    );
  }

  return (
    <div className={groupStyle}>
      {roles.map((item) => (
        <div className={groupItemStyle} key={item.role}>
          {canSelect ? (
            <Emblem.SelectItem
              icon={item.icon}
              label={item.label}
              state={item.isSelected ? 'seleted' : item.isDisabled ? 'disabled' : 'default'}
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
              }}
            />
          ) : (
            <Emblem.Item icon={item.icon} label={item.label} />
          )}
        </div>
      ))}
    </div>
  );
};

export default RoleEmblemGroup;
