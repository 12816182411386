import { FC, useState } from 'react';
import Icon from 'src/web/components/Icon';
import PopoverMenu from 'src/web/components/PopoverMenu';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import { EntitiesEnum } from 'core/common/constants';
import Grid from 'src/web/design_system/Grid';
import Tooltip from 'src/web/design_system/Tooltip';
import { useCore } from 'src/web/common/core';
import Card from 'src/web/components/Card';
import { fileWeightWithUnit } from 'src/web/common/utils/units';
import useTranslate from 'src/web/common/translate/useTranslate';
import { downloadFileFromBlob } from 'src/web/common/download';
import { useTheme } from 'src/web/design_system/Theme';

interface DocumentProps {
  fileName: string;
  fileSize: number;
  createdAt?: string | Date;
  fileExtension: string;
  id: string;
  handleDelete: () => void;
  entity: EntitiesEnum;
}
const Document: FC<DocumentProps> = ({
  fileName,
  fileSize,
  createdAt,
  id,
  handleDelete,
  entity,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { theme } = useTheme();
  const { documentUsecase } = useCore();
  const { translate } = useTranslate();

  const handleDownload = async () => {
    const blob = await documentUsecase.getDocumentDownloadBlob(id, entity);

    if (blob) {
      downloadFileFromBlob(blob, fileName);
    }
  };

  return (
    <>
      <Card.Container border="line">
        <Card.Content>
          <Grid.Container>
            <Grid.Item size={2}>
              <Icon.File size="large" color="disabled" />
            </Grid.Item>
            <Grid.Item size={8}>
              <Tooltip title={fileName}>
                <Text tag="div" variant="textSmall" fontWeight={900} textOverflow={true}>
                  {fileName}
                </Text>
              </Tooltip>
              <Text variant="textSmall">
                {fileWeightWithUnit(fileSize)} . {createdAt?.toLocaleString()}
              </Text>
            </Grid.Item>
            <Grid.Item size={2}>
              <PopoverMenu
                onChange={(state) => setIsMenuOpen(state)}
                isOpen={isMenuOpen}
                items={[
                  {
                    label: (
                      <Displayer
                        justifyContent="space-between"
                        alignItems="center"
                        layout="row"
                        innerSpacing={1}
                      >
                        <Icon.Export size="small" />
                        <Text>{translate('assets.documents.download.button')}</Text>
                      </Displayer>
                    ),
                    onClick: () => {
                      setIsMenuOpen(false);
                      handleDownload();
                    },
                  },
                  {
                    label: (
                      <Displayer
                        alignItems="center"
                        justifyContent="space-between"
                        layout="row"
                        innerSpacing={1}
                      >
                        <Icon.Delete size="small" color="error" />
                        <Text color={theme.palettes.error?.[500]}>
                          {translate('assets.documents.delete.button')}
                        </Text>
                      </Displayer>
                    ),
                    onClick: () => {
                      setIsMenuOpen(false);
                      handleDelete();
                    },
                  },
                ]}
              ></PopoverMenu>
            </Grid.Item>
          </Grid.Container>
        </Card.Content>
      </Card.Container>
    </>
  );
};

export default Document;
