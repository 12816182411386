import Text from 'src/web/design_system/Text';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import AssetStateChip from 'src/web/components/Chip/AssetStateChip';
import { themeColors } from 'src/web/common/theme/constant';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import Icon from 'src/web/components/Icon';

export interface EntityDetailHeaderProps {
  title: string | null;
  subtitle?: string | null;
  states?: {
    label: string;
    value: string;
  }[];
  isLoading?: boolean;
  site?: string;
}

const EntityDetailHeader: React.FC<EntityDetailHeaderProps> = ({
  isLoading,
  title,
  subtitle,
  states,
  site,
}) => {
  const identifiedStates = useMemo(() => {
    return (states ?? []).map((state) => ({
      id: nanoid(),
      ...state,
    }));
  }, [states]);

  return (
    <Displayer bottomSpacing={5}>
      <Skeleton isLoading={isLoading === true} width="20em" height="150px">
        <Text variant="sectionTitle" tag="h1" fontWeight={900} color={themeColors.blackColor}>
          {title}
        </Text>

        {subtitle && (
          <Text variant="textLarge" tag="div">
            {subtitle}
          </Text>
        )}

        {identifiedStates.length && (
          <Displayer layout="row" innerSpacing={1} topSpacing={1}>
            {identifiedStates.map((state) => (
              <AssetStateChip key={state.id} value={state.value} label={state.label} size="small" />
            ))}
          </Displayer>
        )}
        <Displayer alignItems={'center'} layout={'row'} topSpacing={1} innerSpacing={1}>
          {site ? (
            <>
              <Icon.Location />
              <Text>{site}</Text>
            </>
          ) : (
            <></>
          )}
        </Displayer>
      </Skeleton>
    </Displayer>
  );
};

export default EntityDetailHeader;
