import { FC } from 'react';
// import useEditNotificationsConfig from './hooks/useEditNotificationsConfig';
// import FormEditnotificationsConfig from './partials/FormEditNotificationsConfig';
import Tabs from 'src/web/design_system/Tabs';
import useTranslate from 'src/web/common/translate/useTranslate';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';

const NotificationsConfigPage: FC = () => {
  // const { form, isLoading } = useEditNotificationsConfig();
  const { translate } = useTranslate();

  // const tabs = Object.keys(form.items).map((key, index) => ({
  //   id: key,
  //   label: translate(`notificationsConfig.tabs.${form.items[key].name}.label`),
  //   content: (
  //     <FormEditnotificationsConfig
  //       key={index}
  //       form={form.items[key]}
  //       handleSubmit={(e) => form.handleSubmit(e)}
  //       isLoading={isLoading}
  //     />
  //   ),
  // }));

  return (
    <>
      <Displayer innerSpacing={2}>
        <Text variant="pageTitle">{translate('notifications.settings.title')}</Text>
        {/* <Tabs.Global tabs={tabs} /> */}
      </Displayer>
    </>
  );
};

export default NotificationsConfigPage;
