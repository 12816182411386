import { FC, useState } from 'react';
import Header from 'src/web/design_system/Header';
import Icon from 'src/web/components/Icon';
import { HEADER_ICON_COLOR, HEADER_ICON_SIZE } from './constants';
import CounterBadge from 'src/web/design_system/CounterBadge';
import Drawer from 'src/web/design_system/Drawer';
import { createCss } from 'src/web/common/styles/createCss';
import { useTheme, Theme } from 'src/web/common/theme';
import useHeaderNotification from './useHeaderNotification';
import Skeleton from 'src/web/design_system/Skeleton';
import Toolbar from 'src/web/design_system/Toolbar';
import NotificationPreviewItem from './NotificationPreviewItem';
import NotificationPreviewEmpty from './NotifiationPreviewEmpty';
import Divider from 'src/web/design_system/Divider';
import Translate from 'src/web/common/translate/translate.component';
import useTranslate from 'src/web/common/translate/useTranslate';
import GrantRoles from '../Grant';
import { RolesEnum } from 'src/core/common/constants';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import { ROUTE_NOTIFICATIONS_SETTINGS } from 'src/web/common/router/constantes';
import { useNavigate } from 'react-router-dom';

const NAV_WIDTH_MOBILE = 320;
const NAV_WIDTH_DESKTOP = 320;

const drawerStyle = (theme: Theme) =>
  createCss(`
  width: ${NAV_WIDTH_MOBILE}px;
  @media (min-width: ${theme.breakpoints.md + 1}px) {
    width: ${NAV_WIDTH_DESKTOP}px;
  }
`);

const HeaderNotification: FC = () => {
  const { theme } = useTheme();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const { isLoading, data, count, handleMarkAsRead } = useHeaderNotification();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const handleGoToSettings = () => {
    setDrawerOpen(false);
    return navigate(ROUTE_NOTIFICATIONS_SETTINGS);
  };

  return (
    <>
      <Header.ButtonIcon
        onClick={() => setDrawerOpen((currentState) => !currentState)}
        title={translate('global.nav.notifications.button')}
      >
        <CounterBadge value={count}>
          <Icon.Notification size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />
        </CounterBadge>
      </Header.ButtonIcon>

      <Drawer.Default open={drawerOpen} onClose={() => setDrawerOpen(false)} position="right">
        <div className={drawerStyle(theme)}>
          <Toolbar
            primaryContent={<Translate id="notifications.notifications.title" />}
            compact
            secondaryContent={
              <GrantRoles includes={[RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION]}>
                <ButtonIcon
                  onClick={() => handleGoToSettings()}
                  color="inherit"
                  edge="end"
                  title={translate('notifications.settings.title')}
                >
                  <Icon.Settings size="small" />
                </ButtonIcon>
              </GrantRoles>
            }
          />
          <Divider />

          <Skeleton isLoading={isLoading} width="100%" height="100px">
            {count === 0 ? (
              <NotificationPreviewEmpty />
            ) : (
              data.map((item) => (
                <>
                  <NotificationPreviewItem
                    key={item.id}
                    topic={item.topic}
                    dateToDo={item.dateToDo}
                    dateReceived={item.date}
                    entity={item.entityType}
                    entityLabel={item.subject}
                    entityLink={item.entityLink}
                    comment={item.comment}
                    onNavigate={() => setDrawerOpen(false)}
                    onMarkAsRead={() => handleMarkAsRead(item.id)}
                  />
                  <Divider />
                </>
              ))
            )}
          </Skeleton>
        </div>
      </Drawer.Default>
    </>
  );
};

export default HeaderNotification;
