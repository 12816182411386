import type AppStore from 'core/services/store/store.service';
import { type CoreAdapters } from 'core/adapters';
import { type TimeClient } from './time.port';

class TimeService {
  timeClient: TimeClient;

  defaultLocale = 'fr';

  backendFormating = 'YYYY-MM-DDTHH:mm:ssZ';

  currentLocale: string = this.defaultLocale;

  constructor(private adapters: CoreAdapters, private store: AppStore) {
    this.timeClient = this.adapters.timeClient;

    this.getTimeLocale();

    store.subscribe((state) => {
      if (state.user.current?.preferredLanguage !== this.currentLocale) {
        this.getTimeLocale();
      }
    });
  }

  public toBackend = (value: Date): ReturnType<TimeClient['toBackend']> => {
    return this.timeClient.toBackend(value, this.backendFormating);
  };

  public fromBackend = (value: string | null): ReturnType<TimeClient['fromBackend']> => {
    return this.timeClient.fromBackend(value, this.backendFormating);
  };

  public format: TimeClient['format'] = (value, format) => {
    return this.timeClient.format(value, format);
  };

  public isFuture: TimeClient['isFuture'] = (ref, value) => {
    return this.timeClient.isFuture(ref, value);
  };

  public manipulate: TimeClient['manipulate'] = (value, rules) => {
    return this.timeClient.manipulate(value, rules);
  };

  public initTime: TimeClient['initTime'] = (value, unit) => {
    return this.timeClient.initTime(value, unit);
  };

  public build(year: number, month: number, day: number): Date {
    return this.timeClient.build(year, month, day);
  }

  public now(): Date {
    return new Date();
  }

  public tomorrow(): Date {
    return this.manipulate(this.now(), { day: 1 });
  }

  public yesterday(): Date {
    return this.manipulate(this.now(), { day: -1 });
  }

  private getTimeLocale() {
    const shortLocale = (
      this.store.getState((state) => state.user.current?.preferredLanguage) ?? ''
    ).split('-')[0];

    this.currentLocale = shortLocale ?? this.defaultLocale;

    this.timeClient.updateLocale(this.currentLocale);
  }
}

export default TimeService;
