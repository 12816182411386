import FocusLayout from 'src/web/layouts/FocusLayout';
import FleezyIcon from 'src/web/components/FleezyIcon';
import Text from 'src/web/design_system/Text';
import Button from 'src/web/components/Button';
import { useCore } from 'src/web/common/core';
import { useEffect, useState } from 'react';
import Alert from 'src/web/design_system/Alert';
import Icon from 'src/web/components/Icon';
import { themeColors } from 'src/web/common/theme/constant';
import { ROUTE_LOGIN } from 'src/web/common/router/constantes';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import { useNavigate } from 'react-router-dom';
import { authCardStyle } from '../styles';
import useTranslate from 'src/web/common/translate/useTranslate';

//SET to 10 mins because in backend we could sent another notification after 10 mins since last update
const ALERT_DISPLAY_TIME = 600000;

export default function LostPassword() {
  const { translate } = useTranslate();

  const { currentUserUsecase } = useCore();
  const [requestStatus, setRequestStatus] = useState<'initial' | 'loading' | 'success' | 'error'>(
    'initial',
  );
  const navigate = useNavigate();
  const [userField, setUserField] = useState('');
  const handleRequestUpdate = async () => {
    setRequestStatus('loading');

    const response = await currentUserUsecase.requestPasswordUpdate(userField);

    if (response) {
      setRequestStatus('success');
    } else {
      setRequestStatus('error');
    }
  };
  // hide message after a time
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (requestStatus === 'error' || requestStatus === 'success') {
      timer = setTimeout(() => {
        setRequestStatus('initial');
      }, ALERT_DISPLAY_TIME);
    }

    return () => clearTimeout(timer);
  }, [requestStatus]);

  const handleUserNameChange = (value: string) => {
    setUserField(value);
  };

  return (
    <FocusLayout>
      <FleezyIcon width="lg" />
      <Card.Container className={authCardStyle}>
        <Card.Content>
          <Displayer bottomSpacing={3}>
            <Text
              variant="sectionTitle"
              color={themeColors.blackColor}
              align="left"
              fontWeight={900}
            >
              {translate('auth.lostPasswordPage.title')}
            </Text>
            <Text variant="textSmall" align="left" fontWeight={400} color={themeColors.blackColor}>
              {translate('auth.lostPasswordPage.subtitle')}
            </Text>
          </Displayer>
          <Displayer innerSpacing={2}>
            <Form.Text
              startExtra={<Icon.Email />}
              isRequired
              label={translate('auth.personEmail.label')}
              onChange={(value) => handleUserNameChange(value ?? '')}
              value={userField}
            />
            {requestStatus === 'success' && (
              <Alert icon={<Icon.Done />} mode="success">
                {translate('auth.lostPasswordPage.succes.message')}
              </Alert>
            )}
            {requestStatus === 'error' && (
              <Alert icon={<Icon.Fail />} mode="error">
                {translate('auth.lostPasswordPage.error.message')}
              </Alert>
            )}
          </Displayer>
        </Card.Content>
        <Card.Footer>
          <Button.Custom
            variant="outlined"
            onClick={() => navigate(ROUTE_LOGIN)}
            disabled={requestStatus === 'loading' || requestStatus === 'success'}
          >
            {translate('auth.lostPasswordPage.cancel')}
          </Button.Custom>
          <Button.Custom
            isLoading={requestStatus === 'loading'}
            onClick={() => handleRequestUpdate()}
            disabled={requestStatus === 'loading' || requestStatus === 'success'}
          >
            {translate('auth.lostPasswordPage.submit')}
          </Button.Custom>
        </Card.Footer>
      </Card.Container>
    </FocusLayout>
  );
}
