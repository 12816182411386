import axios, { AxiosError, AxiosRequestConfig, AxiosStatic } from 'axios';
import HttpClient, { RequestOption, RequestResponse } from 'core/services/api/http.port';

class Axios implements HttpClient {
  private client: AxiosStatic;

  constructor() {
    this.client = axios;
  }

  private createRequestConfig(option: RequestOption): AxiosRequestConfig {
    return {
      method: option.method,
      url: option.url,
      headers: {
        'Content-Type': option.contentType,
        ...option.headers,
      },
      data: option.body,
      params: option.query,
      withCredentials: !!option.allowCredentials,
      responseType: option.responseType,
    };
  }

  public async request<Response>(option: RequestOption): RequestResponse<Response> {
    try {
      const config = this.createRequestConfig(option);

      const response = await this.client(config);

      return {
        status: response.status,
        data: response.data,
      };
    } catch (error: unknown) {
      // native trycatch returned error type is any|unknown
      // but we know here that Axios returns a specific type
      const axiosError = error as AxiosError;

      return {
        status: axiosError.status || axiosError.response?.status,
        message: axiosError.message || axiosError.code,
        data: axiosError.response?.data as Response,
      };
    }
  }
}

export default Axios;
