import { type FC, useState, type MouseEvent, type PropsWithChildren } from 'react';
import {
  Button as MuiButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface MenuItemProps {
  title: string;
  id: number;
  icon?: React.ReactElement;
  onClick?: () => void;
}

export interface ButtonDropdownProps {
  menuItems: MenuItemProps[];
  endIcon?: React.ReactElement;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

const ButtonDropdown: FC<PropsWithChildren<ButtonDropdownProps>> = ({
  children,
  menuItems,
  endIcon,
  color,
  variant = 'contained',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MuiButton
        endIcon={endIcon ? endIcon : <DropDownIcon />}
        onClick={handleClick}
        sx={{ textTransform: 'none' }}
        variant={variant}
        color={color}
      >
        {children}
      </MuiButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem
            onClick={() => {
              handleClose();
              if (item.onClick) item.onClick();
            }}
            key={item.id}
          >
            {item.icon && (
              <ListItemIcon>
                <Tooltip title={item.title}>{item.icon}</Tooltip>
              </ListItemIcon>
            )}
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonDropdown;
