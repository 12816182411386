import { FC, useMemo } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import InputText, { type InputTextProps } from './InputText';
import useFormComponent from './useFormComponent';

interface FormTextProps
  extends Omit<
    InputTextProps,
    'value' | 'min' | 'max' | 'error' | 'message' | 'onChange' | 'isRequired' | 'pattern'
  > {
  item: FormItem;
}

const FormText: FC<FormTextProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props, item);
    return <></>;
  }
  const { type, ...restProps } = props;
  const { formatFormMessage } = useFormComponent();
  const value = item.formValue ? String(item.formValue) : '';

  const computedType = useMemo(() => {
    if (type) {
      return type;
    }
    if (item.type === 'password') {
      return 'password';
    }
    if (item.format === 'email') {
      return 'email';
    }
    return 'text';
  }, [item]);

  return (
    <InputText
      value={value}
      isRequired={item.required}
      error={!!item.errors}
      min={item.minLength}
      max={item.maxLength}
      message={formatFormMessage(item)}
      onChange={(val) => item.handleChange(val)}
      type={computedType}
      {...restProps}
    />
  );
};

export default FormText;
