import { createCss } from 'src/web/common/styles/createCss';
import { themeColors } from 'src/web/common/theme/constant';

export const historyBoxContent = createCss(`
  max-height:160px;
  overflow-y:auto;
`);

export const mainMileageHistoryContainer = createCss(`
  background:${themeColors.greyColorLight};
`);

export const inputFormEditMileage = createCss('margin:0px !important');
