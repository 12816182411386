import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  Timeline as TimelineMui,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { SvgIconProps, useTheme } from '@mui/material';
import React, { FC } from 'react';

type ColorPalette = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

type SizeIcon = 'small' | 'medium' | 'large';

export interface TimelineData {
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  dotColor?: ColorPalette;
  dotIcon?: React.ReactNode;
  dotIconSize?: SizeIcon;
}
interface AllowedProps extends Pick<SvgIconProps, 'classes' | 'color'> {
  size?: 'small' | 'medium' | 'large';
}
export interface TimelineProps {
  datas: TimelineData[];
}
const Timeline: FC<TimelineProps> = ({ datas }) => {
  const theme = useTheme();
  const sizeValue = {
    small: theme.spacing(1),
    medium: theme.spacing(1.5),
    large: theme.spacing(2),
  };

  const getPaddingLeft = (data: TimelineData) => {
    const py = data.leftContent ? (data.dotIcon ? sizeValue[data.dotIconSize || 'medium'] : 1) : 0;
    const px = data.leftContent ? theme.spacing(2) : 0;
    const flex = data.leftContent ? theme.spacing(1) : 0;
    return { py, px, flex };
  };
  const getPaddingRight = (data: TimelineData) => {
    const py = data.rightContent ? (data.dotIcon ? sizeValue[data.dotIconSize || 'medium'] : 1) : 0;
    const px = data.rightContent ? theme.spacing(2) : 0;
    const flex = data.rightContent ? theme.spacing(1) : 0;
    return { py, px, flex };
  };

  return (
    <TimelineMui sx={{ padding: 0, margin: 0 }}>
      {datas.map((data, index) => {
        const paddingLeft = getPaddingLeft(data);
        const paddingRight = getPaddingRight(data);

        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent sx={paddingLeft}>
              {data.leftContent ? data.leftContent : ''}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={data.dotColor || 'grey'}>
                {data.dotIcon
                  ? React.cloneElement(data.dotIcon as React.ReactElement<AllowedProps>, {
                      size: data.dotIconSize,
                    })
                  : ''}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={paddingRight}>
              {data.rightContent ? data.rightContent : ''}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </TimelineMui>
  );
};

export default Timeline;
