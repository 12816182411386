import { FC, PropsWithChildren } from 'react';
import { RolesEnum } from 'src/core/common/constants';
import { useCore } from 'src/web/common/core';

interface GrantRolesProps {
  includes?: ReadonlyArray<RolesEnum>[number][];
  excludes?: ReadonlyArray<RolesEnum>[number][];
}

const GrantRoles: FC<PropsWithChildren<GrantRolesProps>> = ({
  children,
  includes = [],
  excludes = [],
}) => {
  const { store } = useCore();
  const userRoles = store.getState((state) => state.user?.current?.roles);

  // user has these roles
  const includesRoles =
    Array.isArray(userRoles) && includes.filter((role) => userRoles.includes(role)).length > 0;

  // user doesn't has these roles
  const excludesRoles =
    !Array.isArray(userRoles) || excludes.filter((role) => userRoles.includes(role)).length === 0;

  if ((includes.length > 0 && !includesRoles) || (excludes.length > 0 && !excludesRoles)) {
    return <></>;
  }

  return <>{children}</>;
};

export default GrantRoles;
