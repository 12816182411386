import { FC } from 'react';
import CookieConsentSettings from 'src/web/common/cookieConsent/cookieConsentSettings';
import useTranslate from 'src/web/common/translate/useTranslate';
import Card from 'src/web/components/Card';

const CookiesPartial: FC = () => {
  const { translate } = useTranslate();
  return (
    <Card.Basic title={translate('currentUser.cookies.title')}>
      <CookieConsentSettings />
    </Card.Basic>
  );
};

export default CookiesPartial;
