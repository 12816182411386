import { VariantType, useSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { isNumber } from 'core/common/utils/predicatesType';

interface UseToasterProps {
  message: ReactNode;
  variant: VariantType;
  hideIconVariant?: boolean;
  duration?: number | false;
  action?: (handleClose: () => void) => ReactNode;
}

const useToaster = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCreate = ({ message, variant, duration, action }: UseToasterProps) =>
    enqueueSnackbar(message, {
      variant,
      persist: duration === false,
      autoHideDuration: isNumber(duration) ? duration : null,
      preventDuplicate: true,
      action: (key: string | number) => action && action(() => closeSnackbar(key)),
    });

  return {
    createToast: handleCreate,
  };
};

export default useToaster;
