import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import Icon from 'src/web/components/Icon';
import Menu from 'src/web/design_system/Menu';
import { nanoid } from 'nanoid';
import { createCss } from 'src/web/common/styles/createCss';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import Popover from 'src/web/design_system/Popover';

export interface PopoverMenuItem {
  label: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  name?: string;
  onClick: () => void;
}

interface PopoverMenuProps {
  isOpen?: boolean;
  onChange?: (state: boolean) => void;
  items: PopoverMenuItem[];
}

const PopMenuStyle = createCss(`
  min-width: 150px;
`);

const PopoverMenu: FC<PopoverMenuProps> = ({ isOpen, items, onChange }) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen);
  const computedItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        name: item.name ?? nanoid(),
      })),
    [items],
  );

  const handleChange = (state: boolean) => {
    setIsPopoverOpen(state);

    if (onChange) {
      onChange(state);
    }
  };

  useEffect(() => {
    setIsPopoverOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <ButtonIcon onClick={() => handleChange(true)} ref={ref} size="small">
        <Icon.Menu size="small" />
      </ButtonIcon>
      <Popover
        isOpen={isPopoverOpen}
        targetRef={ref}
        onChange={(state) => handleChange(state)}
        horizontal="right"
      >
        <Menu.Container className={PopMenuStyle}>
          {computedItems.map((item) => (
            <Menu.Item
              key={item.name}
              startIcon={item.startIcon}
              endIcon={item.endIcon}
              label={item.label}
              onClick={() => item.onClick()}
              inset={false}
              size={'small'}
            />
          ))}
        </Menu.Container>
      </Popover>
    </>
  );
};

export default PopoverMenu;
