import { PropsWithChildren, forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';

export interface ButtonIconProps {
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  isLoading?: boolean;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  edge?: 'end' | 'start';
  title?: string;
}

const ButtonIcon = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonIconProps>>(
  (props, ref) => {
    const {
      children,
      color = 'primary',
      className,
      size = 'medium',
      type = 'button',
      disabled,
      edge,
      title,
      onClick,
    } = props;

    return (
      <IconButton
        color={color}
        type={type}
        ref={ref}
        className={className}
        size={size}
        onClick={() => !!onClick && onClick()}
        disabled={disabled}
        edge={edge ?? false}
        title={title}
      >
        {children}
      </IconButton>
    );
  },
);

export default ButtonIcon;
