import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { isUserGranted } from 'core/common/utils/grant';
import { SwaggerRequestBody } from 'core/swagger';
import TimeService from 'core/services/time/time.service';
import { AssetsTypesMap, EntitiesEnum, TaskTopics } from 'core/common/constants';
import FormService from 'core/services/form/form.service';
import AssignmentUseCase from 'core/usecases/assignment.usecase';
import TaskItemEntity from 'core/entities/taskItem.entity';
import TaskGroupEntity from 'core/entities/taskGroup.entity';
import UserEntity from 'core/entities/user.entity';
import TaskEntity from 'core/entities/task.entity';
import { isString } from 'core/common/utils/predicatesType';
import { dateToUnixTimestamp } from 'core/common/utils/date';

interface BoardingTaskType {
  id: string;
  task: string;
}

class BoardingUseCase implements Usecase {
  onboardingTaskTypes: BoardingTaskType[] = [];

  offboardingTaskTypes: BoardingTaskType[] = [];

  constructor(
    private store: AppStore,
    private timeService: TimeService,
    private formService: FormService,
    private assignmentUseCase: AssignmentUseCase,
    private taskEntity: TaskEntity,
    private taskItemEntity: TaskItemEntity,
    private taskGroupEntity: TaskGroupEntity,
    private userEntity: UserEntity,
  ) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * PERSON AS BOARDING
   */
  async getPersonBoardingTasks(personId: string, boardingType: TaskTopics) {
    const response = await this.taskGroupEntity.getAll({
      query: {
        entityId: personId,
        entityType: AssetsTypesMap[EntitiesEnum.person],
        topic: boardingType,
      },
    });

    if (response.data && response.data.length > 0) {
      return response.data[0];
    }

    return undefined;
  }

  /**
   * GET WHICH BOARDING THE PERSON COULD NEED
   */
  public async getPersonBoarding(person: {
    entryAt: string | null;
    exitAt?: string | null | undefined;
    id?: string;
  }) {
    let boardingType: TaskTopics | undefined = undefined;
    let hasBoarding = false;

    const isPersonEntered = this.timeService.isFuture(
      this.timeService.now(),
      this.timeService.fromBackend(person.entryAt),
    );

    if (!isPersonEntered) {
      boardingType = TaskTopics.peopleOnBoarding;
    }

    if (isPersonEntered) {
      boardingType = TaskTopics.peopleOffBoarding;
    }

    if (boardingType && person.id) {
      const boardingData = await this.getPersonBoardingTasks(person.id, boardingType);
      hasBoarding = !!boardingData;
    }

    return {
      type: boardingType,
      exist: hasBoarding,
    };
  }

  /**
   * GET COMMON BOARDING FORM
   */
  public getBoardingForm() {
    const { properties, required, validate } = this.formService.createForm(
      this.taskItemEntity.getPathAll(),
      'post',
    );
    const initialValues = {
      customFormAnswer: '',
      comment: '',
    };

    return {
      properties,
      getInitialValues: () => initialValues,
      required,
      validate,
    };
  }

  /**
   * Return the body formatted data to update boarding into gabriel
   *
   * @param data
   * @param personId
   * @param boardingType
   */
  public getBoardingFormattedData(
    data: object,
    limit: string | undefined,
    personId: string,
    boardingType: string,
  ) {
    const formattedData = this.formService.unformatData(data);

    let newLimit = '';
    if (isString(formattedData.deadline)) {
      const date = new Date(formattedData.deadline);
      formattedData.deadline = dateToUnixTimestamp(date).toString();
      newLimit = formattedData.deadline;
    } else if (isString(limit)) {
      const date = new Date(limit);
      newLimit = dateToUnixTimestamp(date).toString();
    }

    return {
      entityType: 'person',
      entityId: personId,
      emailTo: formattedData.owner,
      limit: newLimit,
      comment: formattedData.comment,
      alertUserId: this.store.getState((state) => state.user.current?.id) ?? '',
      topic:
        boardingType === TaskTopics.peopleOnBoarding
          ? 'onBoarding'
          : boardingType === TaskTopics.peopleOffBoarding
          ? 'offBoarding'
          : undefined,
    };
  }

  /**
   * CREATE A TASK GROUP (= CONTAINER)
   */
  public async createTaskGroup(
    boardingType: TaskTopics,
    person: {
      id?: string;
      lastname?: string;
      firstname?: string;
      jobTitle?: string;
    },
  ) {
    const commonName = [person.lastname, person.firstname].filter((el) => !!el).join(' ');

    // build task group body from person and boarding type
    const taskGroupBody: SwaggerRequestBody<
      ReturnType<typeof this.taskGroupEntity.getPathAll>,
      'post'
    >['application/json'] = {
      commonName,
      jobTitle: person.jobTitle as string,
      entityId: person.id,
      entityType: 'person',
      topic: boardingType,
    };

    // submit task group
    const response = await this.taskGroupEntity.post({
      body: taskGroupBody,
    });

    return response.data?.['@id'];
  }

  /**
   * CREATE A TASK (= ITEM)
   */
  public async createTask(taskGroupIri: string, data: object) {
    return this.taskItemEntity
      .post({
        body: this.formService.unformatData({
          ...data,
          customFormAnswer: taskGroupIri,
        }),
      })
      .then((response) => !!response.data);
  }

  /**
   * UPDATE A TASK
   */
  public async updateTask(taskId: string, data: object) {
    return this.taskItemEntity
      .put({
        pathVar: {
          id: taskId,
        },
        body: this.formService.unformatData(data),
      })
      .then((response) => !!response.data);
  }

  /**
   * DELETE A TASK
   */
  public async deleteTask(taskId: string) {
    return this.taskItemEntity
      .delete({
        pathVar: {
          id: taskId,
        },
      })
      .then((response) => !!response.data);
  }

  /**
   * GET BOARDING TASK TYPES
   */
  public async getboardingTaskTypes(boardingType: TaskTopics) {
    const boardingList = await this.taskEntity.getAll({
      query: {
        'topic[]':
          boardingType === TaskTopics.peopleOnBoarding
            ? [TaskTopics.peopleOnBoarding]
            : [TaskTopics.peopleOffBoarding],
        'order[listOrder]': 'asc',
      },
    });

    if (boardingList && boardingList.data) {
      return boardingList.data.map((item) => ({
        task: item.task,
        id: item['@id'] ?? '',
      }));
    }
    return [];
  }

  /**
   * SEARCH PERSON
   */
  public async searchOwners(value = '') {
    return this.userEntity
      .getSibling({
        query: {
          username: value,
          'status[]': ['normal', 'toBeNotified', 'newPasswordPending'],
        },
      })
      .then((response) => response?.data ?? []);
  }

  /**
   * Get person current assignment and bind it with tasks types
   * Returns a tuple containing the assignment and its related task type
   */
  public async getOffboardingCurrentAssignmentTasks(personIri: string) {
    const response = await this.assignmentUseCase.getBoardingPersonCurrentAssigments(personIri);
    const list: [string, (typeof response)[number]][] = [];

    if (response) {
      for (let index = 0; index < response.length; index++) {
        const assignment = response[index];
        if (assignment.asset.assetType) {
          const taskType = await this.getOffboardingTaskFromAssetType(assignment.asset.assetType);
          list.push([taskType, assignment]);
        }
      }
    }

    return list;
  }

  /**
   * GET TASKS TYPE ID FROM ASSIGNMENT ASSET TYPE
   */
  private async getOffboardingTaskFromAssetType(assetType: string): Promise<string> {
    const taskTypes = await this.getboardingTaskTypes(TaskTopics.peopleOffBoarding);

    const taskName = (() => {
      switch (assetType) {
        case 'vehicle':
          return 'returnVehicle';
        case 'computer':
          return 'returnComputer';
        case 'licence':
          return 'returnLicence';
        case 'telephone':
          return 'returnTelephone';
        case 'card':
          return 'returnCard';
        default:
          return 'returnOther';
      }
    })();

    return taskTypes?.find((taskType) => taskType.task === taskName)?.id ?? '';
  }
}

export default BoardingUseCase;
