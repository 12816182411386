import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { useNavigate } from 'react-router-dom';
import { ROUTE_USERS } from 'src/web/common/router/constantes';
import { getIdFromIri } from 'src/core/common/utils/id';

const useAddUserForm = () => {
  const { setUserUseCase, rolesUseCase, userUseCase } = useCore();
  const navigate = useNavigate();
  const { properties, initialValues, required, validate, submit } = useMemo(
    () => setUserUseCase.getAddForm(),
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [isRolesLoading, setIsRolesLoading] = useState(true);
  const [optionsServices, setOptionsServices] =
    useState<Awaited<ReturnType<typeof userUseCase.getServices>>>(undefined);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const loadServices = async () => {
    if (searchValue.length > 1) {
      const response = await userUseCase.getServices(searchValue);
      if (response) {
        setOptionsServices(response);
      }
    } else {
      setOptionsServices(undefined);
    }
  };

  useEffect(() => {
    loadServices();
  }, [searchValue]);

  const form = useCreateForm({
    properties,
    initialValues,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);

      if (response) {
        // Todo: dunno why Typescript returns the type of only some properties but not the full object... A mistake somewhere.
        const id = getIdFromIri((response as { '@id'?: string })?.['@id'] ?? '');
        navigate(`${ROUTE_USERS}/${id}`);
      }
      setIsLoading(false);
    },
  });

  const handleToggleRole = (role: string) => {
    setCurrentRoles((current) => {
      if (current.includes(role)) {
        return [...current].filter((data) => data !== role);
      } else {
        return [...current, role];
      }
    });
  };

  useEffect(() => {
    rolesUseCase
      .getAvailableRoles()
      .then((roles) => setAvailableRoles(roles))
      .finally(() => setIsRolesLoading(false));
  }, []);

  useEffect(() => {
    form.imperativeChangeField('roles', currentRoles);
  }, [currentRoles]);

  return {
    form,
    isRolesLoading,
    availableRoles,
    currentRoles,
    handleToggleRole,
    isLoading,
    optionsServices,
    handleSearch,
  };
};

export default useAddUserForm;
