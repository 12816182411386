import { useEffect, useState } from 'react';
import { getIdFromIri } from 'src/core/common/utils/id';
import { useCore } from 'src/web/common/core';
import { downloadFileFromBlob } from 'src/web/common/download';
import useTranslate from 'src/web/common/translate/useTranslate';
import { useGetAssignementDatas } from 'src/web/pages/assets/detail/hooks/useGetAssignementDatas';

function useGetAssignmentCertificate(assetIri?: string) {
  const { translate } = useTranslate();
  const { assignmentUseCase } = useCore();
  const { dataAssignement, reload } = useGetAssignementDatas(assetIri);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [startDates, setStartDates] = useState('');
  const [persons, setPersons] = useState('');

  useEffect(() => {
    const download = async () => {
      if (triggerDownload && dataAssignement && dataAssignement?.[0]?.['@id']) {
        const downloadBlob = await assignmentUseCase.getCertificateDownLoadBlob(
          startDates,
          persons,
          [getIdFromIri(dataAssignement?.[0]?.['@id']) ?? ''],
          'assetAssignments',
        );

        if (downloadBlob) {
          downloadFileFromBlob(downloadBlob, translate('asset.assetAssignment.delevery.fileName'));
          setTriggerDownload(false);
        }
      }
    };

    download();
  }, [dataAssignement]);

  const handleGetAssignmentCertificate = (startDate: string, person: string) => {
    reload();
    setStartDates(startDate);
    setPersons(person);
    setTriggerDownload(true);
  };
  return { handleGetAssignmentCertificate };
}

export default useGetAssignmentCertificate;
