import * as FR from './fr-FR/translations.json';
import * as EN from './en-US/translations.json';
import { LocalesEnum } from 'core/common/constants';

const locales: Record<LocalesEnum, Record<string, string>> = {
  [LocalesEnum.fr]: FR,
  [LocalesEnum.en]: EN,
};

export default locales;
