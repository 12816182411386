import { FC, useRef } from 'react';
import useEditContact from '../hooks/useEditContact';
import Form from 'src/web/components/FormInput';
import Skeleton from 'src/web/design_system/Skeleton';
import Card from 'src/web/components/Card';
import Text from 'src/web/design_system/Text';
import Button from 'src/web/components/Button';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import { useCore } from 'src/web/common/core';
import Displayer from 'src/web/design_system/Displayer';
import DataDetailSection from 'src/web/components/dataDetailSection';
import EmptyValue from 'src/web/components/EmptyValue';
import Grid from 'src/web/design_system/Grid';
import useEditAddress from '../hooks/useEditAddress';
import useTranslate from 'src/web/common/translate/useTranslate';
import Icon from 'src/web/components/Icon';
import Link from 'src/web/components/Link';

const SubscriptionPartial: FC = () => {
  const { translate } = useTranslate();
  const { store, timeService } = useCore();
  const formRefAddres = useRef<FormContainerRef>(null);
  const formRefContact = useRef<FormContainerRef>(null);
  const {
    isLoading: isContactLoading,
    form: contactForm,
    showSubmit: showContactSubmit,
  } = useEditContact();
  const {
    isLoading: isAddressLoading,
    form: addressForm,
    showSubmit: showAddressSubmit,
  } = useEditAddress();
  const legalEntityName = store.getState((state) => state.legalEntity.current?.name);
  const subscriptionType = store.getState((state) => state.legalEntity.current?.subscription);
  const subscriberSince = store.getState((state) => {
    const createAt = state.legalEntity.current?.createdAt;
    if (!createAt) return <EmptyValue />;
    return timeService.format(timeService.fromBackend(createAt), 'short');
  });

  return (
    <Displayer innerSpacing={3}>
      <Grid.Container>
        <Grid.Item
          size={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 4,
          }}
        >
          <Card.Container>
            <Card.Toolbar>
              <Text variant="sectionTitle">{translate('legalEntity.account.title')}</Text>
            </Card.Toolbar>
            <Card.Content>
              <DataDetailSection
                data={[
                  {
                    label: translate('legalEntity.legalEntityName.title'),
                    value: legalEntityName,
                  },
                  {
                    label: translate('legalEntity.subscriptionType.title'),
                    value: subscriptionType,
                  },
                  {
                    label: translate('legalEntity.subscriberSince.title'),
                    value: subscriberSince,
                  },
                  {
                    label: translate('legalEntity.invoices.title'),
                    value: (
                      <>
                        <Link
                          target="_blank"
                          to="https://billing.stripe.com/p/login/28ocNA3vaeql3SM4gg"
                        >
                          <Displayer layout={'row'} alignItems={'center'} innerSpacing={1}>
                            {translate('legalEntity.invoices.label')}
                            <Icon.NewTab size="small" />
                          </Displayer>
                        </Link>
                      </>
                    ),
                  },
                ]}
              />
            </Card.Content>
          </Card.Container>
        </Grid.Item>
      </Grid.Container>

      <Grid.Container>
        <Grid.Item>
          <FormContainer ref={formRefAddres} onSubmit={(e) => addressForm.handleSubmit(e)}>
            <Card.Container>
              <Card.Toolbar
                secondaryContent={
                  showAddressSubmit && (
                    <Button.Custom
                      onClick={() => {
                        if (formRefAddres.current) {
                          formRefAddres.current.submit();
                        }
                      }}
                      disabled={addressForm.hasErrors}
                      fullWidth={false}
                      isLoading={isAddressLoading}
                    >
                      {translate('legalEntity.billingAddress.submit')}
                    </Button.Custom>
                  )
                }
              >
                <Text variant="sectionTitle">{translate('legalEntity.billingAddress.title')}</Text>
              </Card.Toolbar>
              <Card.Content>
                <Skeleton isLoading={isAddressLoading} width="100%">
                  <Displayer innerSpacing={2}>
                    <Form.TextAdapted
                      label={translate('legalEntity.locationStreetName.label')}
                      item={addressForm.items.locationStreetName}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.locationStreetNameBis.label')}
                      item={addressForm.items.locationStreetNameBis}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.locationPostcode.label')}
                      item={addressForm.items.locationPostcode}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.locationCity.label')}
                      item={addressForm.items.locationCity}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.locationCountry.label')}
                      item={addressForm.items.locationCountry}
                    />
                  </Displayer>
                </Skeleton>
              </Card.Content>
            </Card.Container>
          </FormContainer>
        </Grid.Item>

        <Grid.Item>
          <FormContainer ref={formRefContact} onSubmit={(e) => contactForm.handleSubmit(e)}>
            <Card.Container>
              <Card.Toolbar
                secondaryContent={
                  showContactSubmit && (
                    <Button.Custom
                      onClick={() => {
                        if (formRefContact.current) {
                          formRefContact.current.submit();
                        }
                      }}
                      disabled={contactForm.hasErrors}
                      fullWidth={false}
                      isLoading={isContactLoading}
                    >
                      {translate('legalEntity.contact.submit')}
                    </Button.Custom>
                  )
                }
              >
                <Text variant="sectionTitle">{translate('legalEntity.contact.title')}</Text>
              </Card.Toolbar>
              <Card.Content>
                <Skeleton isLoading={isContactLoading} width="100%">
                  <Displayer innerSpacing={2}>
                    <Form.TextAdapted
                      label={translate('legalEntity.personFirstname.label')}
                      item={contactForm.items.personFirstname}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.personLastname.label')}
                      item={contactForm.items.personLastname}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.personJobTitle.label')}
                      item={contactForm.items.personJobTitle}
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.personEmail.label')}
                      item={contactForm.items.personEmail}
                      type="email"
                    />
                    <Form.TextAdapted
                      label={translate('legalEntity.personTelephone.label')}
                      item={contactForm.items.personTelephone}
                    />
                  </Displayer>
                </Skeleton>
              </Card.Content>
            </Card.Container>
          </FormContainer>
        </Grid.Item>
      </Grid.Container>
    </Displayer>
  );
};

export default SubscriptionPartial;
