import { useState } from 'react';
import { useCore } from 'src/web/common/core';
const useSite = () => {
  const { sitesUseCase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<typeof sitesUseCase.getAllSites>>>([]);
  const handleLoadData = async () => {
    setIsLoading(true);

    const response = await sitesUseCase.getAllSites();

    setData(response);

    setIsLoading(false);
  };

  const handleDeleteData = async (id: string) => {
    setIsLoading(true);

    await sitesUseCase.deleteSite(id);

    await handleLoadData();

    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    loadData: handleLoadData,
    deleteData: handleDeleteData,
  };
};

export default useSite;
