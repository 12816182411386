import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import { type PropsWithChildren, type FC, ReactNode } from 'react';

type ButtonDefaultProps = {
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  isLoading?: boolean;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
};

const ButtonDefault: FC<PropsWithChildren<ButtonDefaultProps>> = ({
  children,
  color = 'primary',
  className,
  isLoading,
  size = 'medium',
  variant = 'contained',
  fullWidth,
  type = 'button',
  startIcon,
  endIcon,
  disabled,
  onClick,
}) => {
  return (
    <MuiLoadingButton
      color={color}
      type={type}
      className={className}
      variant={variant}
      size={size}
      onClick={() => !!onClick && onClick()}
      loading={isLoading}
      loadingPosition="center"
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </MuiLoadingButton>
  );
};

export default ButtonDefault;
