export const fileWeightWithUnit = (size: number) => {
  const units = ['b', 'ko', 'Mo', 'Go', 'To'];

  let index = 0;
  while (size >= 1000 && index < units.length - 1) {
    size /= 1000;
    index++;
  }

  return `${size.toFixed(2)} ${units[index]}`;
};
