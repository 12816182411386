/**
 * flatten the server data
 * @todo: this is a duplication of the 'format' method in the Core FormService.
 * Probably we should merge both (and the 'unformat' method) into a specific
 * @todo: The assertion 'T = R' is wrong and should be replaced by a complicated Typescript type
 * to transform a nested object into a flatten object
 */
export function flattenDataList<T extends Record<string, unknown>[], R = T>(data: T): R {
  function flattenObject<O extends object>(obj: O, prefix = '') {
    const result: Record<string, unknown> = {};

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;

        if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          Object.assign(result, flattenObject(obj[key as keyof typeof obj] as object, newKey));
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  return data.map((item) => flattenObject(item)) as R;
}
