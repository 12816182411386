import { FC, PropsWithChildren } from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';

interface TooltipProps {
  className?: string;
  arrow?: boolean;
  title: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  arrow,
  children,
  title,
  placement,
  className,
}) => {
  return (
    <MuiTooltip title={title} arrow={arrow} placement={placement}>
      <div className={className}>{children}</div>
    </MuiTooltip>
  );
};

export default Tooltip;
