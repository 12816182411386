import { FC, useEffect, useMemo, useState } from 'react';
import Card from 'src/web/components/Card';
import Icon from 'src/web/components/Icon';
import PopoverMenu, { PopoverMenuItem } from 'src/web/components/PopoverMenu';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';
import { useTheme } from 'src/web/common/theme';
import AssetAssignmentEditModal from 'src/web/components/AssetAssignmentEditModal';
import AssetAssignmentUnassignModal from 'src/web/components/AssetAssignmentUnassignModal';
import { getIdFromIri } from 'core/common/utils/id';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';
import { pluralize } from 'src/core/common/utils/format';
import Link from 'src/web/components/Link';
import useGrantRole from 'src/web/common/grant/useGrantRole';
import { EntitiesEnum, RolesEnum } from 'src/core/common/constants';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Chip from 'src/web/design_system/Chip';
import { truncateAssetAssignmentStyle } from './style';

interface AssetAssignmentProps {
  assignment: {
    iri?: string;
    startAt: Date;
    endAt?: Date;
    assetId?: string;
    assetType?: string;
    assetIdentification?: string;
    assetManufacturer?: string | null;
    assetModel?: string;
    service_Name?: string;
    service_Provider?: string;
    telephoneLine_LineNumber?: string | null;
    assetCategory?: string;
  };
  isAssigned?: boolean;
  onChange?: () => void;
}

const AssetAssignment: FC<AssetAssignmentProps> = ({ assignment, onChange, isAssigned }) => {
  const { translate } = useTranslate();
  const { timeService } = useCore();
  const { theme } = useTheme();
  const { id: personIri } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [assetRole, setAssetRole] = useState(false);
  const [assetEntitie, setAssetEntitie] = useState<EntitiesEnum>(EntitiesEnum.card);

  const buildAssetUrl = (assetType: string | undefined, assetId: string | undefined) => {
    if (assetType && assetId) {
      return `/${pluralize(assetType)}/${assetId}`;
    }

    return undefined;
  };

  function containsUppercase(str: string) {
    return /[A-Z]/.test(str);
  }

  const formatTitle = (assetType: string) => {
    switch (assetType) {
      case 'vehicle':
        return `${assignment.assetManufacturer} ${assignment.assetModel} ${assignment.assetIdentification}`;

      case 'telephone':
        return `${
          assignment.assetCategory
            ? containsUppercase(assignment.assetCategory)
              ? assignment.assetCategory
              : translate(`telephones.formFactor.option.${assignment.assetCategory}.label`)
            : ''
        } ${assignment.assetManufacturer} ${assignment.assetModel}`;

      case 'telephoneLine':
        return `${
          assignment.assetCategory
            ? containsUppercase(assignment.assetCategory)
              ? assignment.assetCategory
              : translate(`telephoneLines.asset.category.option.${assignment.assetCategory}.label`)
            : ''
        } ${assignment.telephoneLine_LineNumber}`;

      case 'computer':
        return `${
          assignment.assetCategory
            ? containsUppercase(assignment.assetCategory)
              ? assignment.assetCategory
              : translate(`computers.formFactor.option.${assignment.assetCategory}.label`)
            : ''
        } ${assignment.assetManufacturer} ${assignment.assetModel}`;

      case 'licence':
        return `${
          assignment.assetCategory
            ? containsUppercase(assignment.assetCategory)
              ? assignment.assetCategory
              : translate(`licences.termAndConditionsType.option.${assignment.assetCategory}.label`)
            : ''
        } ${assignment.assetIdentification}`;

      case 'card':
        return `${
          assignment.assetCategory
            ? translate(`cards.purpose.option.${assignment.assetCategory}.label`)
            : ''
        } ${assignment.assetIdentification}`;

      case 'customAsset':
        return `${assignment.assetCategory ? assignment.assetCategory : ''} ${
          assignment.assetManufacturer
        } ${assignment.assetModel}`;

      case 'service':
        return `${assignment.service_Name} ${assignment.service_Provider}`;

      default:
        return `${assignment.assetManufacturer} ${assignment.assetModel}`;
    }
  };

  const assignmentFormattedData = useMemo(() => {
    return {
      assignmmentId: assignment.iri ? getIdFromIri(assignment.iri) : undefined,
      assetId: assignment.assetId,
      identification: assignment.assetIdentification,
      type: assignment.assetType,
      title: formatTitle(assignment.assetType ?? ''),
      dateFrom: timeService.format(assignment.startAt, 'short'),
      url: buildAssetUrl(assignment.assetType, assignment.assetId),
    };
  }, [assignment]);

  const menuItems: PopoverMenuItem[] = [
    {
      label: translate('assetAssignment.edit.cta'),
      onClick: () => {
        setIsMenuOpen(false);
        setIsEditModalOpen(true);
      },
    },
    {
      label: translate('assetAssignment.restitute.cta'),
      onClick: () => {
        setIsMenuOpen(false);
        setIsUnassignModalOpen(true);
      },
    },
  ];

  const menu = (
    <PopoverMenu isOpen={isMenuOpen} items={menuItems} onChange={(state) => setIsMenuOpen(state)} />
  );

  const colorIcon = isAssigned ? 'primary' : 'disabled';

  const getAssetIcon = (type: string) => {
    switch (type) {
      case 'vehicle':
        return <Icon.Vehicle size="large" color={colorIcon} />;
      case 'card':
        return <Icon.Card size="large" color={colorIcon} />;
      case 'service':
        return <Icon.Service size="large" color={colorIcon} />;
      case 'licence':
        return <Icon.Licence size="large" color={colorIcon} />;
      case 'telephone':
        return <Icon.Telephone size="large" color={colorIcon} />;
      case 'telephoneLine':
        return <Icon.TelephoneLine size="large" color={colorIcon} />;
      case 'computer':
        return <Icon.Computer size="large" color={colorIcon} />;
      case 'customAsset':
        return <Icon.Autofill size="large" color={colorIcon} />;
      default:
        return <Icon.Random size="large" color={colorIcon} />;
    }
  };

  if (
    !assignmentFormattedData.assetId ||
    !assignmentFormattedData.assignmmentId ||
    !assignmentFormattedData.type
  ) {
    return <></>;
  }

  const isRoleAdminTelephoneLine = useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE_LINE);
  const isRoleAdminTelephone = useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE);
  const isRoleAdminCard = useGrantRole(RolesEnum.ROLE_ADMIN_CARD);
  const isRoleAdminComputer = useGrantRole(RolesEnum.ROLE_ADMIN_COMPUTER);
  const isRoleAdminService = useGrantRole(RolesEnum.ROLE_ADMIN_SERVICE);
  const isRoleAdminLicence = useGrantRole(RolesEnum.ROLE_ADMIN_LICENCE);
  const isRoleAdminVehicle = useGrantRole(RolesEnum.ROLE_ADMIN_VEHICLE);
  const isRoleAdminCustomAsset = useGrantRole(RolesEnum.ROLE_ADMIN_CUSTOM_ASSET);

  const getRoleFromUserRoles = () => {
    switch (assignment.assetType) {
      case 'telephoneLine':
        setAssetRole(isRoleAdminTelephoneLine);
        setAssetEntitie(EntitiesEnum.telephoneLine);
        break;
      case 'telephone':
        setAssetRole(isRoleAdminTelephone);
        setAssetEntitie(EntitiesEnum.telephone);
        break;
      case 'card':
        setAssetRole(isRoleAdminCard);
        setAssetEntitie(EntitiesEnum.card);
        break;
      case 'computer':
        setAssetRole(isRoleAdminComputer);
        setAssetEntitie(EntitiesEnum.computer);
        break;
      case 'service':
        setAssetRole(isRoleAdminService);
        setAssetEntitie(EntitiesEnum.service);
        break;
      case 'licence':
        setAssetRole(isRoleAdminLicence);
        setAssetEntitie(EntitiesEnum.licence);
        break;
      case 'vehicle':
        setAssetRole(isRoleAdminVehicle);
        setAssetEntitie(EntitiesEnum.vehicle);
        break;
      case 'customAsset':
        setAssetRole(isRoleAdminCustomAsset);
        setAssetEntitie(EntitiesEnum.customAsset);
        break;
    }
  };

  useEffect(() => {
    getRoleFromUserRoles();
  }, [assignment.assetType]);

  return (
    <div>
      <Card.Container border="line">
        <Card.Toolbar secondaryContent={onChange && assetRole && menu}>
          <Displayer
            layout="row"
            innerSpacing={2}
            bottomSpacing={2}
            topSpacing={2}
            flexWrap="nowrap"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getAssetIcon(assignmentFormattedData.type)}
            </Box>
            <div>
              <Displayer layout={'row'} alignItems={'center'} innerSpacing={2}>
                <Link
                  className={truncateAssetAssignmentStyle}
                  to={assetRole ? assignmentFormattedData.url : ''}
                >
                  <Text textOverflow variant="textLarge">
                    {assignmentFormattedData.title}
                  </Text>
                </Link>
                {isAssigned ? (
                  <Chip
                    size="small"
                    variant="filled"
                    bgColor="primary"
                    color={theme.palettes.primary?.[800]}
                    label={translate('assignment.state.current.label')}
                  />
                ) : (
                  <></>
                )}
              </Displayer>

              <Text variant="textSmall" tag="div">
                {assignment.endAt
                  ? translate('assignment.assignedBetween.label', {
                      dateStart: assignmentFormattedData.dateFrom,
                      dateEnd: timeService.format(assignment.endAt, 'short'),
                    })
                  : translate('assignment.assignedFrom.label', {
                      date: assignmentFormattedData.dateFrom,
                    })}
              </Text>
            </div>
          </Displayer>
        </Card.Toolbar>
      </Card.Container>

      <AssetAssignmentEditModal
        entity={assetEntitie}
        assetIri={assignmentFormattedData.assignmmentId}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onEdit={() => onChange && onChange()}
        personIri={personIri}
      />

      <AssetAssignmentUnassignModal
        entity={assetEntitie}
        assetIri={assignmentFormattedData.assignmmentId}
        isOpen={isUnassignModalOpen}
        onClose={() => setIsUnassignModalOpen(false)}
        onUnassign={() => onChange && onChange()}
        personIri={personIri}
      />
    </div>
  );
};

export default AssetAssignment;
