import { useCallback, useEffect, useState } from 'react';
import { useCore } from 'src/web/common/core';

const useBoardingAssignments = (personIri?: string) => {
  const { assignmentUseCase, boardingUseCase } = useCore();

  const [isAssignmentsLoading, setIsAssignmentsLoading] = useState<boolean>(true);

  const [currentAssignments, setCurrentAssignments] = useState<
    Awaited<ReturnType<typeof assignmentUseCase.getBoardingPersonCurrentAssigments>>
  >([]);

  // get person current assignments and associated tasktype
  const getCurrentAssignments = useCallback(async () => {
    if (personIri) {
      setIsAssignmentsLoading(true);

      const response = await boardingUseCase.getOffboardingCurrentAssignmentTasks(personIri);

      if (response) {
        setCurrentAssignments(response.map((currentAssignmentTasks) => currentAssignmentTasks[1]));
      }

      setIsAssignmentsLoading(false);
    }
  }, [personIri]);

  // load current assignments
  useEffect(() => {
    getCurrentAssignments();
  }, [personIri]);

  return {
    isLoading: isAssignmentsLoading,
    currentAssignments,
  };
};

export default useBoardingAssignments;
