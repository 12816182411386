import { isString } from 'core/common/utils/predicatesType';
import { InventorySorter } from './useCreateInventory';

const useInventoryHelpers = () => {
  const handleSort = (sorters: InventorySorter, key: string, sortState: unknown) => {
    const sorter = sorters[`order[${key}]`];

    if (sorter && isString(sortState)) {
      sorter.onChange(sorter.options[sorter.options.indexOf(sortState)]);
    }
  };

  return {
    handleSort,
  };
};

export default useInventoryHelpers;
