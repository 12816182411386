import { FC, useEffect, useState } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import { useCore } from 'src/web/common/core';
import Skeleton from 'src/web/design_system/Skeleton';
import { isString } from 'core/common/utils/predicatesType';
import FormInput from 'src/web/components/FormInput';

interface CustomAssetInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const CustomAssetInfoFormPartial: FC<CustomAssetInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();
  const { customAssetCategoryUseCase } = useCore();

  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<
    Awaited<ReturnType<typeof customAssetCategoryUseCase.getAllCategories>>
  >([]);

  const loadCategories = async () => {
    setIsLoadingCategories(true);
    const response = await customAssetCategoryUseCase.getAllCategories();
    setCategories(response);
    setIsLoadingCategories(false);

    // TODO: Improve this!
    // form.items.category field is not nullable (on schema) and got as default formValue = "" (cf: formatUIFormValues() from helpers func)
    // This method force category.formValue to be null to blank the select box... strange behavior. need refactor.
    if (!isString(form.items.category.formValue) || form.items.category.formValue === '') {
      form.items.category.handleChange(null);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Skeleton isLoading={isLoadingCategories} width="100%" height="55px">
          <FormInput.Select
            label={translate('vehicles.category.label')}
            value={
              isString(form.items.category.formValue) ? form.items.category.formValue : undefined
            }
            options={categories.map((category) => category['@id'])}
            renderOptionItem={(value) => ({
              label: categories.find((opt) => opt['@id'] === value)?.name,
            })}
            renderValue={(value) => categories.find((opt) => opt['@id'] === value)?.name}
            onChange={(value) => {
              form.items.category?.handleChange(value);
            }}
            isFullWidth
            isRequired={form.items.category.required}
          />
        </Skeleton>

        <FormInput.TextAdapted
          item={form.items.asset_manufacturer}
          label={translate('assets.manufacturer.label')}
        />

        <FormInput.TextAdapted
          item={form.items.asset_model}
          label={translate('assets.asset.model.label')}
        />

        <FormInput.TextAdapted
          item={form.items.asset_identification}
          label={translate('computers.asset.identification.label')}
        />
      </Displayer>
    </Card.Basic>
  );
};

export default CustomAssetInfoFormPartial;
