import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface TelephoneLineInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const TelephoneLineInfoFormPartial: FC<TelephoneLineInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.RadioAdapted
          item={form.items.formFactor}
          label={translate('telephoneLines.formFactor.label')}
          renderOptionLabel={(value) =>
            translate(`telephoneLines.formFactor.option.${value}.label`)
          }
        />

        <Form.TextAdapted
          item={form.items.lineNumber}
          label={translate('telephoneLines.lineNumber.label')}
          type="tel"
        />

        <Form.TextAdapted
          item={form.items.asset_identification}
          label={translate('telephoneLines.asset.identification.label')}
        />

        <Form.TextAdapted item={form.items.sim} label={translate('telephoneLines.sim.label')} />

        <Form.TextAdapted item={form.items.puk} label={translate('telephoneLines.puk.label')} />
      </Displayer>
    </Card.Basic>
  );
};

export default TelephoneLineInfoFormPartial;
