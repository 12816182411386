import { FC } from 'react';
import { useCore } from 'src/web/common/core';
import TextPair from 'src/web/components/TextPair';
import { EntitiesEnum } from 'core/common/constants';
import { DynamicAssetDetailsReturn } from 'core/usecases/details.usecases';
import useTranslate from 'src/web/common/translate/useTranslate';

interface DynamicCardFieldsProps {
  dataAsset?: DynamicAssetDetailsReturn<EntitiesEnum.card>;
}
const DynamicCardFields: FC<DynamicCardFieldsProps> = ({ dataAsset }) => {
  const assetCommitment = dataAsset?.asset?.contractualCommitment;
  const { timeService, legalEntityUsecase } = useCore();
  const currency = legalEntityUsecase.getCurrency();
  const { translate } = useTranslate();

  if (dataAsset?.purpose === 'badge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }
  if (dataAsset?.purpose === 'computerBadge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }
  if (dataAsset?.purpose === 'creditCard') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
        <TextPair
          label={translate('cards.expirationDate.label')}
          value={
            dataAsset?.expirationDate
              ? timeService.format(timeService.fromBackend(dataAsset?.expirationDate), 'short')
              : undefined
          }
        />
      </>
    );
  }
  if (dataAsset?.purpose === 'key') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }
  if (dataAsset?.purpose === 'deviceBadge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
        <TextPair label={translate('cards.device.label')} value={dataAsset?.device} />
      </>
    );
  }
  if (dataAsset?.purpose === 'lock') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }
  if (dataAsset?.purpose === 'oilCard') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
        <TextPair
          label={translate('cards.expirationDate.label')}
          value={
            dataAsset?.expirationDate
              ? timeService.format(timeService.fromBackend(dataAsset?.expirationDate), 'short')
              : undefined
          }
        />
      </>
    );
  }
  if (dataAsset?.purpose === 'parkBadge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
        <TextPair label={translate('cards.space.label')} value={dataAsset?.space} />
      </>
    );
  }
  if (dataAsset?.purpose === 'restaurantCard') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }
  if (dataAsset?.purpose === 'securityBadge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
        <TextPair label={translate('cards.space.label')} value={dataAsset?.area} />
      </>
    );
  }
  if (dataAsset?.purpose === 'tollBadge') {
    return (
      <>
        <TextPair
          label={translate('cards.identification.label')}
          value={dataAsset?.asset?.identification}
        />
        <TextPair
          label={translate('cards.expirationDate.label')}
          value={
            dataAsset?.expirationDate
              ? timeService.format(timeService.fromBackend(dataAsset?.expirationDate), 'short')
              : undefined
          }
        />
        <TextPair label={translate('cards.services.label')} value={dataAsset?.services} />
        <TextPair label={translate('cards.provider.label')} value={dataAsset?.provider} />
      </>
    );
  }

  return (
    <>
      <TextPair
        label={translate('assets.financingMethod.label')}
        value={dataAsset?.financingMethod}
      />
      <TextPair
        label={translate('contractualCommitment.supplier.label')}
        value={assetCommitment?.supplier}
      />
      <TextPair
        label={translate('contractualCommitment.reference.label')}
        value={assetCommitment?.reference}
      />
      <TextPair
        label={translate('contractualCommitment.cost.label')}
        value={`${assetCommitment?.priceProrated} ${currency} par ${assetCommitment?.frequency}`}
      />
      <TextPair
        label={translate('contractualCommitment.startDate.label')}
        value={
          assetCommitment?.startDate
            ? timeService.format(timeService.fromBackend(assetCommitment?.startDate), 'short')
            : undefined
        }
      />
      <TextPair
        label={translate('contractualCommitment.autorenew.label')}
        value={
          dataAsset?.asset?.contractualCommitment?.autoRenew
            ? translate('contractualCommitment.autorenew.option.with.label')
            : translate('contractualCommitment.autorenew.option.without.label')
        }
      />
      <TextPair
        label={translate('contractualCommitment.counter.label')}
        value={`${assetCommitment?.counter} ${translate(
          'contractualCommitment.endOfWarranty.option.day.label',
        )}`}
      />
      <TextPair
        label={translate('contractualCommitment.endDate.label')}
        value={
          assetCommitment?.endDate
            ? timeService.format(timeService.fromBackend(assetCommitment?.endDate), 'short')
            : undefined
        }
      />
      <TextPair
        label={translate('contractualCommitment.limit.label')}
        value={`${dataAsset?.asset?.contractualCommitment?.limit}  ${translate(
          'contractualCommitment.endOfWarranty.option.day.label',
        )}`}
      />
    </>
  );
};

export default DynamicCardFields;
