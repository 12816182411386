import { FC, PropsWithChildren, useState } from 'react';
import Modal from 'src/web/components/Modal';
import Button from 'src/web/components/Button';
import GuiButtonIcon, { ButtonIconProps } from 'src/web/design_system/Button/buttonIcon';
import Icon from 'src/web/components/Icon';
import useTranslate from 'src/web/common/translate/useTranslate';

type AllowedGuiButtonProps = Pick<ButtonIconProps, 'size' | 'edge'>;

interface FiltersModalByButtonProps extends AllowedGuiButtonProps {
  onSubmitFilters: () => boolean | Promise<boolean>;
  onCancelFilters?: () => void;
}

const FiltersModalByButton: FC<PropsWithChildren<FiltersModalByButtonProps>> = ({
  children,
  size,
  edge,
  onSubmitFilters,
  onCancelFilters,
}) => {
  const { translate } = useTranslate();
  const [openFilters, setOpenFilters] = useState(false);
  const handleToggleFilter = () => {
    setOpenFilters((current) => !current);
  };

  const handleCancelFilters = () => {
    if (onCancelFilters) {
      onCancelFilters();
    }
    setOpenFilters(false);
  };

  const handleSubmitFilters = async () => {
    Promise.resolve(onSubmitFilters()).then((isSuccess) => {
      if (isSuccess) {
        setOpenFilters(false);
      }
    });
  };

  return (
    <>
      <GuiButtonIcon onClick={handleToggleFilter} size={size} edge={edge}>
        <Icon.Filter />
      </GuiButtonIcon>

      <Modal
        title={translate('global.filters.title')}
        open={openFilters}
        onClose={handleCancelFilters}
        footer={
          <>
            <Button.Custom onClick={() => handleCancelFilters()} variant="outlined">
              {translate('global.filters.cancel')}
            </Button.Custom>
            <Button.Custom onClick={() => handleSubmitFilters()}>
              {translate('global.filters.submit')}
            </Button.Custom>
          </>
        }
      >
        {children}
      </Modal>
    </>
  );
};

export default FiltersModalByButton;
