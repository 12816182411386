import { FC } from 'react';
import OptimizedInput, { OptimizedInputProps } from 'src/web/design_system/Input/Input';

export interface InputTextAreaProps extends Omit<OptimizedInputProps<'text'>, 'mode'> {
  className?: string;
}

const InputTextArea: FC<InputTextAreaProps> = ({ className, ...props }) => {
  const { fullWidth, ...restProps } = props;

  return (
    <div className={className}>
      <OptimizedInput
        mode="text"
        {...restProps}
        fullWidth={fullWidth ?? true}
        minRows={1}
        multiline
      />
    </div>
  );
};

export default InputTextArea;
