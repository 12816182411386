import { Component, ErrorInfo, ReactNode } from 'react';
import RootErrorCatched from './RootErrorCatched';

interface Props {
  children?: ReactNode;
}

type ErrorBoundaryState = {
  hasError: boolean;
  errorMessage?: string;
};

class RootErrorBoundary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update the state so the next render will show the fallback UI
    return {
      hasError: true,
      errorMessage: error.message,
    };
  }

  // Add extra logic here, like log the error to Sentry
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error catched:', error, errorInfo.componentStack);
  }

  public render(): ReactNode {
    const { hasError } = this.state;

    if (hasError) {
      // TODO: Customize the error catched message
      // return <RootErrorCatched errorMessage={this.state?.errorMessage} />
      return <RootErrorCatched />;
    }
    return this.props.children;
  }
}

export default RootErrorBoundary;
