import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap, AssetsTypesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import {
  ExhaustiveValue,
  SwaggerPathsKeys,
  SwaggerPathsMethods,
  SwaggerRequestResponse,
} from 'core/swagger';
import FormService, { FormProperties, FormRequired } from 'core/services/form/form.service';
import { FormValidationHandler } from 'core/services/form/form.port';
import CardEntity from 'core/entities/card.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import ServiceEntity from 'core/entities/service.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ComputerEntity from 'core/entities/computer.entity';
import VehicleEntity from 'core/entities/vehicle.entity';
import CustomAssetEntity from 'core/entities/customAsset.entity';

type SetAssetFormInitialValues = Record<keyof FormProperties, ExhaustiveValue>;

export interface SetAssetFormReturn<
  I = SetAssetFormInitialValues | Promise<SetAssetFormInitialValues>,
> {
  properties: FormProperties;
  initialValues: I;
  required: FormRequired;
  validate: FormValidationHandler;
  submit: <P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    data: object,
  ) => Promise<SwaggerRequestResponse<P, M> | undefined>;
}

class SetAssetUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private formService: FormService,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private licenceEntity: LicenceEntity,
    private serviceEntity: ServiceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private customAssetEntity: CustomAssetEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  public getAddForm(asset: EntitiesEnum) {
    switch (asset) {
      case EntitiesEnum.vehicle:
        return this.getAddVehicleForm();
      case EntitiesEnum.computer:
        return this.getAddComputerForm();
      case EntitiesEnum.licence:
        return this.getAddLicenceForm();
      case EntitiesEnum.service:
        return this.getAddServiceForm();
      case EntitiesEnum.telephone:
        return this.getAddTelephoneForm();
      case EntitiesEnum.telephoneLine:
        return this.getAddTelephoneLineForm();
      case EntitiesEnum.card:
        return this.getAddCardForm();
      case EntitiesEnum.customAsset:
        return this.getAddCustomAssetsForm();
      default:
        // @todo: improve to remove
        return {
          properties: {},
          required: [],
          validate: () => ({}),
          getValues: () => null,
          initialValues: {} as SetAssetFormInitialValues,
          submit: () => new Promise((r) => r(false)),
        };
    }
  }

  public getEditForm(asset: EntitiesEnum, assetId: string) {
    switch (asset) {
      case EntitiesEnum.vehicle:
        return this.getEditVehicleForm(assetId);
      case EntitiesEnum.computer:
        return this.getEditComputerForm(assetId);
      case EntitiesEnum.licence:
        return this.getEditLicenceForm(assetId);
      case EntitiesEnum.service:
        return this.getEditServiceForm(assetId);
      case EntitiesEnum.telephone:
        return this.getEditTelephoneForm(assetId);
      case EntitiesEnum.telephoneLine:
        return this.getEditTelephoneLineForm(assetId);
      case EntitiesEnum.card:
        return this.getEditCardForm(assetId);
      case EntitiesEnum.customAsset:
        return this.getEditCustomAssetForm(assetId);
      default:
        // @todo: improve to remove
        return {
          properties: {},
          required: [],
          validate: () => ({}),
          getValues: () => new Promise((r) => r(null)),
          initialValues: {} as SetAssetFormInitialValues,
          submit: () => new Promise((r) => r(false)),
        };
    }
  }

  /**
   * GET ADD VEHICLE FORM
   */
  public getAddVehicleForm() {
    const formDefinition = this.formService.createForm(this.vehicleEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.vehicleEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.vehicle),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD COMPUTER FORM
   */
  public getAddComputerForm() {
    const formDefinition = this.formService.createForm(this.computerEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.computerEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.computer),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD LICENCE FORM
   */
  public getAddLicenceForm() {
    const formDefinition = this.formService.createForm(this.licenceEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.licenceEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.licence),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD SERVICE FORM
   */
  public getAddServiceForm() {
    const formDefinition = this.formService.createForm(this.serviceEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.serviceEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.service),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD TELEPHONE FORM
   */
  public getAddTelephoneForm() {
    const formDefinition = this.formService.createForm(this.telephoneEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.telephoneEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.telephone),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD TELEPHONE LINE FORM
   */
  public getAddTelephoneLineForm() {
    const formDefinition = this.formService.createForm(
      this.telephoneLineEntity.getPathAll(),
      'post',
    );

    const handleSubmit = (data: object) => {
      return this.telephoneLineEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.telephoneLine),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD CARD FORM
   */
  public getAddCardForm() {
    const formDefinition = this.formService.createForm(this.cardEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.cardEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.card),
      submit: handleSubmit,
    };
  }

  /**
   * GET ADD CUSTOM ASSETS
   */
  public getAddCustomAssetsForm() {
    const formDefinition = this.formService.createForm(this.customAssetEntity.getPathAll(), 'post');

    const handleSubmit = (data: object) => {
      return this.customAssetEntity
        .post({ body: this.formatAddDataToBackend(data) })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: this.getAddFormInitialData(EntitiesEnum.customAsset),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT VEHICLE FORM
   */
  public getEditVehicleForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.vehicleEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.vehicleEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.vehicleEntity.getOne({ pathVar });

      return this.vehicleEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT COMPUTER FORM
   */
  public getEditComputerForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.computerEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.computerEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.computerEntity.getOne({ pathVar });
      return this.computerEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT LICENCE FORM
   */
  public getEditLicenceForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.licenceEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.licenceEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.licenceEntity.getOne({ pathVar });
      return this.licenceEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT SERVICE FORM
   */
  public getEditServiceForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.serviceEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.serviceEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.serviceEntity.getOne({ pathVar });
      return this.serviceEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT TELEPHONE FORM
   */
  public getEditTelephoneForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.telephoneEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.telephoneEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.telephoneEntity.getOne({ pathVar });
      return this.telephoneEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT TELEPHONE LINE FORM
   */
  public getEditTelephoneLineForm(assetId: string) {
    const formDefinition = this.formService.createForm(
      this.telephoneLineEntity.getPathOne(),
      'put',
    );
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.telephoneLineEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.telephoneLineEntity.getOne({ pathVar });
      return this.telephoneLineEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT CARD FORM
   */
  public getEditCardForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.cardEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.cardEntity.getOne({ pathVar });
      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.cardEntity.getOne({ pathVar });
      return this.cardEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * GET EDIT CUSTOM ASSET FORM
   */
  public getEditCustomAssetForm(assetId: string) {
    const formDefinition = this.formService.createForm(this.customAssetEntity.getPathOne(), 'put');
    const pathVar = { id: assetId };

    const getInitialValues = async () => {
      const currentResponse = await this.customAssetEntity.getOne({ pathVar });

      return this.getEditInitialValues(
        formDefinition.properties,
        currentResponse.data,
        currentResponse.data?.asset?.attachmentSite?.['@id'],
        currentResponse.data?.category?.['@id'],
      );
    };

    const handleSubmit = async (data: object) => {
      const currentResponse = await this.customAssetEntity.getOne({ pathVar });
      return this.customAssetEntity
        .put({
          pathVar,
          body: this.formatEditDataToBackend(data, currentResponse.data),
        })
        .then((response) => ({ status: response.status, data: response.data }));
    };

    return {
      ...formDefinition,
      initialValues: getInitialValues(),
      submit: handleSubmit,
    };
  }

  /**
   * ADD FORM INITIAL VALUES
   */
  private getAddFormInitialData(asset: EntitiesEnum) {
    // because the useless asset_legalEntityId is not nullable on the backend
    // TODO: To remove when that will be fixed backend side
    const currentLegalEntity = this.store.getState((state) => state.user.current?.legalEntity?.id);

    //TODO: get an answer from the backend team about how to define it because this useless data is not nullable
    const country = 'FR';

    return {
      asset_legalEntityId: currentLegalEntity,
      asset_assetType: AssetsTypesMap[asset],
      country: country,
    };
  }

  /**
   * ADD FORM HACKS
   * - Make a copy of the input data (from form)
   * - Removes asset_id and asset_ContractualCommitment_id as they wlil be created on the backend
   */
  private formatAddDataToBackend(data: object) {
    const copyData = { ...data };

    // the asset id will be created by the backend
    if ('asset_id' in copyData) {
      delete copyData.asset_id;
    }

    // the asset id will be created by the backend
    if ('asset_contractualCommitment_id' in copyData) {
      delete copyData.asset_contractualCommitment_id;
    }

    return this.formService.unformatData(copyData);
  }

  /**
   * EDIT FORM INITIAL VALUES
   */
  private getEditInitialValues(
    properties: FormProperties,
    currentData?: object,
    attachmentSite?: string,
    customAssetCategory?: string,
  ): SetAssetFormInitialValues {
    let initialValues = {};

    if (currentData) {
      const data = this.formService.formatData(currentData);

      for (const propkey of Object.keys(properties)) {
        if (propkey in data) {
          initialValues = {
            ...initialValues,
            [propkey]: data[propkey],
          };
        }
      }

      // TODO: improve
      // Adds or overrides specific values for asset_attachmentSite and category if provided.
      if (attachmentSite) {
        initialValues = {
          ...initialValues,
          asset_attachmentSite: attachmentSite,
        };
      }
      if (customAssetCategory) {
        initialValues = {
          ...initialValues,
          category: customAssetCategory,
        };
      }
    }

    return initialValues;
  }

  /**
   * EDIT FORM HACKS
   * - formats the data from the "edit" form before sending it to the backend
   */
  private formatEditDataToBackend(data: object, currentData?: object) {
    let copyCurrentOne = { ...this.formService.formatData(currentData ?? {}) };

    // if asset_comment is not present in the data, adds it with a value of null
    if (!('asset_comment' in data)) {
      copyCurrentOne = {
        ...copyCurrentOne,
        asset_comment: null,
      };
    }

    // Removes any fields starting with asset_attachmentSite or category
    Object.keys(copyCurrentOne).forEach((key) => {
      if (key.startsWith('asset_attachmentSite')) {
        delete copyCurrentOne[key];
      }
    });

    Object.keys(copyCurrentOne).forEach((key) => {
      if (key.startsWith('category')) {
        delete copyCurrentOne[key];
      }
    });

    // Combine the formatted current data with the new data
    return this.formService.unformatData({
      ...copyCurrentOne,
      ...data,
    });
  }
}

export default SetAssetUseCase;
