import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import VehicleEntity from 'core/entities/vehicle.entity';

interface RealPartialSivinPayload {
  manufacturer?: string;
  modelName?: string;
  fuelType?: string;
  bodyType?: string;
  fiscalHorsepower?: number;
  maxAllowedWeight?: number;
  co2Emissions: number;
  registrationDate: string;

  // The following fields are present in the payload but not used in the app
  paintColor?: string;
  veiculeCategoryCode: string;
}

export interface SivinAppData {
  intoCirculationDate?: Date;
  manufacturer?: string;
  model?: string;
  weightLimit?: number;
  category?: string;
  fuelType?: string;
  horsePower?: number;
  co2Emission?: number;
}

class SivinUseCase implements Usecase {
  constructor(private store: AppStore, private vehicleEntity: VehicleEntity) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);
    return isUserGranted(currentUser, this.getRoles());
  }

  getSivinData() {
    return {
      requestData: (identification?: string) => this.requestSivinData(identification),
    };
  }

  private async requestSivinData(identification?: string): Promise<SivinAppData> {
    const result: SivinAppData = {};

    if (identification) {
      try {
        const response = await this.vehicleEntity.getSivin({
          pathVar: { licencePlate: identification },
        });

        if (!response.data) return {};

        const {
          manufacturer,
          modelName: model,
          maxAllowedWeight: weightLimit,
          bodyType: category,
          fuelType,
          fiscalHorsepower: horsePower,
          co2Emissions: co2Emission,
          registrationDate,
        } = response.data as RealPartialSivinPayload;

        return {
          manufacturer,
          model,
          weightLimit,
          category,
          fuelType,
          horsePower,
          co2Emission,
          intoCirculationDate: registrationDate ? new Date(registrationDate) : undefined,
        };
      } catch (error) {
        // TODO: broadcast an event or rethrow the error
        return {};
      }
    }

    return result;
  }
}

export default SivinUseCase;
