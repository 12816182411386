import { type FC } from 'react';
import { BaseButtonProps } from '.';
import Icon from 'src/web/components/Icon';
import ButtonBase from './buttonBase';

interface ButtonCreateProps extends BaseButtonProps {
  to?: string;
  onClick?: () => void;
}

const DEFAULT_LABEL = 'Creadte';

const ButtonCreate: FC<ButtonCreateProps> = ({ to, onClick, label }) => {
  return (
    <ButtonBase variant="contained" onClick={onClick} to={to} startIcon={<Icon.Add />}>
      {label ?? DEFAULT_LABEL}
    </ButtonBase>
  );
};

export default ButtonCreate;
