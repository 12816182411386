import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FiltersData } from './useCreateInventory';

export function useHistoryPagination(
  inventoryChangeNumberItem: (e: number) => void,
  inventoryChangePage: (e: number) => void,
  filters: Record<string, FiltersData>,
  filterIsLoaded: boolean,
) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const defaultItemPerPage = 10;
  const defaultPage = 1;
  const pageFromUrl = Number(searchParams.get('page')) || defaultPage;
  const itemPerPageFromUrl = Number(searchParams.get('itemPerpage')) || defaultItemPerPage;

  const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page')) || 1);
  const [itemPerPage, setItemPerPage] = useState(
    Number(searchParams.get('itemPerpage')) || defaultItemPerPage,
  );

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    inventoryChangePage(newPage);
  };

  const handleChangeItemPerPage = (newItemPerPage: number) => {
    setItemPerPage(newItemPerPage);
    inventoryChangeNumberItem(newItemPerPage);
  };

  useEffect(() => {
    handleChangeItemPerPage(itemPerPage);
    handleChangePage(currentPage);
  }, [pageFromUrl, itemPerPageFromUrl]);

  //update the current url query depend of filters
  useEffect(() => {
    if (filterIsLoaded) {
      const filtersQueryString = Object.entries(filters)
        .flatMap(([key, filter]) => {
          if (Array.isArray(filter.value)) {
            const combinedValues = filter.value.map((val) => encodeURIComponent(val)).join(',');
            return `${encodeURIComponent(key)}=${combinedValues}`;
          } else if (filter.value !== undefined) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(filter.value)}`;
          } else {
            return [];
          }
        })
        .join('&');

      const queryString = `?itemPerpage=${itemPerPage}&page=${currentPage}${
        filtersQueryString ? '&' + filtersQueryString : ''
      }`;

      navigate(queryString);
    }
  }, [currentPage, itemPerPage, filters]);

  return { handleChangePage, handleChangeItemPerPage };
}
