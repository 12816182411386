import InventoryCardContainer from './inventoryCardContainer';
import InventoryCardCurrentFilters from './inventoryCardCurrentFilters';
import InventoryCardInventory from './inventoryCardInventory';
import InventoryCardPagination from './inventoryCardPagination';
import InventoryCardToolbar from './inventoryCardToolbar';

export default {
  Container: InventoryCardContainer,
  Toolbar: InventoryCardToolbar,
  CurrentFilters: InventoryCardCurrentFilters,
  Inventory: InventoryCardInventory,
  Pagination: InventoryCardPagination,
};
