import dayjs, { ManipulateType } from 'dayjs';
import { TimeClient, TimeNormalizedFormats } from 'core/services/time/time.port';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';

class DayjsAdapter implements TimeClient {
  client: typeof dayjs;

  constructor() {
    this.client = dayjs;
    this.client.extend(localizedFormat);
    dayjs.extend(objectSupport);
  }

  public updateLocale(lang: string) {
    dayjs.locale(lang);
  }

  public toBackend: TimeClient['toBackend'] = (value, format) => {
    return this.client(value).format(format);
  };

  public fromBackend: TimeClient['fromBackend'] = (value, format) => {
    return this.client(value, format).toDate();
  };

  public format: TimeClient['format'] = (value, format) => {
    return this.client(value).format(this.denormalizeFormat(format));
  };

  public isFuture: TimeClient['isFuture'] = (ref, value) => {
    return this.client(ref).diff(value) > 0;
  };

  public manipulate: TimeClient['manipulate'] = (value, rules) => {
    let base = this.client(value);

    Object.keys(rules).forEach((key) => {
      const rule = rules[key as keyof typeof rules];
      const unit = this.getUnit(key);
      if (rule && unit) {
        if (rule > 0) {
          base = base.add(rule, unit);
        }
        if (rule < 0) {
          base = base.subtract(-rule, unit);
        }
      }
    });

    return base.toDate();
  };

  public initTime: TimeClient['initTime'] = (value, unit) => {
    const adaptedUnit = this.getUnit(unit);

    if (adaptedUnit) {
      return this.client(value).startOf(adaptedUnit).toDate();
    }

    return value;
  };

  private getUnit = (value: string): ManipulateType | undefined => {
    switch (value) {
      case 'day':
        return 'day';
      case 'week':
        return 'week';
      case 'year':
        return 'year';
      default:
        return undefined;
    }
  };

  public build(year: number, month: number, day: number): Date {
    return this.client({ year, month, day }).toDate();
  }

  private denormalizeFormat(format: TimeNormalizedFormats): string {
    switch (format) {
      case 'short':
        return 'L';
      default:
        return 'L';
    }
  }
}

export default DayjsAdapter;
