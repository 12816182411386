import { FC, PropsWithChildren, useMemo } from 'react';
import MuiAvatar from '@mui/material/Avatar';

interface AvatarProps {
  src?: string;
  alt?: string;
  shape?: 'circle' | 'square' | 'round';
  size?: number;
  bgColor?: string;
}

const Avatar: FC<PropsWithChildren<AvatarProps>> = ({
  children,
  src,
  alt,
  shape = 'circle',
  size,
  bgColor,
}) => {
  const getVariant = useMemo(() => {
    switch (shape) {
      case 'round':
        return 'rounded';
      case 'square':
        return 'square';
      case 'circle':
      default:
        return undefined;
    }
  }, [shape]);

  const customSize = useMemo(() => {
    let sx = {};

    if (size) {
      sx = {
        ...sx,
        width: size,
        height: size,
      };
    }

    return sx;
  }, [size]);

  return (
    <MuiAvatar
      src={src}
      alt={alt}
      variant={getVariant}
      sx={{
        ...customSize,
        bgcolor: bgColor,
      }}
    >
      {children}
    </MuiAvatar>
  );
};

export default Avatar;
