import { FC, PropsWithChildren } from 'react';
import Card from 'src/web/design_system/NewCard';
import GuiPopover, { PopoverProps as GuiPopoverProps } from 'src/web/design_system/Popover';

type PopoverProps = GuiPopoverProps;

const Popover: FC<PropsWithChildren<PopoverProps>> = ({
  children,
  targetRef,
  isOpen,
  onChange,
}) => {
  return (
    <>
      <GuiPopover
        targetRef={targetRef}
        isOpen={isOpen}
        onChange={(state) => {
          if (onChange) {
            onChange(state);
          }
        }}
      >
        <Card.Container>
          <Card.Content compact>{children}</Card.Content>
        </Card.Container>
      </GuiPopover>
    </>
  );
};

export default Popover;
