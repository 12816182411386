import { useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

const useEditContact = () => {
  const { legalEntityUsecase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const { initialValues, properties, required, validate, submit } = useMemo(
    () => legalEntityUsecase.getUpdateLegalEntityForm(),
    [legalEntityUsecase],
  );

  const form = useCreateForm({
    initialValues,
    properties,
    required,
    validate,
    onFormChange: () => {
      setShowSubmit(true);
    },
    submit: async (data) => {
      setIsLoading(true);

      await submit(data);
      setShowSubmit(false);
      setIsLoading(false);
    },
  });

  const exposeOnlyNecessaryContactFields = useMemo(() => {
    const filteredItems = {
      personFirstname: form.items.personFirstname,
      personLastname: form.items.personLastname,
      personJobTitle: form.items.personJobTitle,
      personEmail: form.items.personEmail,
      personTelephone: form.items.personTelephone,
    };

    return {
      ...form,
      items: filteredItems,
    };
  }, [form]);

  return {
    form: exposeOnlyNecessaryContactFields,
    isLoading,
    showSubmit,
  };
};

export default useEditContact;
