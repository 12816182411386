import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap, RolesEnum } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import { isStringArray } from 'core/common/utils/predicatesType';
import EnumEntity from 'core/entities/enum.entity';

class RolesUseCase implements Usecase {
  private shadowRoles: string[] = [RolesEnum.ROLE_USER];

  constructor(private store: AppStore, private enumEntity: EnumEntity) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  getAvailableRoles = async () => {
    return this.enumEntity
      .get({
        pathVar: {
          id: 'EnumUserRolesAvailableType',
        },
      })
      .then((response) => {
        if (isStringArray(response.data)) {
          return response.data;
        }

        return [];
      });
  };

  public getShadowRoles() {
    return this.shadowRoles;
  }
}

export default RolesUseCase;
