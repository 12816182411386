import { useMemo } from 'react';
import { RolesEnum } from 'core/common/constants';
import Icon, { type IconProps } from 'src/web/components/Icon';
import { useCore } from '../core';
import useTranslate from '../translate/useTranslate';

interface RolesDisplay {
  key: string;
  label: string;
  icon: React.FC<IconProps>;
}

const useDisplayRoles = (roles: string[]): RolesDisplay[] => {
  const { rolesUseCase } = useCore();
  const { translate } = useTranslate();
  const displayedIcon = (role: string) => {
    switch (role) {
      case RolesEnum.ROLE_ADMIN_USER:
        return Icon.RoleUser;
      case RolesEnum.ROLE_ADMIN_PERSON:
        return Icon.RolePerson;
      case RolesEnum.ROLE_USER_NOTIFICATION:
        return Icon.RoleNotification;
      case RolesEnum.ROLE_ADMIN_VEHICLE:
        return Icon.RoleVehicle;
      case RolesEnum.ROLE_ADMIN_ASSET:
        return Icon.RoleAsset;
      case RolesEnum.ROLE_ADMIN_COMPUTER:
        return Icon.RoleComputer;
      case RolesEnum.ROLE_ADMIN_TELEPHONE:
        return Icon.RoleTelephone;
      case RolesEnum.ROLE_ADMIN_TELEPHONE_LINE:
        return Icon.RoleTelephoneLine;
      case RolesEnum.ROLE_ADMIN_CARD:
        return Icon.RoleCard;
      case RolesEnum.ROLE_ADMIN_SERVICE:
        return Icon.RoleService;
      case RolesEnum.ROLE_ADMIN_LICENCE:
        return Icon.RoleLicence;
      case RolesEnum.ROLE_DISTRIBUTOR:
        return Icon.RoleDistributor;
      case RolesEnum.ROLE_MY_LEGAL_ENTITY:
        return Icon.RoleLegalEntity;
      case RolesEnum.ROLE_BLUE_PRINT_RO:
        return Icon.RoleBluePrint;
      case RolesEnum.ROLE_ADMIN_CONTRACTUAL_COMMITMENT:
        return Icon.RoleContractualCommitment;
      case RolesEnum.ROLE_ADMIN_LEGAL_ENTITY_NOTIFICATION:
        return Icon.RoleLegalEntityNotification;
      case RolesEnum.ROLE_ADMIN_CUSTOM_FORM:
        return Icon.RoleCustomForm;
      default:
        return Icon.Random;
    }
  };

  const filteredRoles = useMemo(
    () => roles.filter((role) => !rolesUseCase.getShadowRoles().includes(role)),
    [roles],
  );

  return filteredRoles.map((item) => ({
    key: item,
    label: translate(`enums.EnumUserRolesAvailableType.${item}.label`),
    icon: displayedIcon(item),
  }));
};

export default useDisplayRoles;
