import { FC } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { ROUTE_COMPUTER } from 'src/web/common/router/constantes';
import useAddAssetForm from './hooks/useAddAssetForm';
import PageContainerPartial from './partials/pageContainerPartial';
import FinancingFormPartial from './partials/financingFormPartial';
import DetailFormPartial from './partials/DetailFormPartial';
import ComputerInfoFormPartial from './partials/computerInfoFormPartial';
import useTranslate from 'src/web/common/translate/useTranslate';
import { useSearchParams } from 'react-router-dom';

const ENTITY: EntitiesEnum = EntitiesEnum.computer;

const AddComputerPage: FC = () => {
  const { form, isLoading } = useAddAssetForm({
    asset: ENTITY,
    baseRoute: ROUTE_COMPUTER,
  });
  const { translate } = useTranslate();

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  return (
    <PageContainerPartial
      pageTitle={translate('assets.computer.add.title')}
      submitLabel={translate('assets.add.submit')}
      canSubmit={form.isDirty && !form.hasErrors}
      onSubmit={(e) => form.handleSubmit(e)}
      isLoading={isLoading}
      backNavigate={`${ROUTE_COMPUTER}?${queryString}`}
    >
      <ComputerInfoFormPartial form={form} />

      <DetailFormPartial entityType={ENTITY} form={form} />

      <FinancingFormPartial form={form} />
    </PageContainerPartial>
  );
};

export default AddComputerPage;
