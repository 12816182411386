import { FC, useMemo } from 'react';
import useBoarding from './hooks/useBoarding';
import Skeleton from 'src/web/design_system/Skeleton';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';
import Card from 'src/web/components/Card';
import FormInput from 'src/web/components/FormInput';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import Icon from 'src/web/components/Icon';
import Button from 'src/web/components/Button';
import { useCore } from 'src/web/common/core';
import Grid from 'src/web/design_system/Grid';
import { isString, isStringNotEmpty } from 'core/common/utils/predicatesType';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import { TaskTopics } from 'core/common/constants';
import useBoardingAssignments from './hooks/useBoardingAssignments';
import AssetAssignment from 'src/web/components/AssetAssignment';
import Toolbar from 'src/web/design_system/Toolbar';
import useTranslate from 'src/web/common/translate/useTranslate';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';
import { useParams, useSearchParams } from 'react-router-dom';

interface OffboardingPageProps {
  isEdition?: boolean;
}

const OffboardingPage: FC<OffboardingPageProps> = ({ isEdition }) => {
  const { timeService } = useCore();
  const {
    isDirty,
    personForm,
    personData,
    isLoading: isBoardingLoading,
    taskRows,
    taskTypes,
    handleAddTask,
    personLimitAction,
    handleSubmit,
    handleSearchRowOwner,
    handleDeleteRow,
    isSubmitLoading,
  } = useBoarding(TaskTopics.peopleOffBoarding);
  const { translate, translateWithPlural } = useTranslate();

  const { isLoading: isAssignmentLoading, currentAssignments } = useBoardingAssignments(
    personData?.['@id'],
  );

  const isLoading = isBoardingLoading || isAssignmentLoading;

  // Build displayed person name
  const personFullName = useMemo(() => {
    return [personData?.lastname, personData?.firstname].filter((d) => !!d).join(' ');
  }, [personData]);

  // Check is the task is not the last one
  const canDeleteRow = useMemo(() => taskRows.length > 1, [taskRows]);

  // can submit the page
  const canSubmit = useMemo((): boolean => {
    const formSubmittable = taskRows.every((row) => {
      return Object.keys(row.items).every((key) => {
        return Object.keys(row.items[key].errors).length === 0;
      });
    });

    return isDirty && formSubmittable && !isLoading && !!personForm.items.exitAt.formValue;
  }, [taskRows, isDirty, isLoading, personForm.items.exitAt.formValue]);

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { id } = useParams();

  return (
    <>
      <PageHeaderBasic
        title={isEdition ? translate('offBoarding.edit.title') : translate('offBoarding.add.title')}
        subtitle={
          <Skeleton isLoading={isLoading}>
            <Text variant="textLarge">{personFullName}</Text>
          </Skeleton>
        }
      />

      <Button.BackNavigate
        to={`${ROUTE_PERSON}/${id}?${queryString}`}
        label={translate('global.navigateBack.details.label')}
      />
      <Displayer innerSpacing={3}>
        <Card.Container>
          <Card.Toolbar>
            <Text variant="sectionTitle">{translate('offBoarding.exitDate.title')}</Text>
          </Card.Toolbar>
          <Card.Content>
            <Skeleton isLoading={isLoading}>
              <FormInput.DateAdapted
                item={personForm.items.exitAt}
                minDate={timeService.now()}
                fullWidth={false}
              />
            </Skeleton>
          </Card.Content>
        </Card.Container>

        {currentAssignments && currentAssignments?.length > 0 && (
          <Card.Container>
            <Card.Toolbar>
              <Text variant="sectionTitle">
                {translateWithPlural(
                  'offBoarding.currentAssignment.title',
                  'offBoarding.currentAssignments.title',
                  currentAssignments.length,
                  { count: `(${currentAssignments?.length})` },
                )}
              </Text>
            </Card.Toolbar>
            <Card.Content>
              <Grid.Container>
                <Skeleton isLoading={isAssignmentLoading} width="100%" height="3rem">
                  {currentAssignments?.map((assignment) => (
                    <Grid.Item size={{ xs: 12, sm: 12, md: 6, lg: 4 }} key={assignment['@id']}>
                      <AssetAssignment
                        assignment={{
                          iri: assignment['@id'],
                          startAt: timeService.fromBackend(assignment.startAt),
                          endAt: assignment.endAt
                            ? timeService.fromBackend(assignment.endAt)
                            : undefined,
                          assetId: assignment.asset.id,
                          assetIdentification: assignment.asset.identification,
                          assetModel: assignment.asset.model ?? '',
                          assetType: assignment.asset.assetType,
                          assetCategory: assignment.asset.category ?? '',
                          service_Name: assignment.asset.service.name,
                          service_Provider: assignment.asset.service.provider,
                          telephoneLine_LineNumber: assignment.asset.telephoneLine.lineNumber,
                          assetManufacturer: assignment.asset.manufacturer,
                        }}
                      />
                    </Grid.Item>
                  ))}
                </Skeleton>
              </Grid.Container>
            </Card.Content>
          </Card.Container>
        )}

        <Card.Container>
          <Card.Toolbar
            secondaryContent={
              <ButtonIcon onClick={() => handleAddTask()} edge="end">
                <Icon.Add />
              </ButtonIcon>
            }
          >
            <Text variant="sectionTitle">{translate('offBoarding.task.title')}</Text>
          </Card.Toolbar>
          <Card.Content>
            <Skeleton isLoading={isLoading} width="100%" height="5rem">
              <Displayer innerSpacing={2}>
                {taskRows.map((taskRow) => (
                  <Toolbar
                    noGutters
                    key={taskRow.id}
                    primaryContent={
                      <Grid.Container>
                        <Grid.Item size={3}>
                          <FormInput.Select
                            label={translate('boarding.task.label')}
                            value={
                              isString(taskRow.items.task.value) ? taskRow.items.task.value : ''
                            }
                            options={taskTypes.map((option) => option.value)}
                            renderOptionItem={(value) => ({
                              label: translate(
                                `tasks.task.option.${
                                  taskTypes.find((el) => el.value === value)?.label
                                }.label`,
                              ),
                            })}
                            renderValue={(value) =>
                              translate(
                                `tasks.task.option.${
                                  taskTypes.find((el) => el.value === value)?.label
                                }.label`,
                              )
                            }
                            onChange={(value) => {
                              if (isString(value)) {
                                taskRow.items.task.handleChange(value);
                              }
                            }}
                            isFullWidth
                            isRequired={taskRow.items.task.required}
                            isError={taskRow.isDirty && taskRow.items.task.hasErrors}
                          />
                        </Grid.Item>
                        <Grid.Item size={3}>
                          <Autocomplete
                            noOptionsText={translate('global.autocomplete.noOptions')}
                            value={
                              isStringNotEmpty(taskRow.items.owner.value)
                                ? {
                                    label: taskRow.items.owner.value,
                                    value: taskRow.items.owner.value,
                                  }
                                : undefined
                            }
                            label={translate('boarding.owner.label')}
                            onChange={(value) => {
                              if (Array.isArray(value)) {
                                taskRow.items.owner.handleChange(value[0]);
                              } else {
                                taskRow.items.owner.handleChange(value);
                              }
                            }}
                            isRequired={taskRow.items.owner.required}
                            renderOption={(item) => item.label}
                            selectValue={(item) => item.value}
                            isFullWidth
                            onSearch={(value) => handleSearchRowOwner(taskRow.id, value)}
                            options={taskRow.owners}
                            isError={taskRow.isDirty && taskRow.items.owner.hasErrors}
                          />
                        </Grid.Item>
                        <Grid.Item size={3}>
                          <FormInput.Date
                            label={translate('boarding.limit.label')}
                            value={
                              isStringNotEmpty(taskRow.items.deadline.value)
                                ? timeService.fromBackend(taskRow.items.deadline.value)
                                : undefined
                            }
                            onChange={(value) => {
                              if (value) {
                                taskRow.items.deadline.handleChange(timeService.toBackend(value));
                              }
                            }}
                            isRequired={taskRow.items.deadline.required}
                            minDate={timeService.now()}
                            maxDate={personLimitAction}
                            error={taskRow.isDirty && taskRow.items.deadline.hasErrors}
                          />
                        </Grid.Item>
                        <Grid.Item size={3}>
                          <FormInput.Text
                            label={translate('boarding.comment.label')}
                            value={
                              isString(taskRow.items.comment.value)
                                ? taskRow.items.comment.value
                                : ''
                            }
                            onChange={(value) => taskRow.items.comment.handleChange(value)}
                            isRequired={taskRow.items.comment.required}
                            error={taskRow.isDirty && taskRow.items.comment.hasErrors}
                          />
                        </Grid.Item>
                      </Grid.Container>
                    }
                    secondaryContent={
                      <ButtonIcon
                        title={translate('offBoarding.delete.tooltip')}
                        onClick={() => handleDeleteRow(taskRow.id)}
                        disabled={!canDeleteRow}
                        edge="end"
                      >
                        <Icon.Delete />
                      </ButtonIcon>
                    }
                  />
                ))}
              </Displayer>
            </Skeleton>
          </Card.Content>
          <Card.Footer>
            <Button.Custom
              isLoading={isSubmitLoading}
              onClick={() => handleSubmit()}
              fullWidth={false}
              disabled={!canSubmit}
            >
              Enregistrer
            </Button.Custom>
          </Card.Footer>
        </Card.Container>
      </Displayer>
    </>
  );
};

export default OffboardingPage;
