import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { nanoid } from 'nanoid';
import { FC, ReactNode, useMemo } from 'react';

type RadioFieldPrimitiveValue = string | number | boolean | undefined;

export interface RadioFieldItem {
  value: RadioFieldPrimitiveValue;
  label: ReactNode;
  disabled?: boolean;
}

export interface RadioFieldProps {
  value?: RadioFieldPrimitiveValue;
  label?: string;
  direction?: 'vertical' | 'horizontal';
  options: RadioFieldItem[];
  isError?: boolean;
  isRequired?: boolean;
  message?: string;
  fullWidth?: boolean;
  onChange?: (value: RadioFieldPrimitiveValue) => void;
}

const RadioField: FC<RadioFieldProps> = ({
  value,
  label,
  options,
  isError,
  onChange,
  isRequired,
  message,
  fullWidth,
  direction = 'horizontal',
}) => {
  const labelId = useMemo(() => nanoid(), []);

  return (
    <FormControl error={isError} required={isRequired} fullWidth={fullWidth}>
      {!!label && <FormLabel id={labelId}>{label}</FormLabel>}
      <RadioGroup
        row={direction === 'horizontal'}
        aria-labelledby={labelId}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        name="radio-buttons-group"
      >
        {options.map((option) => (
          <FormControlLabel
            key={`${labelId}${String(option.value)}`}
            control={<Radio data-test-id="gui_radio" />}
            value={option.value}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </RadioGroup>
      {!!message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
};

export default RadioField;
