import React, { useMemo } from 'react';
import { themeColors } from 'src/web/common/theme/constant';

interface FleezyIconProps {
  color?: 'primary' | 'grey';
  width?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'auto';
}

const FleezyIcon: React.FC<FleezyIconProps> = ({ color = 'primary', width = 'auto' }) => {
  const colors = useMemo(() => {
    switch (color) {
      case 'grey':
        return {
          color1: themeColors.blackColorLight,
          color2: themeColors.blackColorLight,
          color3: themeColors.blackColorLight,
        };
      default:
        return {
          color1: themeColors.secondaryColor,
          color2: themeColors.secondaryColor,
          color3: themeColors.secondaryColor,
        };
    }
  }, [color, themeColors]);

  const actualWidth = useMemo(() => {
    switch (width) {
      case 'sm':
        return '75px';
      case 'md':
        return '100px';
      case 'lg':
        return '150px';
      case 'xl':
        return '200px';
      case '2xl':
        return '300px';
      default:
        return 'auto';
    }
  }, [width]);

  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.83 139.62"
      width={actualWidth}
    >
      <g>
        <path
          style={{ fill: colors.color3 }}
          d="m123.17,61.54c.28,32.78-26.35,61.6-61.58,61.62C27.63,123.18-.06,95.83,0,61.51.06,26.43,28.66.02,61.5,0c34.28-.03,61.87,27.99,61.67,61.54Zm-26.76,10.53c.21-7.8-5.97-14.09-12.81-14.82-1.26-.13-2.54-.18-3.8-.32-6.09-.68-10.3-3.87-12.59-9.54-.75-1.86-.98-3.83-1.02-5.84-.18-10.06-9.6-16.47-18.33-14.32-6.3,1.55-11.76,7.27-11.49,15.06.25,7.35,5.62,13.21,12.76,14.16,1.49.2,3.02.18,4.51.4,5.95.85,10.08,4.02,12.1,9.76.61,1.74.84,3.55.9,5.39.03.87.02,1.76.18,2.61,1.56,8.19,8.77,12.87,15.83,12.24,8.78-.78,13.9-8.19,13.75-14.78Zm-69.68,18.51c0,1.11-.05,2.22.01,3.33.08,1.52.87,2.3,2.4,2.44.55.05,1.11.01,1.67-.02,3.81-.25,7.61-.53,11.35-1.3,3.51-.72,6.95-1.67,10.15-3.32,4.72-2.44,8.09-6.12,9.79-11.18,1.48-4.41,1.38-8.79-.9-12.92-3.14-5.69-9.19-8.61-16.49-7.16-5.69,1.14-9.91,4.49-12.83,9.46-1.33,2.27-2.23,4.74-2.92,7.27-1.18,4.39-1.85,8.85-2.23,13.39Zm54.55-56.59c-5.93-.39-10.35,4.38-9.68,9.73.7,5.59,5.49,8.12,9.27,7.77,6-.55,8.5-5.51,8.22-9.35-.03-.39-.12-.78-.16-1.18-.07-.78.28-1.16,1.06-1.21.58-.04,1.11.07,1.6.35.34.2.66.44,1.02.61.62.3,1.33.09,1.65-.47.27-.47.2-1.1-.23-1.53-.87-.88-1.9-1.42-3.16-1.48-.88-.04-1.75-.02-2.6.21-.53.14-.91-.02-1.27-.4-.38-.4-.78-.8-1.2-1.15-.43-.36-.47-.78-.35-1.28.13-.54.27-1.08.31-1.63.11-1.5-.36-2.82-1.38-3.93-.57-.62-1.34-.68-1.91-.21-.49.4-.61,1-.12,1.69.63.9.92,1.83.68,2.91-.1.43-.32.71-.81.66-.39-.04-.79-.1-.94-.11Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m477.78,87.92c.34-.36.52-.45.55-.58,1.13-5.63,3.14-11,4.97-16.42,3.22-9.56,6.45-19.12,9.69-28.68q.89-2.64,3.7-2.64c4.14,0,8.28.09,12.42-.04,2.38-.07,3.34.89,2.36,3.36-3.55,8.94-7,17.91-10.48,26.88-6.34,16.3-12.67,32.59-19.01,48.89-2.65,6.82-7.13,12.16-13.21,16.19-2.81,1.86-5.83,3.28-9.1,4.07-5.45,1.32-10.74.69-15.84-1.57-.8-.35-1.59-.74-2.34-1.19-1.18-.7-1.46-1.38-.94-2.58,1.31-3.07,2.66-6.12,4.05-9.16.59-1.29,1.4-1.55,2.72-1.02.81.33,1.58.74,2.4,1.06,2.51.98,4.98.87,7.4-.31,4.81-2.34,8.49-5.84,10.81-10.69.72-1.5,1.25-3.1,1.97-4.6.53-1.1.47-2.07-.01-3.18-2.45-5.61-4.83-11.24-7.25-16.87-6.42-14.98-12.85-29.95-19.26-44.93-.31-.73-.65-1.46-.88-2.21-.3-.99.36-1.93,1.39-2.06.47-.06.95-.04,1.43-.04,4.38,0,8.76,0,13.13,0,.48,0,.95.01,1.43.03.62.03,1.05.37,1.31.9.24.5.42,1.03.61,1.55,4.44,12.17,8.87,24.34,13.31,36.51,1.09,2.99,1.86,6.07,2.68,9.32Z"
        ></path>
        <path
          style={{ fill: colors.color2 }}
          d="m272.41,77.27c-6.61,0-13.21,0-19.82,0-.72,0-1.44-.01-2.14.07-.91.1-1.45.72-1.4,1.62.04.71.16,1.43.36,2.11,1.99,7.02,6.6,11.46,13.55,13.47,5.05,1.47,10.08,1.03,15.03-.58,2.88-.94,5.59-2.27,8.15-3.91.4-.26.79-.54,1.2-.78,1.43-.85,2.28-.65,3.11.85,1.47,2.64,2.89,5.31,4.3,7.98,1.01,1.91.92,2.34-.77,3.58-3.15,2.33-6.58,4.17-10.26,5.51-9.5,3.48-19.15,3.99-28.76.73-12.29-4.16-19.88-12.81-22.86-25.37-1.89-7.94-1.29-15.84,1.75-23.43,4.48-11.19,12.76-17.96,24.54-20.44,5.46-1.15,10.93-1.14,16.33.24,9.48,2.42,15.88,8.36,19.44,17.42,1.89,4.81,2.59,9.82,2.47,14.96-.03,1.27-.08,2.55-.19,3.81-.13,1.55-.7,2.06-2.29,2.14-.56.03-1.11,0-1.67,0-6.69,0-13.37,0-20.06,0Zm-8.07-11.81c3.82,0,7.64,0,11.45,0,.56,0,1.12.03,1.67-.02,1.18-.11,1.88-.92,1.71-2.11-.33-2.21-.95-4.34-2.16-6.26-1.74-2.75-4.19-4.57-7.32-5.37-6.97-1.8-16.53.66-19.73,10.97-.57,1.84.05,2.72,1.97,2.79.48.02.95,0,1.43,0,3.66,0,7.32,0,10.98,0Z"
        ></path>
        <path
          style={{ fill: colors.color2 }}
          d="m344.48,77.27c-6.69,0-13.37,0-20.06,0-.71,0-1.44-.01-2.14.08-.85.11-1.4.82-1.36,1.68.02.39.12.78.21,1.17,1.78,7.5,6.47,12.23,13.81,14.35,5.57,1.61,11.03.91,16.37-1.08,2.39-.89,4.65-2.07,6.79-3.44.33-.21.66-.45,1-.66,1.59-.97,2.41-.8,3.3.8,1.5,2.71,2.94,5.46,4.41,8.19.04.07.08.14.11.21.6,1.33.47,1.95-.72,2.87-2.08,1.62-4.34,2.95-6.71,4.1-6.71,3.25-13.79,4.71-21.26,4.43-5.81-.22-11.36-1.45-16.5-4.18-9.92-5.25-15.88-13.55-17.98-24.5-1.48-7.73-.86-15.39,2.22-22.71,4.61-10.93,12.83-17.52,24.43-19.92,5.46-1.13,10.93-1.11,16.32.29,9.67,2.5,16.09,8.64,19.55,17.93,2.14,5.77,2.7,11.75,2.11,17.85-.03.32-.06.64-.12.95-.19.91-.67,1.41-1.59,1.53-.63.08-1.27.06-1.91.06-6.77,0-13.53,0-20.3,0Zm-8.13-11.81c3.97,0,7.95.01,11.92,0,2.58,0,3.07-.62,2.66-3.17-.03-.16-.04-.32-.08-.47-1.28-5.56-4.87-9.41-10.7-10.45-8-1.43-15.92,3.35-18.22,11.12-.62,2.1,0,2.95,2.26,2.96,4.05.02,8.11,0,12.16,0Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m400.17,93.79c.32.39.65.29.93.25,2.45-.34,4.92-.27,7.39-.27,8.43,0,16.87,0,25.3,0,.64,0,1.28-.03,1.91.04,1.15.13,1.73.7,1.74,1.87.04,3.58.04,7.16,0,10.74-.02,1.44-.59,1.93-2.08,2.01-.56.03-1.11,0-1.67,0-17.82,0-35.65,0-53.47,0-.64,0-1.28.03-1.91-.02-1.04-.09-1.66-.63-1.68-1.65-.06-2.3-.03-4.61-.03-6.92,0-.49.19-.93.5-1.31.35-.43.69-.87,1.04-1.31,9.72-12.18,19.45-24.37,29.17-36.55,1.49-1.87,3.04-3.68,4.74-5.36.32-.32.65-.63.86-.99-.43-.47-.84-.3-1.21-.29-10.26.03-20.53.09-30.79.11-2.69,0-3.02-.34-3.03-3.02-.02-2.86,0-5.73,0-8.59,0-.48,0-.96.07-1.43.15-.91.68-1.39,1.6-1.47.55-.05,1.11-.03,1.67-.03,17.35,0,34.69,0,52.04,0,.64,0,1.28,0,1.91.07.71.08,1.14.54,1.28,1.23.07.39.08.79.08,1.19,0,1.75-.04,3.5.02,5.25.03,1.1-.29,1.98-.98,2.83-2.07,2.52-4.08,5.09-6.12,7.63-7.74,9.7-15.49,19.39-23.22,29.09-1.64,2.06-3.35,4.04-5.23,5.88-.32.32-.72.6-.83,1.02Z"
        ></path>
        <path
          style={{ fill: colors.color2 }}
          d="m171.48,81.61c0,7.56,0,15.12,0,22.67,0,.56.01,1.11,0,1.67-.06,1.97-.49,2.41-2.38,2.47-.16,0-.32,0-.48,0-3.82,0-7.64,0-11.46,0-.4,0-.8.02-1.19-.03-.87-.1-1.52-.64-1.64-1.48-.1-.7-.08-1.43-.08-2.14,0-15.43,0-30.87,0-46.3,0-.56,0-1.11,0-1.67-.05-2.09-.38-2.42-2.56-2.48-.88-.02-1.75,0-2.63,0-.48,0-.96.02-1.43-.03-1.18-.13-1.7-.65-1.81-1.82-.04-.39-.02-.79-.02-1.19,0-2.55,0-5.09,0-7.64,0-.4-.01-.8.02-1.19.12-1.2.62-1.73,1.8-1.79,1.11-.05,2.23-.01,3.34-.02.56,0,1.12.03,1.67-.03.9-.1,1.47-.64,1.56-1.52.07-.71.05-1.43.07-2.14.1-2.95.43-5.87,1.31-8.7,1.75-5.68,5.26-9.91,10.52-12.68,2.91-1.54,6.03-2.44,9.26-3,3.15-.55,6.33-.64,9.52-.5.32.01.64.02.95.06,1.91.21,2.38.67,2.4,2.54.03,3.26,0,6.52,0,9.79,0,.24,0,.48-.04.71-.14,1.07-.64,1.58-1.7,1.63-.95.05-1.91.01-2.86.01-1.77,0-3.48.34-5.15.89-3.93,1.29-6.22,3.96-6.8,8.08-.16,1.11-.29,2.21-.13,3.32.13.89.7,1.42,1.59,1.52.55.06,1.11.04,1.67.04,2.71,0,5.41,0,8.12,0,.56,0,1.12-.03,1.67.03.9.09,1.43.64,1.56,1.53.03.23.03.48.03.71,0,3.02.02,6.05,0,9.07-.02,1.75-.53,2.29-2.27,2.32-2.86.05-5.73.01-8.6.02-.64,0-1.28-.02-1.91.03-1.3.1-1.8.62-1.9,1.97-.04.55-.01,1.11-.01,1.67,0,7.88,0,15.75,0,23.63Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m199.43,52.8c0-12.17,0-24.34,0-36.51,0-.72-.02-1.43.04-2.15.08-.88.64-1.45,1.51-1.59.39-.06.79-.04,1.19-.04,3.82,0,7.64,0,11.46,0,.4,0,.8-.01,1.19.05.88.14,1.46.68,1.56,1.55.08.79.07,1.59.07,2.38,0,22.19,0,44.39.01,66.58,0,1.43.1,2.86.19,4.29.03.55.13,1.11.27,1.64.68,2.69,2.25,4.65,5.74,4.72.63.01,1.28,0,1.9.11.77.13,1.28.64,1.41,1.43.08.47.08.95.08,1.43,0,3.1,0,6.2,0,9.31,0,.32,0,.64-.02.95-.13,1.3-.63,1.87-1.96,1.95-4.14.24-8.27.31-12.31-.9-5.06-1.51-8.58-4.68-10.51-9.6-1.03-2.61-1.56-5.34-1.73-8.13-.09-1.43-.09-2.86-.09-4.29,0-11.06,0-22.11,0-33.17Z"
        ></path>
        <path
          style={{ fill: colors.color2 }}
          d="m92.1,72.1c.14,4.26-3.11,9.83-9.78,10.47-4.56.44-9.83-2.35-11.18-8.36-.21-.92-.16-1.89-.21-2.85-.14-2.8-.65-5.53-1.87-8.07-3.08-6.4-8.25-9.88-15.24-10.75-1.34-.17-2.69-.22-4.04-.33-4.39-.38-8.71-4.38-9.06-9.58-.37-5.59,3.21-9.79,7.61-11.05,6.72-1.92,13.29,2.79,13.54,9.79.1,2.73.38,5.4,1.46,7.91,3.05,7.12,8.46,11.11,16.17,11.95,1.11.12,2.22.18,3.33.26,4.78.37,9.47,4.83,9.26,10.61Z"
        ></path>
        <path
          style={{ fill: colors.color2 }}
          d="m31.32,89.16c.36-4.28.99-8.51,2.33-12.62.55-1.67,1.21-3.28,2.13-4.78,2.16-3.54,5.19-5.98,9.26-6.98,2.36-.58,4.75-.6,7.04.29,3.9,1.53,6.19,4.44,6.54,8.61.51,6.1-2.33,10.54-7.36,13.69-1.82,1.14-3.85,1.87-5.92,2.45-4.07,1.14-8.22,1.81-12.44,2-1.69.07-1.69.06-1.62-1.71.01-.32.04-.63.05-.95Z"
        ></path>
        <path
          style={{ fill: colors.color1 }}
          d="m80,48.02c-3.53-.24-5.14-3.18-5.02-5.55.14-2.9,2.7-5.26,5.53-5.07,2.99.19,5.22,2.43,5.13,5.47-.1,3.23-2.58,5.28-5.64,5.15Z"
        ></path>
      </g>
      <g>
        <path
          style={{ fill: colors.color3 }}
          d="m162.41,129.45v1.03h-7.89v-11.74h7.74v1.03h-6.58v4.23h5.74v.98h-5.74v4.48h6.73Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m172.94,118.74h.98l4.88,11.74h-1.22l-1.52-3.67h-5.27l-1.5,3.67h-1.24l4.91-11.74Zm2.84,7.16l-2.36-5.8-2.4,5.8h4.76Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m191.87,120.94c-.33-.37-.77-.68-1.32-.91-.55-.23-1.18-.35-1.89-.35-1.04,0-1.79.2-2.26.59-.47.39-.71.92-.71,1.6,0,.35.06.64.19.87s.32.42.59.59c.26.17.6.31,1.02.43.41.12.9.24,1.46.36.63.13,1.19.28,1.69.45.5.17.93.37,1.28.62s.62.55.81.9c.19.35.28.79.28,1.32s-.1,1.01-.31,1.41c-.21.4-.5.73-.88.99-.37.26-.82.46-1.34.59-.52.13-1.09.19-1.7.19-1.82,0-3.39-.57-4.71-1.7l.58-.94c.21.22.46.43.75.63.29.2.62.37.98.52.36.15.74.26,1.15.35.41.08.84.12,1.29.12.94,0,1.67-.17,2.19-.5.52-.34.79-.85.79-1.53,0-.36-.07-.67-.22-.92-.15-.25-.37-.46-.67-.64-.3-.18-.67-.34-1.11-.47-.44-.13-.95-.26-1.54-.4-.62-.14-1.16-.29-1.62-.45-.46-.15-.85-.35-1.17-.58-.32-.23-.56-.51-.72-.84-.16-.32-.24-.72-.24-1.2,0-.54.1-1.02.31-1.45.2-.42.49-.78.87-1.06s.82-.5,1.32-.64c.51-.15,1.07-.22,1.69-.22.78,0,1.48.12,2.09.36s1.17.57,1.66,1l-.56.93Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m199.57,118.74l3.84,6.32,3.87-6.32h1.26l-4.55,7.37v4.36h-1.16v-4.4l-4.53-7.34h1.27Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m226.47,118.74h.98l4.88,11.74h-1.22l-1.52-3.67h-5.27l-1.5,3.67h-1.24l4.91-11.74Zm2.84,7.16l-2.36-5.8-2.4,5.8h4.76Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m245.4,120.94c-.33-.37-.77-.68-1.32-.91-.55-.23-1.18-.35-1.89-.35-1.04,0-1.79.2-2.26.59-.47.39-.71.92-.71,1.6,0,.35.06.64.19.87s.32.42.59.59c.26.17.6.31,1.02.43.41.12.9.24,1.46.36.63.13,1.19.28,1.69.45.5.17.93.37,1.28.62s.62.55.81.9c.19.35.28.79.28,1.32s-.1,1.01-.31,1.41c-.21.4-.5.73-.88.99-.37.26-.82.46-1.34.59-.52.13-1.09.19-1.7.19-1.82,0-3.39-.57-4.71-1.7l.58-.94c.21.22.46.43.75.63.29.2.62.37.98.52.36.15.74.26,1.15.35.41.08.84.12,1.29.12.94,0,1.67-.17,2.19-.5.52-.34.79-.85.79-1.53,0-.36-.07-.67-.22-.92-.15-.25-.37-.46-.67-.64-.3-.18-.67-.34-1.11-.47-.44-.13-.95-.26-1.54-.4-.62-.14-1.16-.29-1.62-.45-.46-.15-.85-.35-1.17-.58-.32-.23-.56-.51-.72-.84-.16-.32-.24-.72-.24-1.2,0-.54.1-1.02.31-1.45.2-.42.49-.78.87-1.06s.82-.5,1.32-.64c.51-.15,1.07-.22,1.69-.22.78,0,1.48.12,2.09.36s1.17.57,1.66,1l-.56.93Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m260.15,120.94c-.33-.37-.77-.68-1.32-.91-.55-.23-1.18-.35-1.89-.35-1.04,0-1.79.2-2.26.59-.47.39-.71.92-.71,1.6,0,.35.06.64.19.87s.32.42.59.59c.26.17.6.31,1.02.43.41.12.9.24,1.46.36.63.13,1.19.28,1.69.45.5.17.93.37,1.28.62s.62.55.81.9c.19.35.28.79.28,1.32s-.1,1.01-.31,1.41c-.21.4-.5.73-.88.99-.37.26-.82.46-1.34.59-.52.13-1.09.19-1.7.19-1.82,0-3.39-.57-4.71-1.7l.58-.94c.21.22.46.43.75.63.29.2.62.37.98.52.36.15.74.26,1.15.35.41.08.84.12,1.29.12.94,0,1.67-.17,2.19-.5.52-.34.79-.85.79-1.53,0-.36-.07-.67-.22-.92-.15-.25-.37-.46-.67-.64-.3-.18-.67-.34-1.11-.47-.44-.13-.95-.26-1.54-.4-.62-.14-1.16-.29-1.62-.45-.46-.15-.85-.35-1.17-.58-.32-.23-.56-.51-.72-.84-.16-.32-.24-.72-.24-1.2,0-.54.1-1.02.31-1.45.2-.42.49-.78.87-1.06s.82-.5,1.32-.64c.51-.15,1.07-.22,1.69-.22.78,0,1.48.12,2.09.36s1.17.57,1.66,1l-.56.93Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m275.92,129.45v1.03h-7.89v-11.74h7.74v1.03h-6.58v4.23h5.74v.98h-5.74v4.48h6.73Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m291.15,119.76h-4.18v10.71h-1.16v-10.71h-4.18v-1.03h9.52v1.03Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m316.48,130.48v-9.64l-4.28,7.46h-.68l-4.28-7.46v9.64h-1.16v-11.74h1.19l4.58,8.04,4.61-8.04h1.17v11.74h-1.16Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m328.93,118.74h.98l4.88,11.74h-1.22l-1.52-3.67h-5.27l-1.5,3.67h-1.24l4.91-11.74Zm2.84,7.16l-2.36-5.8-2.4,5.8h4.76Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m342.34,120.89v9.59h-1.16v-11.74h.89l7.77,9.77v-9.75h1.16v11.72h-1.01l-7.65-9.59Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m362.29,118.74h.98l4.88,11.74h-1.22l-1.52-3.67h-5.27l-1.5,3.67h-1.24l4.91-11.74Zm2.84,7.16l-2.36-5.8-2.4,5.8h4.76Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m382.6,128.76c-1.09,1.2-2.35,1.8-3.77,1.8-.79,0-1.52-.17-2.19-.51-.67-.34-1.25-.79-1.74-1.36s-.87-1.2-1.15-1.91c-.28-.71-.41-1.44-.41-2.19s.14-1.53.41-2.25c.27-.72.65-1.35,1.13-1.89.49-.55,1.06-.98,1.72-1.31s1.38-.49,2.17-.49c.57,0,1.1.06,1.57.19.47.13.9.3,1.26.53.37.23.69.5.96.81.27.31.5.66.69,1.03l-.89.6c-.39-.73-.89-1.26-1.5-1.6-.62-.34-1.32-.51-2.1-.51-.65,0-1.24.14-1.76.41-.52.28-.97.64-1.34,1.1-.37.46-.65.98-.85,1.57-.2.59-.3,1.2-.3,1.83,0,.66.11,1.29.34,1.88s.54,1.12.93,1.56c.4.45.86.8,1.4,1.07.54.26,1.12.4,1.74.4.67,0,1.31-.16,1.93-.47.61-.31,1.2-.81,1.76-1.5v-1.95h-2.61v-.89h3.59v5.77h-.98v-1.72Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m398.55,129.45v1.03h-7.89v-11.74h7.74v1.03h-6.58v4.23h5.74v.98h-5.74v4.48h6.73Z"
        ></path>
        <path
          style={{ fill: colors.color3 }}
          d="m405.45,130.48v-11.74h4.96c.51,0,.97.11,1.4.32.42.22.79.5,1.1.84.31.35.55.74.72,1.18.17.44.26.89.26,1.34s-.06.84-.19,1.23-.3.74-.53,1.06-.5.58-.82.79-.68.36-1.07.44l2.88,4.53h-1.31l-2.76-4.33h-3.47v4.33h-1.16Zm1.16-5.36h3.84c.34,0,.65-.07.93-.22s.52-.35.72-.6c.2-.25.35-.54.46-.86.11-.32.17-.66.17-1.01s-.06-.69-.19-1.02c-.13-.32-.3-.61-.51-.85-.22-.24-.47-.44-.76-.58-.29-.14-.6-.22-.92-.22h-3.74v5.36Z"
        ></path>
      </g>
    </svg>
  );
};

export default FleezyIcon;
