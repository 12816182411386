import { FC } from 'react';
import Translate from 'src/web/common/translate/translate.component';
import Button from 'src/web/design_system/Button';
import Icon from 'src/web/components/Icon';

interface ButtonTaskStatusProps {
  state: string;
  onClick: () => void;
  isLoading?: boolean;
}

const ALLOWED_STATUS = ['pending', 'success'];

const ButtonTaskStatus: FC<ButtonTaskStatusProps> = ({ state, onClick, isLoading }) => {
  if (!ALLOWED_STATUS.includes(state)) {
    return <></>;
  }

  const labelTranslateId =
    state === 'pending' ? 'tasks.status.option.pending.cta' : 'tasks.status.option.success.cta';
  const icon = state === 'pending' ? <Icon.Done size="small" /> : <Icon.Undo size="small" />;
  const variant = state === 'pending' ? 'contained' : 'outlined';
  const color = state === 'pending' ? 'primary' : 'inherit';

  return (
    <Button.Default
      onClick={() => onClick()}
      isLoading={isLoading}
      variant={variant}
      startIcon={icon}
      color={color}
      size="small"
    >
      <Translate id={labelTranslateId} />
    </Button.Default>
  );
};

export default ButtonTaskStatus;
