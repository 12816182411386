import { css, cx } from '@emotion/css';
import { Paper, PaperOwnProps, Stack, Theme, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { isNumber } from 'core/common/utils/predicatesType';

export interface CardContainerProps {
  className?: string;
  floatingActionContent?: ReactNode;
  floatingActionPositionX?: number;
  floatingActionPositionY?: number;
  shadow?: boolean | number | 'outline';
}

const DEFAULT_SPACING = 1;

const cardContainerStyle = css`
  position: relative;
  overflow: hidden;
`;

const cardDesignStyle = (theme: Theme) => css`
  border-radius: ${theme.shape.borderRadius}px;
`;

const cardMenuBtnStyle = (x: string, y: string) => css`
  position: absolute;
  z-index: 10;
  right: ${x};
  top: ${y};
`;
const CardContainer: FC<PropsWithChildren<CardContainerProps>> = ({
  children,
  className,
  floatingActionContent,
  floatingActionPositionX,
  floatingActionPositionY,
  shadow = true,
}) => {
  const theme = useTheme();

  const x = theme.spacing(floatingActionPositionX ?? DEFAULT_SPACING);

  const y = theme.spacing(floatingActionPositionY ?? DEFAULT_SPACING);

  const shadowProps = useMemo((): Partial<PaperOwnProps> => {
    if (shadow === 'outline') {
      return {
        variant: 'outlined',
      };
    } else if (shadow === false) {
      return {
        elevation: 0,
      };
    } else if (isNumber(shadow)) {
      return {
        elevation: shadow,
      };
    } else {
      return {};
    }
  }, [shadow]);

  return (
    <Paper className={cx(cardContainerStyle, cardDesignStyle(theme))} {...shadowProps}>
      <div className={cx(cardDesignStyle(theme), className)}>
        {floatingActionContent && (
          <div className={cardMenuBtnStyle(x, y)}>{floatingActionContent}</div>
        )}
        <Stack direction="column">{children}</Stack>
      </div>
    </Paper>
  );
};

export default CardContainer;
