import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

type InitialValuesType = {
  [key: string]: string;
};
const usePutMileageVehicle = (dataId: string, onSubmit: () => void) => {
  const [isLoading, setIsLoading] = useState(true);

  const { setVehicleUseCase } = useCore();

  const { properties, required, validate, submit, initialValues } = useMemo(
    () => setVehicleUseCase.putMileageVehicle(dataId),
    [dataId],
  );
  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        onSubmit();
      }
      setIsLoading(false);
    },
  });
  useEffect(() => {
    if (initialValues) {
      Promise.resolve(initialValues).then((values: InitialValuesType) => {
        for (const key in values) {
          form.imperativeChangeField(key, values[key]);
        }

        setIsLoading(false);
      });
    }
  }, [initialValues]);
  return {
    form,
    isLoading,
  };
};
export default usePutMileageVehicle;
