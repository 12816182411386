import { FC, PropsWithChildren } from 'react';
import { useTheme } from 'src/web/design_system/Theme';
import Displayer from 'src/web/design_system/Displayer';
import Link from 'src/web/components/Link';
import useTranslate from 'src/web/common/translate/useTranslate';
import NotFoundIcon from 'src/web/resources/images/error_404.svg';
import Button from 'src/web/design_system/Button';
import * as styles from './styles';

interface NotFoundProps {
  backUrl?: string;
}

const NotFound: FC<PropsWithChildren<NotFoundProps>> = ({ backUrl }) => {
  const { theme } = useTheme();
  const { translate } = useTranslate();

  return (
    <div className={styles.containerStyle}>
      <img src={NotFoundIcon} alt="Fleezy" />

      <h1 className={styles.title(theme)}>{translate('global.notfound.title')}</h1>

      <div className={styles.descriptionLayout}>
        <span className={styles.description(theme)}>{translate('global.notfound.message')}</span>
      </div>

      <Displayer alignContent="center">
        <Link to={backUrl}>
          <Button.Default variant={'contained'}>
            {translate('global.notfound.button.label')}
          </Button.Default>
        </Link>
      </Displayer>
    </div>
  );
};

export default NotFound;
