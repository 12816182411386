import { FC, useMemo, useRef, useState } from 'react';
import Modal from 'src/web/components/Modal';
import Button from 'src/web/components/Button';
import Displayer from 'src/web/design_system/Displayer';
import useSetPersonAssignment from 'src/web/pages/person/detail/hooks/useSetPersonAssignment';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import Form from 'src/web/components/FormInput';
import { useCore } from 'src/web/common/core';
import { isString } from 'core/common/utils/predicatesType';
import useTranslate from 'src/web/common/translate/useTranslate';
import Switch from 'src/web/design_system/Input/Switch';
import { EntitiesEnum } from 'src/core/common/constants';

interface AssetAssignmentUnassignModalProps {
  isOpen: boolean;
  onClose: () => void;
  assetIri: string;
  onUnassign: () => void;
  personIri?: string;
  entity: EntitiesEnum;
}

const AssetAssignmentUnassignModal: FC<AssetAssignmentUnassignModalProps> = ({
  assetIri,
  isOpen,
  onClose,
  onUnassign,
  personIri,
  entity,
}) => {
  const { translate } = useTranslate();
  const { timeService } = useCore();

  const formRef = useRef<FormContainerRef>(null);
  const [generateReturnAssignmentCertificate, setGenerateReturnAssignmentCertificate] =
    useState(false);

  const { form, isLoading } = useSetPersonAssignment({
    assetIri,
    personIri: personIri ?? '',
    afterSubmit: () => onUnassign(),
    generateReturnAssignmentCertificate,
    entity: entity,
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const unassignMinDate = useMemo((): Date => {
    const refDate = isString(form.items.startAt.formValue)
      ? timeService.fromBackend(form.items.startAt.formValue)
      : timeService.now();

    return timeService.manipulate(refDate, { day: 1 });
  }, [form.items.startAt.formValue]);

  const isDisabled = !form.items.endAt.formValue;

  const actionArea = (
    <Displayer layout="row" justifyContent="center" innerSpacing={3}>
      <Button.Custom onClick={() => onClose()} variant="outlined" fullWidth={false}>
        {translate('assets.unassign.cancel')}
      </Button.Custom>
      <Button.Custom
        fullWidth={false}
        disabled={isDisabled}
        onClick={() => handleSubmit()}
        isLoading={isLoading}
      >
        {translate('assets.unassign.submit')}
      </Button.Custom>
    </Displayer>
  );

  if (!isOpen) return <></>;

  return (
    <FormContainer ref={formRef} onSubmit={form.handleSubmit}>
      <Modal
        title={translate('assets.unassign.title')}
        open={isOpen}
        onClose={() => onClose()}
        footer={actionArea}
      >
        <Form.DateAdapted
          label={translate('assets.unassign.endAt.label')}
          item={form.items.endAt}
          minDate={unassignMinDate}
        />
        {form.items.endAt.formValue || form.items.endAt.formValue !== null ? (
          <Switch
            label={translate('persons.assign.generateReturnAssignmentCertificate.label')}
            value={generateReturnAssignmentCertificate}
            onChange={(value) => setGenerateReturnAssignmentCertificate(value)}
          />
        ) : (
          <></>
        )}
      </Modal>
    </FormContainer>
  );
};

export default AssetAssignmentUnassignModal;
