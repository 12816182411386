import React from 'react';
import { useDropzone } from 'react-dropzone';
import { createCss } from 'src/web/common/styles/createCss';
import useTranslate from 'src/web/common/translate/useTranslate';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';
import { useTheme } from 'src/web/design_system/Theme';
import Icon from 'src/web/components/Icon';
import { IconButton } from '@mui/material';

interface DropZoneFileUploadProps {
  onChange: (content: File) => void;
}

const DropZoneFileUpload: React.FC<DropZoneFileUploadProps> = ({ onChange }) => {
  const { translate } = useTranslate();
  const theme = useTheme();

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles[0]);
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const dropZoneStyle = createCss(`
  background: ${isDragActive ? '#00000010' : '#00000005'};
  padding: 20px;
  height: 120px;
  border: 2px dotted #00000015;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`);

  return (
    <div className={dropZoneStyle} {...getRootProps()}>
      <input {...getInputProps()} type="file" />
      <Displayer innerSpacing={1} alignItems={'center'} justifyContent={'center'}>
        <Displayer alignItems={'center'} justifyContent={'center'} innerSpacing={1}>
          <IconButton color="primary" size="large" onClick={open}>
            <Icon.Add size="large" />
          </IconButton>
          <Text color={theme.theme.palettes.neutral?.[500]}>
            {translate('documents.import.add.label')}
          </Text>
          <Text color={theme.theme.palettes.neutral?.[500]}>
            {translate('documents.import.size.label')}{' '}
            {translate('documents.instruction.size.label.value')}
          </Text>
        </Displayer>
      </Displayer>
    </div>
  );
};

export default DropZoneFileUpload;
