import React, { useRef } from 'react';
import Icon from 'src/web/components/Icon';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';

interface ButtonFileUploadProps {
  onChange: (content: File) => void;
  edge?: 'end' | 'start';
}

const ButtonFileUpload: React.FC<ButtonFileUploadProps> = ({ onChange, edge }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      onChange(event.target.files[0]);
    }
  };

  const handleIconClick = () => {
    inputFileRef.current?.click();
  };
  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        ref={inputFileRef}
        style={{ display: 'none' }}
      />
      <ButtonIcon onClick={handleIconClick} edge={edge}>
        <Icon.Add />
      </ButtonIcon>
    </>
  );
};

export default ButtonFileUpload;
