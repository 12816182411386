import type { FC, PropsWithChildren, ReactElement } from 'react';
import * as styles from './styles';

interface FocusLayoutProps {
  content?: ReactElement | null;
}

const FocusLayout: FC<PropsWithChildren<FocusLayoutProps>> = ({ children, content }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{children ?? content}</div>
    </div>
  );
};

export default FocusLayout;
