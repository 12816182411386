// TODO: FILE TO REMOVE
import { GUI_PALETTE_PRIMARY } from 'src/web/design_system/Theme/palettes';

type ThemeColorKeys =
  | 'brandColor'
  | 'primaryColor'
  | 'secondaryColor'
  | 'backgroundColor'
  | 'blackColor'
  | 'blackColorLight'
  | 'greyColor'
  | 'greyColorLight';

export const themeColors: Record<ThemeColorKeys, string> = {
  brandColor: '#ff5912',
  primaryColor: '#ff5912',
  secondaryColor: '#eb5e28',
  backgroundColor: '#fff6f3',
  blackColor: '#2E404F',
  blackColorLight: '#2E404F90',
  greyColor: '#f2f2f2',
  greyColorLight: '#F9FAFB',
};

export const linearGradientBlue = `linear-gradient(-135deg, ${GUI_PALETTE_PRIMARY['100']},${GUI_PALETTE_PRIMARY['50']} );`;
