import { FC } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';

const DashboardPage: FC = () => {
  const { translate } = useTranslate();

  return (
    <div>
      <PageHeaderBasic title={translate('global.dashboard.welcome.title')} />
    </div>
  );
};

export default DashboardPage;
