import HttpClient, { HttpResponse, RequestOption, RequestResponse } from './http.port';
import { join } from 'core/common/utils/path';
import { type CoreAdapters } from 'core/adapters';
import EventService from 'core/services/events/events.service';
import {
  SwaggerPathsKeys,
  SwaggerPathsMethods,
  SwaggerRequestBodyJsonContent,
  SwaggerRequestBodyMediaContent,
  SwaggerRequestHeaders,
  SwaggerRequestPathVar,
  SwaggerRequestQuery,
  SwaggerRequestResponse,
} from 'core/swagger';
import { transformCurlyPath } from 'src/core/common/utils/transformCurlyPath';
import { AppEventsNames } from 'core/common/constants';
import { isString } from 'core/common/utils/predicatesType';
import AppStore from 'core/services/store/store.service';

export type RequestDataShape<D> = {
  data: D | undefined;
  count: number;
  search: unknown[];
  status?: number;
};

export type RequestOptions<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>> = {
  pathVar?: SwaggerRequestPathVar<P, M>;
  query?: SwaggerRequestQuery<P, M>;
  headers?: SwaggerRequestHeaders<P, M>;
  body?: SwaggerRequestBodyJsonContent<P, M> | SwaggerRequestBodyMediaContent<P, M>;
  contentType?: RequestOption['contentType'];
};

/**
 * Filter parameters to expose to the app
 */
export type HttpClientRequestParams = Pick<
  RequestOption,
  'method' | 'url' | 'body' | 'query' | 'contentType' | 'responseType'
>;

class HttpService {
  private HttpClient: HttpClient;

  private requestsWithoutLegalEntityId = [
    '/api/private/current-user',
    '/api/user-password-reset',
    '/api/login_check',
    '/api/token/refresh',
    '/api/user-password-retrieve',
  ];

  constructor(
    private adapters: CoreAdapters,
    private eventService: EventService,
    private store: AppStore,
  ) {
    this.HttpClient = this.adapters.httpClient;
  }

  private responseInterceptor<R extends HttpResponse<unknown>>(response: R): R {
    if (response?.status && response.status >= 300) {
      if (response.status === 401) {
        this.eventService.emit(AppEventsNames.OnUnauthorized, {
          message: response.status.toString(),
        });
      }
    }

    return response;
  }

  /**
   * Ensure the provided query object fits the need
   * Basically remove empty string value
   */
  private formatQuery<Q extends Record<string, string | string[]>>(query?: Q): Q | undefined {
    if (!query) return undefined;

    return Object.fromEntries(
      Object.keys(query)
        .filter((fk) => {
          const value = query[fk];

          if (isString(value) && value.length === 0) {
            return false;
          } else {
            return true;
          }
        })
        .map((mk) => {
          return [mk, query[mk]];
        }),
    ) as Q;
  }

  /**
   * Build a request by exploring the API Swagger
   */
  public async requestFromSwagger<
    P extends SwaggerPathsKeys,
    M extends SwaggerPathsMethods<P>,
    R extends SwaggerRequestResponse<P, M>,
  >(route: P, method: M, options?: RequestOptions<P, M>): Promise<RequestDataShape<R>> {
    let url: HttpClientRequestParams['url'] = route.toString();
    let body: HttpClientRequestParams['body'] = undefined;
    let query: HttpClientRequestParams['query'] = undefined;

    if (options && 'pathVar' in options && options.pathVar) {
      url = transformCurlyPath(url, options.pathVar as Record<string, string>);
    }

    if (options && 'query' in options && options.query) {
      query = this.formatQuery(options.query);
    }

    if (options && 'body' in options && options.body) {
      body = options.body;
    }

    const params: HttpClientRequestParams = {
      method: method as HttpClientRequestParams['method'],
      url,
      body,
      query,
      contentType: options?.contentType ?? 'application/ld+json',
    };

    const requestParams = this.createRequestParams(params);

    return this.HttpClient.request<R>(requestParams)
      .then((data) => this.responseInterceptor(data))
      .then((response) => {
        let data = undefined;
        let count = 0;
        const search: unknown[] = [];

        if (response && response.data) {
          if (typeof response.data === 'object' && 'hydra:member' in response.data) {
            data = response.data['hydra:member'];
          } else {
            data = response.data;
          }

          if (typeof response.data === 'object' && 'hydra:totalItems' in response.data) {
            count = response.data['hydra:totalItems'] as number;
          } else {
            count = 1;
          }

          if (typeof response.data === 'object' && 'hydra:search' in response.data) {
            // decide what to do with that
          }
        }

        return { data, count, search, status: response.status };
      });
  }

  /**
   * @description Currently only used by Auth routes because there are not documented in the swagger
   */
  public async request<R>(params: HttpClientRequestParams): RequestResponse<R> {
    const requestParams = this.createRequestParams(params);

    return this.HttpClient.request<R>(requestParams).then((data) => this.responseInterceptor(data));
  }

  /**
   * Build params by adding some global values
   */

  private createRequestParams = (params: HttpClientRequestParams): RequestOption => {
    let requestOptions: RequestOption = {
      ...params,
      url: join(process.env.API_BASE_URL, params.url),
      allowCredentials: true,
      enableCors: true,
    };

    const currentLegalEntity = this.store.getState((state) => state.user.current?.legalEntity?.id);

    if (!this.requestsWithoutLegalEntityId.includes(params.url)) {
      if (currentLegalEntity) {
        requestOptions = {
          ...requestOptions,
          headers: {
            ...requestOptions.headers,
            'X-Legal-Entity': this.store.getState((state) => state.user.current?.legalEntity?.id),
          },
        };
      } else {
        // No legalEntity means an issue on authentification caused by a mistake.
        // If this case happens requests will fail so the better to do is to make the user to login again and hope that solve the issuecore/.
        this.eventService.emit(AppEventsNames.OnUnauthorized);
      }
    }

    return requestOptions;
  };
}

export default HttpService;
