import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';

class EnumEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPath() {
    return exactString('/api/private/enums/{id}');
  }

  get = async (options: RequestOptions<ReturnType<typeof this.getPath>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPath(), 'get', options),
      {
        path: this.getPath(),
        method: 'get',
        payload: options,
        ttl: 1000 * 60 * 60,
      },
    );
  };
}

export default EnumEntity;
