import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import CardEntity from 'core/entities/card.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import ServiceEntity from 'core/entities/service.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ComputerEntity from 'core/entities/computer.entity';
import VehicleEntity from 'core/entities/vehicle.entity';
import CustomAssetEntity from 'core/entities/customAsset.entity';

class DeleteAssetUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private licenceEntity: LicenceEntity,
    private serviceEntity: ServiceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private customAssetEntity: CustomAssetEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  public async deleteAsset<T extends EntitiesEnum>(entity: T, assetId: string) {
    const pathVar = {
      id: assetId,
    };

    switch (entity) {
      case EntitiesEnum.vehicle:
        return this.vehicleEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.computer:
        return this.computerEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.service:
        return this.serviceEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.licence:
        return this.licenceEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.telephone:
        return this.telephoneEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity
          .delete({ pathVar })
          .then((response) => response.status === 204);
      case EntitiesEnum.card:
        return this.cardEntity.delete({ pathVar }).then((response) => response.status === 204);
      case EntitiesEnum.customAsset:
        return this.customAssetEntity
          .delete({ pathVar })
          .then((response) => response.status === 204);
      default:
        return new Promise<false>((resolve) => resolve(false));
    }
  }
}

export default DeleteAssetUseCase;
