import Icon from 'src/web/components/Icon';
import Card from 'src/web/components/Card';
import Text from 'src/web/design_system/Text';
import { FC, useEffect, useState } from 'react';
import Modal from 'src/web/components/Modal';
import HistoryMileage from './HistoryMileage';
import FormAddMileage from './FormAddMileage';
import { useGetMileageFromVehicleId } from 'src/web/pages/assets/detail/hooks/vehicle/useGetMileageFromVehicleId';
import { useCore } from 'src/web/common/core';
import Displayer from 'src/web/design_system/Displayer';
import Skeleton from 'src/web/design_system/Skeleton';
import CounterAnimation from 'src/web/components/CounterAnimation/CounterAnimation';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import useTranslate from 'src/web/common/translate/useTranslate';

interface CardMileageProps {
  lastMileage?: number | null;
  lastMileageDate?: string | null;
  initialMileage?: number | null;
  assetId?: string;
  assetIri?: string;
  recordedInitialMileage?: string | null;
  isLoadingDetails?: boolean;
  reloadData?: boolean;
  setReloadData?: React.Dispatch<React.SetStateAction<boolean>>;
  reload: () => void;
}

const CardMileage: FC<CardMileageProps> = ({
  lastMileage,
  lastMileageDate,
  initialMileage,
  assetId,
  assetIri,
  recordedInitialMileage,
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { data, isLoading, loadData } = useGetMileageFromVehicleId(assetId ?? '');

  useEffect(() => {
    if (assetId) {
      loadData();
    }
  }, [lastMileage, initialMileage, assetId]);

  useEffect(() => {
    setOpenEdit(false);
  }, [data]);

  const { timeService } = useCore();
  const minDateMileage = recordedInitialMileage
    ? timeService.fromBackend(recordedInitialMileage)
    : undefined;
  const { translate } = useTranslate();

  return (
    <>
      <Card.Container>
        <Card.Toolbar
          secondaryContent={
            <ButtonIcon onClick={() => setOpenEdit(true)} edge="end">
              {openEdit ? <Icon.Close /> : <Icon.Add />}
            </ButtonIcon>
          }
        >
          <Text variant="sectionTitle">{translate('assets.mileage.title')}</Text>
        </Card.Toolbar>

        <Card.Content>
          <Displayer layout={'row'} innerSpacing={1} alignItems={'center'} bottomSpacing={2}>
            <Text variant="textSmall">{translate('assets.mileage.last.title')} </Text>
            <Text variant="textMedium" fontWeight={900}>
              <Skeleton shape="text" isLoading={isLoading}>
                <CounterAnimation
                  targetNumber={data && data[0] ? data[0]?.value : initialMileage ?? 0}
                  duration={500}
                />
              </Skeleton>
            </Text>
            <Text variant="textSmall">km le</Text>
            <Text variant="textSmall">
              <Skeleton shape="text" isLoading={isLoading}>
                {lastMileageDate
                  ? timeService.format(timeService.fromBackend(lastMileageDate), 'short')
                  : ''}
              </Skeleton>
            </Text>
          </Displayer>
        </Card.Content>

        <Card.Content>
          <HistoryMileage
            initialMileage={initialMileage}
            recordedInitialMileage={recordedInitialMileage}
            isLoading={isLoading}
            data={data?.map((item) => ({
              assetId: item.id ?? '',
              date: timeService.fromBackend(item.recordedAt),
              value: item.value ?? 0,
            }))}
            reloadMileage={() => loadData()}
          />
        </Card.Content>
      </Card.Container>

      {openEdit ? (
        <Modal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          title={translate('assets.mileage.post.modal.title')}
        >
          <FormAddMileage
            assetIri={assetIri}
            reload={loadData}
            minDate={minDateMileage ? minDateMileage : undefined}
            minValue={lastMileage ?? initialMileage ?? undefined}
            lastMileage={data && data[0]?.value !== undefined ? data[0]?.value : 0}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
export default CardMileage;
