import { FC, ReactNode, useMemo } from 'react';
import Card from 'src/web/components/Card';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import Icon from 'src/web/components/Icon';
import Text from 'src/web/design_system/Text';
import { useTheme } from 'src/web/common/theme';
import { AssetsTypesMap, EntitiesEnum } from 'core/common/constants';
import Link from 'src/web/components/Link';
import Displayer from 'src/web/design_system/Displayer';
import Translate from 'src/web/common/translate/translate.component';

interface NotificationPreviewItemProps {
  topic: ReactNode;
  dateToDo?: string;
  dateReceived: string;
  entity: string;
  entityLabel: ReactNode;
  entityLink?: string;
  comment?: string;
  onMarkAsRead: () => void;
  onNavigate: () => void;
}

const NotificationPreviewItem: FC<NotificationPreviewItemProps> = ({
  topic,
  dateToDo,
  entity,
  entityLabel,
  entityLink,
  dateReceived,
  comment,
  onMarkAsRead,
  onNavigate,
}) => {
  const { theme } = useTheme();

  const subjectIcon = useMemo(() => {
    switch (entity) {
      case AssetsTypesMap[EntitiesEnum.person]:
        return <Icon.Person />;
      case AssetsTypesMap[EntitiesEnum.vehicle]:
        return <Icon.Vehicle />;
      case AssetsTypesMap[EntitiesEnum.computer]:
        return <Icon.Computer />;
      case AssetsTypesMap[EntitiesEnum.licence]:
        return <Icon.Licence />;
      case AssetsTypesMap[EntitiesEnum.service]:
        return <Icon.Service />;
      case AssetsTypesMap[EntitiesEnum.telephone]:
        return <Icon.Telephone />;
      case AssetsTypesMap[EntitiesEnum.telephoneLine]:
        return <Icon.TelephoneLine />;
      case AssetsTypesMap[EntitiesEnum.card]:
        return <Icon.Card />;
      case AssetsTypesMap[EntitiesEnum.user]:
        return <Icon.User />;
      default:
        return <Icon.Random />;
    }
  }, [entity]);

  const markAsReadButton = (
    <ButtonIcon onClick={() => onMarkAsRead()} size="small" color="inherit">
      <Icon.Close size="small" />
    </ButtonIcon>
  );

  return (
    <Card.Container floatingActionContent={markAsReadButton} border="none">
      <Card.Content>
        <Text tag="div">
          <Translate
            id={`notifications.notification.${topic}.label`}
            variables={{ date: dateToDo ?? '' }}
          />
        </Text>
        <Displayer bottomSpacing={1} innerSpacing={1} layout={'row'} alignItems="end">
          {subjectIcon}
          <Link to={entityLink} onClick={() => onNavigate()}>
            <Text>{entityLabel}</Text>
          </Link>
        </Displayer>
        {comment && (
          <Displayer bottomSpacing={1}>
            <Card.Container border="line">
              <Card.Content compact>{comment}</Card.Content>
            </Card.Container>
          </Displayer>
        )}
        <Text variant="textSmall" color={theme.palettes.neutral?.[500]}>
          {dateReceived}
        </Text>
      </Card.Content>
    </Card.Container>
  );
};

export default NotificationPreviewItem;
