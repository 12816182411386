import { FC } from 'react';
import useAssignPersonToAssets from './useAssignPersonToAsset';
import Form from 'src/web/components/FormInput';
import Autocomplete from 'src/web/design_system/Input/Autocomplete';
import Switch from 'src/web/design_system/Input/Switch';
import Skeleton from 'src/web/design_system/Skeleton';
import Button from 'src/web/components/Button';
import Displayer from 'src/web/design_system/Displayer';
import useTranslate from 'src/web/common/translate/useTranslate';
import { kebabCaseTocamelCase } from 'core/common/utils/format';
import { useCore } from 'src/web/common/core';
import { EntitiesEnum } from 'src/core/common/constants';

interface AssignPersonToAssetProps {
  personIri: string;
  onSubmit?: () => void;
}

type Asset = {
  iri: string;
  name: string;
};

const AssignPersonToAsset: FC<AssignPersonToAssetProps> = ({ personIri, onSubmit }) => {
  const { translate } = useTranslate();
  const { timeService, store } = useCore();
  const customAssetTitle = store.getState(
    (state) => state.legalEntity.settings?.settings?.customAssetTitle,
  );

  const {
    form,
    assetsList,
    isLoading,
    handleSearchAsset,
    handleChangeSelectedAssets,
    handleSubmit: handleFormSubmit,
  } = useAssignPersonToAssets(personIri);

  const handleSubmit = () => {
    handleFormSubmit().then(() => {
      if (onSubmit) {
        onSubmit();
      }
    });
  };

  // Adding specific order for the data to be similar to the Sidenav's items order
  const orderedAssets = [
    'vehicles',
    'computers',
    'licences',
    'services',
    'telephones',
    'telephone-lines',
    'cards',
    'custom-assets',
  ];

  // Sorting function in the desired order
  const customSort = (a: string, b: string) => {
    return orderedAssets.indexOf(a) - orderedAssets.indexOf(b);
  };

  const assetKeys = Object.keys(assetsList).sort(customSort);

  //translate the categories when it's necessary
  const translateAssetLabel = (asset: Asset, translateKey: string) => {
    const labelArray = asset.name.split(' ');
    labelArray[0] = translate(`${translateKey}.${labelArray[0]}`);
    return {
      value: asset.iri,
      label: labelArray.join(' '),
    };
  };

  return (
    <Displayer innerSpacing={2}>
      <Form.Date
        label={translate('persons.assign.startDate.label')}
        value={form.startDate.value}
        onChange={(value) => {
          if (value) {
            form.startDate.onChange(value);
          }
        }}
      />

      <Form.Date
        label={translate('persons.assign.endDate.label')}
        value={form.endDate.value}
        minDate={timeService.manipulate(form.startDate.value, {
          day: 1,
        })}
        onChange={(value) => {
          if (value) {
            form.endDate.onChange(value);
          }
        }}
      />

      <Switch
        label={translate('persons.assign.includePendingAssets.label')}
        value={form.includePendingAssets.value}
        onChange={(value) => form.includePendingAssets.onChange(value)}
      />

      {assetKeys.map((assetKey) => (
        <Skeleton isLoading={isLoading} width="100%" key={assetKey}>
          <Autocomplete
            label={
              assetKey === EntitiesEnum.customAsset
                ? customAssetTitle
                : translate(`${kebabCaseTocamelCase(assetKey)}.title`)
            }
            noOptionsText={translate('global.autocomplete.noOptions')}
            multiple
            options={assetsList[assetKey as keyof typeof assetsList]
              .map((asset) => {
                switch (assetKey) {
                  case EntitiesEnum.computer:
                    return translateAssetLabel(asset, 'computer.formFactor');
                  case EntitiesEnum.card:
                    return translateAssetLabel(asset, 'card.purpose');
                  default:
                    return {
                      value: asset.iri,
                      label: asset.name,
                    };
                }
              })
              ?.sort((a, b) => a.label.localeCompare(b.label))}
            onSearch={(value) => handleSearchAsset(assetKey as keyof typeof assetsList, value)}
            onChange={(ids) => handleChangeSelectedAssets(assetKey as keyof typeof assetsList, ids)}
            renderInput={(option) => option.label}
            renderOption={(option) => option.label}
          />
        </Skeleton>
      ))}

      <Switch
        label={translate('persons.assign.generateDeliveryAssignmentCertificate.label')}
        value={form.generateCertificate.value}
        onChange={(value) => form.generateCertificate.onChange(value)}
      />

      <Button.Custom onClick={() => handleSubmit()} isLoading={isLoading}>
        {translate('persons.assign.submit')}
      </Button.Custom>
    </Displayer>
  );
};

export default AssignPersonToAsset;
