import { useCallback, useEffect, useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';
import { flattenDataList } from 'src/web/common/inventory/tools';
import useCreateInventory from 'src/web/common/inventory/useCreateInventory';
import useTranslate from 'src/web/common/translate/useTranslate';

const enum TaskStatus {
  pending = 'pending',
  success = 'success',
}

export const useTasksInventory = (displayKeys?: string[]) => {
  const { tasksItemsUseCase } = useCore();
  const { translate } = useTranslate();
  const { rows, handleLoadData, isLoading, selectedFilters, ...rest } = useCreateInventory(
    EntitiesEnum.task,
  );
  const [tabCurrent, setTabCurrent] = useState<string>(TaskStatus.pending);
  const [taskStatusOnLoad, setTaskStatusOnLoad] = useState<string>();

  const handleLoadInventory = useCallback(() => {
    return Promise.resolve(
      handleLoadData({
        query: {
          status: tabCurrent,
        },
        filter: selectedFilters ?? '',
      }),
    );
  }, [tabCurrent, selectedFilters]);

  const handleToggleStatus = (taskItemId: string, currentStatus: string) => {
    setTaskStatusOnLoad(taskItemId);

    tasksItemsUseCase.switchStatus(taskItemId, currentStatus).then(() => {
      handleLoadInventory().then(() => {
        setTaskStatusOnLoad(undefined);
      });
    });
  };

  const handleChangeTab = (name: string) => {
    setTabCurrent(name);
  };

  const tabOptions = [
    { id: TaskStatus.pending, label: translate('tasks.status.option.pending.tab') },
    { id: TaskStatus.success, label: translate('tasks.status.option.success.tab') },
  ];

  useEffect(() => {
    handleLoadInventory();
  }, [tabCurrent]);

  return {
    taskStatusOnLoad,
    tabCurrent,
    tabOptions,
    handleChangeTab,
    selectedFilters,
    rows: rows ? flattenDataList(rows) : undefined,
    handleLoadData: handleLoadInventory,
    handleToggleStatus,
    columns: displayKeys,
    isLoading: isLoading || taskStatusOnLoad !== undefined,
    ...rest,
  };
};

export default useTasksInventory;
