import { FC, useMemo, useState } from 'react';
import PersonAssignmentPartial from './partials/personAssignmentPartial';
import usePersonDetail from './hooks/usePersonDetail';
import Skeleton from 'src/web/design_system/Skeleton';
import ContentLayout from 'src/web/layouts/ContentLayout/ContentLayout';
import Card from 'src/web/components/Card';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CardMenuProps } from 'src/web/components/Card/CardMenu';
import { ROUTE_PERSON, ROUTE_UPDATE_TAG } from 'src/web/common/router/constantes';
import EntityDetailHeader from 'src/web/components/EntityDetailHeader';
import DataDetailSection from 'src/web/components/dataDetailSection';
import Displayer from 'src/web/design_system/Displayer';
import { useCore } from 'src/web/common/core';
import DocumentsCard from 'src/web/components/DocumentsCard';
import { EntitiesEnum } from 'core/common/constants';
import Text from 'src/web/design_system/Text';
import DeleteEntityModal from 'src/web/components/DeleteEntityModal';
import useDeletePerson from './hooks/useDeletePerson';
import DividerDatas from 'src/web/components/Divider';
import useTranslate from 'src/web/common/translate/useTranslate';
import Icon from 'src/web/components/Icon';
import { useTheme } from 'src/web/design_system/Theme';
import Button from 'src/web/components/Button';

const ENTITY = EntitiesEnum.person;

const PersonDetailPage: FC = () => {
  const { translate } = useTranslate();
  const { theme } = useTheme();
  const { timeService } = useCore();
  const { isLoading, data, boardingLink } = usePersonDetail();
  const { handleDelete } = useDeletePerson();
  const personIri = useMemo(() => data?.['@id'], [data]);
  const navigate = useNavigate();
  const hasDrivingLicence = useMemo(
    () =>
      !!data?.drivingLicenceRef ||
      !!data?.drivingLicenceDeliveryDate ||
      !!data?.drivingLicenceExpiryDate,
    [data],
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const cardMenuItems = useMemo((): CardMenuProps['menuItems'] => {
    let baseMenuItem = [
      {
        label: (
          <Displayer
            justifyContent="space-between"
            alignItems="center"
            layout="row"
            innerSpacing={1}
          >
            <Icon.Edit size="small" />
            <Text>{translate('persons.edit.button')}</Text>
          </Displayer>
        ),
        onClick: () => {
          navigate(`${ROUTE_UPDATE_TAG}?${queryString}`);
        },
      },
    ];

    if (boardingLink) {
      baseMenuItem = [
        ...baseMenuItem,
        {
          label: (
            <Displayer
              justifyContent="space-between"
              alignItems="center"
              layout="row"
              innerSpacing={1}
            >
              <Icon.Boarding size="small" />
              <Text>{translate(boardingLink.label)}</Text>
            </Displayer>
          ),
          onClick: () => {
            navigate(`${boardingLink.to}?${queryString}`);
          },
        },
      ];
    }
    baseMenuItem.push({
      label: (
        <Displayer alignItems="center" justifyContent="space-between" layout="row" innerSpacing={1}>
          <Icon.Delete size="small" color="error" />
          <Text color={theme.palettes.error?.[500]}>{translate('persons.delete.button')}</Text>
        </Displayer>
      ),
      onClick: () => {
        setIsDeleteModalOpen(true);
      },
    });

    return baseMenuItem;
  }, [boardingLink]);

  return (
    <>
      <Button.BackNavigate
        to={`${ROUTE_PERSON}?${queryString}`}
        label={translate('global.navigateBack.inventory.label')}
      />
      <ContentLayout
        primaryContent={
          <Card.Container floatingActionContent={<Card.Menu menuItems={cardMenuItems} />}>
            <Card.Content>
              {/* HEADER */}
              <EntityDetailHeader
                isLoading={isLoading}
                title={`${data?.lastname} ${data?.firstname}`}
                subtitle={data?.identification}
                states={[
                  {
                    label: data?.status
                      ? translate(`persons.status.option.${data?.status}.label`)
                      : '',
                    value: data?.status ?? '',
                  },
                ]}
                site={data?.attachmentSite?.name}
              />

              {/* PERSON INFOS */}
              <Skeleton isLoading={isLoading} width="100%" height="150px">
                <DataDetailSection
                  data={[
                    {
                      label: translate('persons.gender.label'),
                      value:
                        data && data.gender
                          ? translate(`persons.gender.option.${data.gender}.label`)
                          : undefined,
                    },
                    { label: translate('persons.lastname.label'), value: data?.lastname },
                    { label: translate('persons.firstname.label'), value: data?.firstname },
                    {
                      label: translate('persons.birthdate.label'),
                      value: data?.birthdate
                        ? timeService.format(timeService.fromBackend(data.birthdate), 'short')
                        : undefined,
                    },
                  ]}
                />
              </Skeleton>
              <DividerDatas dashed />
              {/* HUMAN RESOURCE INFOS */}
              <Skeleton isLoading={isLoading} width="100%" height="150px">
                <DataDetailSection
                  title={translate('persons.humanRessources.title')}
                  data={[
                    {
                      label: translate('persons.entryAt.label'),
                      value: data?.entryAt
                        ? timeService.format(timeService.fromBackend(data.entryAt), 'short')
                        : undefined,
                    },
                    {
                      label: translate('persons.exitAt.label'),
                      value: data?.exitAt
                        ? timeService.format(timeService.fromBackend(data.exitAt), 'short')
                        : undefined,
                    },
                    { label: translate('persons.jobTitle.label'), value: data?.jobTitle },
                    { label: translate('persons.email.label'), value: data?.email },
                  ]}
                />
              </Skeleton>

              {/* DRIVING LICENCE INFOS */}
              <Skeleton isLoading={isLoading} width="100%" height="150px">
                {hasDrivingLicence && (
                  <>
                    <DividerDatas dashed />
                    <DataDetailSection
                      title={translate('persons.drivingLicence.title')}
                      data={[
                        {
                          label: translate('persons.drivingLicenceRef.label'),
                          value: data?.drivingLicenceRef ?? undefined,
                        },
                        {
                          label: translate('persons.drivingLicenceDeliveryDate.label'),
                          value: data?.drivingLicenceDeliveryDate
                            ? timeService.format(
                                timeService.fromBackend(data.drivingLicenceDeliveryDate),
                                'short',
                              )
                            : undefined,
                        },
                        {
                          label: translate('persons.drivingLicenceExpiryDate.label'),
                          value: data?.drivingLicenceExpiryDate
                            ? timeService.format(
                                timeService.fromBackend(data.drivingLicenceExpiryDate),
                                'short',
                              )
                            : undefined,
                        },
                      ]}
                    />
                  </>
                )}
              </Skeleton>

              {data?.comment && (
                <>
                  <DividerDatas dashed />
                  <Card.Basic>
                    <Displayer innerSpacing={1}>
                      <Text fontWeight={900} variant="sectionTitle">
                        {translate('assets.asset.comment.title')}
                      </Text>
                      <Text variant="textSmall">{data.comment}</Text>
                    </Displayer>
                  </Card.Basic>
                </>
              )}
            </Card.Content>
          </Card.Container>
        }
        secondaryContent={
          <Displayer innerSpacing={3}>
            {/* ASSIGNMENTS*/}
            <Skeleton isLoading={isLoading} width="100%" height="300px">
              {personIri && <PersonAssignmentPartial iri={personIri} />}
            </Skeleton>

            {/* DOCUMENTS */}
            <Skeleton isLoading={isLoading} width="100%" height="250px">
              <DocumentsCard type={data?.['@type'] ?? ''} entity={ENTITY} id={data?.id ?? ''} />
            </Skeleton>
          </Displayer>
        }
      />
      <DeleteEntityModal
        title={translate('persons.delete.title')}
        text={translate('persons.delete.confirm.title')}
        open={isDeleteModalOpen}
        redirect={ROUTE_PERSON}
        onSubmit={() => handleDelete()}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};

export default PersonDetailPage;
