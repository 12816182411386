import { type FC, SyntheticEvent, useEffect } from 'react';
import useLoginForm from './hooks//useLoginForm';
import Button from 'src/web/design_system/Button';
import Text from 'src/web/design_system/Text';
import FocusLayout from 'src/web/layouts/FocusLayout';
import { themeColors } from 'src/web/common/theme/constant';
import FleezyIcon from 'src/web/components/FleezyIcon';
import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGNUP } from 'src/web/common/router/constantes';
import Form from 'src/web/components/FormInput';
import Displayer from 'src/web/design_system/Displayer';
import { useTheme } from 'src/web/design_system/Theme';
import Link from 'src/web/components/Link';
import Card from 'src/web/components/Card';
import { authCardStyle } from '../styles';
import useTranslate from 'src/web/common/translate/useTranslate';
import { useSearchParams } from 'react-router-dom';
import LanguageSwitching from 'src/web/components/LanguageSwitching';

const LoginPage: FC = () => {
  const { formItems, handleFormSubmit, isLoading } = useLoginForm();
  const { theme } = useTheme();

  const onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const [searchParams] = useSearchParams();
  const mailPathQueryValue = searchParams.get('email');

  useEffect(() => {
    formItems.username.handleChange(mailPathQueryValue);
  }, [mailPathQueryValue]);

  const { translate } = useTranslate();

  return (
    <FocusLayout>
      <FleezyIcon width={'lg'} />
      <Card.Container className={authCardStyle}>
        <Card.Content>
          <Displayer bottomSpacing={3}>
            <Text
              variant="sectionTitle"
              color={themeColors.blackColor}
              align="left"
              fontWeight={900}
            >
              {translate('auth.loginPage.title')}
            </Text>
            <Text variant="textSmall" align="left" fontWeight={400} color={themeColors.blackColor}>
              {translate('auth.loginPage.subtitle')}
            </Text>
          </Displayer>

          <form onSubmit={onFormSubmit}>
            <Displayer innerSpacing={2}>
              <Form.TextAdapted
                label={translate('auth.username.label')}
                item={formItems.username}
              />
              <Form.PasswordAdapted
                label={translate('auth.password.label')}
                item={formItems.password}
              />
              <Displayer alignContent="flex-end">
                <Link to={ROUTE_FORGOT_PASSWORD}>
                  <Text fontWeight={600} color={theme.colors.brand} variant="textSmall">
                    {translate('auth.loginPage.lostPassword.cta')}
                  </Text>
                </Link>
              </Displayer>
              <Button.Default variant={'contained'} type="submit" isLoading={isLoading} fullWidth>
                {translate('auth.login.submit')}
              </Button.Default>
            </Displayer>
          </form>
        </Card.Content>
      </Card.Container>
      <Displayer alignContent="center">
        <Link to={ROUTE_SIGNUP}>
          <Text fontWeight={600} color={theme.colors.black} variant="textSmall">
            {translate('auth.loginPage.goToSignup.cta')}
          </Text>
        </Link>
      </Displayer>
      <LanguageSwitching />
    </FocusLayout>
  );
};
export default LoginPage;
