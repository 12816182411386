type PaletteeKeys =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'A100'
  | 'A200'
  | 'A400'
  | 'A700'
  | 'contrast';
export type Palette = Record<PaletteeKeys, string>;

export const NEUTRAL: Palette = {
  '50': '#f2f3f5',
  '100': '#dee2e6',
  '200': '#c8cfd5',
  '300': '#b2bbc4',
  '400': '#a2adb8',
  '500': '#919eab',
  '600': '#8996a4',
  '700': '#7e8c9a',
  '800': '#748291',
  '900': '#627080',
  A100: '#f3f9ff',
  A200: '#c1defe',
  A400: '#8dc2ff',
  A700: '#74b5ff',
  contrast: '#2E404F',
};

export const PRIMARY: Palette = {
  '50': '#e4edf9',
  '100': '#bcd1f1',
  '200': '#90b2e8',
  '300': '#6393df',
  '400': '#417cd8',
  '500': '#2065d1',
  '600': '#1c5dcc',
  '700': '#1853c6',
  '800': '#1349c0',
  '900': '#0b37b5',
  A100: '#e1e8ff',
  A200: '#aec0ff',
  A400: '#7b98ff',
  A700: '#6284ff',
  contrast: '#ffffff',
};

export const SECONDARY: Palette = {
  '50': '#fff5ed',
  '100': '#ffe8d4',
  '200': '#ffcda8',
  '300': '#ffaa70',
  '400': '#ff7b37',
  '500': '#ff5811',
  '600': '#f03c06',
  '700': '#c72a07',
  '800': '#9e220e',
  '900': '#450c05',
  A100: '#ffe8d420',
  A200: '#ffcda820',
  A400: '#ff7b3720',
  A700: '#c72a0720',
  contrast: '#ffffff',
};

export const INFO: Palette = {
  '50': '#e3f2ff',
  '100': '#badeff',
  '200': '#8cc8ff',
  '300': '#5db1ff',
  '400': '#3ba1ff',
  '500': '#1890ff',
  '600': '#1588ff',
  '700': '#117dff',
  '800': '#0e73ff',
  '900': '#0861ff',
  A100: '#ffffff',
  A200: '#f4f7ff',
  A400: '#c1d4ff',
  A700: '#a7c3ff',
  contrast: '#ffffff',
};

export const SUCCESS: Palette = {
  '50': '#e5f4e9',
  '100': '#bfe5c7',
  '200': '#94d3a2',
  '300': '#69c17d',
  '400': '#48b461',
  '500': '#28a745',
  '600': '#249f3e',
  '700': '#1e9636',
  '800': '#188c2e',
  '900': '#0f7c1f',
  A100: '#aeffb8',
  A200: '#7bff8b',
  A400: '#48ff5e',
  A700: '#2fff47',
  contrast: '#ffffff',
};

export const WARNING: Palette = {
  '50': '#fff8e1',
  '100': '#ffecb5',
  '200': '#ffe083',
  '300': '#ffd451',
  '400': '#ffca2c',
  '500': '#ffc107',
  '600': '#ffbb06',
  '700': '#ffb305',
  '800': '#ffab04',
  '900': '#ff9e02',
  A100: '#ffffff',
  A200: '#fffaf2',
  A400: '#ffe4bf',
  A700: '#ffd9a6',
  contrast: '#000000',
};

export const ERROR: Palette = {
  '50': '#ffe9e8',
  '100': '#ffc8c6',
  '200': '#ffa4a1',
  '300': '#ff7f7b',
  '400': '#ff635e',
  '500': '#ff4842',
  '600': '#ff413c',
  '700': '#ff3833',
  '800': '#ff302b',
  '900': '#ff211d',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffcece',
  A700: '#ffb5b4',
  contrast: '#ffffff',
};
export const DANGER: Palette = {
  '50': '#FFFFF0',
  '100': '#FEFCBF',
  '200': '#FAF089',
  '300': '#F6E05E',
  '400': '#ECC94B',
  '500': '#D69E2E',
  '600': '#B7791F',
  '700': '#975A16',
  '800': '#744210',
  '900': '#5F370E',
  A100: '#ffffff',
  A200: '#FDF8DD',
  A400: '#FFF1B5',
  A700: '#FFE06E',
  contrast: '#ffffff',
};
