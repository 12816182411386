import { FC, PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { isNumber } from 'core/common/utils/predicatesType';

type SizeValue = number | 'auto' | 'flex';

type ResponsiveSize = Record<'xs' | 'sm' | 'md' | 'lg', SizeValue>;

interface GridItemProps {
  size?: ResponsiveSize | SizeValue;
}

const GridItem: FC<PropsWithChildren<GridItemProps>> = ({ children, size = 'flex' }) => {
  const getPropsFromSize = (
    value: string | number | ResponsiveSize,
    key = 'xs',
  ): Record<string, string | number | boolean> => {
    if (typeof value === 'object') {
      let formatedObject: Record<string, string | number | boolean> = {};

      for (const objkey in value) {
        formatedObject = {
          ...formatedObject,
          ...getPropsFromSize(value[objkey as keyof typeof value], objkey),
        };
      }

      return formatedObject;
    }

    if (isNumber(value) || value === 'auto') {
      return { [key]: value };
    }

    return { [key]: true };
  };

  return (
    <Grid item {...getPropsFromSize(size)}>
      {children}
    </Grid>
  );
};

export default GridItem;
