export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isStringNotEmpty(value: unknown): value is string {
  return typeof value === 'string' && value.length > 0;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every((item) => isString(item));
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

// Many thing are Object in Javascript.
// We want an object but not an array (= object) nor a null (= object) nor an instance of Class (=object)
export function isObject(value: unknown): value is object {
  return (
    !!value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    !value.constructor.toString().startsWith('class')
  );
}

export function isArray(value: unknown): value is [] {
  return Array.isArray(value);
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return !!value;
}
