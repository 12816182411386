import { Chip as MuiChip } from '@mui/material';
import { FC, ReactElement } from 'react';

export interface ChipProps {
  label: string;
  icon?: ReactElement;
  bgColor?: string;
  color?: string;
  size?: 'small' | 'default';
  variant?: 'outlined' | 'filled';
  fontWeight?: string | number;
  border?: boolean;
  borderRadius?: string | number;
  onDelete?: () => void;
}

const Chip: FC<ChipProps> = ({
  label,
  icon,
  size,
  bgColor = '#d0d0d0',
  color = '#404040',
  border = true,
  borderRadius,
  fontWeight,
  variant,
  onDelete,
}) => {
  return (
    <MuiChip
      label={label}
      variant={variant}
      icon={icon}
      size={size === 'default' ? 'medium' : size}
      onDelete={onDelete}
      sx={{
        backgroundColor: variant === 'filled' ? bgColor : 'transparent',
        color: color,
        border: border ? `1px solid ${bgColor}` : 0,
        fontWeight,
        borderRadius,
      }}
    />
  );
};

export default Chip;
