import { useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';
import useToaster from 'src/web/design_system/Toaster/useToaster';
import useTranslate from 'src/web/common/translate/useTranslate';

export interface UsePostDocumentsReturn {
  handlePostDocument: (content?: File) => void;
  loading: boolean;
}

interface UsePostDocumentsProps {
  type: string;
  entity: EntitiesEnum;
  id: string;
  afterSubmit?: () => void;
}

const usePostDocuments = ({
  type,
  entity,
  id,
  afterSubmit,
}: UsePostDocumentsProps): UsePostDocumentsReturn => {
  const { documentUsecase } = useCore();
  const [loading, setLoading] = useState<boolean>(false);
  const toaster = useToaster();
  const { translate } = useTranslate();

  const createErrorToast = () => {
    toaster.createToast({
      message: translate('documents.import.error.toaster'),
      variant: 'error',
      duration: 6000,
    });
  };

  const handlePostDocument = async (content?: File) => {
    if (content) {
      setLoading(true);

      const response = await documentUsecase.postDocuments(content, entity, type, id);
      if (response?.status !== 201) {
        createErrorToast();
      }
      if (response && afterSubmit) {
        afterSubmit();
      }

      setLoading(false);
    }
  };

  return {
    handlePostDocument,
    loading,
  };
};

export default usePostDocuments;
