import { FC, type PropsWithChildren, ReactElement, useEffect } from 'react';
import { useRouter } from 'src/web/common/router/router.hooks';
import { useCore } from 'src/web/common/core';
import CookieContent from 'src/web/common/cookieConsent/cookieConsent.component';
import 'normalize.css';
import './index.css';
import { useLocation } from 'react-router-dom';
import { AppEventsNames } from 'core/common/constants';

interface BaseLayoutProps {
  content?: ReactElement | null;
}

const BaseLayout: FC<PropsWithChildren<BaseLayoutProps>> = ({ children, content }) => {
  const { eventService } = useCore();
  const { navigate } = useRouter();
  const location = useLocation();

  useEffect(() => {
    const eventId1 = eventService.subscribe(AppEventsNames.OnLogin, () => {
      navigate('/');
    });
    const eventId2 = eventService.subscribe(AppEventsNames.OnLogout, () => {
      if (location.pathname !== '/login') {
        navigate('login');
      }
    });

    return () => {
      eventService.unsubscribe(eventId1);
      eventService.unsubscribe(eventId2);
    };
  }, []);

  return (
    <>
      {children ?? content}

      <CookieContent />
    </>
  );
};

export default BaseLayout;
