import { FC, useMemo } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import useFormComponent from './useFormComponent';
import InputNumber, { type InputNumberProps } from './InputNumber';
import { isNumber } from 'core/common/utils/predicatesType';

type FormNumberExcludedProps = keyof Pick<InputNumberProps, 'value' | 'error' | 'isRequired'>;

interface FormNumberProps extends Omit<InputNumberProps, FormNumberExcludedProps> {
  item: FormItem;
}

const FormNumber: FC<FormNumberProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props);
    return <></>;
  }
  const { type, onChange, min, max, message, ...restProps } = props;
  const { formatFormMessage } = useFormComponent();
  const value = isNumber(item.formValue) ? item.formValue : undefined;

  //I cannot find the decimal type in the MUI number input type or in HTMLInputTypeAttribute, this code has updated in the FL-2245 US by EK
  // I add instead a type = integer in the InputNumber to specify that the number is not decimal and type  number to specify that the number is decimal

  const computedType = useMemo(() => {
    if (type) {
      return type;
    }
    return 'number';
  }, []);

  return (
    <InputNumber
      value={value}
      isRequired={item.required}
      error={!!item.errors}
      min={min ?? item.minLength}
      max={max ?? item.maxLength}
      message={formatFormMessage(item) ?? message}
      onChange={(val) => {
        if (onChange) {
          onChange(val);
        } else {
          item.handleChange(val);
        }
      }}
      type={computedType}
      {...restProps}
    />
  );
};

export default FormNumber;
