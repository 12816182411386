import { useState, useCallback } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';

const useGetDocumentsByAssetId = (id: string, entity: EntitiesEnum) => {
  const { documentUsecase } = useCore();
  const [data, setData] =
    useState<Awaited<ReturnType<typeof documentUsecase.getDocumentsByEntityId>>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const response = await documentUsecase.getDocumentsByEntityId(id, entity);

    if (response) {
      setData(response);
    }

    setIsLoading(false);
  }, [id, entity, documentUsecase]);

  return {
    data,
    isLoading,
    loadData,
  };
};

export default useGetDocumentsByAssetId;
