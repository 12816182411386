import { RolesEnum } from 'core/common/constants';
import { User } from 'core/entities/user.entity';

export function isUserGranted(
  currentUser: User | undefined,
  roles: ReadonlyArray<RolesEnum> = [],
): boolean {
  return (
    !!currentUser &&
    Array.isArray(currentUser?.roles) &&
    roles.filter((role) => currentUser.roles?.includes(role)).length !== 0
  );
}
