import React, { FC } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { DynamicAssetDetailsReturn } from 'core/usecases/details.usecases';
import { themeColors } from 'src/web/common/theme/constant';
import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import DividerDatas from 'src/web/components/Divider';
import TextPair from 'src/web/components/TextPair';
import { commentCardStyle } from './style';
import DynamicFinancingFields from './DynamicFinancingFields';
import useTranslate from 'src/web/common/translate/useTranslate';

type ENTITY = EntitiesEnum.licence;
interface LicenceReturnAsset {
  dataAsset?: DynamicAssetDetailsReturn<ENTITY>;
}

const LicenceInformations: FC<LicenceReturnAsset> = ({ dataAsset }) => {
  const { translate } = useTranslate();

  return (
    <>
      <Displayer innerSpacing={1}>
        <TextPair label={translate('licences.version.label')} value={dataAsset?.version} />
        <TextPair
          label={translate('licences.invoicingMethod.label')}
          value={translate(`licences.invoicingMethod.option.${dataAsset?.invoicingMethod}.label`)}
        />
        <TextPair label={translate('licences.name.label')} value={dataAsset?.asset?.name} />
      </Displayer>
      <DividerDatas dashed />
      <Displayer innerSpacing={1}>
        <Text color={themeColors.blackColor} fontWeight={900} variant="sectionTitle">
          {translate('assets.asset.contractualCommitment.title')}
        </Text>
        <DynamicFinancingFields dataAsset={dataAsset} />
      </Displayer>
      {dataAsset?.asset?.comment ? (
        <>
          <DividerDatas dashed />
          <Card.Basic title={translate('assets.asset.comment.title')} className={commentCardStyle}>
            <Card.Content compact>
              <Text variant="textSmall">{dataAsset.asset.comment}</Text>
            </Card.Content>
          </Card.Basic>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default LicenceInformations;
