import { RouterProvider } from 'react-router-dom';
import routes from './router.routes';
import { FC } from 'react';

/**
 * Provide and setup the routing library
 */
const AppRouter: FC = () => {
  return <RouterProvider router={routes} />;
};

export default AppRouter;
