import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';

class VehicleEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathOne() {
    return exactString('/api/private/vehicles/{id}');
  }

  getPathAll() {
    return exactString('/api/private/vehicles');
  }

  getPathMileageOne() {
    return exactString('/api/private/vehicle-mileages/{id}');
  }

  getPathMileageAll() {
    return exactString('/api/private/vehicle-mileages');
  }

  getPathMediaObjectOne() {
    return exactString('/api/private/vehicle-media-objects/{id}');
  }

  getPathMediaObjectAll() {
    return exactString('/api/private/vehicle-media-objects');
  }

  getPathSivinData() {
    return exactString('/api/private/sivin/vehicle/{licencePlate}');
  }

  getPathMediaObjectDownload() {
    return exactString('/api/private/vehicle-media-objects-download/{id}');
  }

  getOne = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathOne(), 'get', options),
      {
        path: this.getPathOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathAll(), 'get', options),
      {
        path: this.getPathAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  post = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'post'>) => {
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathAll(), 'post', options);
  };

  put = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMileageOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'put', options);
  };

  delete = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'delete'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMileageOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'delete', options);
  };

  getMileageOne = async (
    options: RequestOptions<ReturnType<typeof this.getPathMileageOne>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMileageOne(), 'get', options),
      {
        path: this.getPathMileageOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getMileageAll = async (
    options?: RequestOptions<ReturnType<typeof this.getPathMileageAll>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMileageAll(), 'get', options),
      {
        path: this.getPathMileageAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  postMileage = async (
    options: RequestOptions<ReturnType<typeof this.getPathMileageAll>, 'post'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathMileageAll(), 'post', options);
  };

  putMileage = async (
    options: RequestOptions<ReturnType<typeof this.getPathMileageOne>, 'put'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMileageOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathMileageOne(), 'put', options);
  };

  deleteMileage = async (
    options: RequestOptions<ReturnType<typeof this.getPathMileageOne>, 'delete'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMileageOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    this.cacheService.cacheInvalidate(this.getPathMileageAll());
    return this.http.requestFromSwagger(this.getPathMileageOne(), 'delete', options);
  };

  getMediaObjectOne = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectOne>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMediaObjectOne(), 'get', options),
      {
        path: this.getPathMediaObjectOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getMediaObjectAll = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectAll>, 'get'>,
  ) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathMediaObjectAll(), 'get', options),
      {
        path: this.getPathMediaObjectAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  postMediaObject = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectAll>, 'post'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathMediaObjectAll());
    return this.http.requestFromSwagger(this.getPathMediaObjectAll(), 'post', options);
  };

  deleteMediaObject = async (
    options: RequestOptions<ReturnType<typeof this.getPathMediaObjectOne>, 'delete'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathMediaObjectOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathMediaObjectAll());
    return this.http.requestFromSwagger(this.getPathMediaObjectOne(), 'delete', options);
  };

  getSivin = async (options: RequestOptions<ReturnType<typeof this.getPathSivinData>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathSivinData(), 'get', options),
      {
        path: this.getPathSivinData(),
        method: 'get',
        payload: options,
        ttl: 1000 * 60,
      },
    );
  };
}

export default VehicleEntity;
