import { useState, type FC, type PropsWithChildren, type ReactElement } from 'react';
import * as styles from './styles';
import Header from 'src/web/components/Header';
import useBreakpoint from 'src/web/common/utils/useBreakpoints';
import SideContent from './partials/sideContent';
import Drawer from 'src/web/design_system/Drawer';
import { useTheme } from 'src/web/common/theme';
import DrawerPartial from './partials/drawerPartial';

interface DashboardLayoutProps {
  content?: ReactElement | null;
}

const DashboardLayout: FC<PropsWithChildren<DashboardLayoutProps>> = ({ children, content }) => {
  const { theme } = useTheme();
  const isMobile = useBreakpoint('down', 'md');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  return (
    <div className={styles.rootContainer(drawerOpen, theme)}>
      <div className={styles.headerContainer(theme)}>
        <Header onClickMenuButton={() => setDrawerOpen((currentState) => !currentState)} />
      </div>

      {isMobile ? (
        <Drawer.Default
          open={!drawerOpen}
          onClose={() => setDrawerOpen((currentState) => !currentState)}
        >
          <div className={styles.sideContainer(theme)}>
            <SideContent isMobile={isMobile} />
          </div>
        </Drawer.Default>
      ) : (
        <DrawerPartial mode="fixed" open={drawerOpen}>
          <div className={styles.sideContainer(theme)}>
            <SideContent open={drawerOpen} setOpen={setDrawerOpen} />
          </div>
        </DrawerPartial>
      )}
      <div className={styles.wrapContentContainer(theme)}>
        <div className={styles.contentContainer(theme)}>{children ?? content}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
