export type FontWeights = Record<string, number>;
export const FONT_WEIGHTS = {
  light: 300,
  regular: 400,
  semibold: 500,
  bold: 700,
  heavy: 900,
};

export type FontFamilies = Record<string, string>;
export const FONT_FAMILIES = {
  default: 'inherit',
  variant: 'inherit',
};

export type LineHeights = Record<string, string>;
export const LINE_HEIGHTS = {
  compact: '1.25',
  default: '1.5',
  large: '1.75',
};

export type FontSizes = Record<`title_${number}` | `text_${string}`, number>;
export const FONT_SIZES: FontSizes = {
  title_1: 26,
  title_2: 22,
  title_3: 20,
  title_4: 17,
  title_5: 17,
  title_6: 17,
  text_big: 17,
  text_default: 14,
  text_small: 13,
  text_tiny: 12,
};
