import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createCss } from 'src/web/common/styles/createCss';
import useTranslate from 'src/web/common/translate/useTranslate';
import Text from 'src/web/design_system/Text';
import Displayer from 'src/web/design_system/Displayer';
import { useTheme } from 'src/web/design_system/Theme';
import Icon from 'src/web/components/Icon';

interface SmallCardFileUploadProps {
  onChange: (content: File) => void;
}

const SmallCardFileUpload: React.FC<SmallCardFileUploadProps> = ({ onChange }) => {
  const { translate } = useTranslate();
  const [isHover, setIsHover] = useState(false);
  const theme = useTheme();
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles[0]);
      }
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    noKeyboard: true,
  });

  const isHovered = isDragActive || isHover;

  const cardDropZoneStyle = createCss(`
  background: ${isHovered ? '#00000010' : '#00000005'};
  padding: 17px 20px ;
  height: auto;
  width: auto;
  border: 2px dotted #00000015;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`);

  return (
    <div
      className={cardDropZoneStyle}
      {...getRootProps()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <input {...getInputProps()} type="file" />
      <Displayer alignItems={'center'} justifyContent={'center'} innerSpacing={0.5}>
        <Icon.Add color="primary" />
        <Text color={theme.theme.palettes.neutral?.[500]}>
          {translate('documents.import.add.label')}
        </Text>
      </Displayer>
    </div>
  );
};

export default SmallCardFileUpload;
