import { FC, useEffect } from 'react';
import useTasksInventory from './hooks/useTasksInventory';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import InventoryCard from 'src/web/components/InventoryCard';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';
import Text from 'src/web/design_system/Text';
import Link from 'src/web/components/Link';
import { useCore } from 'src/web/common/core';
import { createCss } from 'src/web/common/styles/createCss';
import SearchExpandable from 'src/web/design_system/Input/SearchExpandable';
import Tabs from 'src/web/design_system/Tabs';
import EmptyValue from 'src/web/components/EmptyValue';
import useTranslate from 'src/web/common/translate/useTranslate';
import ButtonTaskStatus from 'src/web/components/Button/ButtonTaskStatus';
import useInventoryHelpers from 'src/web/common/inventory/useInventoryHelpers';
import Displayer from 'src/web/design_system/Displayer';
import InventoryFilter from 'src/web/components/InventoryFilter';
import Skeleton from 'src/web/design_system/Skeleton';
import FiltersModalByButton from 'src/web/components/FiltersModalByButton';

const commentCeilStyle = createCss(`
  max-height: 100px;
  overflow-y: auto;
  padding: 10px;
`);

const TaskInventoryPage: FC = () => {
  const { timeService } = useCore();
  const inventoryData = useTasksInventory([
    'digestColumn',
    'owner',
    'deadline',
    'person',
    'comment',
    'status',
  ]);
  const { translate } = useTranslate();
  const inventoryHelpers = useInventoryHelpers();

  useEffect(() => {
    inventoryData.handleLoadData();
  }, []);

  const handleSubmit = () => {
    inventoryData.handleLoadData();
    return true;
  };

  return (
    <>
      <PageHeaderBasic title={translate('tasks.title')} />

      <InventoryCard.Container>
        {/* TOOLBAR */}
        <InventoryCard.Toolbar
          primaryContent={
            <>
              <Displayer>
                <Displayer layout={'row'} alignItems={'center'} innerSpacing={1} topSpacing={1}>
                  <FiltersModalByButton edge="start" onSubmitFilters={() => handleSubmit()}>
                    <Skeleton isLoading={inventoryData.isLoading} width="100%" height="250px">
                      <Displayer innerSpacing={2}>
                        <InventoryFilter
                          item={inventoryData.filters['customFormAnswer.topic']}
                          label={translate('tasks.topic.label')}
                          renderOptionItem={(value) => ({
                            label: translate(`tasks.topic.option.${value}.label`),
                          })}
                        />
                        <InventoryFilter
                          item={inventoryData.filters.owner}
                          label={translate('tasks.owner.label')}
                        />
                        <InventoryFilter
                          item={inventoryData.filters['customFormAnswer.commonName']}
                          label={translate('tasks.commonName.label')}
                        />
                      </Displayer>
                    </Skeleton>
                  </FiltersModalByButton>
                  {/* CURRENT FILTERS */}
                  <InventoryCard.CurrentFilters
                    verticalPadding
                    activeFilters={Object.keys(inventoryData?.selectedFilters)}
                    onClearFilter={(name) => inventoryData.handleClearFilter(name)}
                  />
                </Displayer>
                <Tabs.Header
                  selectedId={inventoryData.tabCurrent}
                  onChange={(id) => inventoryData.handleChangeTab(id)}
                  tabs={inventoryData.tabOptions}
                />
              </Displayer>
            </>
          }
          secondaryContent={
            <SearchExpandable
              edge="end"
              onSearch={(val) => inventoryData.handleSearch(val)}
              initialValue={inventoryData.currentSearch}
            />
          }
        />

        {/* INVENTORY */}
        <InventoryCard.Inventory
          size="auto"
          isLoading={inventoryData.isLoading}
          onSort={(key, sortState) =>
            inventoryHelpers.handleSort(inventoryData.sorters, key, sortState)
          }
          rows={inventoryData.rows ?? []}
          columns={inventoryData.columns!.map((column) => ({
            key: column,
            label: () => `${column}`,
            minWidth: (key) => {
              if (['status', 'deadline'].includes(key)) {
                return 150;
              }
              return 200;
            },
            isFlex: (key) => {
              if (['owner', 'digestColumn', 'person'].includes(key)) {
                return true;
              }
              return false;
            },
            maxWidth: () => 700,
            isSortable: () =>
              Object.keys(inventoryData.sorters ?? []).includes(`order[${String(column)}]`),
            renderHeader: (field) => {
              return translate(`tasks.${field}.label`);
            },
            render: (row) => {
              if (column === 'person') {
                const personId = row['customFormAnswer.entityId'];
                const personName = row['customFormAnswer.commonName'];
                return (
                  <Text>
                    <Link to={`${ROUTE_PERSON}/${personId}`}>{personName}</Link>
                  </Text>
                );
              }

              if (column === 'deadline') {
                return (
                  <Text>
                    {row[column] ? (
                      timeService.format(new Date(row[column]), 'short')
                    ) : (
                      <EmptyValue />
                    )}
                  </Text>
                );
              }

              if (column === 'owner') {
                return (
                  <Text textOverflow={true} title={row[column]}>
                    {row[column]}
                  </Text>
                );
              }

              if (column === 'digestColumn') {
                return (
                  <div>
                    <Text variant="textSmall">
                      {translate(`tasks.topic.option.${row['task.topic']}.label`)}
                    </Text>
                    <Text tag="div" fontWeight={600}>
                      {translate(`tasks.task.option.${row['task.task']}.label`)}
                    </Text>
                  </div>
                );
              }

              if (column === 'comment') {
                return (
                  <div>
                    <div className={commentCeilStyle}>{row[column]}</div>
                  </div>
                );
              }

              if (column === 'status') {
                return (
                  <Displayer topSpacing={1.5} bottomSpacing={1.5}>
                    <ButtonTaskStatus
                      state={row[column]}
                      onClick={() => inventoryData.handleToggleStatus(row.id, row[column])}
                      isLoading={inventoryData.taskStatusOnLoad === row.id}
                    />
                  </Displayer>
                );
              }

              return <Text>{row[column]}</Text>;
            },
          }))}
        />
        {/* PAGINATION */}
        <InventoryCard.Pagination
          paginationCount={Math.ceil(inventoryData.totalItems / inventoryData.itemPerPage)}
          paginationCurrent={inventoryData.currentPage}
          paginationPerPage={inventoryData.itemPerPage}
          onPaginationChange={(value) => inventoryData.setCurrentPage(value)}
          onPaginationChunkChange={(value) => inventoryData.setItemPerPage(Number(value))}
        />
      </InventoryCard.Container>
    </>
  );
};

export default TaskInventoryPage;
