import { useEffect, useState } from 'react';
import { EntitiesEnum } from 'src/core/common/constants';
import { getIdFromIri } from 'src/core/common/utils/id';
import { useCore } from 'src/web/common/core';
import { downloadFileFromBlob } from 'src/web/common/download';
import useCreateForm from 'src/web/common/form/useCreateForm';
import useTranslate from 'src/web/common/translate/useTranslate';

interface UseSetPersonAssignmentProps {
  assetIri: string;
  afterSubmit: (status: boolean) => void;
  personIri: string;
  generateDeliveryAssignementCertificate?: boolean;
  generateReturnAssignmentCertificate?: boolean;
  minDate?: string;
  entity: EntitiesEnum;
}

const useSetPersonAssignment = ({
  assetIri,
  afterSubmit,
  personIri,
  generateDeliveryAssignementCertificate,
  generateReturnAssignmentCertificate,
  entity,
}: UseSetPersonAssignmentProps) => {
  const { assignmentUseCase, cacheService } = useCore();

  const { translate } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const { properties, getInitialValues, required, submit, validate } =
    assignmentUseCase.getAssignAssetUpdateForm(assetIri);

  const handleGetAssignmentCertificate = async (
    startDate: string,
    person: string,
    assignmentIds: string[],
    restituteType: 'assetAssignments' | 'assetReturns',
  ) => {
    const downloadBlob = await assignmentUseCase.getCertificateDownLoadBlob(
      startDate,
      person,
      assignmentIds,
      restituteType,
    );

    if (downloadBlob) {
      downloadFileFromBlob(
        downloadBlob,
        translate(
          restituteType === 'assetAssignments'
            ? 'asset.assetAssignment.delevery.fileName'
            : 'asset.assetAssignment.return.fileName',
        ),
      );
    }
  };

  const form = useCreateForm({
    properties,
    required: [...required],
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        cacheService.cacheInvalidate(`/api/private/${entity}`);
      }

      // condition for delery assignment asset pdf
      if (response && generateDeliveryAssignementCertificate) {
        handleGetAssignmentCertificate(
          data.startAt ? data.startAt?.toString() : '',
          personIri,
          [getIdFromIri(assetIri ?? '') ?? ''],
          'assetAssignments',
        );
      }

      // condition for return assignment asset pdf
      if (response && generateReturnAssignmentCertificate) {
        handleGetAssignmentCertificate(
          data.endAt ? data.endAt?.toString() : '',
          personIri,
          [getIdFromIri(assetIri ?? '') ?? ''],
          'assetReturns',
        );
      }

      if (afterSubmit) {
        afterSubmit(response);
      }
      setIsLoading(false);
    },
  });

  // Update form values from current data
  useEffect(() => {
    getInitialValues().then((values) => {
      for (const key in values) {
        form.imperativeChangeField(key, values[key as keyof typeof values]);
      }
      setIsLoading(false);
    });
  }, []);

  return {
    form,
    isLoading,
  };
};

export default useSetPersonAssignment;
