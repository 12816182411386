import { useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

const useAddSignupForm = () => {
  const { authUsecase, store } = useCore();
  const { properties, required, validate, submit } = authUsecase.getSignupForm();

  const [isLoading, setIsLoading] = useState(false);
  const [succesSubmit, setSuccesSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const form = useCreateForm({
    properties,
    initialValues: {
      id: '',
      signed: true,
      status: 2,
      preferredLanguage: store.getState((st) => st.user.guest?.preferredLanguage),
    },
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        setSuccesSubmit(true);
      } else {
        setErrorSubmit(true);
      }
      setIsLoading(false);
    },
  });

  return {
    form,
    succesSubmit,
    errorSubmit,
    isLoading,
  };
};

export default useAddSignupForm;
