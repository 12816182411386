import { LocalesEnum } from 'src/core/common/constants';
import AppStore from 'src/core/services/store/store.service';

class GuestUsecase {
  constructor(private store: AppStore) {}

  public updatePreferredLanguage(value: LocalesEnum) {
    this.store.setState((state) => {
      state.user = { guest: { preferredLanguage: value } };
    });
  }
}

export default GuestUsecase;
