import { createCss } from 'src/web/common/styles/createCss';

export const backToNavigationButtonStyle = (color: string) =>
  createCss(`
    color:${color} !important;
    justify-content: start !important;
`);

export const fixBackToNavigationButtonStyle = createCss(`
    display: block !important;
`);
