import { EntitiesEnum } from 'core/common/constants';
import { flattenDataList } from 'src/web/common/inventory/tools';
import useCreateInventory from 'src/web/common/inventory/useCreateInventory';

export const useAssetInventory = <N extends EntitiesEnum>(entity: N, displayKeys?: string[]) => {
  const { rows, ...rest } = useCreateInventory(entity);

  return {
    rows: rows ? flattenDataList(rows) : undefined,
    ...rest,
    columns: displayKeys,
  };
};

export default useAssetInventory;
