type Executable = (...args: any) => any;

type Instanciable = new (...args: any) => any;

type MappedServices<S> = {
  [Property in keyof S]: S[Property] extends Instanciable
    ? InstanceType<S[Property]>
    : S[Property] extends Executable
    ? ReturnType<S[Property]>
    : S[Property];
};

class Injector {
  private dependencies: Map<string, any>;

  private resolvedDeps: Map<string, any>;

  constructor() {
    this.dependencies = new Map();
    this.resolvedDeps = new Map();
  }

  public init<Services extends object>(services: Services): MappedServices<Services> {
    if (this.dependencies.size > 0) {
      throw new Error('Injector already setup');
    }

    for (const key in services) {
      this.dependencies.set(key, services[key]);
    }

    const exposed = Object.fromEntries(
      Object.entries(services)
        .map(([k]) => k as Extract<keyof typeof this.dependencies, string>)
        .map((k) => [k, this.resolve(k)]),
    );

    return exposed as MappedServices<Services>;
  }

  private isExecutable(dependency: any): boolean {
    return typeof dependency === 'function';
  }

  private isInstanciable(dependency: any): boolean {
    return (
      typeof dependency === 'function' &&
      dependency.prototype &&
      dependency.prototype.constructor === dependency
    );
  }

  private resolve<T>(name: string): T {
    const dependency = this.dependencies.get(name);
    const dependencies = () =>
      this.getDependencies(name as Extract<keyof typeof this.dependencies, string>);

    if (this.isInstanciable(dependency)) {
      if (this.resolvedDeps.has(dependency)) {
        return this.resolvedDeps.get(dependency);
      } else {
        const resolved = new dependency(...dependencies());
        this.resolvedDeps.set(dependency, resolved);
        return resolved;
      }
    } else if (this.isExecutable(dependency)) {
      return dependency(...dependencies());
    } else {
      return dependency;
    }
  }

  private getDependencies(name: Extract<keyof typeof this.dependencies, string>) {
    const dependency = this.dependencies.get(name);

    if (!this.isInstanciable(dependency)) return [];

    const fnStr = dependency.toString();

    const argNames =
      fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(/([^\s,]+)/g) || [];

    const mapping = argNames
      .filter((depName: string) => this.dependencies.has(depName))
      .map((depName: Extract<keyof typeof this.dependencies, string>) => this.resolve(depName));

    return mapping;
  }
}

export default new Injector();
