import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AllowedAssetDetailsEntities,
  DynamicAssetDetailsReturn,
} from 'core/usecases/details.usecases';
import { useCore } from 'src/web/common/core';

function useGetAssetDetailData<N extends AllowedAssetDetailsEntities>(
  entityType: N,
  redirect: string,
) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { assetDetailsUseCase } = useCore();
  const [dataRes, setDataRes] = useState<DynamicAssetDetailsReturn<N>>(undefined);
  const [isLoadingRes, setIsLoadingRes] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    setIsLoadingRes(true);

    const response = await assetDetailsUseCase.getOneAsset(entityType, id ?? '');
    if (response) {
      setDataRes(response);
    } else {
      navigate(redirect);
    }
    setIsLoadingRes(false);
  }, [id, assetDetailsUseCase]);

  useEffect(() => {
    if (id) {
      loadData();
    } else {
      navigate(redirect);
    }
  }, [id, loadData]);

  return {
    data: dataRes,
    isLoading: isLoadingRes,
    reload: loadData,
  };
}

export { useGetAssetDetailData };
