import { themeColors } from 'src/web/common/theme/constant';
import { keyframes } from '@emotion/css';
import { createCss } from 'src/web/common/styles/createCss';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    scale:0.9;
  }
  100% {
    opacity: 0.1;
    scale:1;
  }
`;

export const wrapper = createCss(`
  z-index: -1;
  display: flex;
  background: ${themeColors.backgroundColor};
  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    height: 80vmax;
    width: 80vmax;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 767px) {
      height: 90vmin;
      width: 90vmin;
    }
    @media (min-width: 1024px) {
      height: 80vmin;
      width: 80vmin;
    }
    background: url('/images/nid-abeille-fleezy-3.webp');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    animation: ${fadeIn} 2s ease-out 1s forwards;
  }
`);

export const content = createCss(`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100vh;
  margin: auto;
`);
