import { FC } from 'react';
import SideNav from 'src/web/components/SideNav';
import FleezyLogo from 'src/web/components/FleezyLogo';
import Button from 'src/web/design_system/Button';
import * as styles from '../styles';
import Icon from 'src/web/components/Icon';
import { useTheme } from 'src/web/common/theme';
import { mergeCss } from 'src/web/common/styles/createCss';

interface SideContentProps {
  isMobile?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const SideContent: FC<SideContentProps> = ({ isMobile, open, setOpen }) => {
  const { theme } = useTheme();
  const handleDrawerOpen = () => {
    if (setOpen) {
      setOpen(!open);
    }
  };

  return (
    <>
      {!isMobile && (
        <Button.Icon
          size="small"
          className={styles.reduceMenuButtonStyle(theme)}
          onClick={handleDrawerOpen}
        >
          {open ? <Icon.ChevronLeft size="small" /> : <Icon.ChevronRight size="small" />}
        </Button.Icon>
      )}
      <section className={mergeCss(styles.containerStyle(theme), styles.scrollBarStyle(open))}>
        <div className={styles.headContainer}>
          <FleezyLogo version={isMobile ? 'default' : open ? 'default' : 'minimal'} />
        </div>
        <div className={styles.content(open ?? true)}>
          <SideNav open={open ?? true} />
        </div>
      </section>
    </>
  );
};

export default SideContent;
