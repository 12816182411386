import { useCallback, useState } from 'react';
import { useCore } from 'src/web/common/core';

interface UseDeleteMileageVehicleReturn {
  isLoading: boolean;
  isDeleted: boolean;
  handleDelete: () => void;
}

export const useDeleteMileageVehicle = (
  dataId: string,
  onSubmit: () => void,
): UseDeleteMileageVehicleReturn => {
  const [isLoadingRes, setIsLoadingRes] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const { setVehicleUseCase } = useCore();

  const handleDelete = useCallback(async () => {
    setIsLoadingRes(true);
    const response = await setVehicleUseCase.deleteMileageVehicle(dataId);
    if (response) {
      setIsDeleted(true);
      onSubmit();
    } else {
      setIsDeleted(false);
    }
    setIsLoadingRes(false);
  }, [setVehicleUseCase]);

  return { isLoading: isLoadingRes, isDeleted, handleDelete };
};
