import { FC, PropsWithChildren, useMemo } from 'react';
import { CardContent as MuiCardContent } from '@mui/material';
import { isNumber } from 'core/common/utils/predicatesType';

export interface CardContentProps {
  className?: string;
  padding?: number | Record<'vertical' | 'horizontal', number>;
  noPadding?: ('top' | 'bottom' | 'left' | 'right')[];
}

const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  children,
  className,
  padding,
  noPadding,
}) => {
  const customPadding = useMemo(() => {
    let obj: Record<string, number> = {};

    if (padding) {
      obj = {
        ...obj,
        paddingTop: isNumber(padding) ? padding : padding.vertical,
        paddingBottom: isNumber(padding) ? padding : padding.vertical,
        paddingLeft: isNumber(padding) ? padding : padding.horizontal,
        paddingRight: isNumber(padding) ? padding : padding.horizontal,
      };
    }

    if (noPadding) {
      if (noPadding.includes('top')) {
        obj = { ...obj, paddingTop: 0 };
      }
      if (noPadding.includes('bottom')) {
        obj = { ...obj, paddingBottom: 0 };
      }
      if (noPadding.includes('left')) {
        obj = { ...obj, paddingLeft: 0 };
      }
      if (noPadding.includes('right')) {
        obj = { ...obj, paddingRight: 0 };
      }
    }

    return obj;
  }, [padding, noPadding]);

  return (
    <MuiCardContent
      className={className}
      sx={{
        ...customPadding,
      }}
    >
      {children}
    </MuiCardContent>
  );
};

export default CardContent;
