import { FC } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { ROUTE_SERVICE } from 'src/web/common/router/constantes';
import useEditAssetForm from './hooks/useEditAssetForm';
import PageContainerPartial from './partials/pageContainerPartial';
import FinancingFormPartial from './partials/financingFormPartial';
import DetailFormPartial from './partials/DetailFormPartial';
import ServiceInfoFormPartial from './partials/serviceInfoFormPartial';
import useTranslate from 'src/web/common/translate/useTranslate';
import { useParams, useSearchParams } from 'react-router-dom';

const ENTITY: EntitiesEnum = EntitiesEnum.service;

const EditServicePage: FC = () => {
  const { form, isLoading } = useEditAssetForm({
    asset: ENTITY,
    baseRoute: ROUTE_SERVICE,
  });
  const { translate } = useTranslate();

  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { id } = useParams();

  return (
    <PageContainerPartial
      pageTitle={translate('assets.service.edit.title')}
      submitLabel={translate('assets.edit.submit')}
      canSubmit={form.isDirty && !form.hasErrors}
      onSubmit={(e) => form.handleSubmit(e)}
      isLoading={isLoading}
      backNavigate={`${ROUTE_SERVICE}/${id}?${queryString}`}
    >
      <ServiceInfoFormPartial form={form} />

      <DetailFormPartial entityType={ENTITY} form={form} />

      <FinancingFormPartial form={form} />
    </PageContainerPartial>
  );
};

export default EditServicePage;
