import { FC, useCallback, useEffect, useState } from 'react';
import Text from 'src/web/design_system/Text';
import Accordion from 'src/web/design_system/Accordion';
import Switch from 'src/web/design_system/Input/Switch';
import { COOKIE_CONSENT_NAME } from './constant';
import { Cookies } from 'react-cookie-consent';
import Button from 'src/web/components/Button';
import Displayer from 'src/web/design_system/Displayer';
import useTranslate from '../translate/useTranslate';

interface CookieConsentSettingsProps {
  onSubmit?: () => void;
}

const CookieConsentSettings: FC<CookieConsentSettingsProps> = ({ onSubmit }) => {
  const [enableOptionalCookie, setEnableOptionalCookie] = useState(true);
  const [mandatoryAreaOpen, setMandatoryAreaOpen] = useState(false);
  const [optionalAreaOpen, setOptionalAreaOpen] = useState(false);
  const { translate } = useTranslate();

  const handleSubmit = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_NAME, enableOptionalCookie);
    setMandatoryAreaOpen(false);
    setOptionalAreaOpen(false);
    if (onSubmit) {
      onSubmit();
    }
  }, [enableOptionalCookie]);

  useEffect(() => {
    if (Cookies.get(COOKIE_CONSENT_NAME) === 'false') {
      setEnableOptionalCookie(false);
    }
  }, []);

  return (
    <>
      <Text tag="p">{translate('currentUser.cookies.description')}</Text>

      <Accordion
        expended={mandatoryAreaOpen}
        onChange={(state) => setMandatoryAreaOpen(state)}
        title={<Text variant="textLarge">{translate('currentUser.cookies.mandatory.title')}</Text>}
        extraContent={
          <Text tag="span">{translate('currentUser.cookies.mandatory.state.label')}</Text>
        }
      >
        <p>{translate('currentUser.cookies.mandatory.description')}</p>
      </Accordion>

      <Accordion
        expended={optionalAreaOpen}
        onChange={(state) => setOptionalAreaOpen(state)}
        title={<Text variant="textLarge">{translate('currentUser.cookies.optional.title')}</Text>}
        extraContent={
          <Text tag="span">
            {translate(
              enableOptionalCookie
                ? 'currentUser.cookies.optional.state.enabled.label'
                : 'currentUser.cookies.optional.state.disabled.label',
            )}
          </Text>
        }
      >
        <p>{translate('currentUser.cookies.optional.description')}</p>
        <br />
        <Switch value={enableOptionalCookie} onChange={(value) => setEnableOptionalCookie(value)} />
      </Accordion>
      <Displayer topSpacing={3}>
        <div>
          <Button.Custom onClick={() => handleSubmit()} fullWidth={false}>
            {translate('currentUser.cookies.submit')}
          </Button.Custom>
        </div>
      </Displayer>
    </>
  );
};

export default CookieConsentSettings;
