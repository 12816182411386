import { FC } from 'react';
import Header from 'src/web/design_system/Header';
import Icon from 'src/web/components/Icon';
import HeaderAccount from './headerAccount';
import { HEADER_ICON_COLOR, HEADER_ICON_SIZE } from './constants';
import HeaderNotification from './headerNotification';
import * as ROUTE from 'src/web/common/router/constantes';
import { useNavigate } from 'react-router-dom';
import useTranslate from 'src/web/common/translate/useTranslate';

interface HeaderProps {
  onClickMenuButton: () => void;
}

const AppHeader: FC<HeaderProps> = ({ onClickMenuButton }) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  return (
    <Header.Container
      onClickMobileButton={onClickMenuButton}
      endContent={
        <>
          <Header.ButtonIcon
            onClick={() => navigate(ROUTE.ROUTE_TASKS)}
            title={translate('global.nav.tasks.button')}
          >
            <Icon.Task size={HEADER_ICON_SIZE} color={HEADER_ICON_COLOR} />
          </Header.ButtonIcon>

          <HeaderNotification />

          <HeaderAccount />
        </>
      }
    />
  );
};

export default AppHeader;
