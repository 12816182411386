import { isArray, isObject, isString } from 'core/common/utils/predicatesType';
import CacheStorageClient, { CacheStatus } from './cacheStorage.port';
import { type CoreAdapters } from 'core/adapters';

export interface CacheOptions {
  path: string;
  method: string;
  payload?: Record<string, unknown>;
  ttl?: number;
  limitCount?: number;
}

class CacheService {
  private cacheStorageClient: CacheStorageClient;

  private DEFAULT_TTL = 1000 * 60 * 1;

  private CACHE_NAME_SEPARATOR = '__';

  private DEFAULT_LIMIT_COUNT = 10;

  private STORAGE_LIMIT_WEIGHT = 2000;

  private PENDING_WAITING_LOOP = 50;

  constructor(private adapters: CoreAdapters) {
    this.cacheStorageClient = this.adapters.cacheStorageClient;
  }

  /**
   * Clean the cache storage
   */
  reset() {
    return this.cacheStorageClient.reset();
  }

  /**
   * Cache a request
   */
  public async cacheRequest<R>(callback: () => Promise<R>, options: CacheOptions): Promise<R> {
    const cacheName = this.getCacheNameFromOptions(options);

    // create cache item when necessary
    this.defineCacheItem(cacheName, options);

    // change programmatically the cache state
    this.checkCacheValidity(cacheName, options);

    // refresh the cache when it is stale
    // and cleanup globally store (because that have to be done frequently but not every time)
    if (this.getCacheStatus(cacheName) === 'stale') {
      this.cleanupGlobal();

      this.updateCacheStatus(cacheName, 'pending');

      callback().then((response) => {
        this.hydrate(cacheName, response, options);
      });
    }

    // pending cache: wait for the cache is done
    let fulfilled = this.getCacheStatus(cacheName) !== 'done';

    do {
      await new Promise((resolve) => setTimeout(resolve, this.PENDING_WAITING_LOOP));
      fulfilled = this.getCacheStatus(cacheName) !== 'done';
    } while (fulfilled);

    // cache data can be returned
    return this.cacheStorageClient.getState((state) => state.cache[cacheName]?.data) as R;
  }

  /**
   * Invalidate a cache
   */
  public cacheInvalidate(name: string, ref?: Record<string, unknown>) {
    const cacheStorage = this.cacheStorageClient.getState((state) => state.cache);

    const results = Object.keys(cacheStorage)
      .filter((key) => {
        return String(key).startsWith(name);
      })
      .filter((key) => {
        if (ref) {
          return this.extraCompare(cacheStorage[key].payload, ref);
        }
        return true;
      });

    results.forEach((cacheName) => {
      this.updateCacheStatus(cacheName, 'stale');
    });
  }

  /**
   * Update cache item status
   */
  private updateCacheStatus(cacheName: string, status: CacheStatus) {
    this.cacheStorageClient.setState((state) => {
      if (cacheName in state.cache) {
        state.cache[cacheName] = {
          ...state.cache[cacheName],
          state: status,
        };
      }
    });
  }

  private isCacheDefined(cacheName: string) {
    return this.cacheStorageClient.getState((state) => cacheName in state.cache);
  }

  /**
   * Get cache item status
   */
  private getCacheStatus(cacheName: string) {
    return this.cacheStorageClient.getState((state) => state.cache[cacheName]?.state);
  }

  /**
   * Init cache item = create if necessary
   */
  private defineCacheItem(cacheName: string, options: CacheOptions) {
    if (!this.isCacheDefined(cacheName)) {
      this.cleanupByGroupName(options);
    }

    this.cacheStorageClient.setState((state) => {
      if (!(cacheName in state.cache)) {
        state.cache[cacheName] = {
          state: 'stale',
          createdAt: Date.now(),
          data: undefined,
          ttl: this.DEFAULT_TTL,
          payload: {
            method: options.method,
            ...options.payload,
          },
        };
      }
    });
  }

  /**
   * Compare objects
   */
  private extraCompare(extra: Record<string, unknown>, ref: Record<string, unknown>): boolean {
    return Object.keys(ref).every((key) => {
      return key in extra && ref[key] === extra[key];
    });
  }

  /**
   * Add an item into a group
   */
  private hydrate<D>(cacheName: string, data: D, options: CacheOptions) {
    this.cacheStorageClient.setState((state) => {
      state.cache[cacheName] = {
        state: 'done',
        createdAt: Date.now(),
        data,
        ttl: options.ttl ?? this.DEFAULT_TTL,
        payload: {
          method: options.method,
          ...options.payload,
        },
      };
    });
  }

  /**
   * Build a reference key from the option key
   * Could be an object of http request
   * @todo keys to allow two equal objects with shuffled keys to match together
   */
  private getCacheNameFromOptions(options: CacheOptions): string {
    const chunkNames = [options.path];

    if (options.payload) {
      if (isString(options.payload)) {
        chunkNames.push(options.payload);
      }

      if (isObject(options.payload) && !isArray(options.payload)) {
        chunkNames.push(JSON.stringify(this.orderObjectByKeys(options.payload)));
      }
    }

    return chunkNames.join(this.CACHE_NAME_SEPARATOR);
  }

  /**
   * Order object keys
   * to prevent { a:1, b:2 } to be different than { b:2, a:1 }
   */
  private orderObjectByKeys(obj: Record<string, unknown>) {
    const container: Record<string, any> = {};

    return Object.keys(obj)
      .sort()
      .reduce((result, key) => ((result[key] = obj[key]), result), container);
  }

  /**
   * Check the cache validity and change the status if necessary
   */
  private checkCacheValidity(cacheName: string, options: CacheOptions) {
    const createdAt = this.cacheStorageClient.getState(
      (state) => state.cache[cacheName]?.createdAt ?? 0,
    );

    if (Date.now() - createdAt > (options.ttl ?? this.DEFAULT_TTL)) {
      this.updateCacheStatus(cacheName, 'stale');
    }
  }

  /**
   * Cleanup a group of items
   */
  private cleanupByGroupName(options: CacheOptions) {
    const limit = options.limitCount ?? this.DEFAULT_LIMIT_COUNT;
    const cache = this.cacheStorageClient.getState((state) => state.cache);

    // get cache items with the same base name
    // sorted by descending age
    const sameBaseName = Object.keys(cache)
      .filter((key) => key.split(this.CACHE_NAME_SEPARATOR)[0] === options.path)
      .sort((a, b) => cache[b].createdAt - cache[a].createdAt);

    // if too much similar cache items are registed
    // select the half oldest part and delete it
    if (sameBaseName.length > limit) {
      sameBaseName.splice(0, Math.ceil(limit / 2)).forEach((key) => {
        if (key in cache) {
          this.cacheStorageClient.deleteState(key);
        }
      });
    }
  }

  /**
   * Cleanup the global storage
   */
  private cleanupGlobal() {
    this.cleanupFromCreationDate();
    this.cleanupFromSize();
  }

  /**
   * Remove outdated cache items
   */
  private cleanupFromCreationDate() {
    const cache = this.cacheStorageClient.getState((state) => state.cache);

    Object.keys(cache).forEach((key) => {
      if (Date.now() - cache[key].createdAt > cache[key].ttl * 2) {
        this.cacheStorageClient.deleteState(key);
      }
    });
  }

  /**
   * Remove items when the store is too large
   */
  private cleanupFromSize() {
    const cache = this.cacheStorageClient.getState((state) => state.cache);

    // size in kilobyte
    const cacheSize = Math.round(new Blob([JSON.stringify(cache)]).size / 1000);

    if (cacheSize >= this.STORAGE_LIMIT_WEIGHT) {
      const sortedCacheItems = this.cacheStorageClient.getState((state) => {
        return Object.keys(state.cache).sort(
          (a, b) => state.cache[b].createdAt - state.cache[a].createdAt,
        );
      });

      sortedCacheItems
        .splice(0, sortedCacheItems.length / 2)
        .forEach((key) => this.cacheStorageClient.deleteState(key));
    }
  }
}

export default CacheService;
