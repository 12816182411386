import { useState, useEffect } from 'react';

export default function usePasswordValidator(password: string) {
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });

  useEffect(() => {
    setPasswordCriteria({
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[\W_]/.test(password),
      isLongEnough: password.length >= 8,
    });
  }, [password]);

  return passwordCriteria;
}
