import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import CustomAssetCategoryEntity from 'core/entities/customAssetCategory.entity';

/**
 * note: Currently, categories are added by hand from the support team.
 *
 */
class CustomAssetCategoryUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private customAssetCategoryEntity: CustomAssetCategoryEntity,
  ) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * Get custom asset categories
   */
  public async getAllCategories() {
    const categories = this.customAssetCategoryEntity
      .getAll()
      .then((response) => response.data ?? []);
    return categories;
  }

  /**
   * Get one custom asset category
   */
  public async getOneCategoryById(id: string) {
    const res = await this.customAssetCategoryEntity.getOne({
      pathVar: {
        id: id,
      },
    });

    // TODO: verify if it's possible
    if (res.status?.toString().charAt(0) !== '2') throw new Error('Category unavailable');
    return res.data;
  }
}

export default CustomAssetCategoryUseCase;
