import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TaskTopics } from 'core/common/constants';
import { isStringNotEmpty } from 'core/common/utils/predicatesType';
import { FormValidationError } from 'core/services/form/form.port';
import { ExhaustiveValue, SwaggerJsonSchemaProperty } from 'core/swagger';
import { useCore } from 'src/web/common/core';
import {
  formatUIFormValues,
  unformatUIFormValues,
  validateValues,
} from 'src/web/common/form/helpers';
import { UiFormValues } from 'src/web/common/form/types';
import useCreateForm from 'src/web/common/form/useCreateForm';
import { ROUTE_PERSON } from 'src/web/common/router/constantes';

interface UiTaskOwner {
  label: string;
  value: string;
}

interface TaskRowItem {
  value: ExhaustiveValue;
  required: boolean;
  schema: SwaggerJsonSchemaProperty;
  hasErrors: boolean;
  errors: Partial<FormValidationError>;
  handleChange: (value: ExhaustiveValue) => void;
}

type TaskRowItems = Record<string, TaskRowItem>;

interface TaskRow {
  id: string;
  taskId?: string;
  items: TaskRowItems;
  owners: UiTaskOwner[];
  isActive: boolean;
  isDirty: boolean;
}

interface UiTaskType {
  label: string;
  value: string;
}

const useBoarding = (boardingType: TaskTopics) => {
  const {
    boardingUseCase,
    timeService,
    personUsercase,
    setPersonUsecase,
    // notificationConditionsUsecase,
  } = useCore();

  const { id } = useParams();
  const navigate = useNavigate();

  const [currentTaskGroupIri, setCurrentTaskGroupIri] = useState<string>();

  // store dirtiness to prevent to submit default values
  const [isTasksFormDirty, setIsTasksFormDirty] = useState(false);

  // store dirtiness to prevent to submit default values
  const [isPersonFormDirty, setIsPersonFormDirty] = useState(false);

  // store dynamic form rows
  const [taskRows, setTaskRows] = useState<TaskRow[]>([]);
  const [isCurrentTaskLoading, setIsCurrentTaskLoading] = useState(true);

  // store current person
  const [personData, setPersonData] =
    useState<Awaited<ReturnType<typeof personUsercase.getPerson>>>();
  const [isPersonDataLoading, setPersonDataLoading] = useState(true);

  // store task types
  const [taskTypes, setTaskTypes] = useState<UiTaskType[]>([]);
  const [isTaskTypesLoading, setIsTaskTypesLoading] = useState(true);

  // store default owners
  const [defaultOwners, setDefaultOwners] = useState<UiTaskOwner[]>([]);
  const [isdefaultOwnersLoading, setIsdefaultOwnersLoading] = useState(true);

  const [isPersonFormLoading, setIsPersonFormLoading] = useState(true);

  // submitting
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // define current person id from url
  const personId = useMemo(() => {
    if (!id) {
      navigate(ROUTE_PERSON);
    }
    return id ?? '';
  }, [id]);

  // form row template
  const boardingFormBuilder = boardingUseCase.getBoardingForm();

  const initialRowValues: Record<keyof typeof boardingFormBuilder.properties, ExhaustiveValue> = {
    task: undefined,
    owner: undefined,
    deadline: '',
    comment: '',
  };

  // build default fields values
  const defaultInitialValues: UiFormValues<string> = useMemo(
    () => formatUIFormValues(boardingFormBuilder.properties),
    [],
  );

  // validate data
  const validateRow = (values: Record<string, ExhaustiveValue>) => {
    const unformattedValues = unformatUIFormValues(
      {},
      values,
      boardingFormBuilder.required,
      'validation',
    );

    const currentErrors = validateValues({
      formValues: unformattedValues,
      key: undefined,
      properties: boardingFormBuilder.properties,
      initialValues: undefined,
      required: boardingFormBuilder.required,
      validate: boardingFormBuilder.validate,
    });

    const errorsList = Object.keys(currentErrors)
      .filter((errkey) => boardingFormBuilder.required.includes(errkey))
      .map((key) => [key, currentErrors[key]]);

    return Object.fromEntries(errorsList);
  };

  // person builder
  const personFormBuilder = useMemo(() => setPersonUsecase.getEditForm(personId), [personId]);

  // person form
  const personForm = useCreateForm({
    properties: personFormBuilder.properties,
    required: personFormBuilder.required,
    validate: personFormBuilder.validate,
    onFormChange: () => {
      setIsPersonFormDirty(true);
    },
  });

  // return the date limit to perform tasks
  const personLimitAction = useMemo(() => {
    let currentVal: ExhaustiveValue = undefined;
    if (boardingType === TaskTopics.peopleOnBoarding) {
      currentVal = personData?.entryAt;
    }
    if (boardingType === TaskTopics.peopleOffBoarding) {
      currentVal = personForm.values.exitAt;
    }
    return isStringNotEmpty(currentVal) ? timeService.fromBackend(currentVal) : undefined;
  }, [personData, personForm.values, boardingType]);

  // get initial field value
  const getInitialValueFromKey = useCallback(
    (key: string, initialData?: Record<string, ExhaustiveValue>) => {
      if (initialData && key in initialData) {
        return initialData[key];
      }
      if (key in defaultInitialValues) {
        return defaultInitialValues[key];
      }
      return '';
    },
    [defaultInitialValues],
  );

  // global loading
  const isLoading =
    isCurrentTaskLoading ||
    isPersonDataLoading ||
    isTaskTypesLoading ||
    isdefaultOwnersLoading ||
    isPersonFormLoading;

  // get row values
  const getValues = (items: TaskRowItems): Record<string, ExhaustiveValue> => {
    return Object.fromEntries(Object.keys(items).map((key) => [key, items[key].value]));
  };

  // handle change a value of a row
  const handleTaskChange = (rowId: string, key: string, value: ExhaustiveValue) => {
    setIsTasksFormDirty(true);

    setTaskRows((currentRows) => {
      const copyRows = [...currentRows];
      const index = copyRows.findIndex((row) => row.id === rowId);

      if (index >= 0) {
        const nextValues = {
          ...getValues(copyRows[index].items),
          [key]: value,
        };

        const currentErrors = validateRow(nextValues);
        const nextItemErrors = currentErrors[key] ?? {};

        const nextItems = {
          ...copyRows[index].items,
          [key]: {
            ...copyRows[index].items[key],
            value: value,
            hasErrors: !!Object.keys(nextItemErrors).length,
            errors: nextItemErrors,
          },
        };

        copyRows[index] = {
          ...copyRows[index],
          isDirty: true,
          items: nextItems,
        };
      }

      return copyRows;
    });
  };

  // create a task row
  const createTaskRow = useCallback(
    (taskId?: string, initialData?: Record<string, ExhaustiveValue>) => {
      setIsTasksFormDirty(false);

      const data = {
        ...initialRowValues,
        ...initialData,
      };
      const rowId = nanoid();
      const values = Object.fromEntries(
        Object.keys(boardingFormBuilder.properties).map((property) => [
          property,
          getInitialValueFromKey(property, data),
        ]),
      );
      const currentErrors = validateRow(values);

      const items: TaskRowItems = Object.fromEntries(
        Object.keys(boardingFormBuilder.properties).map((property) => {
          const currentItemErrors = currentErrors[property] ?? {};
          const item: TaskRowItem = {
            value: values[property],
            schema: boardingFormBuilder.properties[property],
            required: boardingFormBuilder.required.includes(property),
            hasErrors: !!Object.keys(currentItemErrors).length,
            errors: currentItemErrors,
            handleChange: (value) => handleTaskChange(rowId, property, value),
          };
          return [property, item];
        }),
      );

      const taskRowObj: TaskRow = {
        id: rowId,
        taskId,
        owners: defaultOwners,
        items,
        isActive: true,
        isDirty: false,
      };

      setTaskRows((current) => [...current, taskRowObj]);
    },
    [defaultOwners],
  );

  // Load initial tasks
  const loadCurrentTasks = async (currentPersonId: string) => {
    setIsCurrentTaskLoading(true);

    const personIri = personData?.['@id'];
    const currentAssignmentTaskTypes: string[] = [];

    if (personIri && boardingType === TaskTopics.peopleOffBoarding) {
      const assignmentTaskType = await boardingUseCase.getOffboardingCurrentAssignmentTasks(
        personIri,
      );
      assignmentTaskType.forEach((currentAssignmentTasks) =>
        currentAssignmentTaskTypes.push(currentAssignmentTasks[0]),
      );
    }

    const currentTasksGroupResponse = await boardingUseCase.getPersonBoardingTasks(
      currentPersonId,
      boardingType,
    );

    if (currentTasksGroupResponse) {
      const currentGroupIri = currentTasksGroupResponse['@id'];
      setCurrentTaskGroupIri(currentGroupIri);
      const currentTasks = currentTasksGroupResponse.customFormAnswerItems;

      if (currentTasks && currentTasks.length > 0) {
        currentTasks.forEach((currentTask) => {
          createTaskRow(currentTask.id, {
            customFormAnswer: currentGroupIri,
            task: currentTask.task?.['@id'],
            comment: currentTask.comment ?? '',
            owner: currentTask.owner,
            deadline: currentTask.deadline,
          });
        });
      } else {
        createTaskRow();
      }
    } else if (currentAssignmentTaskTypes.length > 0) {
      currentAssignmentTaskTypes.forEach((currentAssignmentTaskType) => {
        createTaskRow(undefined, {
          task: currentAssignmentTaskType,
        });
      });
    } else {
      createTaskRow();
    }

    setIsCurrentTaskLoading(false);
  };

  // load taskTypes
  const handleLoadTaskTypes = () => {
    setIsTaskTypesLoading(true);

    const boardingTaskTypesHandler = boardingUseCase.getboardingTaskTypes(boardingType);

    boardingTaskTypesHandler
      .then((data) => {
        setTaskTypes(
          data.map((item) => ({
            label: item.task,
            value: item.id,
          })),
        );
      })
      .finally(() => {
        setIsTaskTypesLoading(false);
      });
  };

  // get current person
  const getPerson = async (currentPersonId: string) => {
    setPersonDataLoading(true);

    personUsercase.getPerson(currentPersonId).then((data) => {
      if (data) {
        setPersonData(data);
      } else {
        navigate(ROUTE_PERSON);
      }
      setPersonDataLoading(false);
    });
  };

  // handle search owners
  const handleSearchOwner = async (value: string): Promise<UiTaskOwner[]> => {
    const response = await boardingUseCase.searchOwners(value);
    return response.map((item) => ({
      label: item.email,
      value: item.username ?? '',
    }));
  };

  // handle search owners for a specific row
  const handleSearchRowOwner = (rowId: string, value: string) => {
    handleSearchOwner(value).then((data) => {
      setTaskRows((currentTaskRows) => {
        const copyRows = [...currentTaskRows];
        const index = copyRows.findIndex((row) => row.id === rowId);

        if (index >= 0) {
          copyRows[index] = {
            ...copyRows[index],
            owners: data,
          };
        }

        return copyRows;
      });
    });
  };

  // Submit process
  const handleSubmit = useCallback(async () => {
    setIsSubmitLoading(true);

    const tasksToCreate: TaskRow[] = [];
    const tasksToDelete: TaskRow[] = [];
    const tasksToUpdate: TaskRow[] = [];

    // filter rows
    taskRows.forEach(async (taskRow) => {
      if (isStringNotEmpty(taskRow.items.customFormAnswer.value)) {
        if (!taskRow.isActive) {
          tasksToDelete.push(taskRow);
        } else {
          tasksToUpdate.push(taskRow);
        }
      } else {
        tasksToCreate.push(taskRow);
      }
    });

    // CREATE
    if (tasksToCreate.length > 0) {
      let groupIri = currentTaskGroupIri;

      if (!groupIri) {
        groupIri = await boardingUseCase.createTaskGroup(boardingType, {
          id: personData?.id,
          lastname: personData?.lastname,
          firstname: personData?.firstname,
          jobTitle: personData?.jobTitle,
        });
      }

      tasksToCreate.forEach(async (taskRow) => {
        if (isStringNotEmpty(groupIri)) {
          await boardingUseCase.createTask(groupIri, getValues(taskRow.items));
        }
      });

      // Send notification to gabriel to create on/off boarding
      // await notificationConditionsUsecase.getAddBoardingNotificationConditionsForm(
      //   personId,
      //   personForm.values.exitAt?.toString(),
      //   boardingType,
      // );
    }

    // UPDATE
    if (tasksToUpdate.length > 0) {
      tasksToUpdate.forEach(async (taskRow) => {
        if (taskRow.taskId) {
          await boardingUseCase.updateTask(taskRow.taskId, getValues(taskRow.items));

          // Retrieve formatted body to send to Gabriel to update notification for on/off boarding
          const boardingBody = boardingUseCase.getBoardingFormattedData(
            getValues(taskRow.items),
            personForm.values.exitAt?.toString(),
            personId,
            boardingType,
          );

          // Send notification to gabriel to create on/off boarding
          // await notificationConditionsUsecase.getUpdateBoardingNotificationConditionsForm(
          //   taskRow.taskId,
          //   boardingBody,
          //   boardingType,
          // );
        }
      });
    }

    // DELETE
    if (tasksToDelete.length > 0) {
      tasksToDelete.forEach(async (taskRow) => {
        if (taskRow.taskId) {
          await boardingUseCase.deleteTask(taskRow.taskId);
        }
      });
    }

    // UPDATE USER
    if (boardingType === TaskTopics.peopleOffBoarding) {
      const cleanedData = unformatUIFormValues(
        {},
        personForm.values,
        personFormBuilder.required,
        'submit',
      );
      personFormBuilder.submit(cleanedData);
    }

    setIsSubmitLoading(false);
    navigate(`${ROUTE_PERSON}/${personId}`);
  }, [personForm.values, taskRows, currentTaskGroupIri, personId, personData]);

  const handleDeleteRow = (rowId: string) => {
    setIsTasksFormDirty(true);

    setTaskRows((currentRows) => {
      const copyRows = [...currentRows];
      const index = copyRows.findIndex((row) => row.id === rowId);

      copyRows[index] = {
        ...copyRows[index],
        isActive: false,
      };

      return copyRows;
    });
  };

  // load current person
  useEffect(() => {
    if (personId && isPersonDataLoading === true) {
      getPerson(personId);
    }
  }, [personId, isPersonDataLoading]);

  // load current person tasks
  useEffect(() => {
    if (
      personId &&
      isdefaultOwnersLoading === false &&
      isCurrentTaskLoading === true &&
      personData
    ) {
      loadCurrentTasks(personId);
    }
  }, [personId, isdefaultOwnersLoading, isCurrentTaskLoading, personData]);

  // load tasks type
  useEffect(() => {
    handleLoadTaskTypes();
  }, []);

  // load default owners
  useEffect(() => {
    setIsdefaultOwnersLoading(true);

    handleSearchOwner('').then((data) => {
      setDefaultOwners(data);
      setIsdefaultOwnersLoading(false);
    });
  }, []);

  // Update form values from current data
  useEffect(() => {
    personFormBuilder.getInitialValues().then((values) => {
      for (const key in values) {
        personForm.imperativeChangeField(key, values[key]);
      }
      setIsPersonFormLoading(false);
    });
  }, []);

  return {
    isDirty: isTasksFormDirty || isPersonFormDirty,
    personForm,
    isLoading,
    personData,
    personLimitAction,
    taskRows: taskRows.filter((taskRow) => taskRow.isActive),
    taskTypes,
    handleAddTask: () => createTaskRow(),
    handleSearchRowOwner,
    handleSubmit,
    handleDeleteRow,
    isSubmitLoading,
  };
};

export default useBoarding;
