import { createCss } from 'src/web/common/styles/createCss';
import { LogoVesrion } from '.';

export const LogoStyles = createCss(`
  width: auto;
  height: auto;
  object-ratio: auto; 
  object-fit: scale-down; 
  `);
export const iconStyles = createCss(`
  width: auto;
  height: auto;
  max-width: 40px;
  max-height: 40px;
  margin-left: 11px;
  margin-right: 11px;
  object-ratio: auto; 
  object-fit: scale-down; 
`);

export const TextStyles = (version: LogoVesrion) =>
  createCss(`
  max-height: 80px;
  transform: translateX( ${version === 'default' ? '0px' : '-70px'});
  opacity: ${version === 'default' ? 1 : 0};
  transition: .3s ease;
`);
