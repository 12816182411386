import { FC, FormEvent, PropsWithChildren } from 'react';
import Grid from 'src/web/design_system/Grid';
import Text from 'src/web/design_system/Text';
import FormContainer from 'src/web/design_system/FormContainer';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import Button from 'src/web/components/Button';
import useTranslate from 'src/web/common/translate/useTranslate';

interface AddPageLayoutProps {
  pageTitle: string;
  canSubmit: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
  isLoading?: boolean;
  backNavigate?: string;
}

const PageContainerPartial: FC<PropsWithChildren<AddPageLayoutProps>> = ({
  pageTitle,
  children,
  canSubmit,
  onSubmit,
  submitLabel,
  isLoading = false,
  backNavigate,
}) => {
  const { translate } = useTranslate();

  const url = window.location.href;
  const isEdit = url.includes('edit');

  return (
    <Grid.Container justify="center">
      <Grid.Item
        size={{
          xs: 12,
          sm: 12,
          md: 9,
          lg: 6,
        }}
      >
        <Displayer bottomSpacing={5} alignItems={'center'}>
          <Text variant="pageTitle">
            <Skeleton shape="text" isLoading={isLoading}>
              {pageTitle}
            </Skeleton>
          </Text>
        </Displayer>
        <FormContainer onSubmit={onSubmit}>
          {backNavigate ? (
            <Skeleton shape="round" height="40px" width="85px" isLoading={isLoading}>
              <Displayer alignItems={'flex-start'}>
                <Button.BackNavigate
                  to={backNavigate}
                  label={
                    isEdit
                      ? translate('global.navigateBack.details.label')
                      : translate('global.navigateBack.inventory.label')
                  }
                />
              </Displayer>
            </Skeleton>
          ) : (
            <></>
          )}
          {isLoading ? (
            Array(5)
              .fill(null)
              .map((_, i) => (
                <Displayer bottomSpacing={3} key={i}>
                  <Skeleton width="100%" height="200px" />
                </Displayer>
              ))
          ) : (
            <Displayer innerSpacing={3}>{children}</Displayer>
          )}
          <Displayer topSpacing={5} alignItems={'center'}>
            <Button.Custom
              fullWidth={false}
              type="submit"
              isLoading={isLoading}
              disabled={!canSubmit}
            >
              {submitLabel}
            </Button.Custom>
          </Displayer>
        </FormContainer>
      </Grid.Item>
    </Grid.Container>
  );
};

export default PageContainerPartial;
