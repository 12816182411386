import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import { ExhaustiveValue, SwaggerRequestBodyJsonContent } from 'core/swagger';
import FormService, { FormProperties } from 'core/services/form/form.service';
import PersonEntity from 'core/entities/person.entity';
import { isObject } from 'core/common/utils/predicatesType';

class SetPersonUseCase implements Usecase {
  private currentOne: Record<string, unknown> = {};

  constructor(
    private store: AppStore,
    private formService: FormService,
    private personEntity: PersonEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  getAddForm() {
    const { properties, required, validate } = this.formService.createForm(
      this.personEntity.getPathAll(),
      'post',
    );

    const initialValues: Record<keyof FormProperties, ExhaustiveValue> = {
      // because the useless asset_legalEntityId is not nullable on the backend
      // @todo fix the backend typing then remove the next line
      legalEntityId: this.store.getState((state) => state.user.current?.legalEntity?.id),
    };

    const handleSubmit = (data: object) => {
      const formattedData = this.formService.unformatData(data);

      return this.personEntity
        .post({
          body: formattedData,
        })
        .then((response) => response.data);
    };

    return {
      properties,
      getInitialValues: () => initialValues,
      required,
      validate,
      submit: handleSubmit,
    };
  }

  getEditForm(personId: string) {
    const pathVar = { id: personId };
    const { properties, required, validate } = this.formService.createForm(
      this.personEntity.getPathOne(),
      'put',
    );

    this.currentOne = {};

    const getInitialValues = async (): Promise<Record<keyof FormProperties, ExhaustiveValue>> => {
      let initialValues = {
        // because the useless asset_legalEntityId is not nullable on the backend
        // @todo fix the backend typing then remove the next line
        legalEntityId: this.store.getState((state) => state.user.current?.legalEntity?.id),
        attachmentSite: '',
      };

      const currentAssetData = await this.personEntity
        .getOne({
          pathVar,
        })
        .then((response) => response?.data);

      if (isObject(currentAssetData)) {
        this.currentOne = this.formService.formatData(currentAssetData);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in this.currentOne) {
            initialValues = {
              ...initialValues,
              [propkey]: this.currentOne[propkey],
            };
          }
        });
        initialValues = {
          ...initialValues,
          attachmentSite: currentAssetData.attachmentSite?.['@id'] ?? '',
        };
      }

      return initialValues;
    };

    const handleSubmit = (data: object) => {
      let copyCurrentOne = this.formService.unformatData({ ...this.currentOne });

      if (isObject(copyCurrentOne.attachmentSite)) {
        if ('@id' in copyCurrentOne.attachmentSite) {
          copyCurrentOne = {
            ...copyCurrentOne,
            attachmentSite: copyCurrentOne.attachmentSite['@id'],
          };
        } else {
          delete copyCurrentOne.attachmentSite;
        }
      }

      const formattedData: SwaggerRequestBodyJsonContent<
        ReturnType<typeof this.personEntity.getPathOne>,
        'put'
      > = this.formService.unformatData({
        ...this.formService.formatData(copyCurrentOne),
        ...data,
      });

      return this.personEntity
        .put({
          body: formattedData,
          pathVar,
        })
        .then((response) => response.data);
    };

    return {
      properties,
      getInitialValues: () => getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }
}

export default SetPersonUseCase;
