import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';
import { SwaggerRequestBodyJsonContent } from 'core/swagger';

export type AssetAssignmentPostBody = SwaggerRequestBodyJsonContent<
  '/api/private/asset-assignments',
  'post'
>;

class AssetAssignmentEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathOne() {
    return exactString('/api/private/asset-assignments/{id}');
  }

  getPathAll() {
    return exactString('/api/private/asset-assignments');
  }

  getOne = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathOne(), 'get', options),
      {
        path: this.getPathOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathAll(), 'get', options),
      {
        path: this.getPathAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  post = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'post'>) => {
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathAll(), 'post', options);
  };

  put = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'put', options);
  };

  delete = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'delete'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'delete', options);
  };
}

export default AssetAssignmentEntity;
