import { FC, type PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LANGUAGE } from 'src/web/common/config/constants';
import LocalesDictionary from 'src/web/resources/locales';
import { LocalesEnum } from 'core/common/constants';
import { useCore } from '../core';

const TranslateProvider: FC<PropsWithChildren> = ({ children }) => {
  const core = useCore();
  const userLanguage = core.store.getState(
    (state) => state.user.current?.preferredLanguage || state.user.guest?.preferredLanguage,
  );

  const getLocale = (value?: string): LocalesEnum => {
    return value ? (value as LocalesEnum) : DEFAULT_LANGUAGE;
  };

  const [dataset, setDataset] = useState(LocalesDictionary[getLocale(userLanguage)]);

  useEffect(() => {
    setDataset(LocalesDictionary[getLocale(userLanguage)]);
  }, [userLanguage]);

  return (
    <IntlProvider
      messages={dataset}
      defaultLocale={DEFAULT_LANGUAGE}
      locale={getLocale(userLanguage)}
      onError={(error) => console.warn(error)}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslateProvider;
