import { FC } from 'react';
import EmptyValue from 'src/web/components/EmptyValue';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import { useTheme } from 'src/web/design_system/Theme';
interface TextPairProps {
  label: string;
  value?: string | number | Date | null;
}

const TextPair: FC<TextPairProps> = ({ label, value }) => {
  const theme = useTheme();
  return (
    <Displayer layout={'row'} innerSpacing={3}>
      <Text color={theme.theme.colors.secondaryTextColor} variant="textNormal">
        {label}
      </Text>
      <Text color={theme.theme.colors.primaryTextColor} variant="textNormal">
        {(value as string) ?? <EmptyValue />}
      </Text>
    </Displayer>
  );
};

export default TextPair;
