import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Form from 'src/web/components/FormInput';
import Card from 'src/web/components/Card';
import Button from 'src/web/components/Button';
import { SivinAppData } from 'core/usecases/sivin.usecase';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';
import Grid from 'src/web/design_system/Grid';

interface VehicleInfoFormPartialProps {
  form: UseSetAssetFormReturn;
  handleSivin?: (data: SivinAppData) => void;
}

const VehicleInfoFormPartial: FC<VehicleInfoFormPartialProps> = ({ form, handleSivin }) => {
  const { timeService } = useCore();
  const shouldShowSivinbutton = form.isDirty && !form.items.asset_identification.errors;
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.TextAdapted
          item={form.items.asset_identification}
          label={translate('vehicles.asset.identification.label')}
        />

        {handleSivin && (
          <Button.Sivin
            assetIdentification={
              shouldShowSivinbutton ? String(form.items.asset_identification.formValue) : undefined
            }
            onDataReceived={(data) => handleSivin(data)}
          />
        )}

        <Form.DateAdapted
          item={form.items.intoCirculationDate}
          label={translate('vehicles.intoCirculationDate.label')}
          maxDate={timeService.now()}
        />

        <Grid.Container>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.asset_manufacturer}
              label={translate('vehicles.asset.manufacturer.label')}
            />
          </Grid.Item>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.asset_model}
              label={translate('assets.asset.model.label')}
            />
          </Grid.Item>
        </Grid.Container>

        <Grid.Container>
          <Grid.Item>
            <Form.NumberAdapted
              item={form.items.weightLimit}
              label={translate('vehicles.weightLimit.label')}
              endExtra="kg"
              type="integr"
            />
          </Grid.Item>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.category}
              label={translate('vehicles.category.label')}
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item>
            <Form.TextAdapted
              item={form.items.fuelType}
              label={translate('vehicles.fuelType.label')}
            />
          </Grid.Item>
          <Grid.Item>
            <Form.NumberAdapted
              item={form.items.horsePower}
              label={translate('vehicles.horsePower.label')}
              type="integer"
            />
            {/* <TextFieldDefault label={translate('vehicles.horsePower.label')} type="number" /> */}
          </Grid.Item>
        </Grid.Container>

        <Grid.Container>
          <Grid.Item>
            <Form.NumberAdapted
              item={form.items.co2Emission}
              label={translate('vehicles.co2Emission.label')}
              endExtra="g"
              type="integer"
            />
          </Grid.Item>
          <Grid.Item>
            <Form.NumberAdapted
              item={form.items.initialMileage}
              label={translate('vehicles.initialMileage.label')}
              type="integer"
            />
          </Grid.Item>
        </Grid.Container>

        <Grid.Container>
          <Grid.Item>
            <Form.DateAdapted
              item={form.items.lastTechnicalCheckDate}
              label={translate('vehicles.lastTechnicalCheckDate.label')}
              maxDate={timeService.now()}
            />
          </Grid.Item>
          <Grid.Item>
            <Form.DateAdapted
              item={form.items.lastTechnicalServiceDate}
              label={translate('vehicles.lastTechnicalServiceDate.label')}
              maxDate={timeService.now()}
            />
          </Grid.Item>
        </Grid.Container>
      </Displayer>
    </Card.Basic>
  );
};

export default VehicleInfoFormPartial;
