import { useEffect, useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

interface UsePostMileageVehicle {
  reload: () => void;
  lastMileage: number;
  assetIri?: string;
}

export const usePostMileageVehicle = ({ reload, lastMileage, assetIri }: UsePostMileageVehicle) => {
  const [isLoading, setIsLoading] = useState(true);

  const { setVehicleUseCase } = useCore();

  const { properties, required, validate, submit, initialValues } = useMemo(() => {
    return setVehicleUseCase.postNewMileageVehicle(assetIri ?? '', lastMileage);
  }, [assetIri]);

  const form = useCreateForm({
    properties,
    required,
    validate,
    submit: async (data) => {
      setIsLoading(true);
      const response = await submit(data);
      if (response) {
        reload();
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (initialValues) {
      Promise.resolve(initialValues).then((values) => {
        for (const key in values) {
          form.imperativeChangeField(key, values[key as keyof typeof values]);
        }

        setIsLoading(false);
      });
    }
  }, [initialValues]);

  return {
    form,
    isLoading,
  };
};
