import { FC } from 'react';
import GuiInputDate, { type DatePickerProps } from 'src/web/design_system/Input/DatePicker';

export interface InputDateProps extends DatePickerProps {
  className?: string;
}

const InputDate: FC<InputDateProps> = ({ className, ...props }) => {
  const { fullWidth, ...restProps } = props;

  return (
    <div className={className}>
      <GuiInputDate {...restProps} fullWidth={fullWidth ?? true} />
    </div>
  );
};

export default InputDate;
