import { FC, useEffect, useState } from 'react';
import AssetAssignment from 'src/web/components/AssetAssignment';
import AssignPersonToAsset from 'src/web/components/AssignPersonToAsset';
import Card from 'src/web/components/Card';
import Icon from 'src/web/components/Icon';
import Grid from 'src/web/design_system/Grid';
import Modal from 'src/web/components/Modal';
import Text from 'src/web/design_system/Text';
import usePersonAssignment from '../hooks/usePersonAssignment';
import Skeleton from 'src/web/design_system/Skeleton';
import useTranslate from 'src/web/common/translate/useTranslate';
import { useCore } from 'src/web/common/core';
import Displayer from 'src/web/design_system/Displayer';
import { RolesEnum } from 'src/core/common/constants';
import useGrantRole from 'src/web/common/grant/useGrantRole';
import Button from 'src/web/components/Button';
import { assignmentListStyle } from './style';

interface PersonAssignmentPartialProps {
  iri: string;
}

const PersonAssignmentPartial: FC<PersonAssignmentPartialProps> = ({ iri }) => {
  const { translate } = useTranslate();
  const { timeService } = useCore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterAssignments, setFilterAssignments] = useState<{
    isFutur: boolean;
    isPast: boolean;
    isCurrent: boolean;
    isAll: boolean;
    showMore: boolean;
  }>({
    isFutur: false,
    isPast: false,
    isCurrent: true,
    isAll: false,
    showMore: false,
  });

  const handleToggle = (state: 'isAll' | 'isCurrent' | 'isFutur' | 'isPast') => {
    switch (state) {
      case 'isAll':
        setFilterAssignments({
          ...filterAssignments,
          isAll: true,
          isFutur: false,
          isPast: false,
          isCurrent: false,
        });
        break;
      case 'isCurrent':
        setFilterAssignments({
          ...filterAssignments,
          isAll: false,
          isFutur: false,
          isPast: false,
          isCurrent: true,
        });
        break;
      case 'isFutur':
        setFilterAssignments({
          ...filterAssignments,
          isAll: false,
          isFutur: true,
          isPast: false,
          isCurrent: false,
        });
        break;
      case 'isPast':
        setFilterAssignments({
          ...filterAssignments,
          isAll: false,
          isFutur: false,
          isPast: true,
          isCurrent: false,
        });
        break;
    }
  };

  const { data, loadData, isLoading } = usePersonAssignment(
    iri,
    filterAssignments.isPast,
    filterAssignments.isFutur,
    filterAssignments.isCurrent,
    filterAssignments.isAll,
    filterAssignments.showMore,
  );

  const addButton = (
    <Button.Custom
      onClick={() => setIsModalOpen(true)}
      fullWidth={false}
      startIcon={<Icon.Add size="small" />}
    >
      {translate('assets.assignment.attribute')}
    </Button.Custom>
  );

  useEffect(() => {
    loadData();
  }, [filterAssignments]);

  const hasNotAnyAssetAcces =
    !useGrantRole(RolesEnum.ROLE_ADMIN_LICENCE) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_SERVICE) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_CARD) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_VEHICLE) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE_LINE) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_COMPUTER) &&
    !useGrantRole(RolesEnum.ROLE_ADMIN_CUSTOM_ASSET);

  return (
    <>
      <Card.Container>
        <Card.Toolbar secondaryContent={hasNotAnyAssetAcces ? <></> : addButton}>
          <Text variant="sectionTitle">{translate('persons.assignment.title')}</Text>
        </Card.Toolbar>
        <Card.Content>
          <Displayer layout={'row'} innerSpacing={2} bottomSpacing={2}>
            <Button.Custom
              fullWidth={false}
              onClick={() => handleToggle('isCurrent')}
              endIcon={filterAssignments.isCurrent ? <Icon.RadioCheck /> : <Icon.RadioUncheck />}
              variant={'text'}
            >
              {translate('assignment.state.current.label')}
            </Button.Custom>
            <Button.Custom
              fullWidth={false}
              onClick={() => handleToggle('isFutur')}
              endIcon={filterAssignments.isFutur ? <Icon.RadioCheck /> : <Icon.RadioUncheck />}
              variant={'text'}
            >
              {translate('assignment.state.futur.label')}
            </Button.Custom>
            <Button.Custom
              fullWidth={false}
              onClick={() => handleToggle('isPast')}
              endIcon={filterAssignments.isPast ? <Icon.RadioCheck /> : <Icon.RadioUncheck />}
              variant={'text'}
            >
              {translate('assignment.state.past.label')}
            </Button.Custom>
            <Button.Custom
              fullWidth={false}
              onClick={() => handleToggle('isAll')}
              endIcon={filterAssignments.isAll ? <Icon.RadioCheck /> : <Icon.RadioUncheck />}
              variant={'text'}
            >
              {translate('assignment.state.all.label')}
            </Button.Custom>
          </Displayer>
          <Skeleton isLoading={isLoading} width="100%" height="300px">
            {data.length === 0 && (
              <Grid.Item>{translate('persons.assignment.noData.message')}</Grid.Item>
            )}
            <Displayer innerSpacing={2}>
              <div className={assignmentListStyle}>
                {data?.map((assignment) => (
                  <AssetAssignment
                    key={assignment['@id']}
                    isAssigned={
                      assignment.startAt < timeService.toBackend(timeService.now()) &&
                      (!assignment.endAt ||
                        (!!assignment.endAt &&
                          assignment.endAt > timeService.toBackend(timeService.now())))
                    }
                    assignment={{
                      iri: assignment['@id'],
                      startAt: timeService.fromBackend(assignment.startAt),
                      endAt: assignment.endAt
                        ? timeService.fromBackend(assignment.endAt)
                        : undefined,
                      assetId: assignment.asset.id,
                      assetIdentification: assignment.asset.identification,
                      assetModel: assignment.asset.model ?? '',
                      assetType: assignment.asset.assetType,
                      assetCategory: assignment.asset.category ?? '',
                      service_Name: assignment.asset.service.name,
                      service_Provider: assignment.asset.service.provider,
                      telephoneLine_LineNumber: assignment.asset.telephoneLine.lineNumber,
                      assetManufacturer: assignment.asset.manufacturer,
                    }}
                    onChange={() => loadData()}
                  />
                ))}
              </div>
            </Displayer>
          </Skeleton>
          <Displayer alignItems={'center'} topSpacing={2}>
            {filterAssignments.showMore ? (
              <></>
            ) : data.length > 5 ? (
              <>
                <Button.Custom
                  isLoading={isLoading}
                  onClick={() => setFilterAssignments({ ...filterAssignments, showMore: true })}
                  fullWidth={false}
                  variant="text"
                  endIcon={<Icon.DropDown />}
                >
                  {translate('assignment.viewMore.button')}
                </Button.Custom>
              </>
            ) : (
              <></>
            )}
          </Displayer>
        </Card.Content>
      </Card.Container>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={translate('persons.assignment.add.title')}
      >
        <AssignPersonToAsset
          personIri={iri}
          onSubmit={() => {
            setIsModalOpen(false);
            loadData();
          }}
        />
      </Modal>
    </>
  );
};

export default PersonAssignmentPartial;
