import { useMemo } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import EntityDetailHeader, { EntityDetailHeaderProps } from 'src/web/components/EntityDetailHeader';

interface TitleAssetSectionProps {
  isLoading?: boolean;
  headerTextMain?: string | null;
  headerTextSecond?: string | null;
  conditionState?: 'normal' | 'maintenance' | 'locked' | 'broken' | 'stolen' | 'lost';
  statusState?: 'active' | 'disabled' | 'pending';
  site?: string;
}

const TitleAssetSection: React.FC<TitleAssetSectionProps> = ({
  isLoading,
  headerTextMain,
  headerTextSecond,
  conditionState,
  statusState,
  site,
}) => {
  const { translate } = useTranslate();
  const badges = useMemo((): EntityDetailHeaderProps['states'] => {
    const badgesList: EntityDetailHeaderProps['states'] = [];

    if (statusState) {
      badgesList.push({
        label: translate(`assets.asset.status.option.${statusState}.label`),
        value: statusState,
      });
    }

    if (conditionState) {
      badgesList.push({
        label: translate(`assets.assetCondition.option.${conditionState}.label`),
        value: conditionState,
      });
    }

    return badgesList;
  }, [conditionState, statusState]);

  return (
    <EntityDetailHeader
      title={headerTextMain ?? ''}
      subtitle={headerTextSecond}
      isLoading={isLoading}
      states={badges}
      site={site}
    />
  );
};

export default TitleAssetSection;
