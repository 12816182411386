import { FC, PropsWithChildren } from 'react';
import { CardMedia as MuiCardImage } from '@mui/material';

interface CardImageProps {
  className?: string;
  src: string;
  title?: string;
}

const CardImage: FC<PropsWithChildren<CardImageProps>> = ({ className, src, title }) => {
  return <MuiCardImage component="img" className={className} image={src} title={title} />;
};

export default CardImage;
