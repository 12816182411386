import { FormValidationErrors } from 'core/services/form/form.port';
import { UiFormValues } from './types';

export const formatUIFormValues = <Keys extends string>(properties: Record<Keys, any>) => {
  const formatValues = Object.keys(properties).map((key) => {
    const item = properties[key as Keys];
    let value = null;

    if ('default' in item) {
      value = item.default;
    } else if (['string'].includes(item.type)) {
      value = '';
    } else if (['number', 'float'].includes(item.type)) {
      value = '';
    } else if (['array'].includes(item.type)) {
      value = [];
    }

    return [key, value];
  });

  return Object.fromEntries(formatValues);
};

export const formatUIFormNullValues = <Keys extends string>(
  properties: Record<Keys, any>,
  values: UiFormValues<Keys>,
  required: Keys[],
): UiFormValues<Keys> => {
  const copy = { ...values };
  Object.keys(properties).map((key) => {
    const item = properties[key as Keys];
    const currentValue = copy[key as Keys];
    if (
      !required.includes(key as Keys) &&
      item.nullable &&
      (currentValue === undefined || currentValue === null)
    ) {
      copy[key as Keys] = null;
    }
  });

  return copy;
};

// the UI needs default values but these values should not be validated or sent to the backend
export const unformatUIFormValues = <Keys extends string>(
  initialValues: Partial<UiFormValues<Keys>> | undefined,
  values: UiFormValues<Keys>,
  required: Keys[],
  mode: 'validation' | 'submit',
): UiFormValues<Keys> => {
  const copy = { ...values };

  Object.keys(copy).forEach((key) => {
    const value = copy[key as Keys];

    // prevent to remove initial values
    // because initial values can be different than the database data for UI reason (controlled form can't be initially undefined)
    // So the purpose of the following is only to remove such custom values to avoid to validate them
    if (!initialValues || !(key in initialValues)) {
      if (
        (!required.includes(key as Keys) && typeof value === 'string' && value.length === 0) ||
        value === undefined ||
        value === null
      ) {
        if (mode === 'submit') {
          copy[key as Keys] = null;
        } else if (mode === 'validation') {
          delete copy[key as Keys];
        }
      }
    }
  });

  return copy;
};

export const validateValues = <Keys extends string>(props: {
  formValues: UiFormValues<Keys>;
  key?: Keys;
  properties: Record<Keys, any>;
  initialValues?: Partial<UiFormValues<string>>;
  required: Keys[];
  validate: (data: UiFormValues<string>) => FormValidationErrors<string>;
}) => {
  const values = unformatUIFormValues(
    props.initialValues,
    props.formValues,
    props.required,
    'validation',
  );
  const currentErrors = props.validate(values);

  return currentErrors;
};
