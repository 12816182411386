import { useMemo, useState } from 'react';
import { isString } from 'core/common/utils/predicatesType';
import { LoginProps } from 'core/usecases/auth.usecase';
import { useCore } from 'src/web/common/core';

const useLoginForm = () => {
  const { authUsecase } = useCore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<LoginProps>({
    username: '',
    password: '',
  });

  const formItems = useMemo(
    () => ({
      username: {
        name: 'username',
        type: 'string',
        required: true,
        errors: undefined,
        formValue: credentials?.username,
        handleChange: (value: unknown) => {
          return new Promise<void>((resolve) => {
            if (isString(value)) {
              setCredentials((current: LoginProps) => ({ ...current, username: value }));
            }
            resolve();
          });
        },

        handleValidate: () => {},
      },
      password: {
        name: 'password',
        type: 'password',
        required: true,
        errors: undefined,
        formValue: credentials?.password,
        handleChange: (value: unknown) => {
          return new Promise<void>((resolve) => {
            if (isString(value)) {
              setCredentials((current: LoginProps) => ({ ...current, password: value }));
            }
            resolve();
          });
        },

        handleValidate: () => {},
      },
    }),
    [credentials],
  );

  const handleFormSubmit = () => {
    setIsLoading(true);
    setError(false);
    authUsecase.login(credentials).then((success) => {
      setIsLoading(false);
      if (!success) {
        setError(true);
      }
    });
  };

  return {
    formItems,
    handleFormSubmit,
    isLoading,
    error,
  };
};

export default useLoginForm;
