import { FC, useEffect, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';
import Button from 'src/web/components/Button';
import Card from 'src/web/components/Card';
import Icon from 'src/web/components/Icon';
import Alert from 'src/web/design_system/Alert';
import Grid from 'src/web/design_system/Grid';
import Progress from 'src/web/design_system/Progress';
import Text from 'src/web/design_system/Text';

const ALERT_DISPLAY_TIME = 3000;

const PasswordPartial: FC = () => {
  const { currentUserUsecase } = useCore();
  const { translate } = useTranslate();
  const [requestStatus, setRequestStatus] = useState<'initial' | 'loading' | 'success' | 'error'>(
    'initial',
  );

  const handleRequestUpdate = async () => {
    setRequestStatus('loading');

    const response = await currentUserUsecase.requestPasswordUpdate();

    if (response) {
      setRequestStatus('success');
    } else {
      setRequestStatus('error');
    }
  };

  // hide message after a time
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (requestStatus === 'error' || requestStatus === 'success') {
      timer = setTimeout(() => {
        setRequestStatus('initial');
      }, ALERT_DISPLAY_TIME);
    }

    return () => clearTimeout(timer);
  }, [requestStatus]);

  return (
    <Card.Basic title={translate('currentUser.password.edit.title')}>
      <Text tag="p">{translate('currentUser.password.edit.label')}</Text>
      <Grid.Container>
        <Grid.Item size="auto">
          <Button.Custom
            onClick={() => handleRequestUpdate()}
            fullWidth={false}
            disabled={requestStatus === 'loading' || requestStatus === 'success'}
          >
            {translate('currentUser.password.edit.submit')}
          </Button.Custom>
        </Grid.Item>
        <Grid.Item size="flex">
          {requestStatus === 'loading' && <Progress.Loading />}
          {requestStatus === 'success' && (
            <Alert icon={<Icon.Done />} mode="success">
              {translate('currentUser.password.edit.requestSent.label')}
            </Alert>
          )}
          {requestStatus === 'error' && (
            <Alert icon={<Icon.Fail />} mode="error">
              {translate('global.errors.somethingHappened')}
            </Alert>
          )}
        </Grid.Item>
      </Grid.Container>
    </Card.Basic>
  );
};

export default PasswordPartial;
