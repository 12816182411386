import { useEffect, useState } from 'react';

interface AnimatedCounterProps {
  targetNumber?: number;
  duration: number;
  growLevel?: number;
}

const CounterAnimation: React.FC<AnimatedCounterProps> = ({
  targetNumber = 0,
  duration,
  growLevel = 100,
}) => {
  const [currentNumber, setCurrentNumber] = useState<number>(0);

  useEffect(() => {
    let startTimestamp: number | null = null;

    const step = (timestamp: number) => {
      if (startTimestamp === null) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      if (progress >= 0.99) {
        setCurrentNumber(targetNumber);
      } else {
        const nextNumber = Math.floor((progress * targetNumber) / growLevel) * growLevel;
        setCurrentNumber(nextNumber);
      }

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }, [targetNumber, duration]);

  return <>{currentNumber}</>;
};

export default CounterAnimation;
