import { FC, ReactNode, useCallback, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { nanoid } from 'nanoid';
import { GuiSize } from 'src/web/design_system/Theme/config';

type SelectValuePrimitive = string | undefined;
type SelectValue = SelectValuePrimitive | SelectValuePrimitive[];

interface SelectItem {
  id: string;
  value: SelectValuePrimitive;
  label: ReactNode;
  disabled?: boolean;
}

interface SelectProps {
  options: SelectValuePrimitive[];
  value?: SelectValue;
  label?: string;
  className?: string;
  message?: string;
  startExtra?: ReactNode;
  size?: Exclude<GuiSize, 'large'>;
  isMultiple?: boolean;
  isError?: boolean;
  isFullWidth?: boolean;
  isRequired?: boolean;
  renderValue?: (value: SelectValue) => ReactNode;
  renderOptionItem?: (
    value: Omit<SelectValuePrimitive, 'undefined'> & string,
  ) => Omit<SelectItem, 'value' | 'id'>;
  renderOptionEmptyValue?: () => ReactNode;
  onChange?: (value: SelectValue) => void;
  labelWhenNoOptions?: string;
  disabled?: boolean;
}

const Select: FC<SelectProps> = ({
  value,
  options,
  label,
  className,
  message,
  startExtra,
  isRequired,
  isError,
  isFullWidth,
  isMultiple,
  size,
  renderValue,
  renderOptionItem,
  renderOptionEmptyValue,
  onChange,
  labelWhenNoOptions,
}) => {
  const labelId = useMemo(() => nanoid(), []);
  const noOptions = options.length === 0;
  // prevent undefined value to change into a defined value
  // since that cause an React error about controlled component.
  // Also check is the given value fit the requirement of multiple mode
  const safeValue = useMemo(() => {
    if (!Array.isArray(value) && isMultiple) {
      return value ? [value] : [];
    }
    return value ?? '';
  }, [value, isMultiple]);

  // render value
  const handleRenderValue = (val: SelectValue): ReactNode => {
    if (renderValue) {
      return renderValue(val);
    }
    if (Array.isArray(val)) {
      return val
        .map((v) => {
          if (v && renderOptionItem) {
            return renderOptionItem(v).label;
          } else if (!v && renderOptionEmptyValue) {
            return renderOptionEmptyValue();
          }
          return v;
        })
        .join(', ');
    }
    if (val && renderOptionItem) {
      return renderOptionItem(val).label;
    } else if (!val && renderOptionEmptyValue) {
      return renderOptionEmptyValue();
    }
    return <>{val}</>;
  };

  // render input used to build the select
  const inputRendered = useMemo(
    () => <OutlinedInput label={label} value={value} />,
    [label, value],
  );

  // render something before the input
  const startExtraRendered = useMemo(
    () => startExtra && <InputAdornment position="start">{startExtra}</InputAdornment>,
    [startExtra],
  );

  // transform some props into a single object that describe each option
  const optionsBuilded = useMemo(() => {
    return options.map((val) => {
      const emptyObjLabel = renderOptionEmptyValue ? renderOptionEmptyValue() : <></>;
      const optItem =
        val && renderOptionItem
          ? renderOptionItem(val)
          : {
              label: val,
              disabled: false,
            };

      return {
        id: val ? val : nanoid(),
        value: val,
        label: val ? optItem.label : emptyObjLabel,
        disabled: optItem.disabled,
      };
    });
  }, [options]);

  // check if an option is selected / checked
  const handleIsOptionSelected = useCallback(
    (val: SelectValuePrimitive) => {
      if (Array.isArray(value)) {
        return value.includes(val);
      }
      return value === val;
    },
    [value],
  );

  return (
    <FormControl
      fullWidth={isFullWidth}
      required={isRequired}
      error={isError}
      className={className}
      size={size}
    >
      {label && (
        <InputLabel id={labelId}>
          {noOptions && labelWhenNoOptions ? labelWhenNoOptions : label}
        </InputLabel>
      )}

      <MuiSelect
        disabled={noOptions}
        labelId={labelId}
        value={safeValue}
        multiple={isMultiple}
        startAdornment={startExtraRendered}
        input={inputRendered}
        onChange={(event) => onChange && onChange(event.target.value)}
        renderValue={(selected) => handleRenderValue(selected)}
      >
        {optionsBuilded.map((option) => (
          <MenuItem key={option.id} value={option.value} disabled={option.disabled}>
            {isMultiple && <Checkbox checked={handleIsOptionSelected(option.value)} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </MuiSelect>
      {message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
