import type HttpClient from 'core/services/api/http.port';
import type StateManager from 'core/services/store/store.port';
import { type AppState } from 'core/services/store/store.service';
import type FormValidationClient from 'core/services/form/form.port';
import { type TimeClient } from 'core/services/time/time.port';
import type CacheStorageClient from 'core/services/cache/cacheStorage.port';
import AxiosAdapter from './axios.adapter';
import DayjsAdapter from './dayjs.adapter';
import AjvAdapter from './ajv.adapter';
import ZustandStoreAdapter from './zustand_store.adapter';
import ZustandCacheAdapter from './zustand_cache.adapter';
import { LocalesEnum } from 'core/common/constants';

export interface CoreAdapters {
  httpClient: HttpClient;
  appStoreClient: StateManager<AppState>;
  cacheStorageClient: CacheStorageClient;
  formValidationClient: FormValidationClient;
  timeClient: TimeClient;
}

// TODO: Improve the LocalesEnum.fr and set it from the browser language
export const createAdapters = (): CoreAdapters => ({
  httpClient: new AxiosAdapter(),
  appStoreClient: new ZustandStoreAdapter({
    auth: {
      isLogged: false,
    },
    user: {
      current: undefined,
      guest: {
        preferredLanguage: LocalesEnum.fr,
      },
    },
    event: {
      subscriptions: {},
      test: 'initial',
    },
    legalEntity: {
      current: undefined,
    },
  }),
  cacheStorageClient: new ZustandCacheAdapter(),
  formValidationClient: new AjvAdapter(),
  timeClient: new DayjsAdapter(),
});
