import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { RolesEnum } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import UserEntity from 'core/entities/user.entity';

class UserUseCase implements Usecase {
  constructor(private store: AppStore, private userEntity: UserEntity) {}

  getRoles() {
    return [RolesEnum.ROLE_ADMIN_USER];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  async getUser(id: string) {
    try {
      const response = await this.userEntity.getOne({
        pathVar: {
          id: id,
        },
      });

      if (response.data) {
        return response.data;
      }
    } catch (error) {
      // do something here
      return undefined;
    }
  }

  public async deleteUser(personId: string) {
    try {
      await this.userEntity.delete({
        pathVar: {
          id: personId,
        },
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * GET SERVICES FROM USERS
   */
  public async getServices(search: string) {
    const response = await this.userEntity.getAll({
      query: { 'autocomplete[department]': search },
    });
    return response?.data;
  }
}

export default UserUseCase;
