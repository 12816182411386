import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import { isUserGranted } from 'core/common/utils/grant';
import {
  ExhaustiveValue,
  SwaggerPathsKeys,
  SwaggerPathsMethods,
  SwaggerRequestResponse,
} from 'core/swagger';
import FormService, { FormProperties, FormRequired } from 'core/services/form/form.service';
import { FormValidationHandler } from 'core/services/form/form.port';
import UserEntity from 'core/entities/user.entity';
import { isObject } from 'core/common/utils/predicatesType';

type SetUserFormInitialValues = Record<keyof FormProperties, ExhaustiveValue>;

export interface SetUserFormReturn<
  I = SetUserFormInitialValues | Promise<SetUserFormInitialValues>,
> {
  properties: FormProperties;
  initialValues: I;
  required: FormRequired;
  validate: FormValidationHandler;
  submit: <P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    data: object,
  ) => Promise<SwaggerRequestResponse<P, M> | undefined>;
}

class SetUserUseCase implements Usecase {
  private currentOne: Record<string, unknown> = {};

  constructor(
    private store: AppStore,
    private formService: FormService,
    private userEntity: UserEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  // ADD ENTITY FORM
  getAddForm() {
    const formDefinition = this.formService.createForm(this.userEntity.getPathAll(), 'post');

    const initialValues: Record<keyof FormProperties, ExhaustiveValue> = {
      hidden: false,
    };

    const handleSubmit = (data: object) => {
      return this.userEntity
        .post({
          body: this.formService.unformatData(data),
        })
        .then((response) => response.data);
    };

    return {
      ...formDefinition,
      initialValues,
      submit: handleSubmit,
    };
  }

  // UPDATE ENTITY FORM
  getEditForm(userId: string) {
    const pathVar = { id: userId };
    const { properties, required, validate } = this.formService.createForm(
      this.userEntity.getPathOne(),
      'put',
    );

    this.currentOne = {};

    const getInitialValues = async (): Promise<Record<keyof FormProperties, ExhaustiveValue>> => {
      let initialValues = {
        // because the useless asset_legalEntityId is not nullable on the backend
        // // TODO: fix the backend typing then remove the next line
        legalEntityId: this.store.getState((state) => state.user.current?.legalEntity?.id),
      };

      let currentAssetData = await this.userEntity
        .getOne({ pathVar })
        .then((response) => response.data);

      if (isObject(currentAssetData)) {
        if ('accessRights' in currentAssetData && currentAssetData.accessRights) {
          currentAssetData = {
            ...currentAssetData,
            // Fix the backend that returns/expect not homonegeous data between PUT/PUT
            // the GET response returns an array of objects (wrongly typed as string[])
            // while the PUT body expect an array of IRI.
            // TODO: remove ESLint and TS ignore below when the Swagger will be right
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            accessRights: currentAssetData.accessRights.map((right) => right['@id']),
          };
        }

        // FIXME: Fix the user schema
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.currentOne = this.formService.formatData(currentAssetData);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in this.currentOne) {
            initialValues = {
              ...initialValues,
              [propkey]: this.currentOne[propkey],
            };
          }
        });
      }

      return initialValues;
    };

    const handleSubmit = (data: object) => {
      return this.userEntity
        .put({
          body: this.formService.unformatData({ ...this.currentOne, ...data }),
          pathVar,
        })
        .then((response) => response.data);
    };

    return {
      properties,
      initialValues: getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }
}

export default SetUserUseCase;
