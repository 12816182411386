import HttpService from './api/http.service';
import CacheService from './cache/cache.service';
import EventService from './events/events.service';
import ParametersService from './parameters/parameters.service';
import AppStore from './store/store.service';
import TimeService from './time/time.service';

export default {
  store: AppStore,
  eventService: EventService,
  parametersService: ParametersService,
  http: HttpService,
  cacheService: CacheService,
  timeService: TimeService,
};
