// BASE ROUTES
const ROUTE_BASE = '';

export const ROUTE_CREATE_TAG = 'add';

export const ROUTE_UPDATE_TAG = 'edit';

export const ROUTE_ONBOARDING_TAG = 'onboarding';
export const ROUTE_OFFBOARDING_TAG = 'offboarding';

export const ROUTE_ALL = `${ROUTE_BASE}/*`;

// AUTH ROUTES
export const ROUTE_LOGIN = `${ROUTE_BASE}/login`;
export const ROUTE_SIGNUP = `${ROUTE_BASE}/signup`;
export const ROUTE_PASSWORD_DEFINE = `${ROUTE_BASE}/user/password/define`;
export const ROUTE_FORGOT_PASSWORD = `${ROUTE_BASE}/lost-password`;

// DASHBOARD ROUTES
export const ROUTE_DASHBOARD = `${ROUTE_BASE}/dashboard`;

// ASSET ROUTES
export const ROUTE_VEHICULE = `${ROUTE_BASE}/vehicles`;
export const ROUTE_COMPUTER = `${ROUTE_BASE}/computers`;
export const ROUTE_CARD = `${ROUTE_BASE}/cards`;
export const ROUTE_SERVICE = `${ROUTE_BASE}/services`;
export const ROUTE_LICENCE = `${ROUTE_BASE}/licences`;
export const ROUTE_TELEPHONE = `${ROUTE_BASE}/telephones`;
export const ROUTE_TELEPHONE_LINE = `${ROUTE_BASE}/telephoneLines`;
export const ROUTE_CUSTOM_ASSET = `${ROUTE_BASE}/customAssets`;

// ENTITY ROUTES
export const ROUTE_PERSON = `${ROUTE_BASE}/persons`;
export const ROUTE_USERS = `${ROUTE_BASE}/users`;
export const ROUTE_TASKS = `${ROUTE_BASE}/tasks`;
export const ROUTE_NOTIFICATIONS = `${ROUTE_BASE}/notifications`;
export const ROUTE_NOTIFICATIONS_SETTINGS = `${ROUTE_BASE}/notifications-settings`;
export const ROUTE_ACCOUNT = `${ROUTE_BASE}/account`;
export const ROUTE_ACCOUNT_LEGALENTITY = `${ROUTE_BASE}/myLegalEntity`;
export const ROUTE_ACCOUNT_MYPROFILE = `${ROUTE_BASE}/myprofile`;

// PROBABLY USELESS AT THAT TIME
export const ROUTE_CONTRACTS = `${ROUTE_BASE}/contractual-commitments`;
export const ROUTE_TASKS_INVENTAIRE = `${ROUTE_BASE}/inventaire`;
export const ROUTE_TASKS_MODELES = `${ROUTE_BASE}/modeles`;
