import { FC, PropsWithChildren } from 'react';
import MuiBadge from '@mui/material/Badge';

interface CounterBadgeProps {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  value?: number;
  max?: number;
}

const CounterBadge: FC<PropsWithChildren<CounterBadgeProps>> = ({
  children,
  value,
  color = 'error',
  max = 99,
}) => {
  return (
    <MuiBadge
      badgeContent={value}
      showZero={false}
      max={max}
      color={color}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </MuiBadge>
  );
};

export default CounterBadge;
