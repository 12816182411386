import { isUserGranted } from 'src/core/common/utils/grant';
import { useCore } from 'src/web/common/core';
import { RolesEnum } from 'src/core/common/constants';

const useGrantRole = (role: RolesEnum) => {
  const { store } = useCore();

  return isUserGranted(
    store.getState((state) => state.user.current),
    [role],
  );
};

export default useGrantRole;
