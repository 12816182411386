import { FC } from 'react';
import FormInput from 'src/web/components/FormInput';
import { FiltersData } from 'src/web/common/inventory/useCreateInventory';
import { InputSelectProps } from '../FormInput/InputSelect';

export interface InventoryFilterProps extends Pick<InputSelectProps, 'renderOptionItem' | 'label'> {
  item?: FiltersData;
}

const InventoryFilter: FC<InventoryFilterProps> = ({ item, label, renderOptionItem }) => {
  if (!item) return <></>;

  return (
    <FormInput.Select
      label={label}
      value={item.value}
      isMultiple={true}
      options={item.options}
      renderOptionItem={renderOptionItem}
      onChange={(value) => {
        const valueList = Array.isArray(value) ? value : [value];
        const valueListDefined = valueList.map((v) => v ?? '');
        return item.onChange(valueListDefined);
      }}
    />
  );
};

export default InventoryFilter;
