import { Box, Toolbar } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

export interface CardToolbarProps {
  className?: string;
  secondaryContent?: ReactNode;
  compact?: boolean;
  padding?: number;
}

const DEFAULT_PADDING = 3;
const DEFAULT_PADDING_COMPACT = 1.5;

const CardToolbar: FC<PropsWithChildren<CardToolbarProps>> = ({
  children,
  className,
  secondaryContent,
  compact,
  padding,
}) => {
  const safePadding =
    padding !== undefined ? padding : compact ? DEFAULT_PADDING_COMPACT : DEFAULT_PADDING;
  const variant = compact ? 'dense' : 'regular';

  return (
    <Toolbar
      variant={variant}
      disableGutters={compact}
      className={className}
      sx={{
        px: safePadding,
      }}
    >
      {children}
      <Box sx={{ flexGrow: 1 }} />
      {secondaryContent}
    </Toolbar>
  );
};

export default CardToolbar;
