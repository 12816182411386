import type { FC } from 'react';
import { CoreProvider } from './common/core';
import AppRouterProvider from 'src/web/common/router/router.provider';
import TranslateProvider from './common/translate/translate.provider';
import ThemeProvider from './common/theme';
import ToasterProvider from './design_system/Toaster/toasterProvider';

const App: FC = () => {
  return (
    <CoreProvider>
      <TranslateProvider>
        <ThemeProvider>
          <ToasterProvider>
            <AppRouterProvider />
          </ToasterProvider>
        </ThemeProvider>
      </TranslateProvider>
    </CoreProvider>
  );
};

export default App;
