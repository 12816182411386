import { Popover as MuiPopover } from '@mui/material';
import { FC, PropsWithChildren, RefObject, useEffect, useState } from 'react';

export type HorizontalPopoverValues = 'left' | 'center' | 'right';
export interface PopoverProps {
  targetRef: RefObject<HTMLButtonElement | null>;
  isOpen?: boolean;
  horizontal?: HorizontalPopoverValues;
  onChange?: (state: boolean) => void;
}

const Popover: FC<PropsWithChildren<PopoverProps>> = ({
  children,
  targetRef,
  horizontal = 'center',
  isOpen = false,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleClose = () => {
    setOpen(false);

    if (onChange) {
      onChange(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <MuiPopover
      open={open && !!targetRef.current}
      anchorEl={targetRef.current}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontal,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontal,
      }}
    >
      {children}
    </MuiPopover>
  );
};

export default Popover;
