import Button from 'src/web/design_system/Button';
import { useDeleteMileageVehicle } from 'src/web/pages/assets/detail/hooks/vehicle/useDeleteMileageVehicle';
import Grid from 'src/web/design_system/Grid';
import { FC } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';

interface DeleteMileageProps {
  id: string;
  onSubmit: () => void;
  onClose: () => void;
}

const DeleteMileage: FC<DeleteMileageProps> = ({ id, onSubmit, onClose }) => {
  const { handleDelete, isLoading } = useDeleteMileageVehicle(id, onSubmit);
  const { translate } = useTranslate();

  return (
    <Grid.Container hSpacing="small">
      <Grid.Item>
        <Button.Default
          variant="contained"
          size="medium"
          onClick={() => onClose()}
          color="inherit"
          fullWidth
        >
          {translate('assets.mileage.delete.modal.cancel')}
        </Button.Default>
      </Grid.Item>
      <Grid.Item>
        <Button.Default
          variant="contained"
          size="medium"
          color="error"
          fullWidth
          onClick={handleDelete}
          isLoading={isLoading}
        >
          {translate('assets.mileage.delete.modal.submit')}
        </Button.Default>
      </Grid.Item>
    </Grid.Container>
  );
};
export default DeleteMileage;
