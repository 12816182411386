import { FC, useEffect, useState } from 'react';
import Modal from 'src/web/components/Modal';
import Displayer from 'src/web/design_system/Displayer';
import Button from '../Button';
import Text from 'src/web/design_system/Text';
import { useNavigate } from 'react-router-dom';
import useTranslate from 'src/web/common/translate/useTranslate';

interface DeleteEntityModalProps {
  title: string;
  text: string;
  open: boolean;
  redirect: string;
  onSubmit: () => Promise<boolean | undefined>;
  onClose: () => void;
}

const DeleteEntityModal: FC<DeleteEntityModalProps> = ({
  title,
  text,
  open,
  redirect,
  onSubmit,
  onClose,
}) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(open);
  const [isLoading, setIsLoading] = useState(false);

  // close the modal except if the deletion is in progress
  const handleClose = () => {
    if (!isLoading) {
      setIsOpen(false);
    }
  };

  // run the deletion, start the loading then navigate
  const handleDelete = () => {
    setIsLoading(true);
    onSubmit().then((isDeleted) => {
      setIsOpen(false);
      setIsLoading(false);
      if (isDeleted) {
        navigate(redirect);
      }
    });
  };

  // change the local state when the controller changed
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  // inform the controller the local state changed
  useEffect(() => {
    if (isOpen === false) {
      onClose();
    }
  }, [isOpen]);

  const actionArea = (
    <Displayer layout="row" justifyContent="center" innerSpacing={3}>
      <Button.Custom
        onClick={() => handleClose()}
        variant="outlined"
        fullWidth={false}
        disabled={isLoading}
      >
        {translate('persons.delete.cancel')}
      </Button.Custom>
      <Button.Custom
        color="error"
        fullWidth={false}
        onClick={() => handleDelete()}
        isLoading={isLoading}
      >
        {translate('persons.delete.submit')}
      </Button.Custom>
    </Displayer>
  );

  return (
    <Modal title={title} open={isOpen} onClose={() => handleClose()} footer={actionArea}>
      <Text>{text}</Text>
    </Modal>
  );
};

export default DeleteEntityModal;
