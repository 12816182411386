import HttpService, { RequestOptions } from 'core/services/api/http.service';
import { RouteParamsPrefix } from 'core/common/constants';
import {
  JsonSchemaParams,
  schemas,
  SwaggerPathsKeys,
  SwaggerPathsMethods,
  SwaggerRequestBody,
  SwaggerRequestHeaders,
  SwaggerRequestQuery,
} from 'core/swagger';
import { isArray, isDefined } from 'core/common/utils/predicatesType';
import CacheService from 'core/services/cache/cache.service';

export type FilterByName<T> = T extends `${RouteParamsPrefix.filterBy}${string}` ? T : never;

export type FilterOptionsResponse = [key: string, options: string[]];

export type GetFiltersListResponse<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>> = {
  [Key in FilterByName<keyof SwaggerRequestQuery<P, M>>]: () => Promise<FilterOptionsResponse>;
};

export type SortByName<T> = T extends `${RouteParamsPrefix.order}${string}` ? T : never;

export interface SorterOptionsResponse {
  key: string;
  options: string[];
}

export type GetSortersListResponse<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>> = {
  [Key in SortByName<keyof SwaggerRequestQuery<P, M>>]: SorterOptionsResponse;
};

class ParametersService {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  /**
   * Get sorters list
   * @description Format parameters list as object { [key]: getOptionsHandler }
   */
  getAndResolveSortersList<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    path: P,
    method: M,
  ) {
    const parameters = schemas[path][method].parameters;

    const list: [string, string[]][] = [];

    parameters.forEach((parameter) => {
      const realname = String(parameter.name);
      const isSorter = this.parseParameterName(realname).prefix === RouteParamsPrefix.order;

      if (isSorter && isArray(parameter.schema.enum)) {
        list.push([realname, parameter.schema.enum?.map((val) => String(val))]);
      }
    });

    return Object.fromEntries(list);
  }

  /**
   * Get filters list
   * @description Format parameters list as object { [key]: getOptionsHandler }
   */
  async getAndResolveFiltersList<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    path: P,
    method: M,
  ) {
    const parameters = schemas[path][method].parameters;

    const requestsGroup = parameters.map((parameter) => {
      const realname = String(parameter.name);
      const isFilter = this.parseParameterName(realname).prefix === RouteParamsPrefix.filterBy;
      if (isFilter) {
        return this.cacheService.cacheRequest(
          () => this.getFilterOptions<P, M>(path, method, parameter as JsonSchemaParams<P, M>),
          {
            path,
            method: 'get',
            ttl: 1000 * 60 * 10,
            payload: {
              parameter,
            },
          },
        );
      }
    });

    return Promise.all(requestsGroup).then((responses) => {
      return Object.fromEntries(responses.filter(isDefined));
    });
  }

  /**
   * Parse parameter name
   * @description used to handle name as 'prefix[subject]'
   * @example 'filterBy[foo.bar]' will get { prefix: 'filterBy', content: 'foo.bar', subject: 'bar' }
   */
  private parseParameterName(name: string): { prefix?: string; content: string; subject: string } {
    const matchPattern = name.match(/^(.+)\[(.+)\]$/);

    return {
      prefix: matchPattern ? matchPattern[1] : undefined,
      content: matchPattern ? matchPattern[2] : name,
      subject: matchPattern ? matchPattern[2].split('.').splice(-1)[0] : name,
    };
  }

  private async getFilterOptions<P extends SwaggerPathsKeys, M extends SwaggerPathsMethods<P>>(
    path: P,
    method: M,
    parameter: JsonSchemaParams<P, M>,
  ): Promise<FilterOptionsResponse> {
    // todo: not optimal, should be improved
    const requestOptions: RequestOptions<P, M> = {};

    const filterParams = {
      [parameter.name]: '%',
    };

    if (parameter.in === 'header') {
      requestOptions.headers = filterParams as SwaggerRequestHeaders<P, M>;
    } else if (parameter.in === 'query') {
      requestOptions.query = filterParams as SwaggerRequestQuery<P, M>;
    } else if (parameter.in === 'body') {
      requestOptions.body = filterParams as SwaggerRequestBody<P, M>;
    }

    const response = await this.http.requestFromSwagger(path, method, requestOptions);
    const filterOptions: string[] = [];
    const key = this.parseParameterName(parameter.name as string).subject;

    if (response.data && Array.isArray(response.data)) {
      // todo: try to improve the typing inference because type guard doesn't work here
      const data = response.data as object[];

      data.forEach((item) => {
        if (key in item) {
          filterOptions.push(item[key as keyof typeof item]);
        }
      });
    }

    return new Promise((resolve) =>
      resolve([this.parseParameterName(parameter.name as string).content, filterOptions]),
    );
  }
}

export default ParametersService;
