import HttpService, { RequestOptions } from 'core/services/api/http.service';
import CacheService from 'core/services/cache/cache.service';
import { exactString } from 'core/common/utils/manageType';
import { SwaggerRequestResponse } from 'core/swagger';

export type LegalEntity = SwaggerRequestResponse<'/api/private/legal-entities/{id}', 'get'>;
export type LegalEntitySettings = SwaggerRequestResponse<
  '/api/private/legal-entities/{id}/settings',
  'get'
>;

class LegalEntityEntity {
  constructor(private http: HttpService, private cacheService: CacheService) {}

  getPathOne() {
    return exactString('/api/private/legal-entities/{id}');
  }

  getPathResetCreatedId() {
    return exactString('/api/private/legal-entities/{id}/reset-created-at');
  }

  getPathSettings() {
    return exactString('/api/private/legal-entities/{id}/settings');
  }

  getPathAll() {
    return exactString('/api/private/legal-entities');
  }

  getOne = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathOne(), 'get', options),
      {
        path: this.getPathOne(),
        method: 'get',
        payload: options,
      },
    );
  };

  getSettings = async (options: RequestOptions<ReturnType<typeof this.getPathSettings>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathSettings(), 'get', options),
      {
        path: this.getPathSettings(),
        method: 'get',
        payload: options,
      },
    );
  };

  getAll = async (options: RequestOptions<ReturnType<typeof this.getPathAll>, 'get'>) => {
    return this.cacheService.cacheRequest(
      () => this.http.requestFromSwagger(this.getPathAll(), 'get', options),
      {
        path: this.getPathAll(),
        method: 'get',
        payload: options,
      },
    );
  };

  put = async (options: RequestOptions<ReturnType<typeof this.getPathOne>, 'put'>) => {
    this.cacheService.cacheInvalidate(this.getPathOne(), { pathVar: options.pathVar });
    this.cacheService.cacheInvalidate(this.getPathAll());
    return this.http.requestFromSwagger(this.getPathOne(), 'put', options);
  };

  putResetCreatedId = async (
    options: RequestOptions<ReturnType<typeof this.getPathResetCreatedId>, 'put'>,
  ) => {
    this.cacheService.cacheInvalidate(this.getPathResetCreatedId(), { pathVar: options.pathVar });
    return this.http.requestFromSwagger(this.getPathResetCreatedId(), 'put', options);
  };
}

export default LegalEntityEntity;
