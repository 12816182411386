import { FC } from 'react';
import useTranslate from 'src/web/common/translate/useTranslate';
import GuiInputSelect from 'src/web/design_system/Input/Select';

export interface InputSelectProps
  extends Omit<Parameters<typeof GuiInputSelect>[0], 'renderOptionEmptyValue'> {
  className?: string;
}

const InputSelect: FC<InputSelectProps> = ({ className, ...props }) => {
  const { isFullWidth, ...restProps } = props;
  const { translate } = useTranslate();

  return (
    <div className={className}>
      <GuiInputSelect
        {...restProps}
        isFullWidth={isFullWidth ?? true}
        renderOptionEmptyValue={() => translate('global.select.renderOptionEmptyValue')}
        labelWhenNoOptions={props.labelWhenNoOptions}
      />
    </div>
  );
};

export default InputSelect;
