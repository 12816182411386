import { FC, PropsWithChildren, ReactNode } from 'react';
import { createCss, mergeCss } from 'src/web/common/styles/createCss';
import Card from 'src/web/design_system/NewCard';
import Text from 'src/web/design_system/Text';
import { GuiTypographyVariants } from 'src/web/design_system/Theme';

export interface CardBasicProps {
  title?: ReactNode;
  headerExtra?: ReactNode;
  headerIcon?: ReactNode;
  footer?: ReactNode;
  className?: string;
  titleVariant?: GuiTypographyVariants;
}

const cardContainerStyle = createCss(`
  & + & {
    margin-top: 32px;
  }
`);

const CardBasic: FC<PropsWithChildren<CardBasicProps>> = ({
  title,
  titleVariant = 'sectionTitle',
  children,
  headerIcon,
  headerExtra,
  footer,
  className,
}) => {
  const hasHeader = !!title || !!headerIcon || !!headerExtra;
  const hasFooter = !!footer;

  return (
    <Card.Container className={mergeCss(cardContainerStyle, className)}>
      {hasHeader && (
        <Card.Toolbar secondaryContent={headerExtra}>
          <Text variant={titleVariant}>{title}</Text>
        </Card.Toolbar>
      )}
      <Card.Content>{children}</Card.Content>
      {hasFooter && <Card.Footer>{footer}</Card.Footer>}
    </Card.Container>
  );
};

export default CardBasic;
