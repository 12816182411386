import { FC, useEffect, useRef } from 'react';
import Form from 'src/web/components/FormInput';
import Grid from 'src/web/design_system/Grid';
import Modal, { ModalProps } from 'src/web/components/Modal';
import Displayer from 'src/web/design_system/Displayer';
import Button from 'src/web/components/Button';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import useEditSite from '../hooks/useEditSite';
import Skeleton from 'src/web/design_system/Skeleton';
import useTranslate from 'src/web/common/translate/useTranslate';

interface EditSiteModalProps extends Pick<ModalProps, 'open' | 'onClose'> {
  siteId: string;
  onSubmit: () => void;
}

const EditSiteModal: FC<EditSiteModalProps> = ({ siteId, open, onClose, onSubmit }) => {
  const formRef = useRef<FormContainerRef>(null);
  const { formatCountry, translate } = useTranslate();
  const { form, init, isLoading } = useEditSite({
    siteId: siteId,
    afterSubmit: (status) => {
      if (status) {
        onSubmit();
        onClose();
      }
    },
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  useEffect(() => {
    init();
  }, [siteId]);

  return (
    <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
      <Modal
        title={translate('legalEntity.editSite.title')}
        open={open}
        onClose={onClose}
        footer={
          <Displayer layout="row" justifyContent="center" innerSpacing={3}>
            <Button.Custom onClick={() => onClose()} variant="outlined" fullWidth={false}>
              {translate('legalEntity.editSite.cancel')}
            </Button.Custom>
            <Button.Custom
              onClick={() => handleSubmit()}
              fullWidth={false}
              disabled={!(form.isDirty && !form.hasErrors)}
              isLoading={isLoading}
            >
              {translate('legalEntity.editSite.submit')}
            </Button.Custom>
          </Displayer>
        }
      >
        {isLoading ? (
          <>
            {Array(5)
              .fill(null)
              .map((_, i) => (
                <Skeleton width="100%" height="1.5rem" key={`skelSite${i}`} />
              ))}
          </>
        ) : (
          <Displayer innerSpacing={2}>
            <Form.TextAdapted
              item={form.items.name}
              label={translate('attachmentSites.name.label')}
            />

            <Form.TextAdapted
              item={form.items.location_streetName}
              label={translate('attachmentSites.location.streetName.label')}
            />

            <Form.TextAdapted
              item={form.items.location_streetNameExtra}
              label={translate('attachmentSites.location.streetNameExtra.label')}
            />

            <Grid.Container>
              <Grid.Item>
                <Form.TextAdapted
                  item={form.items.location_postcode}
                  label={translate('attachmentSites.location.postcode.label')}
                />
              </Grid.Item>
              <Grid.Item>
                <Form.TextAdapted
                  item={form.items.location_city}
                  label={translate('attachmentSites.location.city.label')}
                />
              </Grid.Item>
              <Grid.Item>
                <Form.SelectAdapted
                  item={form.items.location_country}
                  label={translate('attachmentSites.location.country.label')}
                  renderValue={(value) => {
                    if (Array.isArray(value)) {
                      return value[0] ? formatCountry(value[0]) : '';
                    }
                    return value ? formatCountry(value) : '';
                  }}
                  renderOptionItem={(value) => ({
                    label: formatCountry(value),
                  })}
                />
              </Grid.Item>
            </Grid.Container>
          </Displayer>
        )}
      </Modal>
    </FormContainer>
  );
};

export default EditSiteModal;
