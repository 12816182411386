import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCore } from 'src/web/common/core';

const useDeleteUser = () => {
  const { id } = useParams();
  const { userUseCase } = useCore();

  const handleDelete = useCallback(() => {
    if (id) {
      const response = userUseCase.deleteUser(id);

      return response;
    }

    return new Promise<false>((resolve) => resolve(false));
  }, [id]);

  return {
    handleDelete,
  };
};

export default useDeleteUser;
