import { ReactNode } from 'react';
import {
  Box,
  Chip as MuiChip,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from '@mui/material';

interface AutocompleteRequiredOptionType {
  value: string;
}

interface AutocompleteProps<T> {
  value?: T;
  defaultValue?: T;
  options: T[];
  label?: string;
  isFullWidth?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  className?: string;
  errorMessage?: string;
  multiple?: boolean;
  onSearch: (value: string) => void;
  onChange: (value?: string[]) => void;
  renderOption?: (option: T) => ReactNode;
  renderInput?: (option: T) => string;
  selectValue?: (option: T) => string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  noOptionsText?: string;
}

function Autocomplete<T extends AutocompleteRequiredOptionType>({
  value,
  defaultValue,
  label,
  options,
  isFullWidth = true,
  isRequired,
  isError,
  className,
  multiple = false,
  onSearch,
  onChange,
  renderOption,
  renderInput,
  selectValue,
  onBlur,
  errorMessage,
  noOptionsText,
}: AutocompleteProps<T>) {
  const handleChange = (newOptions: T | T[] | null) => {
    if (!newOptions) return onChange(undefined);

    const arrOptions = Array.isArray(newOptions) ? newOptions : [newOptions];
    const arrValues = arrOptions.map((option) =>
      selectValue ? selectValue(option) : option.value,
    );

    onChange(arrValues);
  };

  return (
    <MuiAutocomplete
      value={value}
      onBlur={onBlur}
      defaultValue={defaultValue}
      autoComplete
      options={options}
      multiple={multiple}
      getOptionLabel={(option) => (renderInput ? renderInput(option) : option.value)}
      filterOptions={(x) => x} // disable auto filter because this is done server side
      blurOnSelect
      noOptionsText={noOptionsText}
      onChange={(_, currentOptions) => handleChange(currentOptions)}
      renderTags={(val: T[], getTagProps) => {
        if (!multiple) return;

        return val.map((option, index) => (
          <MuiChip
            variant="outlined"
            label={renderInput ? renderInput(option) : option.value}
            {...getTagProps({ index })}
          />
        ));
      }}
      onInputChange={(_, currentSearch, reason) => {
        if (reason === 'input') {
          onSearch(currentSearch);
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            {renderOption ? renderOption(option) : option.value}
          </Box>
        );
      }}
      renderInput={(params) => (
        <MuiTextField
          className={className}
          {...params}
          label={label}
          required={isRequired}
          fullWidth={isFullWidth}
          helperText={errorMessage}
          error={isError}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
        />
      )}
    />
  );
}

export default Autocomplete;
