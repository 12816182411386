import { createStore } from 'zustand/vanilla';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import StateManager, {
  StateManagerGetState,
  StateManagerSetState,
} from 'core/services/store/store.port';

class ZustandStoreAdapter<S extends object> implements StateManager<S> {
  private store;

  initialState;

  constructor(initialAppState: S) {
    this.initialState = initialAppState;

    this.store = createStore<S>()(
      immer(
        persist(
          devtools(() => this.initialState),
          {
            name: 'Fleezy',
            storage: createJSONStorage(() => localStorage),
          },
        ),
      ),
    );
  }

  public reset(): void {
    // info: https://github.com/immerjs/immer/issues/296
    this.store.setState(() => {
      return this.initialState;
    });
  }

  public getState: StateManagerGetState<S> = (handler) => {
    return handler(this.store.getState());
  };

  public setState: StateManagerSetState<S> = (handler) => {
    this.store.setState((a) => handler(a as S));
  };

  public subscribe: StateManagerSetState<S> = (handler) => {
    this.store.subscribe((a) => handler(a as S));
  };
}

export default ZustandStoreAdapter;
