import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from 'src/web/common/theme';

const CustomDataGrid = styled(DataGrid)((noBorder) => {
  const { theme } = useTheme();

  return {
    border: noBorder ? 0 : '',
    '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palettes.neutral?.[50],
      boxShadow: 'none',
      padding: '0 16px',
      borderRadius: 0,
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.palettes.neutral?.[900],
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell': {
      outline: 'none',
      padding: '16px 32px',
      border: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-row': {
      '&:last-child .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
      '& .MuiDataGrid-cell': {
        borderBottom: `1px dashed  ${theme.colors.activeAction}25`,
      },
    },
    '& .MuiDataGrid-root': {
      boxShadow: 'none',
      padding: '16px',
      border: 0,
    },
  };
});

export default CustomDataGrid;
