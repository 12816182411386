import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '../Theme';

interface ToasterProviderProps {
  dense?: boolean;
  maxDisplayed?: number;
  horizontal?: 'right' | 'center' | 'left';
  vertical?: 'top' | 'bottom';
}

const ToasterProvider: FC<PropsWithChildren<ToasterProviderProps>> = ({
  children,
  dense,
  maxDisplayed,
  horizontal = 'right',
  vertical = 'bottom',
}) => {
  const theme = useTheme();

  const StyledMaterialDesignContent = styled(MaterialDesignContent)`
    &.notistack-MuiContent-success {
      background-color: ${theme.theme.palettes.success?.[50]};
      color: ${theme.theme.palettes.success?.[600]};
      box-shadow: none;
    }
    &.notistack-MuiContent-error {
      background-color: ${theme.theme.palettes.error?.[50]};
      color: ${theme.theme.palettes.error?.[600]};
      box-shadow: none;
    }
    &.notistack-MuiContent-warning {
      background-color: ${theme.theme.palettes.warning?.[50]};
      color: ${theme.theme.palettes.warning?.[600]};
      box-shadow: none;
    }
    &.notistack-MuiContent-info {
      background-color: ${theme.theme.palettes.info?.[50]};
      color: ${theme.theme.palettes.info?.[600]};
      box-shadow: none;
    }
    &.notistack-MuiContent-default {
      background-color: ${theme.theme.palettes.neutral?.[50]};
      color: ${theme.theme.palettes.neutral?.[600]};
      box-shadow: none;
    }
  `;
  return (
    <SnackbarProvider
      dense={dense}
      maxSnack={maxDisplayed}
      anchorOrigin={{ horizontal, vertical }}
      Components={{
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToasterProvider;
