import { EntitiesEnum, RolesEnum } from 'core/common/constants';
import { FC, useEffect, useState } from 'react';
import useAssetInventory from './hooks/useAssetInventory';
import { ROUTE_CREATE_TAG, ROUTE_PERSON, ROUTE_VEHICULE } from 'src/web/common/router/constantes';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import ButtonCreate from 'src/web/components/Button/buttonCreate';
import InventoryFilter from 'src/web/components/InventoryFilter';
import { useCore } from 'src/web/common/core';
import AssetStateChip from 'src/web/components/Chip/AssetStateChip';
import useTranslate from 'src/web/common/translate/useTranslate';
import Text from 'src/web/design_system/Text';
import InventoryCard from 'src/web/components/InventoryCard';
import FiltersModalByButton from 'src/web/components/FiltersModalByButton';
import SearchExpandable from 'src/web/design_system/Input/SearchExpandable';
import Skeleton from 'src/web/design_system/Skeleton';
import Displayer from 'src/web/design_system/Displayer';
import useInventoryHelpers from 'src/web/common/inventory/useInventoryHelpers';
import useGrantRole from 'src/web/common/grant/useGrantRole';
import { getIdFromIri } from 'src/core/common/utils/id';
import Link from 'src/web/components/Link';
import { truncateContainer } from './style';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHistoryPagination } from 'src/web/common/inventory/useHistoryPagination';

const ENTITY: EntitiesEnum = EntitiesEnum.vehicle;

const InventoryVehiclePage: FC = () => {
  const inventoryData = useAssetInventory(ENTITY, [
    'digestColumn',
    'person',
    'financingMethod',
    'asset.status',
    'assetCondition',
    'asset.attachmentSite.name',
  ]);
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const [inventoryRefresh, setInventoryRefresh] = useState(false);

  const { translate } = useTranslate();
  const { timeService } = useCore();

  const navigate = useNavigate();
  const inventoryHelpers = useInventoryHelpers();
  const isRoleAdminPerson = useGrantRole(RolesEnum.ROLE_ADMIN_PERSON);

  const filterIsLoaded = Object.keys(inventoryData.filters).length > 0;

  const { handleChangePage, handleChangeItemPerPage } = useHistoryPagination(
    inventoryData.setItemPerPage,
    inventoryData.setCurrentPage,
    inventoryData.filters,
    filterIsLoaded,
  );

  const filterList = [
    'asset.assetCondition',
    'asset.attachmentSite.name',
    'asset.contractualCommitment.financingMethod',
    'asset.contractualCommitment.supplier',
    'asset.manufacturer',
    'asset.position',
    'asset.status',
    'category',
  ];

  const getFilterValuesFromSearchParams = (paramValue: string | null) => {
    return paramValue ? paramValue.split(',').map(decodeURIComponent) : undefined;
  };

  useEffect(() => {
    if (filterIsLoaded) {
      filterList.forEach((filterKey) => {
        const paramValue = searchParams.get(filterKey);
        const filterValues = getFilterValuesFromSearchParams(paramValue);

        if (inventoryData.filters[filterKey] && filterValues) {
          inventoryData.filters[filterKey].onChange(filterValues);
          setInventoryRefresh(true);
        }
      });
    }
  }, [filterIsLoaded]);

  const handleSubmitFilters = () => {
    inventoryData.handleLoadData();
    handleChangePage(1);
    return true;
  };

  useEffect(() => {
    inventoryData.handleLoadData();
  }, [inventoryRefresh]);

  return (
    <>
      <PageHeaderBasic
        title={`${translate('vehicles.title')} ${
          inventoryData.isLoading ? '' : `(${inventoryData.totalItems})`
        }`}
        extraContent={
          <ButtonCreate
            label={translate('vehicles.add.cta')}
            to={`${ROUTE_VEHICULE}/${ROUTE_CREATE_TAG}?${queryString}`}
          />
        }
      />

      <InventoryCard.Container>
        {/* TOOLBAR */}
        <InventoryCard.Toolbar
          primaryContent={
            <FiltersModalByButton edge="start" onSubmitFilters={() => handleSubmitFilters()}>
              <Skeleton isLoading={inventoryData.isLoading} width="100%" height="250px">
                <Displayer innerSpacing={2}>
                  <InventoryFilter
                    item={inventoryData.filters['asset.manufacturer']}
                    label={translate('vehicles.asset.manufacturer.label')}
                  />
                  <InventoryFilter
                    item={inventoryData.filters['asset.contractualCommitment.supplier']}
                    label={translate('contractualCommitment.supplier.label')}
                  />
                  <InventoryFilter
                    item={inventoryData.filters['asset.contractualCommitment.financingMethod']}
                    label={translate('assets.financingMethod.label')}
                    renderOptionItem={(value) => ({
                      label: translate(`assets.financingMethod.option.${value}.label`),
                    })}
                  />
                  <InventoryFilter
                    item={inventoryData.filters.category}
                    label={translate('vehicles.category.label')}
                  />
                  <InventoryFilter
                    item={inventoryData.filters['asset.assetCondition']}
                    label={translate('assets.assetCondition.label')}
                    renderOptionItem={(value) => ({
                      label: translate(`assets.assetCondition.option.${value}.label`),
                    })}
                  />
                  <InventoryFilter
                    item={inventoryData.filters['asset.status']}
                    label={translate('assets.asset.status.label')}
                    renderOptionItem={(value) => ({
                      label: translate(`assets.asset.status.option.${value}.label`),
                    })}
                  />
                  <InventoryFilter
                    item={inventoryData.filters['asset.attachmentSite.name']}
                    label={translate('assets.asset.site.label')}
                  />
                </Displayer>
              </Skeleton>
            </FiltersModalByButton>
          }
          secondaryContent={
            <SearchExpandable
              edge="end"
              onSearch={(val) => inventoryData.handleSearch(val)}
              initialValue={inventoryData.currentSearch}
            />
          }
        />
        {/* CURRENT FILTERS */}
        <InventoryCard.CurrentFilters
          activeFilters={Object.keys(inventoryData.selectedFilters)}
          onClearFilter={(name) => inventoryData.handleClearFilter(name)}
        />
        {/* INVENTORY */}
        <InventoryCard.Inventory
          onClickRow={(id, field) => {
            if (field !== 'person') {
              navigate(`${ROUTE_VEHICULE}/${id}?${queryString}`);
            }
          }}
          isLoading={inventoryData.isLoading}
          onSort={(key, sortState) =>
            inventoryHelpers.handleSort(inventoryData.sorters, key, sortState)
          }
          rows={inventoryData.rows ?? []}
          columns={inventoryData.columns!.map((column) => ({
            key: column,
            label: () => `${column}`,
            minWidth: () => 200,
            maxWidth: () => 700,
            isFlex: () => true,
            isSortable: () =>
              Object.keys(inventoryData.sorters ?? []).includes(`order[${String(column)}]`),
            renderHeader: (field) => {
              if (['asset.status', 'financingMethod', 'assetCondition'].includes(field)) {
                return translate(`assets.${field}.label`);
              }

              return translate(`vehicles.${field}.label`);
            },
            render: (row) => {
              if (column === 'digestColumn') {
                return (
                  <div className={truncateContainer}>
                    <Text tag="div" fontWeight={600} textOverflow>
                      {[row?.['asset.manufacturer'], row?.['asset.model']]
                        .filter((d) => !!d)
                        .join(' ')}
                    </Text>
                    <Text textOverflow variant="textSmall">
                      {row?.['asset.identification']}
                    </Text>
                  </div>
                );
              }
              if (column === 'financingMethod') {
                return (
                  <div>
                    <Text tag="div">
                      {translate(`assets.financingMethod.option.${row[column]}.label`)}
                    </Text>
                    <Text variant="textSmall">
                      {row && row['asset.contractualCommitment.endDate']
                        ? translate('global.end.label') +
                          ' ' +
                          timeService.format(row['asset.contractualCommitment.endDate'], 'short')
                        : ''}
                    </Text>
                  </div>
                );
              }
              if (column === 'asset.status') {
                return (
                  <AssetStateChip
                    value={row[column]}
                    label={translate(`assets.asset.status.option.${row[column]}.label`)}
                    size="small"
                  />
                );
              }
              if (column === 'assetCondition') {
                return (
                  <AssetStateChip
                    value={row[column]}
                    label={translate(`assets.assetCondition.option.${row[column]}.label`)}
                    size="small"
                  />
                );
              }
              if (column === 'person') {
                return (
                  <Link
                    to={
                      isRoleAdminPerson
                        ? `${ROUTE_PERSON}/${
                            row?.['asset.person.@id'] && getIdFromIri(row?.['asset.person.@id'])
                          }`
                        : ''
                    }
                  >
                    <Text>
                      {[row?.['asset.person.lastname'], row?.['asset.person.firstname']]
                        .filter((d) => !!d)
                        .join(' ')}
                    </Text>
                  </Link>
                );
              }
              return <Text>{row[column]}</Text>;
            },
          }))}
        />
        {/* PAGINATION */}
        <InventoryCard.Pagination
          paginationCount={Math.ceil(inventoryData.totalItems / inventoryData.itemPerPage)}
          paginationCurrent={inventoryData.currentPage}
          paginationPerPage={inventoryData.itemPerPage}
          onPaginationChange={(value) => handleChangePage(Number(value))}
          onPaginationChunkChange={(value) => handleChangeItemPerPage(Number(value))}
        />
      </InventoryCard.Container>
    </>
  );
};

export default InventoryVehiclePage;
