import { type FC } from 'react';
import { BaseButtonProps } from '.';
import Icon from 'src/web/components/Icon';
import ButtonBase from './buttonBase';
import useTranslate from 'src/web/common/translate/useTranslate';
import { backToNavigationButtonStyle, fixBackToNavigationButtonStyle } from './style';
import { useTheme } from '@mui/material';

interface ButtonBackNavigationProps extends BaseButtonProps {
  to?: string;
  onClick?: () => void;
}

const ButtonBackNavigation: FC<ButtonBackNavigationProps> = ({ to, onClick, label }) => {
  const { translate } = useTranslate();
  const DEFAULT_LABEL = translate('global.navigateBack.label');
  const theme = useTheme();
  return (
    <div className={fixBackToNavigationButtonStyle}>
      <ButtonBase
        fullWidth={false}
        variant="text"
        onClick={onClick}
        to={to}
        startIcon={<Icon.ChevronLeft />}
        color="inherit"
        className={backToNavigationButtonStyle(theme.palette.grey[600])}
      >
        {label ?? DEFAULT_LABEL}
      </ButtonBase>
    </div>
  );
};

export default ButtonBackNavigation;
