import { FC, useMemo } from 'react';
import { useTheme } from 'src/web/common/theme';
import Chip, { ChipProps } from 'src/web/design_system/Chip';

interface AssetStateChipProps extends Omit<ChipProps, 'bgColor' | 'color'> {
  value: string;
}

interface ChipTheme {
  bgColor: ChipProps['bgColor'];
  color: ChipProps['color'];
  variant: ChipProps['variant'];
  fontWeight?: ChipProps['fontWeight'];
  border?: ChipProps['border'];
  borderRadius?: ChipProps['borderRadius'];
}

const AssetStateChip: FC<AssetStateChipProps> = ({ label, icon, size, value }) => {
  const { theme } = useTheme();

  const chipTheme = useMemo((): ChipTheme => {
    const themeObj: ChipTheme = {
      bgColor: theme.palettes.neutral?.[800] ?? 'black',
      color: theme.palettes.neutral?.[800] ?? 'black',
      variant: 'outlined',
    };

    if (value) {
      if (['active', 'employee'].includes(value)) {
        themeObj.variant = 'filled';
        themeObj.bgColor = `${theme.palettes.success?.[50]}` ?? 'black';
        themeObj.color = theme.palettes.success?.[900] ?? 'white';
        themeObj.border = false;
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
        themeObj.fontWeight = 500;
      }

      if (['pending', 'onBoardingEmployee'].includes(value)) {
        themeObj.variant = 'filled';
        themeObj.bgColor = `${theme.palettes.warning?.[50]}` ?? 'black';
        themeObj.color = theme.palettes.warning?.[900] ?? 'white';
        themeObj.border = false;
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
        themeObj.fontWeight = 500;
      }

      if (['disabled', 'formerEmployee'].includes(value)) {
        themeObj.variant = 'filled';
        themeObj.bgColor = `${theme.palettes.error?.[50]}` ?? 'black';
        themeObj.color = theme.palettes.error?.[900] ?? 'white';
        themeObj.border = false;
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
        themeObj.fontWeight = 500;
      }

      if (['normal'].includes(value)) {
        themeObj.bgColor = theme.palettes.success?.[900] ?? 'black';
        themeObj.color = theme.palettes.success?.[900] ?? 'black';
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
      }

      if (['maintenance'].includes(value)) {
        themeObj.bgColor = theme.palettes.warning?.[800] ?? 'black';
        themeObj.color = theme.palettes.warning?.[800] ?? 'black';
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
      }

      if (['locked', 'broken', 'stolen', 'lost', 'blocked'].includes(value)) {
        themeObj.bgColor = theme.palettes.error?.[800] ?? 'black';
        themeObj.color = theme.palettes.error?.[800] ?? 'black';
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
      }

      if (['toBeNotified'].includes(value)) {
        themeObj.borderRadius = `${theme.shape.smallRadius}px`;
      }
    }

    return themeObj;
  }, [value]);

  return (
    <Chip
      label={label}
      icon={icon}
      size={size}
      bgColor={chipTheme.bgColor}
      color={chipTheme.color}
      variant={chipTheme.variant}
      fontWeight={chipTheme.fontWeight}
      border={chipTheme.border}
      borderRadius={chipTheme.borderRadius}
    />
  );
};

export default AssetStateChip;
