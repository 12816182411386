import { FC, useState } from 'react';
import CookieConsentLib from 'react-cookie-consent';
import Translate from 'src/web/common/translate/translate.component';
import { useTheme } from 'src/web/common/theme';
import { COOKIE_CONSENT_NAME } from './constant';
import Modal from 'src/web/design_system/Modal';
import CookieConsentSettings from './cookieConsentSettings';
import Card from 'src/web/components/Card';

const CookieContent: FC = () => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleActiveAnalytics = (_state: boolean) => {
    // @todo: implement like in the in prod website or improve it to the future needs
  };

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Card.Basic title={Translate({ id: 'cookieSetting.modal.title' })}>
          <CookieConsentSettings onSubmit={() => setIsModalOpen(false)} />
        </Card.Basic>
      </Modal>
      <CookieConsentLib
        cookieName={COOKIE_CONSENT_NAME}
        buttonText={Translate({ id: 'global.cookie.accept' })}
        declineButtonText={Translate({ id: 'global.cookie.decline' })}
        onAccept={() => handleActiveAnalytics(true)}
        onDecline={() => handleActiveAnalytics(false)}
        enableDeclineButton={true}
        location="bottom"
        style={{
          background: theme.theme.palettes.neutral?.[900],
          color: theme.theme.palettes.primary?.contrast,
          fontSize: theme.theme.typography.textNormal?.fontSize,
        }}
        buttonStyle={{
          background: theme.theme.palettes.primary?.[500],
          color: theme.theme.palettes.primary?.contrast,
          fontSize: theme.theme.typography.textNormal?.fontSize,
        }}
        declineButtonStyle={{
          background: theme.theme.palettes.neutral?.[100],
          color: theme.theme.palettes.neutral?.[900],
          fontSize: theme.theme.typography.textNormal?.fontSize,
        }}
      >
        {Translate({ id: 'global.cookie.consent' })}
        <span onClick={() => setIsModalOpen(true)}>
          {Translate({ id: 'global.cookie.settings' })}
        </span>
      </CookieConsentLib>
    </>
  );
};

export default CookieContent;
