import { isString } from 'core/common/utils/predicatesType';

export function joinValues(values: unknown[], separator = ', '): string {
  const list: string[] = [];

  values.forEach((value) => {
    if (value && isString(value) && value.length > 0) {
      list.push(value);
    }
  });

  return list.join(separator);
}
