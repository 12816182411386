import { FC } from 'react';
import PageHeaderBasic from 'src/web/components/PageHeaderBasic';
import Tabs from 'src/web/design_system/Tabs';
import SubscriptionPartial from './partials/subscriptionPartial';
import SitesPartial from './partials/sitesPartial';
import useTranslate from 'src/web/common/translate/useTranslate';

const LegalEntityPage: FC = () => {
  const { translate } = useTranslate();
  return (
    <>
      <PageHeaderBasic title={translate('legalEntity.parameters.title')} />
      <Tabs.Global
        initialActiveId="subscription"
        tabs={[
          {
            id: 'subscription',
            label: translate('legalEntity.subscription.title'),
            content: <SubscriptionPartial />,
          },
          {
            id: 'sites',
            label: translate('legalEntity.attachmentSites.title'),
            content: <SitesPartial />,
          },
          // @deprecated Hotfix 2471: Disable the user possibility to setup a custom currency
          // {
          //   id: 'settings',
          //   label: translate('legalEntity.settings.title'),
          //   content: <SettingsPartial />,
          // },
        ]}
      />
    </>
  );
};

export default LegalEntityPage;
