import { FC } from 'react';
import { useCore } from 'src/web/common/core';
import useDisplayRoles from 'src/web/common/roles/useDisplayRoles';
import useTranslate from 'src/web/common/translate/useTranslate';
import Card from 'src/web/components/Card';
import RoleEmblemGroup from 'src/web/components/RoleEmblemGroup';

const RolesPartial: FC = () => {
  const { store } = useCore();
  const currentRoles = store.getState((state) => state.user.current?.roles) ?? [];
  const { translate } = useTranslate();
  const roles = useDisplayRoles(currentRoles);

  return (
    <Card.Basic title={`${translate('currentUser.roles.title')} (${roles.length})`}>
      <RoleEmblemGroup
        canSelect={false}
        roles={roles.map(({ key, label, icon: RoleIcon }) => ({
          role: key,
          label,
          icon: <RoleIcon size="large" />,
        }))}
      />
    </Card.Basic>
  );
};

export default RolesPartial;
