import { useState } from 'react';
import { useCore } from 'src/web/common/core';

const useLogout = () => {
  const { authUsecase } = useCore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogout = () => {
    setIsLoading(true);
    authUsecase.logout().then(() => {
      setIsLoading(false);
    });
  };

  return {
    isLoading,
    handleLogout,
  };
};

export default useLogout;
