import { FC, ReactNode } from 'react';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
  position?: 'static' | 'relative' | 'absolute' | 'fixed';
  bgColor?: 'primary' | 'secondary' | 'transparent';
  onClickMobileButton: () => void;
  iconMobileMenu?: ReactNode;
  startContent?: ReactNode;
  endContent?: ReactNode;
}

const SHOW_ON_MOBILE = {
  md: 'none',
  lg: 'none',
};

const HeaderContainer: FC<HeaderProps> = ({
  position = 'static',
  bgColor = 'transparent',
  iconMobileMenu,
  startContent,
  endContent,
  onClickMobileButton,
}) => {
  return (
    <AppBar
      component="nav"
      position={position}
      color={bgColor}
      sx={{
        height: '100%',
        padding: '12px 24px',
        boxShadow: 'unset',
      }}
    >
      <Toolbar sx={{ height: '100%' }} disableGutters>
        {/* Mobile menu button */}
        <IconButton
          size="large"
          onClick={() => onClickMobileButton()}
          sx={{ display: SHOW_ON_MOBILE }}
        >
          {iconMobileMenu ?? <MenuIcon />}
        </IconButton>

        {startContent}

        {/* flex separator */}
        <Box sx={{ flexGrow: 1 }} />

        {endContent}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderContainer;
