import { LocalesEnum } from 'src/core/common/constants';
import { useCore } from 'src/web/common/core';
import { positionStyle } from './style';
import Form from 'src/web/components/FormInput';
import { useState } from 'react';
import { Language } from '@mui/icons-material';

const LanguageSwitching = () => {
  const options = [
    {
      label: 'Français',
      value: LocalesEnum.fr,
    },
    {
      label: 'English',
      value: LocalesEnum.en,
    },
  ];

  const getLabel = (value: LocalesEnum) => options.filter((e) => e.value === value)[0].label;
  const getValue = (label: string) => options.filter((e) => e.label === label)[0].value;

  const { guestUsecase, store } = useCore();
  const [selectedLanguage, setselectedLanguage] = useState(
    store.getState((state) => state.user.guest?.preferredLanguage ?? LocalesEnum.fr),
  );
  const handleChangeLanguage = (value: LocalesEnum) => {
    setselectedLanguage(getValue(value));
    guestUsecase.updatePreferredLanguage(getValue(value));
  };

  return (
    <div className={positionStyle}>
      <Form.Select
        startExtra={<Language />}
        value={getLabel(selectedLanguage)}
        options={options.map((language) => language.label)}
        onChange={(value) => handleChangeLanguage(value as LocalesEnum)}
      ></Form.Select>
    </div>
  );
};

export default LanguageSwitching;
