import { FC } from 'react';
import Divider from '../Divider';

interface MenuDividerProps {
  color?: string;
  weight?: number;
}

const MenuDivider: FC<MenuDividerProps> = ({ color }) => {
  return <Divider color={color} vSpacing={0} hSpacing={0} />;
};

export default MenuDivider;
