import Card from 'src/web/components/Card';
import Displayer from 'src/web/design_system/Displayer';
import { FC, useEffect, useRef, useState } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { useCore } from 'src/web/common/core';
import Text from 'src/web/design_system/Text';
import Skeleton from 'src/web/design_system/Skeleton';
import Grid from 'src/web/design_system/Grid';
import Document from 'src/web/components/Document';
import useGetDocumentsByAssetId from './useGetDocumentsByAssetId';
import useDeleteDocuments from './useDeleteDocuments';
import usePostDocuments from './usePostDocuments';
import useTranslate from 'src/web/common/translate/useTranslate';
import FileUpload from 'src/web/components/FileUpload';
import Icon from 'src/web/components/Icon';
import Popover from 'src/web/components/Popover';
import { IconButton } from '@mui/material';
import Chip from 'src/web/design_system/Chip';
import { useTheme } from 'src/web/design_system/Theme';
import { createCss } from 'src/web/common/styles/createCss';
import TextPair from 'src/web/components/TextPair';

interface DocumentsCardProps {
  type: string;
  id: string;
  entity: EntitiesEnum;
}

const DocumentsCard: FC<DocumentsCardProps> = ({ type, id, entity }) => {
  const theme = useTheme();

  const { translate } = useTranslate();
  const { data, loadData, isLoading } = useGetDocumentsByAssetId(id, entity);
  const { handleDelete, loading: deleteLoading } = useDeleteDocuments(() => loadData());
  const { timeService } = useCore();
  const { handlePostDocument, loading: postLoading } = usePostDocuments({
    type,
    entity,
    id,
    afterSubmit: () => loadData(),
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ref = useRef<HTMLButtonElement | null>(null);
  const hasNoData = !data || data?.length === 0;

  useEffect(() => {
    loadData();
  }, [loadData]);

  const acceptedFiles = [
    'JPG',
    'PNG',
    'PDF',
    'XML',
    'PPT',
    'PPTX',
    'DOCX',
    'TXT',
    'BMP',
    'CSV',
    'ODS',
    'XLSX',
    'XLS',
    'RTF',
    'DOC',
    'ODT',
  ];

  const popoverStyle = createCss(`
    max-width: 200px
  `);

  return (
    <>
      <Card.Container>
        <Card.Toolbar>
          <Text variant="sectionTitle">{translate('assets.documents.title')}</Text>
          <IconButton ref={ref} onClick={() => setIsMenuOpen(true)}>
            <Icon.Help color="disabled" size="small" />
          </IconButton>
          <Popover onChange={(state) => setIsMenuOpen(state)} isOpen={isMenuOpen} targetRef={ref}>
            <Displayer
              justifyContent="space-between"
              alignItems="start"
              layout="column"
              innerSpacing={1}
            >
              <TextPair
                label={translate('documents.instruction.drop.label')}
                value={translate('documents.instruction.drop.value', { value: '1' })}
              />
              <TextPair
                label={translate('documents.instruction.size.label')}
                value={translate('documents.instruction.size.label.value')}
              />
              <TextPair label={translate('documents.instruction.acceptedFiles.label')} value={''} />
              <div className={popoverStyle}>
                <Displayer flexWrap={'wrap'} layout={'row'} innerSpacing={0.5}>
                  {acceptedFiles.map((fileName, index) => (
                    <Chip
                      label={fileName}
                      key={index}
                      variant="filled"
                      bgColor={theme.theme.palettes.primary?.[50]}
                      color={theme.theme.palettes.primary?.[500]}
                      size="small"
                    />
                  ))}
                </Displayer>
              </div>
            </Displayer>
          </Popover>
        </Card.Toolbar>
        <Card.Content>
          {data && data.length > 0 ? (
            <></>
          ) : isLoading ? (
            <></>
          ) : (
            <FileUpload.DropZone onChange={(file) => handlePostDocument(file)} />
          )}
          <Displayer layout={'row'} innerSpacing={2}>
            <Skeleton
              isLoading={isLoading || postLoading || deleteLoading}
              width={'100%'}
              height="50px"
            >
              {hasNoData ? (
                <></>
              ) : (
                <Grid.Container>
                  <Grid.Item>
                    <FileUpload.Card onChange={(file) => handlePostDocument(file)} />
                  </Grid.Item>
                  {data?.map((item) => {
                    return (
                      <Grid.Item key={item?.id} size={{ xs: 12, sm: 12, md: 12, lg: 6 }}>
                        <Document
                          fileName={item?.fileName ?? ''}
                          createdAt={
                            item?.createdAt &&
                            timeService.format(timeService.fromBackend(item.createdAt), 'short')
                          }
                          fileExtension={item?.fileExtension ?? ''}
                          fileSize={item?.fileSize ?? 0}
                          id={item?.id ?? ''}
                          handleDelete={() => handleDelete(item?.id ?? '', entity)}
                          entity={entity}
                        />
                      </Grid.Item>
                    );
                  })}
                </Grid.Container>
              )}
            </Skeleton>
          </Displayer>
        </Card.Content>
      </Card.Container>
    </>
  );
};
export default DocumentsCard;
