import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  IconButtonProps,
  Input,
  InputLabel,
} from '@mui/material';
import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Clear';

interface SearchExpandableProps extends Pick<IconButtonProps, 'edge'> {
  initialValue?: string;
  label?: string;
  onSearch: (value: string) => void;
}

const SearchExpandable: FC<SearchExpandableProps> = ({
  initialValue = '',
  edge,
  label,
  onSearch,
}) => {
  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && open) {
      inputRef.current.focus();
    }
  }, [open]);

  const toggleInputVisibility = () => {
    setOpen(true);
    if (!open && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSearch = () => {
    onSearch(value);
  };

  const handleClearInput = () => {
    setValue('');
    onSearch('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      if (value === '') {
        setOpen(false);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }} onBlur={handleBlur}>
      <Collapse in={open} orientation="horizontal">
        <FormControl variant="standard">
          {label && <InputLabel htmlFor="component-simple">{label}</InputLabel>}

          <Input
            inputRef={inputRef}
            id="component-simple"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            startAdornment={
              value.length > 1 ? (
                <IconButton
                  aria-label="clear input"
                  onClick={handleClearInput}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <ResetIcon fontSize="small" />
                </IconButton>
              ) : null
            }
          />
        </FormControl>
      </Collapse>
      <IconButton onClick={open ? handleSearch : toggleInputVisibility} edge={edge}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchExpandable;
