import CardContainer from './cardContainer';
import CardToolbar from './cardToolbar';
import CardContent from './cardContent';
import CardFooter from './cardFooter';

export default {
  Container: CardContainer,
  Toolbar: CardToolbar,
  Content: CardContent,
  Footer: CardFooter,
};
