import React, { FC } from 'react';
import { EntitiesEnum } from 'core/common/constants';
import { DynamicAssetDetailsReturn } from 'core/usecases/details.usecases';
import { themeColors } from 'src/web/common/theme/constant';
import Card from 'src/web/components/Card';
import TextPair from 'src/web/components/TextPair';
import Displayer from 'src/web/design_system/Displayer';
import DividerDatas from 'src/web/components/Divider';
import Text from 'src/web/design_system/Text';
import DynamicFinancingFields from './DynamicFinancingFields';
import { commentCardStyle } from './style';
import { useCore } from 'src/web/common/core';
import useTranslate from 'src/web/common/translate/useTranslate';

type ENTITY = EntitiesEnum.vehicle;
interface VehicleReturnAsset {
  dataAsset?: DynamicAssetDetailsReturn<ENTITY>;
}
const VehicleInformations: FC<VehicleReturnAsset> = ({ dataAsset }) => {
  const { timeService } = useCore();
  const { translate } = useTranslate();

  return (
    <>
      <Displayer innerSpacing={1}>
        <TextPair
          label={translate('vehicles.intoCirculationDate.label')}
          value={
            dataAsset?.intoCirculationDate
              ? timeService.format(timeService.fromBackend(dataAsset?.intoCirculationDate), 'short')
              : undefined
          }
        />
        <TextPair
          label={translate('vehicles.weightLimit.label')}
          value={dataAsset?.weightLimit ? `${dataAsset?.weightLimit} kg` : undefined}
        />
        <TextPair label={translate('vehicles.category.label')} value={dataAsset?.category} />
        <TextPair label={translate('vehicles.fuelType.label')} value={dataAsset?.fuelType} />
        <TextPair label={translate('vehicles.horsePower.label')} value={dataAsset?.horsePower} />
        <TextPair label={translate('vehicles.co2Emission.label')} value={dataAsset?.co2Emission} />
        <TextPair label={translate('vehicles.name.label')} value={dataAsset?.asset?.name} />
      </Displayer>
      <DividerDatas dashed />
      <Displayer innerSpacing={1}>
        <Text color={themeColors.blackColor} fontWeight={900} variant="sectionTitle">
          {translate('assets.asset.maintain.title')}
        </Text>
        <TextPair
          label={translate('vehicles.lastTechnicalCheckDate.label')}
          value={
            dataAsset?.lastTechnicalCheckDate
              ? timeService.format(
                  timeService.fromBackend(dataAsset?.lastTechnicalCheckDate),
                  'short',
                )
              : undefined
          }
        />
        <TextPair
          label={translate('vehicles.lastTechnicalServiceDate.label')}
          value={
            dataAsset?.lastTechnicalServiceDate
              ? timeService.format(
                  timeService.fromBackend(dataAsset?.lastTechnicalServiceDate ?? ''),
                  'short',
                )
              : undefined
          }
        />
      </Displayer>
      <DividerDatas dashed />

      <Displayer innerSpacing={1}>
        <Text color={themeColors.blackColor} fontWeight={900} variant="sectionTitle">
          {translate('assets.asset.contractualCommitment.title')}
        </Text>
        <DynamicFinancingFields dataAsset={dataAsset} />
      </Displayer>
      {dataAsset?.asset?.comment ? (
        <>
          <DividerDatas dashed />
          <Card.Basic title={translate('assets.asset.comment.title')} className={commentCardStyle}>
            <Card.Content compact>
              <Text variant="textSmall">{dataAsset.asset.comment}</Text>
            </Card.Content>
          </Card.Basic>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default VehicleInformations;
