import { FC, ReactNode, useMemo } from 'react';
import { useTheme } from 'src/web/common/theme';
import Displayer from 'src/web/design_system/Displayer';
import Text from 'src/web/design_system/Text';
import EmptyValue from 'src/web/components/EmptyValue';
import { nanoid } from 'nanoid';

interface DataDetailRow {
  label: string | ReactNode;
  value?: string | ReactNode;
}

interface DataDetailSectionProps {
  title?: string | ReactNode;
  data: DataDetailRow[];
}

const DataDetailSection: FC<DataDetailSectionProps> = ({ title, data }) => {
  const { theme } = useTheme();

  const identifiedData = useMemo(() => {
    return (data ?? []).map((item) => ({
      id: nanoid(),
      ...item,
    }));
  }, [data]);

  return (
    <Displayer innerSpacing={1}>
      {title && (
        <Text color={theme.colors.primaryTextColor} fontWeight={900} variant="sectionTitle">
          {title}
        </Text>
      )}
      {identifiedData.map((row) => (
        <Displayer layout={'row'} innerSpacing={3} key={row.id}>
          <Text color={theme.colors.secondaryTextColor} variant="textNormal">
            {row.label}
          </Text>
          <Text color={theme.colors.primaryTextColor} variant="textNormal">
            {row.value ?? <EmptyValue />}
          </Text>
        </Displayer>
      ))}
    </Displayer>
  );
};

export default DataDetailSection;
