import { keyframes } from '@emotion/css';
import { css, cx } from '@emotion/css';

/**
 * Agnostic to use oustide a component.
 * Return a className.
 */
export const createCss = (inlineCss: string) => {
  return css(inlineCss);
};

export const mergeCss = (...styles: (string | undefined)[]) => cx(styles);

export const animationCss = (inlineCss: string) => {
  return keyframes(inlineCss);
};
