import { EntitiesEnum, EntitiesRolesMap } from 'core/common/constants';
import ParametersService from 'core/services/parameters/parameters.service';
import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import VehicleEntity from 'core/entities/vehicle.entity';
import ComputerEntity from 'core/entities/computer.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ServiceEntity from 'core/entities/service.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import CardEntity from 'core/entities/card.entity';
import UserEntity from 'core/entities/user.entity';
import PersonEntity from 'core/entities/person.entity';
import TaskItemEntity from 'core/entities/taskItem.entity';
import CustomAssetEntity from 'core/entities/customAsset.entity';
import { SwaggerRequestResponse } from 'core/swagger';

export interface RequestOptions {
  filter?: Record<string, string[]>;
  sorter?: Record<string, string>;
  page?: string;
  itemsPerPage?: string;
  search?: string;
  query?: Record<string, string | string[]>;
}

// @todo: to remove to let Typescript infer the return type
export interface GetInventoryReturn<E extends EntitiesEnum> {
  data?: {
    [EntitiesEnum.vehicle]: {
      getAll: SwaggerRequestResponse<'/api/private/vehicles', 'get'>;
    };
    [EntitiesEnum.card]: {
      getAll: SwaggerRequestResponse<'/api/private/cards', 'get'>;
    };
    [EntitiesEnum.licence]: {
      getAll: SwaggerRequestResponse<'/api/private/licences', 'get'>;
    };
    [EntitiesEnum.computer]: {
      getAll: SwaggerRequestResponse<'/api/private/computers', 'get'>;
    };
    [EntitiesEnum.service]: {
      getAll: SwaggerRequestResponse<'/api/private/services', 'get'>;
    };
    [EntitiesEnum.telephone]: {
      getAll: SwaggerRequestResponse<'/api/private/telephones', 'get'>;
    };
    [EntitiesEnum.telephoneLine]: {
      getAll: SwaggerRequestResponse<'/api/private/telephone-lines', 'get'>;
    };
    [EntitiesEnum.customAsset]: {
      getAll: SwaggerRequestResponse<'/api/private/custom-assets', 'get'>;
    };
    [EntitiesEnum.person]: {
      getAll: SwaggerRequestResponse<'/api/private/persons', 'get'>;
    };
    [EntitiesEnum.user]: {
      getAll: SwaggerRequestResponse<'/api/private/users', 'get'>;
    };
    [EntitiesEnum.task]: {
      getAll: SwaggerRequestResponse<'/api/private/tasks-items', 'get'>;
    };
  }[E]['getAll'];
  filters: Record<string, string[]>;
  sorters: Record<string, string[]>;
  count: number;
}

class InventoryUseCase implements Usecase {
  constructor(
    private store: AppStore,
    private parametersService: ParametersService,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private licenceEntity: LicenceEntity,
    private serviceEntity: ServiceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private userEntity: UserEntity,
    private personEntity: PersonEntity,
    private customAssetEntity: CustomAssetEntity,
    private taskItemEntity: TaskItemEntity,
  ) {}

  getRoles<T extends EntitiesEnum>(params: T) {
    return EntitiesRolesMap[params];
  }

  isGranted<T extends EntitiesEnum>(params: T) {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles(params));
  }

  // map entity name to the accurate route params
  getInventoryRequestByName<N extends EntitiesEnum>(
    name: N,
  ): (opt?: RequestOptions) => Promise<undefined | GetInventoryReturn<N>> {
    return (options) => {
      switch (name) {
        case EntitiesEnum.vehicle:
          return this.getVehiclesInventory(options);
        case EntitiesEnum.computer:
          return this.getComputersInventory(options);
        case EntitiesEnum.licence:
          return this.getLicencesInventory(options);
        case EntitiesEnum.service:
          return this.getServicesInventory(options);
        case EntitiesEnum.telephone:
          return this.getTelephonesInventory(options);
        case EntitiesEnum.telephoneLine:
          return this.getTelephoneLinesInventory(options);
        case EntitiesEnum.card:
          return this.getCardsInventory(options);
        case EntitiesEnum.person:
          return this.getPersonsInventory(options);
        case EntitiesEnum.customAsset:
          return this.getCustomAssetsInventory(options);
        case EntitiesEnum.user:
          return this.getUsersInventory(options);
        case EntitiesEnum.task:
          return this.getTaskItemEntityInventory(options);
        default:
          return new Promise((resolve) => resolve(undefined));
      }
    };
  }

  /**
   * GET VEHICLES INVENTORY
   */
  public async getVehiclesInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.vehicleEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.vehicleEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.vehicleEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET COMPUTERS INVENTORY
   */
  public async getComputersInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.computerEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.computerEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.computerEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET LICENCES INVENTORY
   */
  public async getLicencesInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.licenceEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.licenceEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.licenceEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET SERVICES INVENTORY
   */
  public async getServicesInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.serviceEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.serviceEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.serviceEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET TELEPHONE INVENTORY
   */
  public async getTelephonesInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.telephoneEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.telephoneEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.telephoneEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET TELEPHONE LINES INVENTORY
   */
  public async getTelephoneLinesInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.telephoneLineEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.telephoneLineEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.telephoneLineEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET CARDS INVENTORY
   */
  public async getCardsInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.cardEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.cardEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.cardEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET CUSTOM ASSETS INVENTORY
   */
  public async getCustomAssetsInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
        'search[asset.model]': options.search,
      };
    }

    const response = await this.customAssetEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.customAssetEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.customAssetEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET USERS INVENTORY
   */
  public async getUsersInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[asset.identification]': options.search,
      };
      if (!options?.sorter) {
        query = {
          ...query,
          'order[username]': 'asc',
          'order[order]': 'asc',
        };
      }
    }

    const response = await this.userEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.userEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.userEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET PERSONS INVENTORY
   */
  public async getPersonsInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (!options?.sorter || !Object.keys(options.sorter).length) {
      query = {
        ...query,
        'order[lastname]': 'asc',
      };
    }

    if (options?.search) {
      query = {
        ...query,
        'search[firstname]': options.search,
        'search[lastname]': options.search,
        'search[identification]': options.search,
        'search[email]': options.search,
        'search[telephone]': options.search,
      };
    }

    const response = await this.personEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.personEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.personEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * GET TASK ITEMS INVENTORY
   */
  public async getTaskItemEntityInventory(options?: RequestOptions) {
    let query = this.formatOptionsToQueryParams(options);

    if (options?.search) {
      query = {
        ...query,
        'search[customFormAnswer.commonName]': options.search,
      };
      if (!options?.sorter) {
        query = {
          ...query,
          'order[deadline]': 'asc',
        };
      }
    }

    const response = await this.taskItemEntity.getAll({ query });
    const filters = await this.parametersService.getAndResolveFiltersList(
      this.taskItemEntity.getPathAll(),
      'get',
    );
    const sorters = this.parametersService.getAndResolveSortersList(
      this.taskItemEntity.getPathAll(),
      'get',
    );

    return {
      data: response.data,
      count: response.count,
      filters: filters,
      sorters: sorters,
    };
  }

  /**
   * Transform the InventoryUseCase 'options' parameters
   * into 'query' property from the HttpService RequestOption parameters
   */
  private formatOptionsToQueryParams(options?: RequestOptions) {
    let query: Record<string, string | string[] | undefined> = {
      ...options?.filter,
      ...options?.query,
      ...options?.sorter,
    };

    if (options?.page) {
      query = {
        ...query,
        page: options.page,
      };
    }

    if (options?.itemsPerPage) {
      query = {
        ...query,
        itemsPerPage: options.itemsPerPage,
      };
    }

    return query;
  }
}

export default InventoryUseCase;
