import { FC, useEffect, useRef } from 'react';
import useCreateSite from '../hooks/useCreateSite';
import Form from 'src/web/components/FormInput';
import Grid from 'src/web/design_system/Grid';
import Modal, { ModalProps } from 'src/web/components/Modal';
import Displayer from 'src/web/design_system/Displayer';
import Button from 'src/web/components/Button';
import FormContainer, { FormContainerRef } from 'src/web/design_system/FormContainer';
import useTranslate from 'src/web/common/translate/useTranslate';

interface AddSiteModalProps extends ModalProps {
  onSubmit: () => void;
}

const AddSiteModal: FC<AddSiteModalProps> = ({ open, onClose, onSubmit }) => {
  const { formatCountry, translate } = useTranslate();
  const formRef = useRef<FormContainerRef>(null);
  const { form, handleReset } = useCreateSite({
    afterSubmit: (status) => {
      if (status) {
        onSubmit();
        onClose();
      }
    },
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  // refresh the form when the modal close
  useEffect(() => {
    if (!open) {
      handleReset();
    }
  }, [open]);

  return (
    <FormContainer ref={formRef} onSubmit={(e) => form.handleSubmit(e)}>
      <Modal
        title={translate('attachmentSites.title')}
        open={open}
        onClose={onClose}
        footer={
          <Displayer layout="row" justifyContent="center" innerSpacing={3}>
            <Button.Custom onClick={() => onClose()} variant="outlined" fullWidth={false}>
              {translate('global.cancel.btn')}
            </Button.Custom>
            <Button.Custom
              onClick={() => handleSubmit()}
              fullWidth={false}
              disabled={!(form.isDirty && !form.hasErrors)}
            >
              {translate('global.add.button')}
            </Button.Custom>
          </Displayer>
        }
      >
        <Displayer innerSpacing={2}>
          <Form.TextAdapted
            item={form.items.name}
            label={translate('attachmentSites.name.label')}
          />

          <Form.TextAdapted
            item={form.items.location_streetName}
            label={translate('attachmentSites.location.streetName.label')}
          />

          <Form.TextAdapted
            item={form.items.location_streetNameExtra}
            label={translate('attachmentSites.location.streetNameExtra.label')}
          />

          <Grid.Container>
            <Grid.Item>
              <Form.TextAdapted
                item={form.items.location_postcode}
                label={translate('attachmentSites.location.postcode.label')}
              />
            </Grid.Item>
            <Grid.Item>
              <Form.TextAdapted
                item={form.items.location_city}
                label={translate('attachmentSites.location.city.label')}
              />
            </Grid.Item>
            <Grid.Item>
              <Form.SelectAdapted
                item={form.items.location_country}
                label={translate('attachmentSites.location.country.label')}
                renderValue={(value) => {
                  if (Array.isArray(value)) {
                    return value[0] ? formatCountry(value[0]) : '';
                  }
                  return value ? formatCountry(value) : '';
                }}
                renderOptionItem={(value) => ({
                  label: formatCountry(value),
                })}
              />
            </Grid.Item>
          </Grid.Container>
        </Displayer>
      </Modal>
    </FormContainer>
  );
};

export default AddSiteModal;
