import { useMemo, useState } from 'react';
import { useCore } from 'src/web/common/core';
import useCreateForm from 'src/web/common/form/useCreateForm';

const useEditAddress = () => {
  const { legalEntityUsecase } = useCore();
  const [isLoading, setIsLoading] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const { initialValues, properties, required, validate, submit } = useMemo(
    () => legalEntityUsecase.getUpdateLegalEntityForm(),
    [legalEntityUsecase],
  );

  const form = useCreateForm({
    initialValues,
    properties,
    required,
    validate,
    onFormChange: () => {
      setShowSubmit(true);
    },
    submit: async (data) => {
      setIsLoading(true);

      await submit(data);

      setShowSubmit(false);
      setIsLoading(false);
    },
  });

  const exposeOnlyNecessaryAddressFields = useMemo(() => {
    const filteredItems = {
      locationStreetName: form.items.locationStreetName,
      locationStreetNameBis: form.items.locationStreetNameBis,
      locationPostcode: form.items.locationPostcode,
      locationCity: form.items.locationCity,
      locationCountry: form.items.locationCountry,
    };

    return {
      ...form,
      items: filteredItems,
    };
  }, [form]);

  return {
    form: exposeOnlyNecessaryAddressFields,
    isLoading,
    showSubmit,
  };
};

export default useEditAddress;
