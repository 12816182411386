import { type FormItem } from 'src/web/common/form/types';
import useTranslate from 'src/web/common/translate/useTranslate';

function useFormComponent() {
  const { translate } = useTranslate();

  const formatFormMessage = (item: FormItem) => {
    if (item.errors) {
      return translate(`global.formError.${item.errors.keyword}.label`, item.errors.reasons);
    }
    return undefined;
  };

  return {
    formatFormMessage,
  };
}

export default useFormComponent;
