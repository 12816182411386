/**
 * Path join
 * @description adapted from community source.
 * @link https://stackoverflow.com/questions/29855098/is-there-a-built-in-javascript-function-similar-to-os-path-join
 */
export const join = (...args: (string | undefined)[]) => {
  return args
    .map((part, i) => {
      if (!part) {
        return '';
      } else if (i === 0) {
        return part.trim().replace(/[/]*$/g, '');
      } else {
        return part.trim().replace(/(^[/]*|[/]*$)/g, '');
      }
    })
    .filter((x) => x.length)
    .join('/');
};
