import { FC } from 'react';
import OptimizedInput, { OptimizedInputProps } from 'src/web/design_system/Input/Input';

export interface InputNumberProps extends Omit<OptimizedInputProps<'number'>, 'mode'> {
  className?: string;
}

const InputNumber: FC<InputNumberProps> = ({ className, ...props }) => {
  const { fullWidth, ...restProps } = props;

  return (
    <div className={className}>
      <OptimizedInput mode="number" {...restProps} fullWidth={fullWidth ?? true} />
    </div>
  );
};

export default InputNumber;
