import { Stack } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';
import { isBoolean, isNumber, isString } from 'core/common/utils/predicatesType';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg';

type ResponsiveValue<T> = T | Record<Breakpoints, T>;

interface DisplayerProps {
  hide?: ResponsiveValue<boolean>;
  layout?: ResponsiveValue<'row' | 'column'>;
  innerSpacing?: ResponsiveValue<number>;
  topSpacing?: ResponsiveValue<number>;
  flexWrap?: ResponsiveValue<'wrap' | 'nowrap'>;
  bottomSpacing?: ResponsiveValue<number>;
  justifyContent?: ResponsiveValue<
    'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  >;
  alignItems?: ResponsiveValue<
    'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | 'start' | 'end'
  >;
  alignContent?: ResponsiveValue<
    'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch'
  >;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

function applyOnResponsive<T, R = T>(
  obj: Record<Breakpoints, T>,
  callback?: (val: T) => R,
): Partial<Record<Breakpoints, R>> {
  let responsive: Partial<Record<Breakpoints, R>> = {};

  for (const k in obj) {
    const key: Breakpoints = k as Breakpoints;

    if (obj[key]) {
      responsive = {
        ...responsive,
        [key]: callback ? callback(obj[key]) : obj[key],
      };
    }
  }

  return responsive;
}

const Displayer: FC<PropsWithChildren<DisplayerProps>> = ({
  hide,
  innerSpacing,
  topSpacing,
  bottomSpacing,
  layout,
  justifyContent,
  alignItems,
  alignContent,
  children,
  fullHeight = false,
  flexWrap = 'wrap',
  fullWidth = true,
}) => {
  // props to display SX
  const display = useMemo(() => {
    const getValue = (state?: boolean) => (state ? 'none' : undefined);

    if (!hide) {
      return undefined;
    } else if (isBoolean(hide)) {
      return { display: getValue(hide) };
    } else {
      return { display: applyOnResponsive(hide, getValue) };
    }
  }, [hide]);

  // props to top margin SX
  const marginTop = useMemo(() => {
    if (!topSpacing) {
      return undefined;
    } else if (isNumber(topSpacing)) {
      return { marginTop: topSpacing };
    } else {
      return { marginTop: applyOnResponsive(topSpacing) };
    }
  }, [topSpacing]);

  // props to bottom margin SX
  const marginBottom = useMemo(() => {
    if (!bottomSpacing) {
      return undefined;
    } else if (isNumber(bottomSpacing)) {
      return { marginBottom: bottomSpacing };
    } else {
      return { marginBotton: applyOnResponsive(bottomSpacing) };
    }
  }, [bottomSpacing]);

  // props to spacing
  const childSpacing = useMemo(() => {
    if (!innerSpacing) {
      return undefined;
    } else if (isNumber(innerSpacing)) {
      return innerSpacing;
    } else {
      return applyOnResponsive(innerSpacing);
    }
  }, [innerSpacing]);

  // props to direction
  const flexDirection = useMemo(() => {
    if (!layout) {
      return undefined;
    } else if (isString(layout)) {
      return layout;
    } else {
      return applyOnResponsive(layout);
    }
  }, [layout]);

  // props to justify
  const justifyContentProps = useMemo(() => {
    if (!justifyContent) {
      return undefined;
    } else if (isString(justifyContent)) {
      return justifyContent;
    } else {
      return applyOnResponsive(justifyContent);
    }
  }, [justifyContent]);

  // props to vertical align
  const alignItemsProps = useMemo(() => {
    if (!alignItems) {
      return undefined;
    } else if (isString(alignItems)) {
      return alignItems;
    } else {
      return applyOnResponsive(alignItems);
    }
  }, [alignItems]);
  // props to vertical align
  const alignContentProps = useMemo(() => {
    if (!alignContent) {
      return undefined;
    } else if (isString(alignContent)) {
      return alignContent;
    } else {
      return applyOnResponsive(alignContent);
    }
  }, [alignContent]);

  // props to vertical align
  const flexWrapProps = useMemo(() => {
    if (!flexWrap) {
      return undefined;
    } else if (isString(flexWrap)) {
      return flexWrap;
    } else {
      return applyOnResponsive(flexWrap);
    }
  }, [flexWrap]);

  return (
    <Stack
      sx={{
        ...display,
        ...marginTop,
        ...marginBottom,
      }}
      spacing={childSpacing}
      direction={flexDirection}
      alignContent={alignContentProps}
      justifyContent={justifyContentProps}
      alignItems={alignItemsProps}
      flexWrap={flexWrapProps}
      useFlexGap
      height={fullHeight ? '100%' : undefined}
      width={fullWidth ? '100%' : 'auto'}
    >
      {children}
    </Stack>
  );
};

export default Displayer;
