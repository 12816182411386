import { FC, PropsWithChildren, ReactNode } from 'react';
import LibModal from 'src/web/design_system/Modal';
import ButtonIcon from 'src/web/design_system/Button/buttonIcon';
import Icon from 'src/web/components/Icon';
import Card from 'src/web/components/Card';
import Text from 'src/web/design_system/Text';
import { createCss } from 'src/web/common/styles/createCss';

export interface ModalProps {
  title?: ReactNode;
  footer?: ReactNode;
  open: boolean;
  className?: string;
  onClose: () => void;
}

const modalContainerStyle = createCss(`
  width: 500px;
  flex-shrink: 0;
`);

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  footer,
  children,
  open,
  className,
  onClose,
}) => {
  const hasFooter = !!footer;

  const closeButton = (
    <ButtonIcon onClick={onClose} size="small">
      <Icon.Close size="small" />
    </ButtonIcon>
  );

  return (
    <LibModal open={open} onClose={onClose} className={modalContainerStyle}>
      <Card.Container floatingActionContent={closeButton} className={className}>
        <Card.Toolbar>
          <Text variant="sectionTitle">{title}</Text>
        </Card.Toolbar>
        <Card.Content>{children}</Card.Content>
        {hasFooter && <Card.Footer>{footer}</Card.Footer>}
      </Card.Container>
    </LibModal>
  );
};

export default Modal;
