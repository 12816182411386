import { createCss } from 'src/web/common/styles/createCss';
import { Theme } from 'src/web/common/theme';

export const containerStyle = createCss(`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    min-height: 75vh;
`);

export const title = (theme: Theme) =>
  createCss(`
      color: ${theme.colors.primaryTextColor};
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
`);

export const descriptionLayout = createCss(`
    max-width: 379px;
    margin-bottom: 1.3rem;
`);

export const description = (theme: Theme) =>
  createCss(`
    color: ${theme.colors.secondaryTextColor};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
`);
