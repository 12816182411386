import CardContainer from './CardContainer';
import CardContent from './CardContent';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';
import CardImage from './CardImage';

export default {
  Container: CardContainer,
  Content: CardContent,
  Footer: CardFooter,
  Header: CardHeader,
  Image: CardImage,
};

/*
must use Card.container as a parent of all other Cards

<Card.Container>
  <Card.Header></Card.Header>
  <Card.Content></Card.Content>
  <Card.Footer></Card.Footer>
  <Card.Image></Card.Image>
</CardContainer>
*/
