import AppStore from 'core/services/store/store.service';
import { AppEventsNames } from 'core/common/constants';

export type AppEventsParams = {
  [AppEventsNames.OnUnauthorized]?: { message: string };
  [AppEventsNames.OnLogin]?: undefined;
  [AppEventsNames.OnLogout]?: { message: string };
  [AppEventsNames.OnServerError]?: { message: string };
};

export type AppEvents = {
  [Property in keyof AppEventsParams]: (args: AppEventsParams[Property]) => void;
};

export type Subscriptions = {
  [Property in keyof AppEvents]: {
    callback: AppEvents[Property];
    id: string;
  }[];
};

class EventService {
  private subscriptions: Subscriptions = {};

  private idSeparator = '___';

  constructor(private store: AppStore) {}

  subscribe<E extends keyof AppEvents>(eventName: E, callback: AppEvents[E]): string {
    const id = eventName + this.idSeparator + callback.toString();

    if (!this.subscriptions[eventName]) {
      this.subscriptions[eventName] = [];
    }

    this.subscriptions[eventName]?.push({
      callback,
      id,
    });

    return id;
  }

  unsubscribe(id: string) {
    const splitted = id.split(this.idSeparator);

    const eventName = splitted[0] as keyof Subscriptions;

    if (this.subscriptions[eventName]) {
      this.subscriptions = {
        ...this.subscriptions,
        [eventName]: this.subscriptions[eventName]?.filter(
          (subscription) => subscription.id !== id,
        ),
      };
    }
  }

  emit<E extends keyof AppEvents>(eventName: E, data?: AppEventsParams[E]) {
    if (this.subscriptions[eventName]) {
      this.subscriptions[eventName].forEach((subscription) => {
        if (subscription) {
          subscription.callback(data);
        }
      });
    }
  }
}

export default EventService;
