import { type ThemeOptions as MuiThemOptions, createTheme } from '@mui/material/styles';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CSSProperties } from '@mui/material/styles/createMixins';
import {
  GUI_PALETTE_ERROR,
  GUI_PALETTE_INFO,
  GUI_PALETTE_PRIMARY,
  GUI_PALETTE_SECONDARY,
  GUI_PALETTE_SUCCESS,
  GUI_PALETTE_WARNING,
} from './palettes';

// MODE
export type GuiThemeMode = 'light' | 'dark';

// Shadows (reduce shadows number because MUI insanely required 25 ones!)
type GuiShadows = [string, string, string, string];

// PALETTES
type GuiPaletteeKeys =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'A100'
  | 'A200'
  | 'A400'
  | 'A700'
  | 'contrast';

export type GuiPalette = Record<GuiPaletteeKeys, string>;

// TYPOGRAPHY
interface GuiTypographyOptions extends CSSProperties {
  fontSize: string;
  lineHeight: string;
}

export type GuiTypographyVariants =
  | 'pageTitle'
  | 'sectionTitle'
  | 'elementTitle'
  | 'textLarge'
  | 'textMedium'
  | 'textNormal'
  | 'textSmall'
  | 'textVerySmall'
  | 'textAction';

// BREAKPOINTS
export type GuiBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// THEME
export type GuiTheme = {
  palettes: {
    primary?: GuiPalette;
    secondary?: GuiPalette;
    error?: GuiPalette;
    warning?: GuiPalette;
    info?: GuiPalette;
    success?: GuiPalette;
    neutral?: GuiPalette;
  } & Record<string, GuiPalette>;
  colors: {
    black?: string;
    white?: string;
    mainBg?: string;
    contentBg?: string;
    primaryTextColor?: string;
    secondaryTextColor?: string;
    disabledTextColor?: string;
    activeAction?: string;
    hoverAction?: string;
    selectedAction?: string;
    disabledAction?: string;
    focusAction?: string;
  } & Record<string, string>;
  shape: {
    smallRadius?: number;
    defaultRadius?: number;
    containerPadding?: number;
  } & Record<string, string | number>;
  typography: {
    default: {
      fontFamily: string;
      fontSize: number;
      fontWeightNormal: number;
      fontWeightMedium: number;
      fontWeightBold: number;
    };
  } & Partial<Record<GuiTypographyVariants, GuiTypographyOptions>>;
  breakpoints: Record<GuiBreakpoints, number>;
  spacings: {
    factor: number;
  } & Record<string, number>;
  shadows: GuiShadows;
};

// THEME OPTION
export type GuiThemeConfig = Partial<GuiTheme>;

function expandShadows(shadows: GuiShadows): MuiThemOptions['shadows'] {
  return [
    'none',
    shadows[0],
    shadows[0],
    shadows[1],
    shadows[1],
    shadows[1],
    shadows[1],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[2],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
    shadows[3],
  ];
}

// Map GacUi theme to Mui theme
function createMuiThemeObject(mode: GuiThemeMode, guiTheme: GuiTheme): MuiThemOptions {
  const defaultTheme = createTheme({ palette: { mode } });

  return createTheme({
    spacing: guiTheme.spacings?.factor || defaultTheme.spacing,
    breakpoints: {
      values: {
        xs: guiTheme.breakpoints.xs || defaultTheme.breakpoints.values.xs,
        sm: guiTheme.breakpoints.sm || defaultTheme.breakpoints.values.sm,
        md: guiTheme.breakpoints.md || defaultTheme.breakpoints.values.md,
        lg: guiTheme.breakpoints.lg || defaultTheme.breakpoints.values.lg,
        xl: guiTheme.breakpoints.xl || defaultTheme.breakpoints.values.xl,
      },
    },
    palette: {
      primary: guiTheme.palettes.primary,
      secondary: guiTheme.palettes.secondary,
      error: guiTheme.palettes.error,
      warning: guiTheme.palettes.warning,
      info: guiTheme.palettes.info,
      success: guiTheme.palettes.success,
      mode: mode,
      background: {
        default: guiTheme.colors.mainBg,
        paper: guiTheme.colors.contentBg,
      },
      text: {
        primary: guiTheme.colors.primaryTextColor,
        secondary: guiTheme.colors.secondaryTextColor,
        disabled: guiTheme.colors.disabledTextColor || defaultTheme.palette.text.disabled,
      },
      action: {
        active: guiTheme.colors.activeAction || defaultTheme.palette.action.active,
        hover: guiTheme.colors.hoverAction || defaultTheme.palette.action.hover,
        selected: guiTheme.colors.selectedAction || defaultTheme.palette.action.selected,
        disabled: guiTheme.colors.disabledAction || defaultTheme.palette.action.disabled,
        focus: guiTheme.colors.focusAction || defaultTheme.palette.action.focus,
      },
    },
    shape: {
      borderRadius: guiTheme.shape?.defaultRadius || defaultTheme.shape.borderRadius,
    },

    typography: {
      fontFamily: guiTheme.typography.default.fontFamily || defaultTheme.typography.fontFamily,
      fontSize: guiTheme.typography.default.fontSize || defaultTheme.typography.fontSize,
      fontWeightRegular:
        guiTheme.typography.default.fontWeightNormal || defaultTheme.typography.fontWeightRegular,
      fontWeightMedium:
        guiTheme.typography.default.fontWeightMedium || defaultTheme.typography.fontWeightMedium,
      fontWeightBold:
        guiTheme.typography.default.fontWeightBold || defaultTheme.typography.fontWeightBold,
      h1: guiTheme.typography.pageTitle,
      h2: guiTheme.typography.sectionTitle,
      h3: guiTheme.typography.elementTitle,
      h4: guiTheme.typography.textLarge,
      h5: guiTheme.typography.textMedium,
      h6: guiTheme.typography.textMedium,
      subtitle1: guiTheme.typography.textLarge,
      subtitle2: guiTheme.typography.textMedium,
      body1: guiTheme.typography.textNormal,
      body2: guiTheme.typography.textSmall,
      caption: guiTheme.typography.textVerySmall,
      button: guiTheme.typography.textAction,
    },
    shadows: guiTheme.shadows ? expandShadows(guiTheme.shadows) : defaultTheme.shadows,
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: guiTheme.shape.containerPadding,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: guiTheme.shape.containerPadding,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: 'color: red;',
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: 'translate(14px, 10px) scale(1)',
          },
          shrink: {
            transform: 'translate(14px, -8px) scale(0.75)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          multiline: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
          },
          input: {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      },
    },
  });
}

const DEFAULT_GUI_THEME: GuiTheme = {
  palettes: {
    primary: GUI_PALETTE_PRIMARY,
    secondary: GUI_PALETTE_SECONDARY,
    error: GUI_PALETTE_ERROR,
    warning: GUI_PALETTE_WARNING,
    info: GUI_PALETTE_INFO,
    success: GUI_PALETTE_SUCCESS,
  },
  colors: {},
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  shape: {
    smallRadius: 12,
    defaultRadius: 12,
    containerPadding: 16,
  },
  typography: {
    default: {
      fontFamily: 'inherit',
      fontSize: 16,
      fontWeightNormal: 400,
      fontWeightMedium: 500,
      fontWeightBold: 900,
    },
  },
  spacings: {
    factor: 8,
  },
  shadows: [
    '0px 2px 1px -1px #919eab33,0px 1px 1px 0px #919eab24,0px 1px 3px 0px #919eab1f',
    '0px 3px 5px -1px #919eab33,0px 6px 10px 0px #919eab24,0px 1px 18px 0px #919eab1f',
    '0px 7px 8px -4px #919eab33,0px 12px 17px 2px #919eab24,0px 5px 22px 4px #919eab1f',
    '0px 9px 11px -5px #919eab33,0px 18px 28px 2px #919eab24,0px 7px 34px 6px #919eab1f',
  ],
};

// Unsure to build a complete GUI theme
function createGuiThemObject(providedTheme: GuiThemeConfig): GuiTheme {
  return {
    palettes: {
      ...DEFAULT_GUI_THEME.palettes,
      ...providedTheme.palettes,
    },
    colors: {
      ...providedTheme.colors,
    },
    breakpoints: {
      ...DEFAULT_GUI_THEME.breakpoints,
      ...providedTheme.breakpoints,
    },
    shape: {
      ...DEFAULT_GUI_THEME.shape,
      ...providedTheme.shape,
    },
    typography: {
      ...DEFAULT_GUI_THEME.typography,
      ...providedTheme.typography,
      default: {
        ...DEFAULT_GUI_THEME.typography.default,
        ...providedTheme.typography?.default,
      },
    },
    spacings: {
      ...DEFAULT_GUI_THEME.spacings,
      ...providedTheme.spacings,
    },
    shadows: providedTheme.shadows ?? DEFAULT_GUI_THEME.shadows,
  };
}

// REACT THEME CONTEXT
interface GuiThemeContextType {
  theme: GuiTheme;
  changeThemeMode: (mode: GuiThemeMode) => void;
}

const GuiThemeContext = createContext<GuiThemeContextType>({
  theme: DEFAULT_GUI_THEME,
  changeThemeMode: () => {},
});

// THEME PROVIDER
interface GuiThemeProviderProps {
  children: ReactNode;
  defaultMode: GuiThemeMode;
  themeOptions: Record<GuiThemeMode, GuiThemeConfig>;
  currentLocale?: string;
}

const GuiThemeProvider: FC<PropsWithChildren<GuiThemeProviderProps>> = ({
  children,
  themeOptions,
  defaultMode = 'light',
  currentLocale = 'fr',
}) => {
  const [mode, setMode] = useState<GuiThemeMode>(defaultMode);

  // build GUI theme from partial config
  const currentGuiThemeObject = useMemo(() => createGuiThemObject(themeOptions[mode]), [mode]);

  // build MUI theme from GacUi theme
  const libTheme = useMemo(() => createMuiThemeObject(mode, currentGuiThemeObject), [mode]);

  // handle toggle theme mode
  const changeThemeMode = (nextMode: GuiThemeMode) => {
    setMode(nextMode);
  };

  // currently only one mode is used
  const internalTheme: GuiThemeContextType = useMemo(
    () => ({
      theme: currentGuiThemeObject,
      changeThemeMode,
    }),
    [mode, themeOptions],
  );

  return (
    <GuiThemeContext.Provider value={internalTheme}>
      <MUIThemeProvider theme={libTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale}>
          {children}
        </LocalizationProvider>
      </MUIThemeProvider>
    </GuiThemeContext.Provider>
  );
};

/**
 * Theme context hook
 */
export const useTheme = () => useContext(GuiThemeContext);

export default GuiThemeProvider;
