import { FC, PropsWithChildren } from 'react';
import { CardActions as MuiCardActions } from '@mui/material';

interface CardFooterProps {
  className?: string;
}

const CardFooter: FC<PropsWithChildren<CardFooterProps>> = ({ children, className }) => {
  return <MuiCardActions className={className}>{children}</MuiCardActions>;
};

export default CardFooter;
