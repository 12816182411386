import { FC, PropsWithChildren, useMemo } from 'react';
import Grid from '@mui/material/Grid';

type GridSpacing = 'none' | 'small' | 'default' | 'large';

interface GridContainerProps {
  className?: string;
  spacing?: GridSpacing;
  hSpacing?: GridSpacing;
  vSpacing?: GridSpacing;
  canWrap?: boolean;
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'stretch';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'strech' | 'baseline';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-evenly'
    | 'space-between';
  direction?: 'row' | 'column';
  fullHeight?: boolean;
}

const spacingToMuiSize = (spacing?: GridSpacing): number | undefined => {
  switch (spacing) {
    case 'none':
      return undefined;
    case 'small':
      return 1;
    case 'default':
      return 3;
    case 'large':
      return 6;
    default:
      return undefined;
  }
};

const GridContainer: FC<PropsWithChildren<GridContainerProps>> = ({
  children,
  className,
  hSpacing = 'default',
  vSpacing = 'default',
  spacing,
  canWrap = true,
  alignContent,
  alignItems,
  justify,
  direction = 'row',
  fullHeight,
}) => {
  const spacingProps = useMemo(() => {
    if (spacing) {
      return {
        spacing: spacingToMuiSize(spacing),
      };
    }

    let spacingObj = {};

    if (hSpacing) {
      spacingObj = {
        ...spacingObj,
        columnSpacing: spacingToMuiSize(hSpacing),
      };
    }

    if (vSpacing) {
      spacingObj = {
        ...spacingObj,
        rowSpacing: spacingToMuiSize(vSpacing),
      };
    }

    return spacingObj;
  }, [spacing, hSpacing, vSpacing]);

  return (
    <Grid
      container
      className={className}
      {...spacingProps}
      wrap={canWrap ? 'wrap' : 'nowrap'}
      alignItems={alignItems}
      alignContent={alignContent}
      justifyContent={justify}
      direction={direction}
      height={fullHeight ? '100%' : undefined}
    >
      {children}
    </Grid>
  );
};

export default GridContainer;
