import { FC } from 'react';
import { type FormItem } from 'src/web/common/form/types';
import { type InputTextProps } from './InputText';
import useFormComponent from './useFormComponent';
import InputPassword from './InputPassword';

interface FormPasswordProps
  extends Omit<
    InputTextProps,
    | 'value'
    | 'min'
    | 'max'
    | 'error'
    | 'message'
    | 'onChange'
    | 'isRequired'
    | 'pattern'
    | 'type'
    | 'endExtra'
  > {
  item: FormItem;
}

const FormPassword: FC<FormPasswordProps> = ({ item, ...props }) => {
  if (!item) {
    console.warn('can not render input', props, item);
    return <></>;
  }
  const { ...restProps } = props;
  const { formatFormMessage } = useFormComponent();
  const value = item.formValue ? String(item.formValue) : '';

  return (
    <InputPassword
      value={value}
      isRequired={item.required}
      error={!!item.errors}
      min={item.minLength}
      max={item.maxLength}
      message={formatFormMessage(item)}
      onChange={(val) => item.handleChange(val)}
      {...restProps}
    />
  );
};

export default FormPassword;
