export function kebabCaseTocamelCase(value: string): string {
  return value
    .split('-')
    .map((chunk, index) => {
      const firstLetter =
        index === 0 ? chunk.charAt(0).toLowerCase() : chunk.charAt(0).toUpperCase();
      return firstLetter.concat(chunk.slice(1));
    })
    .join('');
}

export function pluralize(value: string): string {
  if (value.slice(-1) !== 's') {
    return value.concat('s');
  }

  return value;
}

export function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}
