import { FC } from 'react';
import Card from 'src/web/components/Card';
import Text from 'src/web/design_system/Text';
import Chip from 'src/web/design_system/Chip';
import Translate from 'src/web/common/translate/translate.component';
import useTranslate from 'src/web/common/translate/useTranslate';
import { filterSectionStyle } from './style';

interface InventoryCardCurrentFiltersProps {
  activeFilters: string[];
  onClearFilter: (name: string) => void;
  verticalPadding?: boolean;
}

const InventoryCardCurrentFilters: FC<InventoryCardCurrentFiltersProps> = ({
  activeFilters,
  onClearFilter,
  verticalPadding = false,
}) => {
  const { translate } = useTranslate();
  return (
    <Card.Content disablePaddingX={verticalPadding} disablePaddingY={verticalPadding}>
      <div className={filterSectionStyle}>
        {activeFilters.length === 0 ? (
          <Text variant="textSmall">
            <Translate id="global.inventory.filters.current.empty.label" />
          </Text>
        ) : (
          activeFilters.map((activeFilter) => (
            <Chip
              key={activeFilter}
              label={translate(`assets.filter.${activeFilter}.label`)}
              onDelete={() => onClearFilter(activeFilter)}
            />
          ))
        )}
      </div>
    </Card.Content>
  );
};

export default InventoryCardCurrentFilters;
