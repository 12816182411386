import { useCallback, useEffect, useMemo, useState } from 'react';
import { RolesEnum } from 'src/core/common/constants';
import { useCore } from 'src/web/common/core';
import { downloadFileFromBlob } from 'src/web/common/download';
import useGrantRole from 'src/web/common/grant/useGrantRole';
import useTranslate from 'src/web/common/translate/useTranslate';

const useAssignPersonToAssets = (personIri: string) => {
  const { assignmentUseCase, timeService } = useCore();
  const { translate } = useTranslate();
  // get allowed assets
  let allowedAssets = useMemo(() => assignmentUseCase.getAllowedAsset(), []);

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE_LINE))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'telephone-lines');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_TELEPHONE))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'telephones');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_VEHICLE))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'vehicles');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_SERVICE))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'services');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_LICENCE))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'licences');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_CARD))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'cards');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_COMPUTER))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'computers');

  if (!useGrantRole(RolesEnum.ROLE_ADMIN_CUSTOM_ASSET))
    allowedAssets = allowedAssets.filter((asset) => asset !== 'custom-assets');

  const { submit } = useMemo(() => assignmentUseCase.createAssignment(), []);

  //
  const [isLoading, setIsLoading] = useState(false);

  // store all assets data
  const [assetsList, setAssetsList] = useState<
    Record<
      (typeof allowedAssets)[number],
      Awaited<ReturnType<typeof assignmentUseCase.searchAsset>>
    >
  >(() => {
    const mapping = allowedAssets.map((asset) => [asset, []]);
    return Object.fromEntries(mapping);
  });

  // store selected assets ids
  const [selectedAssets, setSelectedAssets] = useState<
    Record<(typeof allowedAssets)[number], string[]>
  >(() => {
    const mapping = allowedAssets.map((asset) => [asset, []]);
    return Object.fromEntries(mapping);
  });

  // control start date input
  const [startDate, setStartDate] = useState<Date>(timeService.now());

  // control end date input
  const [endDate, setEndDate] = useState<Date | null>(null);

  // control include pending assets input
  const [includePendingAssets, setIncludePendingAssets] = useState<boolean>(false);

  // control generate certificate input
  const [generateCertificate, setGenerateCertificate] = useState<boolean>(false);

  // search available assets
  const handleSearchAsset = async (
    asset: (typeof allowedAssets)[number],
    value: string,
    shouldLoad = false,
  ) => {
    if (shouldLoad) {
      setIsLoading(true);
    }

    const currentStartAt = timeService.toBackend(startDate);
    const currentEndAt = endDate ? timeService.toBackend(endDate) : undefined;
    const currentIncludePendingAssets = includePendingAssets;

    const response = await assignmentUseCase.searchAsset(
      asset,
      value,
      currentIncludePendingAssets,
      currentStartAt,
      currentEndAt,
    );

    setAssetsList((currentLists) => ({
      ...currentLists,
      [asset]: response,
    }));

    if (shouldLoad) {
      setIsLoading(false);
    }
  };

  // persist selected assets ids
  const handleChangeSelectedAssets = (asset: (typeof allowedAssets)[number], ids?: string[]) => {
    if (ids) {
      setSelectedAssets((currentSelectedAssets) => ({
        ...currentSelectedAssets,
        [asset]: ids,
      }));
    }
  };

  // from object to array
  const reduceSelectedIds = useCallback((): string[] => {
    const ids: string[] = [];

    Object.keys(selectedAssets).map((key) => {
      selectedAssets[key as keyof typeof selectedAssets].forEach((id) => ids.push(id));
    });

    return ids;
  }, [selectedAssets]);

  const handleGetAssignmentCertificate = async (
    startAt: string,
    person: string,
    assignmentIds: string[],
  ) => {
    const downloadBlob = await assignmentUseCase.getCertificateDownLoadBlob(
      startAt,
      person,
      assignmentIds,
      'assetAssignments',
    );

    if (downloadBlob) {
      downloadFileFromBlob(downloadBlob, translate('asset.assetAssignment.delevery.fileName'));
    }
  };

  // submit the form
  const handleSubmit = async () => {
    setIsLoading(true);
    const ids = reduceSelectedIds();
    const assignmentIds: string[] = [];
    const baseData = {
      startAt: timeService.toBackend(startDate ?? timeService.now()),
      endAt: endDate ? timeService.toBackend(endDate) : undefined,
      person: personIri,
    };

    const resquests = ids.map((id) => {
      return submit({
        ...baseData,
        asset: id,
      });
    });

    return Promise.all(resquests)
      .then((responses: (string | undefined)[]) => {
        responses.forEach((response) => {
          if (response) {
            assignmentIds.push(response);
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
        // generate certificate
        if (generateCertificate && assignmentIds && assignmentIds.length > 0) {
          handleGetAssignmentCertificate(baseData.startAt, baseData.person, assignmentIds);
        }
      });
  };

  // reload when form changes
  useEffect(() => {
    Object.keys(assetsList).map((assetKey) =>
      handleSearchAsset(assetKey as keyof typeof assetsList, '', true),
    );
  }, [includePendingAssets, startDate, endDate]);

  return {
    form: {
      startDate: {
        value: startDate,
        onChange: setStartDate,
      },
      endDate: {
        value: endDate,
        onChange: setEndDate,
      },
      includePendingAssets: {
        value: includePendingAssets,
        onChange: setIncludePendingAssets,
      },
      generateCertificate: {
        value: generateCertificate,
        onChange: setGenerateCertificate,
      },
    },
    assetsList,
    isLoading,
    handleSubmit,
    handleChangeSelectedAssets,
    handleSearchAsset,
  };
};

export default useAssignPersonToAssets;
