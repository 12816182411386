import { FC } from 'react';
import Card from 'src/web/components/Card';
import Pagination from 'src/web/components/Pagination';

interface InventoryCardPaginationProps {
  paginationCount: number;
  paginationCurrent?: number;
  paginationPerPage?: number;
  paginationChunks?: number[];
  onPaginationChange: (value: number) => void;
  onPaginationChunkChange: (value: number) => void;
}

const InventoryCardPagination: FC<InventoryCardPaginationProps> = ({
  paginationCount,
  paginationCurrent = 1,
  paginationPerPage = 10,
  paginationChunks = [10, 25, 50],
  onPaginationChange,
  onPaginationChunkChange,
}) => {
  return (
    <Card.Footer align="center">
      <Pagination
        count={paginationCount}
        current={paginationCurrent}
        itemsPerPage={paginationPerPage}
        onChangeItemPerPage={onPaginationChunkChange}
        itemsPerPageItems={paginationChunks}
        onChange={onPaginationChange}
      />
    </Card.Footer>
  );
};

export default InventoryCardPagination;
