import { isUserGranted } from 'core/common/utils/grant';
import AppStore from 'core/services/store/store.service';
import { Usecase } from 'core/.framework/usecase.abstract';
import FormService from 'core/services/form/form.service';
import { EntitiesEnum } from 'core/common/constants';
import TimeService from 'core/services/time/time.service';
import { getIdFromIri, parseIri } from 'core/common/utils/id';
import { transformCurlyPath } from 'src/core/common/utils/transformCurlyPath';
import PersonEntity from 'core/entities/person.entity';
import AssetAssignmentEntity from 'core/entities/assetAssignment.entity';
import PdfRenderEntity from 'core/entities/pdfRender.entity';
import { isString } from 'core/common/utils/predicatesType';
import CardEntity from 'core/entities/card.entity';
import TelephoneLineEntity from 'core/entities/telephoneLine.entity';
import TelephoneEntity from 'core/entities/telephone.entity';
import ServiceEntity from 'core/entities/service.entity';
import LicenceEntity from 'core/entities/licence.entity';
import ComputerEntity from 'core/entities/computer.entity';
import VehicleEntity from 'core/entities/vehicle.entity';
import CustomAssetEntity from 'core/entities/customAsset.entity';
import HttpService from 'core/services/api/http.service';

type AllowedEntities =
  | EntitiesEnum.card
  | EntitiesEnum.computer
  | EntitiesEnum.licence
  | EntitiesEnum.service
  | EntitiesEnum.telephone
  | EntitiesEnum.telephoneLine
  | EntitiesEnum.vehicle
  | EntitiesEnum.customAsset;

class AssignmentUseCase implements Usecase {
  private currentOne: Record<string, unknown> = {};

  constructor(
    private store: AppStore,
    private http: HttpService,
    private formService: FormService,
    private timeService: TimeService,
    private personEntity: PersonEntity,
    private assetAssignmentEntity: AssetAssignmentEntity,
    private pdfRenderEntity: PdfRenderEntity,
    private vehicleEntity: VehicleEntity,
    private computerEntity: ComputerEntity,
    private serviceEntity: ServiceEntity,
    private licenceEntity: LicenceEntity,
    private telephoneEntity: TelephoneEntity,
    private telephoneLineEntity: TelephoneLineEntity,
    private cardEntity: CardEntity,
    private customAssetEntity: CustomAssetEntity,
  ) {}

  getRoles() {
    return [];
  }

  isGranted() {
    const currentUser = this.store.getState((state) => state.user.current);

    return isUserGranted(currentUser, this.getRoles());
  }

  /**
   * ALLOWED ASSET FROM ENTITIES
   */
  getAllowedAsset(): AllowedEntities[] {
    return [
      EntitiesEnum.vehicle,
      EntitiesEnum.computer,
      EntitiesEnum.telephone,
      EntitiesEnum.telephoneLine,
      EntitiesEnum.licence,
      EntitiesEnum.service,
      EntitiesEnum.card,
      EntitiesEnum.customAsset,
    ];
  }

  /**
   * CREATE ASSIGNMENT
   */
  createAssignment() {
    const { properties, required, validate, getValues } = this.formService.createForm(
      this.assetAssignmentEntity.getPathAll(),
      'post',
    );

    const handleSubmit = async <D>(data: D): Promise<string | undefined> => {
      try {
        const copyData = { ...getValues(data) };

        // update the selected startAt date to reset the time
        // so assignment will start at 00 hour and ease assignment calculations
        if (isString(copyData.startAt)) {
          const startDate = this.timeService.fromBackend(copyData.startAt);
          const adjustedTime = this.timeService.initTime(startDate, 'day');
          copyData.startAt = this.timeService.toBackend(adjustedTime);
        }

        const assignment = await this.assetAssignmentEntity.post({
          body: copyData,
        });

        const assignmentIri = assignment?.data?.['@id'];

        if (assignmentIri) {
          return parseIri(assignmentIri).pathParts.slice(-1)[0];
        }

        return undefined;
      } catch (error) {
        //TODO: do something like broadcasting an event
        console.warn('error: ', error);
        return undefined;
      }
    };

    return {
      properties,
      required,
      validate,
      submit: handleSubmit,
    };
  }

  /**
   * REQUEST ASSIGNMENT CERTIFICATE
   */
  public getCertificateDownLoadBlob = async (
    startDate: string,
    personIri: string,
    assetsIds: string[],
    restituteType: 'assetAssignments' | 'assetReturns',
  ): Promise<Blob | undefined> => {
    const currentLocale = this.store.getState((state) => state.user.current?.preferredLanguage);

    const pdfResponse = await this.pdfRenderEntity.post({
      pathVar: {
        id: restituteType,
      },
      query: {
        returnId: true,
      },
      body: {
        personId: getIdFromIri(personIri),
        assetAssignmentIds: assetsIds,
        assignmentDate: startDate,
        locale: currentLocale,
      },
      //
    });

    if (isString(pdfResponse.data)) {
      const downloadUrl = transformCurlyPath(this.pdfRenderEntity.getPathDownload(), {
        id: pdfResponse.data,
      });

      const blobResponse = await this.http.request<Blob>({
        url: downloadUrl,
        method: 'get',
        responseType: 'blob',
        contentType: 'application/ld+json',
      });

      if (blobResponse.data) {
        return blobResponse.data;
      }
    }

    return undefined;
  };

  /**
   * UPDATE ASSIGNMENT
   */
  getAssignAssetUpdateForm(assignmentId: string) {
    const { properties, required, validate } = this.formService.createForm(
      this.assetAssignmentEntity.getPathOne(),
      'put',
    );

    const getInitialValues = async () => {
      let initialValues = {};

      const currentAssetData = await this.assetAssignmentEntity
        .getOne({
          pathVar: {
            id: parseIri(assignmentId).pathParts.slice(-1)[0],
          },
        })
        .then((response) => response?.data);

      if (typeof currentAssetData === 'object') {
        this.currentOne = this.formService.formatData(currentAssetData);

        Object.keys(properties).forEach((propkey) => {
          if (propkey in this.currentOne) {
            initialValues = {
              ...initialValues,
              [propkey]: this.currentOne[propkey],
            };
          }
        });
      }

      initialValues = {
        ...initialValues,
      };

      return initialValues;
    };

    const handleSubmit = async (data: object) => {
      const copyData: Record<string, unknown> = {};

      Object.keys(data).forEach((key) => {
        const value = data[key as keyof typeof data];
        if (value) {
          copyData[key] = value;
        }
      });
      const formattedData = this.formService.unformatData({ ...this.currentOne, ...copyData });

      return this.assetAssignmentEntity
        .put({
          pathVar: {
            id: parseIri(assignmentId).pathParts.slice(-1)[0],
          },
          body: {
            startAt: formattedData.startAt,
            endAt: formattedData.endAt,
          },
        })
        .then((response) => !!response.data);
    };

    return {
      properties,
      getInitialValues: () => getInitialValues(),
      required,
      validate,
      submit: handleSubmit,
    };
  }
  /**
   * GET BOARDING CURRENT ASSIGNMENT
   */

  public async getBoardingPersonCurrentAssigments(iri: string) {
    return this.assetAssignmentEntity
      .getAll({
        query: {
          'person.id': iri,
          isAssigned: true,
          'order[startAt]': 'asc',
        },
      })
      .then((response) => {
        return (response.data ?? []).map((assignment) => {
          // Because the backend data structure is not well done
          let assetId = '';
          if (assignment.asset.assetType && assignment.asset.assetType in assignment.asset) {
            const asset = assignment.asset[
              assignment.asset.assetType as keyof typeof assignment.asset
            ] as Record<'id', string | undefined>;

            assetId = asset.id ?? '';
          }

          return {
            ...assignment,
            asset: {
              id: assetId,
              identification: assignment.asset.identification,
              model: assignment.asset.model,
              assetType: assignment.asset.assetType,
              manufacturer: assignment.asset.manufacturer,
              category: assignment.asset.category,
              service: {
                name: assignment.asset.service?.name,
                provider: assignment.asset.service?.provider,
              },
              telephoneLine: {
                lineNumber: assignment.asset.telephoneLine?.lineNumber,
              },
            },
          };
        });
      });
  }

  /**
   * GET ASSIGNMENT
   */
  public async getPersonAssigments(
    iri: string,
    isBefore: boolean,
    isAfter: boolean,
    isCurrent: boolean,
    isAll: boolean,
    itemsPerPage?: number,
  ) {
    const strictlyAfter =
      isAfter && !isAll ? this.timeService.toBackend(this.timeService.now()) : '';
    const strictlyBefore =
      isBefore && !isAll ? this.timeService.toBackend(this.timeService.now()) : '';

    return this.assetAssignmentEntity
      .getAll({
        query: {
          'person.id': iri,
          'order[startAt]': 'desc' as const,
          'endAt[strictly_before]': strictlyBefore,
          'startAt[strictly_after]': strictlyAfter,
          itemsPerPage: itemsPerPage,
          isAssigned: isCurrent && !isAll ? true : undefined,
        },
      })
      .then((response) => {
        return (response.data ?? []).map((assignment) => {
          // Because the backend data structure is not well done
          let assetId = '';
          if (assignment.asset.assetType && assignment.asset.assetType in assignment.asset) {
            const asset = assignment.asset[
              assignment.asset.assetType as keyof typeof assignment.asset
            ] as Record<'id', string | undefined>;

            assetId = asset.id ?? '';
          }

          return {
            ...assignment,
            asset: {
              id: assetId,
              identification: assignment.asset.identification,
              model: assignment.asset.model,
              assetType: assignment.asset.assetType,
              manufacturer: assignment.asset.manufacturer,
              category: assignment.asset.category,
              service: {
                // MKU:
                // name: assignment.asset.service?.name as string,
                // provider: assignment.asset.service?.provider as string,
                name: assignment.asset.service?.name,
                provider: assignment.asset.service?.provider,
              },
              telephoneLine: {
                lineNumber: assignment.asset.telephoneLine?.lineNumber,
              },
            },
          };
        });
      });
  }

  /**
   *
   * ASSET TO PERSON
   *
   * To replace by the global method 'createAssignment'
   */
  public assignAssetToPerson(assetId: string) {
    const { properties, required, validate } = this.formService.createForm(
      this.assetAssignmentEntity.getPathAll(),
      'post',
    );

    const initialValues = { asset: assetId, endAt: null };

    const handleSubmit = (data: object) => {
      return this.assetAssignmentEntity
        .post({
          body: this.formService.unformatData(data),
        })
        .then((response) => response.data);
    };

    const handleSearch = (value: string, currentOnly: boolean) =>
      this.searchPersons(value, currentOnly);

    return {
      properties,
      getInitialValues: () => initialValues,
      required,
      validate,
      handleSearch,
      submit: handleSubmit,
    };
  }

  /**
   * SEARCH ASSET
   */
  public async searchAsset(
    asset: AllowedEntities,
    value: string,
    includePending: boolean,
    from: string,
    to?: string,
  ) {
    const statusToSearch: string[] = includePending ? ['active', 'pending'] : ['active'];

    const options = {
      query: {
        'search[asset.identification]': value,
        'search[asset.model]': value,
        'unreserved[asset.assetAssignments.startAt]': from,
        'unreserved[asset.assetAssignments.endAt]': to,
        'asset.status[]': statusToSearch,
      },
    };

    switch (asset) {
      case EntitiesEnum.vehicle:
        return this.vehicleEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.model) {
                chunks.push(item.asset.model);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join('\t'),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.computer:
        return this.computerEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.category) {
                chunks.push(item.asset.category);
              }
              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.telephone:
        return this.telephoneEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.model) {
                chunks.push(item.asset.model);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.telephoneLine:
        return this.telephoneLineEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.lineNumber) {
                chunks.push(item.lineNumber);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.service:
        return this.serviceEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.name) {
                chunks.push(item.name);
              }
              if (item.provider) {
                chunks.push(item.provider);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.card:
        return this.cardEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.category) {
                chunks.push(item.asset?.category);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.licence:
        return this.licenceEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
      case EntitiesEnum.customAsset:
        return this.customAssetEntity.getAll(options).then((response) => {
          if (response?.data) {
            return response.data.map((item) => {
              const chunks: string[] = [];

              if (item.asset?.category) {
                chunks.push(item.asset.category);
              }
              if (item.asset?.manufacturer) {
                chunks.push(item.asset.manufacturer);
              }
              if (item.asset?.identification) {
                chunks.push(item.asset.identification);
              }

              return {
                iri: item.asset?.['@id'] ?? '',
                name: chunks.join(' '),
              };
            });
          }
          return [];
        });
    }
  }

  /**
   * SEARCH PERSON
   */
  private async searchPersons(value: string, currentOnly: boolean) {
    const statusToSearch: string[] = currentOnly
      ? ['employee']
      : ['employee', 'onBoardingEmployee'];

    return this.personEntity
      .getAll({
        query: {
          'search[firstname]': value,
          'search[lastname]': value,
          'status[]': statusToSearch,
        },
      })
      .then((response) => response?.data ?? []);
  }
}

export default AssignmentUseCase;
