import {
  FC,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton, Popover } from '@mui/material';
import { HorizontalPopoverValues } from '../Popover';

interface PopoverMenuProps {
  icon: ReactNode;
  iconActive?: ReactNode;
  horizontal?: HorizontalPopoverValues;
  isOpen?: boolean;
  title?: string;
  onChange?: (state: boolean) => void;
}

const PopoverMenu: FC<PropsWithChildren<PopoverMenuProps>> = ({
  icon,
  iconActive,
  horizontal = 'center',
  children,
  isOpen = false,
  title,
  onChange,
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: SyntheticEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
    if (onChange) {
      onChange(true);
    }
  };

  const handleClose = () => {
    setOpen(null);
    if (onChange) {
      onChange(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen ? ref.current : null);
  }, [isOpen]);

  return (
    <>
      <IconButton onClick={handleOpen} ref={ref} title={title}>
        {!open && !!iconActive ? iconActive : icon}
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontal,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontal,
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default PopoverMenu;
