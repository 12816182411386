import { GridColDef, GridLocaleText } from '@mui/x-data-grid';
import { ReactNode, useMemo } from 'react';
import CustomDataGrid from './styledDatagrid';

// Define the most basic allowed structure
// The issue below force to use the 'any' type here
// Because React can't render an 'unknown' value
// and R can't satisfy easily an exhaustive value according
export type BaseRow = Record<string, any>;

// Object that define all column/cell options
// Todo: Typescript throw an error when R is given to this interface
// then applyed on the 'row' parameter of 'render' method.
// The error appear on the component 'rows' props
interface InventoryColumn {
  key: string;
  label?: (field: string) => string;
  render?: (row: BaseRow) => ReactNode;
  renderHeader?: (field: string) => ReactNode;
  isSortable?: (field: string) => boolean;
  isFlex?: (field: string) => boolean;
  width?: (field: string) => number;
  minWidth?: (field: string) => number;
  maxWidth?: (field: string) => number;
  editable?: (field: string) => boolean;
}

// Component props
export interface InventoryProps<R extends BaseRow> {
  rows: R[];
  columns: InventoryColumn[];
  onClickRow?: (id: string, cellField: string) => void;
  onSort?: (key: string, state: 'asc' | 'desc' | null | undefined) => void;
  isStaticData?: boolean;
  size?: 'compact' | 'standard' | 'comfortable' | 'auto';
  showColumnMenu?: boolean;
  noBorder?: boolean;
  isLoading?: boolean;
  localeText?: Partial<GridLocaleText>;
}

function Inventory<R extends BaseRow>(props: InventoryProps<R>): JSX.Element {
  const {
    rows,
    columns,
    onClickRow,
    onSort,
    isStaticData,
    size = 'standard',
    showColumnMenu = false,
    isLoading = false,
    localeText,
  } = props;

  const dataGridColumns: GridColDef[] = columns.map((column) => {
    const baseDef: GridColDef = {
      field: column.key.toString(),
      flex: undefined,
      renderHeader: (params) => {
        if (column.renderHeader) {
          return column.renderHeader(params.field);
        }
        return <>{params.field}</>;
      },
      renderCell: (params) => {
        if (column.render) {
          return column.render(params.row);
        }
        return <>{params.formattedValue}</>;
      },
    };

    if (column.isFlex) {
      if (column.isFlex(column.key)) {
        baseDef.flex = 1;
      }
    }

    if (column.isSortable) {
      baseDef.sortable = column.isSortable(column.key);
    }

    if (column.label) {
      baseDef.headerName = column.label(column.key);
    }

    if (column.editable) {
      baseDef.editable = column.editable(column.key);
    }

    if (!column.isFlex && column.width) {
      baseDef.width = column.width(column.key);
    }

    if (column.minWidth) {
      baseDef.minWidth = column.minWidth(column.key);
    }

    if (column.maxWidth) {
      baseDef.maxWidth = column.maxWidth(column.key);
    }

    return baseDef;
  });

  const density = useMemo(() => {
    if (size === 'auto') {
      return 'standard';
    }
    return size;
  }, [size]);

  return (
    <CustomDataGrid
      rows={rows}
      columns={dataGridColumns}
      onCellClick={({ field, row }) => {
        if (onClickRow) {
          const rowId = row.id.toString();
          onClickRow(rowId, field);
        }
      }}
      columnHeaderHeight={64}
      density={density}
      hideFooter
      rowSelection={onClickRow !== undefined}
      disableRowSelectionOnClick={onClickRow === undefined}
      disableColumnFilter={!isStaticData}
      disableColumnMenu={!showColumnMenu}
      sortingMode={isStaticData ? 'client' : 'server'}
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      loading={isLoading}
      autoHeight
      getRowHeight={({ densityFactor }) => {
        if (props.size === 'auto') {
          return 'auto';
        }
        return 100 * densityFactor;
      }}
      onSortModelChange={(model) => {
        if (onSort) {
          if (model && model.length) {
            const field = model[0].field;
            const state = model[0].sort;

            if (field && state) {
              onSort(field, state);
            }
          }
        }
      }}
      // source: https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
      localeText={localeText}
    />
  );
}

export default Inventory;
