import { ReactNode } from 'react';
import ButtonCreate from './buttonCreate';
import ButtonBase from './buttonBase';
import ButtonSivin from './buttonSivin';
import ButtonBackNavigation from './buttonBackNavigation';

export interface BaseButtonProps {
  label?: ReactNode;
}

export default {
  Custom: ButtonBase,
  Create: ButtonCreate,
  Sivin: ButtonSivin,
  BackNavigate: ButtonBackNavigation,
};
