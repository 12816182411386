import StateManager, {
  StateManagerGetState,
  StateManagerSetState,
  StateManagerSubscribeState,
} from './store.port';
import { type CoreAdapters } from 'core/adapters';
import { type Subscriptions } from 'core/services/events/events.service';
import { LegalEntity, LegalEntitySettings } from 'src/core/entities/legalEntity.entity';
import { Guest, User } from 'src/core/entities/user.entity';

export interface AppState {
  auth: {
    isLogged: boolean;
    token?: string;
    expiresAt?: number;
    updatedAt?: number;
    TTLTimer?: ReturnType<typeof setTimeout>;
  };
  user: {
    current?: User;
    guest?: Guest;
  };
  event: {
    subscriptions: Subscriptions;
  };
  legalEntity: {
    current?: LegalEntity;
    settings?: LegalEntitySettings;
  };
}

export class AppStore {
  private appStoreClient: StateManager<AppState>;

  constructor(private adapters: CoreAdapters) {
    this.appStoreClient = this.adapters.appStoreClient;
  }

  getState: StateManagerGetState<AppState> = (handler) => {
    return this.appStoreClient.getState(handler);
  };

  setState: StateManagerSetState<AppState> = (handler) => {
    return this.appStoreClient.setState(handler);
  };

  subscribe: StateManagerSubscribeState<AppState> = (handler) => {
    return this.appStoreClient.subscribe(handler);
  };

  reset() {
    return this.appStoreClient.reset();
  }
}

export default AppStore;
