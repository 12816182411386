export type Country = {
  id?: number;
  alpha2: string;
  alpha3?: string;
  nameFr: string;
  nameEn: string;
};

const countries: Country[] = [
  { id: 4, alpha2: 'af', alpha3: 'afg', nameFr: 'Afghanistan', nameEn: 'Afghanistan' },
  { id: 710, alpha2: 'za', alpha3: 'zaf', nameFr: 'Afrique du Sud', nameEn: 'South Africa' },
  { id: 248, alpha2: 'ax', alpha3: 'ala', nameFr: 'Îles Åland', nameEn: 'Åland Islands' },
  { id: 8, alpha2: 'al', alpha3: 'alb', nameFr: 'Albanie', nameEn: 'Albania' },
  { id: 12, alpha2: 'dz', alpha3: 'dza', nameFr: 'Algérie', nameEn: 'Algeria' },
  { id: 276, alpha2: 'de', alpha3: 'deu', nameFr: 'Allemagne', nameEn: 'Germany' },
  { id: 20, alpha2: 'ad', alpha3: 'and', nameFr: 'Andorre', nameEn: 'Andorra' },
  { id: 24, alpha2: 'ao', alpha3: 'ago', nameFr: 'Angola', nameEn: 'Angola' },
  { id: 660, alpha2: 'ai', alpha3: 'aia', nameFr: 'Anguilla', nameEn: 'Anguilla' },
  { id: 10, alpha2: 'aq', alpha3: 'ata', nameFr: 'Antarctique', nameEn: 'Antarctica' },
  {
    id: 28,
    alpha2: 'ag',
    alpha3: 'atg',
    nameFr: 'Antigua-et-Barbuda',
    nameEn: 'Antigua and Barbuda',
  },
  { id: 682, alpha2: 'sa', alpha3: 'sau', nameFr: 'Arabie saoudite', nameEn: 'Saudi Arabia' },
  { id: 32, alpha2: 'ar', alpha3: 'arg', nameFr: 'Argentine', nameEn: 'Argentina' },
  { id: 51, alpha2: 'am', alpha3: 'arm', nameFr: 'Arménie', nameEn: 'Armenia' },
  { id: 533, alpha2: 'aw', alpha3: 'abw', nameFr: 'Aruba', nameEn: 'Aruba' },
  { id: 36, alpha2: 'au', alpha3: 'aus', nameFr: 'Australie', nameEn: 'Australia' },
  { id: 40, alpha2: 'at', alpha3: 'aut', nameFr: 'Autriche', nameEn: 'Austria' },
  { id: 31, alpha2: 'az', alpha3: 'aze', nameFr: 'Azerbaïdjan', nameEn: 'Azerbaijan' },
  { id: 44, alpha2: 'bs', alpha3: 'bhs', nameFr: 'Bahamas', nameEn: 'Bahamas' },
  { id: 48, alpha2: 'bh', alpha3: 'bhr', nameFr: 'Bahreïn', nameEn: 'Bahrain' },
  { id: 50, alpha2: 'bd', alpha3: 'bgd', nameFr: 'Bangladesh', nameEn: 'Bangladesh' },
  { id: 52, alpha2: 'bb', alpha3: 'brb', nameFr: 'Barbade', nameEn: 'Barbados' },
  { id: 112, alpha2: 'by', alpha3: 'blr', nameFr: 'Biélorussie', nameEn: 'Belarus' },
  { id: 56, alpha2: 'be', alpha3: 'bel', nameFr: 'Belgique', nameEn: 'Belgium' },
  { id: 84, alpha2: 'bz', alpha3: 'blz', nameFr: 'Belize', nameEn: 'Belize' },
  { id: 204, alpha2: 'bj', alpha3: 'ben', nameFr: 'Bénin', nameEn: 'Benin' },
  { id: 60, alpha2: 'bm', alpha3: 'bmu', nameFr: 'Bermudes', nameEn: 'Bermuda' },
  { id: 64, alpha2: 'bt', alpha3: 'btn', nameFr: 'Bhoutan', nameEn: 'Bhutan' },
  { id: 68, alpha2: 'bo', alpha3: 'bol', nameFr: 'Bolivie', nameEn: 'Bolivia' },
  {
    id: 535,
    alpha2: 'bq',
    alpha3: 'bes',
    nameFr: 'Pays-Bas caribéens',
    nameEn: 'Caribbean Netherlands',
  },
  {
    id: 70,
    alpha2: 'ba',
    alpha3: 'bih',
    nameFr: 'Bosnie-Herzégovine',
    nameEn: 'Bosnia and Herzegovina',
  },
  { id: 72, alpha2: 'bw', alpha3: 'bwa', nameFr: 'Botswana', nameEn: 'Botswana' },
  { id: 74, alpha2: 'bv', alpha3: 'bvt', nameFr: 'Île Bouvet', nameEn: 'Bouvet Island' },
  { id: 76, alpha2: 'br', alpha3: 'bra', nameFr: 'Brésil', nameEn: 'Brazil' },
  { id: 96, alpha2: 'bn', alpha3: 'brn', nameFr: 'Brunei', nameEn: 'Brunei' },
  { id: 100, alpha2: 'bg', alpha3: 'bgr', nameFr: 'Bulgarie', nameEn: 'Bulgaria' },
  { id: 854, alpha2: 'bf', alpha3: 'bfa', nameFr: 'Burkina Faso', nameEn: 'Burkina Faso' },
  { id: 108, alpha2: 'bi', alpha3: 'bdi', nameFr: 'Burundi', nameEn: 'Burundi' },
  { id: 136, alpha2: 'ky', alpha3: 'cym', nameFr: 'Îles Caïmans', nameEn: 'Cayman Islands' },
  { id: 116, alpha2: 'kh', alpha3: 'khm', nameFr: 'Cambodge', nameEn: 'Cambodia' },
  { id: 120, alpha2: 'cm', alpha3: 'cmr', nameFr: 'Cameroun', nameEn: 'Cameroon' },
  { id: 124, alpha2: 'ca', alpha3: 'can', nameFr: 'Canada', nameEn: 'Canada' },
  { id: 132, alpha2: 'cv', alpha3: 'cpv', nameFr: 'Cap-Vert', nameEn: 'Cape Verde' },
  {
    id: 140,
    alpha2: 'cf',
    alpha3: 'caf',
    nameFr: 'République centrafricaine',
    nameEn: 'Central African Republic',
  },
  { id: 152, alpha2: 'cl', alpha3: 'chl', nameFr: 'Chili', nameEn: 'Chile' },
  { id: 156, alpha2: 'cn', alpha3: 'chn', nameFr: 'Chine', nameEn: 'China' },
  { id: 162, alpha2: 'cx', alpha3: 'cxr', nameFr: 'Île Christmas', nameEn: 'Christmas Island' },
  { id: 196, alpha2: 'cy', alpha3: 'cyp', nameFr: 'Chypre', nameEn: 'Cyprus' },
  { id: 166, alpha2: 'cc', alpha3: 'cck', nameFr: 'Îles Cocos', nameEn: 'Cocos (Keeling) Islands' },
  { id: 170, alpha2: 'co', alpha3: 'col', nameFr: 'Colombie', nameEn: 'Colombia' },
  { id: 174, alpha2: 'km', alpha3: 'com', nameFr: 'Comores', nameEn: 'Comoros' },
  {
    id: 178,
    alpha2: 'cg',
    alpha3: 'cog',
    nameFr: 'République du Congo',
    nameEn: 'Republic of the Congo',
  },
  {
    id: 180,
    alpha2: 'cd',
    alpha3: 'cod',
    nameFr: 'République démocratique du Congo',
    nameEn: 'Democratic Republic of the Congo',
  },
  { id: 184, alpha2: 'ck', alpha3: 'cok', nameFr: 'Îles Cook', nameEn: 'Cook Islands' },
  { id: 410, alpha2: 'kr', alpha3: 'kor', nameFr: 'Corée du Sud', nameEn: 'South Korea' },
  { id: 408, alpha2: 'kp', alpha3: 'prk', nameFr: 'Corée du Nord', nameEn: 'North Korea' },
  { id: 188, alpha2: 'cr', alpha3: 'cri', nameFr: 'Costa Rica', nameEn: 'Costa Rica' },
  { id: 384, alpha2: 'ci', alpha3: 'civ', nameFr: "Côte d'Ivoire", nameEn: 'Ivory Coast' },
  { id: 191, alpha2: 'hr', alpha3: 'hrv', nameFr: 'Croatie', nameEn: 'Croatia' },
  { id: 192, alpha2: 'cu', alpha3: 'cub', nameFr: 'Cuba', nameEn: 'Cuba' },
  { id: 531, alpha2: 'cw', alpha3: 'cuw', nameFr: 'Curaçao', nameEn: 'Curaçao' },
  { id: 208, alpha2: 'dk', alpha3: 'dnk', nameFr: 'Danemark', nameEn: 'Denmark' },
  { id: 262, alpha2: 'dj', alpha3: 'dji', nameFr: 'Djibouti', nameEn: 'Djibouti' },
  {
    id: 214,
    alpha2: 'do',
    alpha3: 'dom',
    nameFr: 'République dominicaine',
    nameEn: 'Dominican Republic',
  },
  { id: 212, alpha2: 'dm', alpha3: 'dma', nameFr: 'Dominique', nameEn: 'Dominica' },
  { id: 818, alpha2: 'eg', alpha3: 'egy', nameFr: 'Égypte', nameEn: 'Egypt' },
  { id: 222, alpha2: 'sv', alpha3: 'slv', nameFr: 'Salvador', nameEn: 'El Salvador' },
  {
    id: 784,
    alpha2: 'ae',
    alpha3: 'are',
    nameFr: 'Émirats arabes unis',
    nameEn: 'United Arab Emirates',
  },
  { id: 218, alpha2: 'ec', alpha3: 'ecu', nameFr: 'Équateur', nameEn: 'Ecuador' },
  { id: 232, alpha2: 'er', alpha3: 'eri', nameFr: 'Érythrée', nameEn: 'Eritrea' },
  { id: 724, alpha2: 'es', alpha3: 'esp', nameFr: 'Espagne', nameEn: 'Spain' },
  { id: 233, alpha2: 'ee', alpha3: 'est', nameFr: 'Estonie', nameEn: 'Estonia' },
  { id: 840, alpha2: 'us', alpha3: 'usa', nameFr: 'États-Unis', nameEn: 'United States' },
  { id: 231, alpha2: 'et', alpha3: 'eth', nameFr: 'Éthiopie', nameEn: 'Ethiopia' },
  { id: 238, alpha2: 'fk', alpha3: 'flk', nameFr: 'Malouines', nameEn: 'Falkland Islands' },
  { id: 234, alpha2: 'fo', alpha3: 'fro', nameFr: 'Îles Féroé', nameEn: 'Faroe Islands' },
  { id: 242, alpha2: 'fj', alpha3: 'fji', nameFr: 'Fidji', nameEn: 'Fiji' },
  { id: 246, alpha2: 'fi', alpha3: 'fin', nameFr: 'Finlande', nameEn: 'Finland' },
  { id: 250, alpha2: 'fr', alpha3: 'fra', nameFr: 'France', nameEn: 'France' },
  { id: 266, alpha2: 'ga', alpha3: 'gab', nameFr: 'Gabon', nameEn: 'Gabon' },
  { id: 270, alpha2: 'gm', alpha3: 'gmb', nameFr: 'Gambie', nameEn: 'Gambia' },
  { id: 268, alpha2: 'ge', alpha3: 'geo', nameFr: 'Géorgie', nameEn: 'Georgia' },
  {
    id: 239,
    alpha2: 'gs',
    alpha3: 'sgs',
    nameFr: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    nameEn: 'South Georgia and the South Sandwich Islands',
  },
  { id: 288, alpha2: 'gh', alpha3: 'gha', nameFr: 'Ghana', nameEn: 'Ghana' },
  { id: 292, alpha2: 'gi', alpha3: 'gib', nameFr: 'Gibraltar', nameEn: 'Gibraltar' },
  { id: 300, alpha2: 'gr', alpha3: 'grc', nameFr: 'Grèce', nameEn: 'Greece' },
  { id: 308, alpha2: 'gd', alpha3: 'grd', nameFr: 'Grenade', nameEn: 'Grenada' },
  { id: 304, alpha2: 'gl', alpha3: 'grl', nameFr: 'Groenland', nameEn: 'Greenland' },
  { id: 312, alpha2: 'gp', alpha3: 'glp', nameFr: 'Guadeloupe', nameEn: 'Guadeloupe' },
  { id: 316, alpha2: 'gu', alpha3: 'gum', nameFr: 'Guam', nameEn: 'Guam' },
  { id: 320, alpha2: 'gt', alpha3: 'gtm', nameFr: 'Guatemala', nameEn: 'Guatemala' },
  { id: 831, alpha2: 'gg', alpha3: 'ggy', nameFr: 'Guernesey', nameEn: 'Guernsey' },
  { id: 324, alpha2: 'gn', alpha3: 'gin', nameFr: 'Guinée', nameEn: 'Guinea' },
  { id: 624, alpha2: 'gw', alpha3: 'gnb', nameFr: 'Guinée-Bissau', nameEn: 'Guinea-Bissau' },
  {
    id: 226,
    alpha2: 'gq',
    alpha3: 'gnq',
    nameFr: 'Guinée équatoriale',
    nameEn: 'Equatorial Guinea',
  },
  { id: 328, alpha2: 'gy', alpha3: 'guy', nameFr: 'Guyana', nameEn: 'Guyana' },
  { id: 254, alpha2: 'gf', alpha3: 'guf', nameFr: 'Guyane', nameEn: 'French Guiana' },
  { id: 332, alpha2: 'ht', alpha3: 'hti', nameFr: 'Haïti', nameEn: 'Haiti' },
  {
    id: 334,
    alpha2: 'hm',
    alpha3: 'hmd',
    nameFr: 'Îles Heard-et-MacDonald',
    nameEn: 'Heard Island and McDonald Islands',
  },
  { id: 340, alpha2: 'hn', alpha3: 'hnd', nameFr: 'Honduras', nameEn: 'Honduras' },
  { id: 344, alpha2: 'hk', alpha3: 'hkg', nameFr: 'Hong Kong', nameEn: 'Hong Kong' },
  { id: 348, alpha2: 'hu', alpha3: 'hun', nameFr: 'Hongrie', nameEn: 'Hungary' },
  { id: 833, alpha2: 'im', alpha3: 'imn', nameFr: 'Île de Man', nameEn: 'Isle of Man' },
  {
    id: 581,
    alpha2: 'um',
    alpha3: 'umi',
    nameFr: 'Îles mineures éloignées des États-Unis',
    nameEn: 'United States Minor Outlying Islands',
  },
  {
    id: 92,
    alpha2: 'vg',
    alpha3: 'vgb',
    nameFr: 'Îles Vierges britanniques',
    nameEn: 'British Virgin Islands',
  },
  {
    id: 850,
    alpha2: 'vi',
    alpha3: 'vir',
    nameFr: 'Îles Vierges des États-Unis',
    nameEn: 'U.S. Virgin Islands',
  },
  { id: 356, alpha2: 'in', alpha3: 'ind', nameFr: 'Inde', nameEn: 'India' },
  { id: 360, alpha2: 'id', alpha3: 'idn', nameFr: 'Indonésie', nameEn: 'Indonesia' },
  { id: 364, alpha2: 'ir', alpha3: 'irn', nameFr: 'Iran', nameEn: 'Iran' },
  { id: 368, alpha2: 'iq', alpha3: 'irq', nameFr: 'Irak', nameEn: 'Iraq' },
  { id: 372, alpha2: 'ie', alpha3: 'irl', nameFr: 'Irlande', nameEn: 'Ireland' },
  { id: 352, alpha2: 'is', alpha3: 'isl', nameFr: 'Islande', nameEn: 'Iceland' },
  { id: 376, alpha2: 'il', alpha3: 'isr', nameFr: 'Israël', nameEn: 'Israel' },
  { id: 380, alpha2: 'it', alpha3: 'ita', nameFr: 'Italie', nameEn: 'Italy' },
  { id: 388, alpha2: 'jm', alpha3: 'jam', nameFr: 'Jamaïque', nameEn: 'Jamaica' },
  { id: 392, alpha2: 'jp', alpha3: 'jpn', nameFr: 'Japon', nameEn: 'Japan' },
  { id: 832, alpha2: 'je', alpha3: 'jey', nameFr: 'Jersey', nameEn: 'Jersey' },
  { id: 400, alpha2: 'jo', alpha3: 'jor', nameFr: 'Jordanie', nameEn: 'Jordan' },
  { id: 398, alpha2: 'kz', alpha3: 'kaz', nameFr: 'Kazakhstan', nameEn: 'Kazakhstan' },
  { id: 404, alpha2: 'ke', alpha3: 'ken', nameFr: 'Kenya', nameEn: 'Kenya' },
  { id: 417, alpha2: 'kg', alpha3: 'kgz', nameFr: 'Kirghizistan', nameEn: 'Kyrgyzstan' },
  { id: 296, alpha2: 'ki', alpha3: 'kir', nameFr: 'Kiribati', nameEn: 'Kiribati' },
  { id: 414, alpha2: 'kw', alpha3: 'kwt', nameFr: 'Koweït', nameEn: 'Kuwait' },
  { id: 418, alpha2: 'la', alpha3: 'lao', nameFr: 'Laos', nameEn: 'Laos' },
  { id: 426, alpha2: 'ls', alpha3: 'lso', nameFr: 'Lesotho', nameEn: 'Lesotho' },
  { id: 428, alpha2: 'lv', alpha3: 'lva', nameFr: 'Lettonie', nameEn: 'Latvia' },
  { id: 422, alpha2: 'lb', alpha3: 'lbn', nameFr: 'Liban', nameEn: 'Lebanon' },
  { id: 430, alpha2: 'lr', alpha3: 'lbr', nameFr: 'Liberia', nameEn: 'Liberia' },
  { id: 434, alpha2: 'ly', alpha3: 'lby', nameFr: 'Libye', nameEn: 'Libya' },
  { id: 438, alpha2: 'li', alpha3: 'lie', nameFr: 'Liechtenstein', nameEn: 'Liechtenstein' },
  { id: 440, alpha2: 'lt', alpha3: 'ltu', nameFr: 'Lituanie', nameEn: 'Lithuania' },
  { id: 442, alpha2: 'lu', alpha3: 'lux', nameFr: 'Luxembourg', nameEn: 'Luxembourg' },
  { id: 446, alpha2: 'mo', alpha3: 'mac', nameFr: 'Macao', nameEn: 'Macau' },
  { id: 807, alpha2: 'mk', alpha3: 'mkd', nameFr: 'Macédoine du Nord', nameEn: 'North Macedonia' },
  { id: 450, alpha2: 'mg', alpha3: 'mdg', nameFr: 'Madagascar', nameEn: 'Madagascar' },
  { id: 458, alpha2: 'my', alpha3: 'mys', nameFr: 'Malaisie', nameEn: 'Malaysia' },
  { id: 454, alpha2: 'mw', alpha3: 'mwi', nameFr: 'Malawi', nameEn: 'Malawi' },
  { id: 462, alpha2: 'mv', alpha3: 'mdv', nameFr: 'Maldives', nameEn: 'Maldives' },
  { id: 466, alpha2: 'ml', alpha3: 'mli', nameFr: 'Mali', nameEn: 'Mali' },
  { id: 470, alpha2: 'mt', alpha3: 'mlt', nameFr: 'Malte', nameEn: 'Malta' },
  {
    id: 580,
    alpha2: 'mp',
    alpha3: 'mnp',
    nameFr: 'Îles Mariannes du Nord',
    nameEn: 'Northern Mariana Islands',
  },
  { id: 504, alpha2: 'ma', alpha3: 'mar', nameFr: 'Maroc', nameEn: 'Morocco' },
  { id: 584, alpha2: 'mh', alpha3: 'mhl', nameFr: 'Îles Marshall', nameEn: 'Marshall Islands' },
  { id: 474, alpha2: 'mq', alpha3: 'mtq', nameFr: 'Martinique', nameEn: 'Martinique' },
  { id: 480, alpha2: 'mu', alpha3: 'mus', nameFr: 'Maurice', nameEn: 'Mauritius' },
  { id: 478, alpha2: 'mr', alpha3: 'mrt', nameFr: 'Mauritanie', nameEn: 'Mauritania' },
  { id: 175, alpha2: 'yt', alpha3: 'myt', nameFr: 'Mayotte', nameEn: 'Mayotte' },
  { id: 484, alpha2: 'mx', alpha3: 'mex', nameFr: 'Mexique', nameEn: 'Mexico' },
  {
    id: 583,
    alpha2: 'fm',
    alpha3: 'fsm',
    nameFr: 'États fédérés de Micronésie',
    nameEn: 'Federated States of Micronesia',
  },
  { id: 498, alpha2: 'md', alpha3: 'mda', nameFr: 'Moldavie', nameEn: 'Moldova' },
  { id: 492, alpha2: 'mc', alpha3: 'mco', nameFr: 'Monaco', nameEn: 'Monaco' },
  { id: 496, alpha2: 'mn', alpha3: 'mng', nameFr: 'Mongolie', nameEn: 'Mongolia' },
  { id: 499, alpha2: 'me', alpha3: 'mne', nameFr: 'Monténégro', nameEn: 'Montenegro' },
  { id: 500, alpha2: 'ms', alpha3: 'msr', nameFr: 'Montserrat', nameEn: 'Montserrat' },
  { id: 508, alpha2: 'mz', alpha3: 'moz', nameFr: 'Mozambique', nameEn: 'Mozambique' },
  { id: 104, alpha2: 'mm', alpha3: 'mmr', nameFr: 'Birmanie', nameEn: 'Myanmar' },
  { id: 516, alpha2: 'na', alpha3: 'nam', nameFr: 'Namibie', nameEn: 'Namibia' },
  { id: 520, alpha2: 'nr', alpha3: 'nru', nameFr: 'Nauru', nameEn: 'Nauru' },
  { id: 524, alpha2: 'np', alpha3: 'npl', nameFr: 'Népal', nameEn: 'Nepal' },
  { id: 558, alpha2: 'ni', alpha3: 'nic', nameFr: 'Nicaragua', nameEn: 'Nicaragua' },
  { id: 562, alpha2: 'ne', alpha3: 'ner', nameFr: 'Niger', nameEn: 'Niger' },
  { id: 566, alpha2: 'ng', alpha3: 'nga', nameFr: 'Nigeria', nameEn: 'Nigeria' },
  { id: 570, alpha2: 'nu', alpha3: 'niu', nameFr: 'Niue', nameEn: 'Niue' },
  { id: 574, alpha2: 'nf', alpha3: 'nfk', nameFr: 'Île Norfolk', nameEn: 'Norfolk Island' },
  { id: 578, alpha2: 'no', alpha3: 'nor', nameFr: 'Norvège', nameEn: 'Norway' },
  { id: 540, alpha2: 'nc', alpha3: 'ncl', nameFr: 'Nouvelle-Calédonie', nameEn: 'New Caledonia' },
  { id: 554, alpha2: 'nz', alpha3: 'nzl', nameFr: 'Nouvelle-Zélande', nameEn: 'New Zealand' },
  {
    id: 86,
    alpha2: 'io',
    alpha3: 'iot',
    nameFr: "Territoire britannique de l'océan Indien",
    nameEn: 'British Indian Ocean Territory',
  },
  { id: 512, alpha2: 'om', alpha3: 'omn', nameFr: 'Oman', nameEn: 'Oman' },
  { id: 800, alpha2: 'ug', alpha3: 'uga', nameFr: 'Ouganda', nameEn: 'Uganda' },
  { id: 860, alpha2: 'uz', alpha3: 'uzb', nameFr: 'Ouzbékistan', nameEn: 'Uzbekistan' },
  { id: 586, alpha2: 'pk', alpha3: 'pak', nameFr: 'Pakistan', nameEn: 'Pakistan' },
  { id: 585, alpha2: 'pw', alpha3: 'plw', nameFr: 'Palaos', nameEn: 'Palau' },
  { id: 275, alpha2: 'ps', alpha3: 'pse', nameFr: 'Palestine', nameEn: 'Palestine' },
  { id: 591, alpha2: 'pa', alpha3: 'pan', nameFr: 'Panama', nameEn: 'Panama' },
  {
    id: 598,
    alpha2: 'pg',
    alpha3: 'png',
    nameFr: 'Papouasie-Nouvelle-Guinée',
    nameEn: 'Papua New Guinea',
  },
  { id: 600, alpha2: 'py', alpha3: 'pry', nameFr: 'Paraguay', nameEn: 'Paraguay' },
  { id: 528, alpha2: 'nl', alpha3: 'nld', nameFr: 'Pays-Bas', nameEn: 'Netherlands' },
  { id: 604, alpha2: 'pe', alpha3: 'per', nameFr: 'Pérou', nameEn: 'Peru' },
  { id: 608, alpha2: 'ph', alpha3: 'phl', nameFr: 'Philippines', nameEn: 'Philippines' },
  { id: 612, alpha2: 'pn', alpha3: 'pcn', nameFr: 'Îles Pitcairn', nameEn: 'Pitcairn Islands' },
  { id: 616, alpha2: 'pl', alpha3: 'pol', nameFr: 'Pologne', nameEn: 'Poland' },
  {
    id: 258,
    alpha2: 'pf',
    alpha3: 'pyf',
    nameFr: 'Polynésie française',
    nameEn: 'French Polynesia',
  },
  { id: 630, alpha2: 'pr', alpha3: 'pri', nameFr: 'Porto Rico', nameEn: 'Puerto Rico' },
  { id: 620, alpha2: 'pt', alpha3: 'prt', nameFr: 'Portugal', nameEn: 'Portugal' },
  { id: 634, alpha2: 'qa', alpha3: 'qat', nameFr: 'Qatar', nameEn: 'Qatar' },
  { id: 638, alpha2: 're', alpha3: 'reu', nameFr: 'La Réunion', nameEn: 'Réunion' },
  { id: 642, alpha2: 'ro', alpha3: 'rou', nameFr: 'Roumanie', nameEn: 'Romania' },
  { id: 826, alpha2: 'gb', alpha3: 'gbr', nameFr: 'Royaume-Uni', nameEn: 'United Kingdom' },
  { id: 643, alpha2: 'ru', alpha3: 'rus', nameFr: 'Russie', nameEn: 'Russia' },
  { id: 646, alpha2: 'rw', alpha3: 'rwa', nameFr: 'Rwanda', nameEn: 'Rwanda' },
  {
    id: 732,
    alpha2: 'eh',
    alpha3: 'esh',
    nameFr: 'République arabe sahraouie démocratique',
    nameEn: 'Sahrawi Arab Democratic Republic',
  },
  { id: 652, alpha2: 'bl', alpha3: 'blm', nameFr: 'Saint-Barthélemy', nameEn: 'Saint Barthélemy' },
  {
    id: 659,
    alpha2: 'kn',
    alpha3: 'kna',
    nameFr: 'Saint-Christophe-et-Niévès',
    nameEn: 'Saint Kitts and Nevis',
  },
  { id: 674, alpha2: 'sm', alpha3: 'smr', nameFr: 'Saint-Marin', nameEn: 'San Marino' },
  {
    id: 663,
    alpha2: 'mf',
    alpha3: 'maf',
    nameFr: 'Saint-Martin (FR)',
    nameEn: 'Saint Martin (French part)',
  },
  {
    id: 534,
    alpha2: 'sx',
    alpha3: 'sxm',
    nameFr: 'Saint-Martin (NL)',
    nameEn: 'Sint Maarten (Dutch part)',
  },
  {
    id: 666,
    alpha2: 'pm',
    alpha3: 'spm',
    nameFr: 'Saint-Pierre-et-Miquelon',
    nameEn: 'Saint Pierre and Miquelon',
  },
  {
    id: 336,
    alpha2: 'va',
    alpha3: 'vat',
    nameFr: 'Saint-Siège (État de la Cité du Vatican)',
    nameEn: 'Vatican City',
  },
  {
    id: 670,
    alpha2: 'vc',
    alpha3: 'vct',
    nameFr: 'Saint-Vincent-et-les-Grenadines',
    nameEn: 'Saint Vincent and the Grenadines',
  },
  {
    id: 654,
    alpha2: 'sh',
    alpha3: 'shn',
    nameFr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    nameEn: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { id: 662, alpha2: 'lc', alpha3: 'lca', nameFr: 'Sainte-Lucie', nameEn: 'Saint Lucia' },
  { id: 90, alpha2: 'sb', alpha3: 'slb', nameFr: 'Îles Salomon', nameEn: 'Solomon Islands' },
  { id: 882, alpha2: 'ws', alpha3: 'wsm', nameFr: 'Samoa', nameEn: 'Samoa' },
  { id: 16, alpha2: 'as', alpha3: 'asm', nameFr: 'Samoa américaines', nameEn: 'American Samoa' },
  {
    id: 678,
    alpha2: 'st',
    alpha3: 'stp',
    nameFr: 'Sao Tomé-et-Principe',
    nameEn: 'Sao Tome and Principe',
  },
  { id: 686, alpha2: 'sn', alpha3: 'sen', nameFr: 'Sénégal', nameEn: 'Senegal' },
  { id: 688, alpha2: 'rs', alpha3: 'srb', nameFr: 'Serbie', nameEn: 'Serbia' },
  { id: 690, alpha2: 'sc', alpha3: 'syc', nameFr: 'Seychelles', nameEn: 'Seychelles' },
  { id: 694, alpha2: 'sl', alpha3: 'sle', nameFr: 'Sierra Leone', nameEn: 'Sierra Leone' },
  { id: 702, alpha2: 'sg', alpha3: 'sgp', nameFr: 'Singapour', nameEn: 'Singapore' },
  { id: 703, alpha2: 'sk', alpha3: 'svk', nameFr: 'Slovaquie', nameEn: 'Slovakia' },
  { id: 705, alpha2: 'si', alpha3: 'svn', nameFr: 'Slovénie', nameEn: 'Slovenia' },
  { id: 706, alpha2: 'so', alpha3: 'som', nameFr: 'Somalie', nameEn: 'Somalia' },
  { id: 729, alpha2: 'sd', alpha3: 'sdn', nameFr: 'Soudan', nameEn: 'Sudan' },
  { id: 728, alpha2: 'ss', alpha3: 'ssd', nameFr: 'Soudan du Sud', nameEn: 'South Sudan' },
  { id: 144, alpha2: 'lk', alpha3: 'lka', nameFr: 'Sri Lanka', nameEn: 'Sri Lanka' },
  { id: 752, alpha2: 'se', alpha3: 'swe', nameFr: 'Suède', nameEn: 'Sweden' },
  { id: 756, alpha2: 'ch', alpha3: 'che', nameFr: 'Suisse', nameEn: 'Switzerland' },
  { id: 740, alpha2: 'sr', alpha3: 'sur', nameFr: 'Suriname', nameEn: 'Suriname' },
  {
    id: 744,
    alpha2: 'sj',
    alpha3: 'sjm',
    nameFr: 'Svalbard et ile Jan Mayen',
    nameEn: 'Svalbard and Jan Mayen',
  },
  { id: 748, alpha2: 'sz', alpha3: 'swz', nameFr: 'Eswatini', nameEn: 'Eswatini' },
  { id: 760, alpha2: 'sy', alpha3: 'syr', nameFr: 'Syrie', nameEn: 'Syria' },
  { id: 762, alpha2: 'tj', alpha3: 'tjk', nameFr: 'Tadjikistan', nameEn: 'Tajikistan' },
  {
    id: 158,
    alpha2: 'tw',
    alpha3: 'twn',
    nameFr: 'Taïwan / (République de Chine (Taïwan))',
    nameEn: 'Taiwan',
  },
  { id: 834, alpha2: 'tz', alpha3: 'tza', nameFr: 'Tanzanie', nameEn: 'Tanzania' },
  { id: 148, alpha2: 'td', alpha3: 'tcd', nameFr: 'Tchad', nameEn: 'Chad' },
  { id: 203, alpha2: 'cz', alpha3: 'cze', nameFr: 'Tchéquie', nameEn: 'Czech Republic' },
  {
    id: 260,
    alpha2: 'tf',
    alpha3: 'atf',
    nameFr: 'Terres australes et antarctiques françaises',
    nameEn: 'French Southern Territories',
  },
  { id: 764, alpha2: 'th', alpha3: 'tha', nameFr: 'Thaïlande', nameEn: 'Thailand' },
  { id: 626, alpha2: 'tl', alpha3: 'tls', nameFr: 'Timor oriental', nameEn: 'Timor-Leste' },
  { id: 768, alpha2: 'tg', alpha3: 'tgo', nameFr: 'Togo', nameEn: 'Togo' },
  { id: 772, alpha2: 'tk', alpha3: 'tkl', nameFr: 'Tokelau', nameEn: 'Tokelau' },
  { id: 776, alpha2: 'to', alpha3: 'ton', nameFr: 'Tonga', nameEn: 'Tonga' },
  {
    id: 780,
    alpha2: 'tt',
    alpha3: 'tto',
    nameFr: 'Trinité-et-Tobago',
    nameEn: 'Trinidad and Tobago',
  },
  { id: 788, alpha2: 'tn', alpha3: 'tun', nameFr: 'Tunisie', nameEn: 'Tunisia' },
  { id: 795, alpha2: 'tm', alpha3: 'tkm', nameFr: 'Turkménistan', nameEn: 'Turkmenistan' },
  {
    id: 796,
    alpha2: 'tc',
    alpha3: 'tca',
    nameFr: 'Îles Turques-et-Caïques',
    nameEn: 'Turks and Caicos Islands',
  },
  { id: 792, alpha2: 'tr', alpha3: 'tur', nameFr: 'Turquie', nameEn: 'Turkey' },
  { id: 798, alpha2: 'tv', alpha3: 'tuv', nameFr: 'Tuvalu', nameEn: 'Tuvalu' },
  { id: 804, alpha2: 'ua', alpha3: 'ukr', nameFr: 'Ukraine', nameEn: 'Ukraine' },
  { id: 858, alpha2: 'uy', alpha3: 'ury', nameFr: 'Uruguay', nameEn: 'Uruguay' },
  { id: 548, alpha2: 'vu', alpha3: 'vut', nameFr: 'Vanuatu', nameEn: 'Vanuatu' },
  { id: 862, alpha2: 've', alpha3: 'ven', nameFr: 'Venezuela', nameEn: 'Venezuela' },
  { id: 704, alpha2: 'vn', alpha3: 'vnm', nameFr: 'Viêt Nam', nameEn: 'Vietnam' },
  { id: 876, alpha2: 'wf', alpha3: 'wlf', nameFr: 'Wallis-et-Futuna', nameEn: 'Wallis and Futuna' },
  { id: 887, alpha2: 'ye', alpha3: 'yem', nameFr: 'Yémen', nameEn: 'Yemen' },
  { id: 894, alpha2: 'zm', alpha3: 'zmb', nameFr: 'Zambie', nameEn: 'Zambia' },
  { id: 716, alpha2: 'zw', alpha3: 'zwe', nameFr: 'Zimbabwe', nameEn: 'Zimbabwe' },
];

export default countries;
