import { Stack, Toolbar } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';

export interface CardFooterProps {
  className?: string;
  align?: 'start' | 'center' | 'end' | 'between';
  padding?: number;
  spacing?: number;
  compact?: boolean;
}

const DEFAULT_PADDING = 3;
const DEFAULT_PADDING_COMPACT = 1.5;

const DEFAULT_SPACING = 2;
const DEFAULT_SPACING_COMPACT = 1.5;

const CardFooter: FC<PropsWithChildren<CardFooterProps>> = ({
  children,
  className,
  align = 'center',
  spacing,
  compact,
  padding,
}) => {
  const safePadding =
    padding !== undefined ? padding : compact ? DEFAULT_PADDING_COMPACT : DEFAULT_PADDING;
  const safeSpacing =
    spacing !== undefined ? spacing : compact ? DEFAULT_SPACING_COMPACT : DEFAULT_SPACING;
  const variant = compact ? 'dense' : 'regular';
  const justify = useMemo(() => {
    switch (align) {
      case 'between':
        return 'space-between';
      default:
        return align;
    }
  }, [align]);

  return (
    <Toolbar
      variant={variant}
      disableGutters={compact}
      className={className}
      sx={{
        px: safePadding,
      }}
    >
      <Stack
        direction="row"
        justifyContent={justify}
        spacing={safeSpacing}
        sx={{
          width: '100%',
        }}
      >
        {children}
      </Stack>
    </Toolbar>
  );
};

export default CardFooter;
