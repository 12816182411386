import { FC } from 'react';
import { UseSetAssetFormReturn } from '../hooks/commonType';
import Card from 'src/web/components/Card';
import Form from 'src/web/components/FormInput';
import useTranslate from 'src/web/common/translate/useTranslate';
import Displayer from 'src/web/design_system/Displayer';

interface LicenceInfoFormPartialProps {
  form: UseSetAssetFormReturn;
}

const LicenceInfoFormPartial: FC<LicenceInfoFormPartialProps> = ({ form }) => {
  const { translate } = useTranslate();

  return (
    <Card.Basic title={translate('assets.asset.informations.title')}>
      <Displayer innerSpacing={2}>
        <Form.RadioAdapted
          item={form.items.termAndConditionsType}
          label={translate('licences.termAndConditionsType.label')}
          renderOptionLabel={(value) =>
            translate(`licences.termAndConditionsType.option.${value}.label`)
          }
        />

        <Form.TextAdapted
          item={form.items.asset_identification}
          label={translate('licences.asset.identification.label')}
        />

        <Form.TextAdapted
          item={form.items.asset_manufacturer}
          label={translate('licences.asset.manufacturer.label')}
        />

        <Form.TextAdapted item={form.items.version} label={translate('licences.version.label')} />

        <Form.SelectAdapted
          item={form.items.invoicingMethod}
          label={translate('licences.invoicingMethod.label')}
          renderOptionItem={(value) => ({
            label: translate(`licences.invoicingMethod.option.${value}.label`),
          })}
        />

        {/* TODO check jira ticket for a better limit unit */}
        {/* <Grid.Container>
          <Grid.Item>
            <Form.NumberAdapted item={form.items.limit} label={translate('licences.limit.label')} />
          </Grid.Item>
          <Grid.Item>
            <Form.SelectAdapted
              item={form.items.limitUnit}
              label={translate('licences.limitUnit.label')}
              renderOptionItem={(value) => ({
                label: translate(`licences.limitUnit.option.${value}.label`),
              })}
            />
          </Grid.Item>
        </Grid.Container> */}
      </Displayer>
    </Card.Basic>
  );
};

export default LicenceInfoFormPartial;
