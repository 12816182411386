import { FC, PropsWithChildren, useMemo } from 'react';
import MuiSkeleton from '@mui/material/Skeleton';

interface SkeletonProps {
  shape?: 'circle' | 'rectangle' | 'round' | 'text';
  isAnimate?: boolean;
  isLoading?: boolean;
  width?: string;
  height?: string;
}

const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  shape = 'round',
  isAnimate = true,
  isLoading = true,
  width,
  height,
  children,
}) => {
  const getVariant = useMemo(() => {
    switch (shape) {
      case 'circle':
        return 'circular';
      case 'round':
        return 'rounded';
      case 'text':
        return 'text';
      case 'rectangle':
      default:
        return 'rectangular';
    }
  }, [shape]);

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <MuiSkeleton
      variant={getVariant}
      animation={isAnimate === true ? 'wave' : false}
      width={width}
      height={height}
      sx={{
        display: 'inline-block',
      }}
    >
      <div style={{ visibility: 'hidden' }}>{children}</div>
    </MuiSkeleton>
  );
};

export default Skeleton;
